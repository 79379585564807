import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';

import { CapacityTags } from '../capacity-tags/capacityTags.model';
import { ReportingTags } from '../reporting-tags/reportingTags.model';
import { PaymentTags } from '../payment-tags/paymentTags.model';


import { DatabaseService } from '../../services/database.service';
import { PathSetterService } from '../../services/pathSetter.service';
import { TagAddService } from '../tagAdd.service';

import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-add-tags-popup',
  templateUrl: './add-tags-popup.component.html',
  styleUrls: ['./add-tags-popup.component.css']
})
export class AddTagsPopupComponent implements OnInit {

  afdb: AngularFireDatabase;
  afs: AngularFirestore;

  addTagType: string;
  ischecked = true;

  isAmtEditable = false;
  isCmmntRqd = false;
  isCommntEditable = false;
  isPhtEnbld = false;
  isPhtRqd = false;
  isBillRqd = false;


  groupID: string;
  businessList: any[];
  businessID: string;
  businessName: string;
  paymentModifierDisp: string;
  paymentModifierNo: number;

  constructor(public activeModal: NgbActiveModal, private databaseService: DatabaseService, afdb: AngularFireDatabase,
    afs: AngularFirestore, private pathService: PathSetterService, private tagAddService: TagAddService, public snackBar: MatSnackBar) {
    this.afdb = afdb;
    this.afs = afs;

    this.paymentModifierDisp = 'No change to parking charge';
    this.paymentModifierNo = 920;

    this.addTagType = tagAddService.getTagType();
    // console.log(this.addTagType);
    this.setGroupID();
    this.setBusinessList();
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  setBusinessList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setBusinessID(biz) {
    this.businessName = biz.name;
    this.businessID = biz.id;
  }

  setPaymentModifier(paymentmodNo: number) {

    switch (paymentmodNo) {
      case 920: {
        this.paymentModifierDisp = 'No change to parking charge';
        this.paymentModifierNo = 920;
        break;
      }
      case 921: {
        this.paymentModifierDisp = 'Replace parking charge with amount';
        this.paymentModifierNo = 921;
        break;
      }
      case 922: {
        this.paymentModifierDisp = 'Add amount to parking charge';
        this.paymentModifierNo = 922;
        break;
      }
      case 924: {
        this.paymentModifierDisp = 'Exempt on purchase proof';
        this.paymentModifierNo = 924;
        break;
      }
      case 925: {
        this.paymentModifierDisp = 'Exempt on min purchase';
        this.paymentModifierNo = 925;
        break;
      }
      case 926: {
        this.paymentModifierDisp = 'Exempt if purchase exceeds parking charge';
        this.paymentModifierNo = 926;
        break;
      }
      default: {
        // console.log('Invalid choice');
        break;
      }
    }

  }

  ngOnInit() {
  }


  onSubmit(Formdata) {
    // console.log(Formdata);
    this.activeModal.close('Close click');

    if (this.addTagType === 'Capacity') {
      this.addCapacityTags(Formdata);
    }

    if (this.addTagType === 'Reporting') {
      this.addReportingTags(Formdata);
    }

    if (this.addTagType === 'Payment') {
      this.addPaymentTags(Formdata);
    }

  }

  addPaymentTags(Formdata) {

    let itemkey = null;
    if (this.businessID !== null) {


      // Firestore URL
      const paymentTagsCollection = this.afs.collection<PaymentTags>('/groups/' + this.groupID + '/bizs/' + this.businessID +
        '/payment_tags/');

      const minTimeMinutes = 1;
      const maxTimeMinutes = 1;


      Promise.resolve(
        paymentTagsCollection.add({
          exitType: Formdata.tagType,
          editAmount: Formdata.isAmountEditable,
          defaultAmount: Formdata.amt,
          editComment: Formdata.isCommentEditable,
          commentRequired: Formdata.isCommentRequired,
          defaultComment: Formdata.defComment,
          photoEnabled: Formdata.isPhotoEnabled,
          photoRequired: Formdata.isPhotoRequired,
          exitTypeLabel: Formdata.tagLabel,
          minTime: Formdata.minDur,
          maxTime: Formdata.maxDur,
          shoppingBillAmountRequired: Formdata.isShoppingBillRequired,
          amountModifierFlag: this.paymentModifierNo,
          tagId: ''
        })).then((fsitem) => {
          itemkey = fsitem.id;
          paymentTagsCollection.doc(itemkey).update({ tagId: itemkey });

          // Firebase URL
          const itemsRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() +
            '/Setup/' + this.businessID + '/manager_settings/custom_exit_flow/');

          Promise.resolve(
            itemsRef.set(itemkey, {
              exitType: Formdata.tagType,
              editAmount: Formdata.isAmountEditable,
              defaultAmount: Formdata.amt,
              editComment: Formdata.isCommentEditable,
              commentRequired: Formdata.isCommentRequired,
              defaultComment: Formdata.defComment,
              photoEnabled: Formdata.isPhotoEnabled,
              photoRequired: Formdata.isPhotoRequired,
              exitTypeLabel: Formdata.tagLabel,
              minTime: Formdata.minDur,
              maxTime: Formdata.maxDur,
              shoppingBillAmountRequired: Formdata.isShoppingBillRequired,
              amountModifierFlag: this.paymentModifierNo,
              tagId: itemkey
            })
          );

        }).catch(err => {
          console.log(err);
        });
    }
  }
  addReportingTags(Formdata) {
    let itemkey = null;
    if (this.businessID !== null) {
      // Firestore URL
      // Firestore URL
      const reportingTagsCollection = this.afs.collection<ReportingTags>('/groups/' + this.groupID + '/bizs/' + this.businessID +
        '/reporting_tags/');

      Promise.resolve(
        reportingTagsCollection.add({
          affectCounter: Formdata.CapacityModifier,
          selected: false,
          tagColor: -1,
          tagCreatedOn: new Date().getTime(),
          tagId: '',
          tagLabel: Formdata.fullTitle,
        })
      ).then((fsitem) => {
        itemkey = fsitem.id;
        reportingTagsCollection.doc(itemkey).update({ tagId: itemkey });

        // Firebase URL
        const itemsRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() + '/gpDecorativeTags/'
          + this.businessID + '/');

        Promise.resolve(
          itemsRef.set(itemkey, {
            affectCounter: Formdata.CapacityModifier,
            selected: false,
            tagColor: -1,
            tagCreatedOn: new Date().getTime(),
            tagId: itemkey,
            tagLabel: Formdata.fullTitle,
          })
        );
      }).catch(err => {
        console.log(err);
      });
    }
  }


  addCapacityTags(Formdata) {
    let itemkey = null;
    if (this.businessID !== null) {
      // Firestore URL
      const capacityTagsCollection = this.afs.collection<CapacityTags>('/groups/' + this.groupID + '/bizs/' + this.businessID +
        '/corporate_tags/');

      const formVehMap = new Map();
      // Add new elements to the map
      formVehMap.set('2W', Formdata.twoWhlCap);
      formVehMap.set('4W', Formdata.fourWhlCap);

      Promise.resolve(
        capacityTagsCollection.add({
          gpTagCreatedById: '',
          gpTagCreatedByName: 'Dashboard Admin',
          gpTagCreatedOn: new Date().getTime(),
          gpTagCreatedOnSorter: 0 - new Date().getTime(),
          gpTagFullTitle: Formdata.fullTitle,
          gpTagId: '',
          gpTagParkingCapacity: null,
          gpTagTitle: Formdata.shortTitle,
          gpTagType: 'corporate',
          selected: false,
          tagColor: -1
        })
      ).then((fsitem) => {
        itemkey = fsitem.id;
        capacityTagsCollection.doc(itemkey)
          .update({
            gpTagId: itemkey,
            gpTagParkingCapacity: {
              '2W': Formdata.twoWhlCap,
              '4W': Formdata.fourWhlCap
            }
          });

        // Firebase URL
        const itemsRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() + '/gpTags/' + this.businessID + '/');
        Promise.resolve(
          itemsRef.set(itemkey, {
            gpTagCreatedById: '',
            gpTagCreatedByName: 'Dashboard Admin',
            gpTagCreatedOn: new Date().getTime(),
            gpTagCreatedOnSorter: 0 - new Date().getTime(),
            gpTagFullTitle: Formdata.fullTitle,
            gpTagId: itemkey,
            gpTagParkingCapacity: {
              '2W': Formdata.twoWhlCap,
              '4W': Formdata.fourWhlCap
            },
            gpTagTitle: Formdata.shortTitle,
            gpTagType: 'corporate',
            selected: false,
            tagColor: -1
          })
        );

        // Add to Counter Node
        const counterRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() +
        '/Counter/' + this.businessID + '/corporate_park/');
        Promise.resolve(
          counterRef.set(itemkey, {
            'title': Formdata.fullTitle,
            'sTitle': Formdata.shortTitle,
            '2W': {
              'capacity': Formdata.twoWhlCap,
              'occupancy': 0
            },
            '4W': {
              'capacity': Formdata.fourWhlCap,
              'occupancy': 0
            }
          }
          )
        );

        // Added to Counter Database

        const counterRef1 = this.afdb.database.app.database(this.pathService.getOtherPathEnvi())
          .ref('/getParking/' + this.pathService.getPathEnvironment() + '/Counter/' + this.businessID + '/corporate_park/' + itemkey);
        counterRef1.set({
          'title': Formdata.fullTitle,
          'sTitle': Formdata.shortTitle,
          '2W': {
            'capacity': Formdata.twoWhlCap,
            'total': Formdata.twoWhlCap
          },
          '4W': {
            'capacity': Formdata.fourWhlCap,
            'total': Formdata.fourWhlCap
          }
        });
      }).catch(err => {
        console.log(err);
      });
    }

  }
}
