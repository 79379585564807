import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { BehaviorSubject, Observable, Subscription, concat } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatabaseService } from '../services/database.service';
import { PathSetterService } from '../services/pathSetter.service';
import { DownloadService } from '../services/download.service';
import { RtoList } from './rtolist.model';
import * as firebase from 'firebase';


export interface rtoReportList{
a3areaCode: any;
}

@Component({
  selector: 'app-rto-list',
  templateUrl: './rto-list.component.html',
  styleUrls: ['./rto-list.component.css']
})
export class RtoListComponent implements OnInit {
  
  title = 'Demographic report';

  db: AngularFirestore;
  groupID: string;
  StrToday: string;
  businessList: any[];
  businessID: string;
  selectedValue: string;
  businessName: string;
  progressBarVisible = false;
  rearrangedReportArray: any[] = [];
  model: NgbDateStruct;
  dateFilter: BehaviorSubject<number | null>;
  areaVisitorList: Observable<RtoList[]>;
  areaVisitorListSub: Subscription;
  private areaVisitor: AngularFirestoreCollection<RtoList>;

  rtoName: any;
  rtoList: RtoList[];
  private sorted = false;

  custom: string;
  StrYYYY: string;
  StrMM: string;
  StrDate: string;
  areaCounts:  Array<any>;
  isDesc: boolean;
  column: any;


  constructor(private databaseService: DatabaseService, db: AngularFirestore , private downloadService: DownloadService,
    private pathService: PathSetterService) {

      this.db = db;

    const date = new Date();
    this.model = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
    this.dateFilter = new BehaviorSubject(new Date().getTime());
    const StrToday = this.setDateFormat(this.model);
    this.StrDate = this.setCalenderdate(this.model);
    this.StrYYYY = this.setCalenderYear(this.model);
    this.StrMM = this.setCalenderMonth(this.model);

      this.setGroupID();
      this.setBusinessDropDownList();

    }

  ngOnInit() {
    this.loadList();
  }

  setDateFormat(model) {
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    const StrToday = Strdd + '-' + Strmm + '-' + yyyy;
    return StrToday;
  }
  setCalenderdate(model) {
    let Strdd = null;
    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const StrDate = Strdd;
    return StrDate;
  }

  setCalenderMonthYear(model) {
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    const yyyy = model.year;

    // const StrToday = Strmm + '-' + yyyy;
    const StrToday = yyyy + Strmm;
    return StrToday;
  }
  setCalenderYear(model) {
    const yyyy = model.year;
    return yyyy;
  }

  setCalenderMonth(model) {
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    return Strmm;
  }

  filterByDate(model) {
    this.areaVisitorListSub.unsubscribe();
    this.dateFilter.next(new Date().getTime()); 
    this.StrYYYY = this.setCalenderYear(this.model);
    this.StrMM = this.setCalenderMonth(this.model);
    this.StrDate = this.setCalenderdate(this.model);
    this.loadList();
   
  }
  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }
  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }
  setBusinessID(biz) {
    this.areaVisitorListSub.unsubscribe();
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.loadList();
  }

  loadList() {
    console.log('Path:' , '/groups/' + this.groupID + '/bizs/' + this.businessID +
    '/rtoAreaRecords/' + this.StrYYYY +  '/'+ this.StrMM + '/' + this.StrDate + '/rtoRecords/');
    this.areaVisitorList = this.db.collection<RtoList>('/groups/' + this.groupID + '/bizs/' + this.businessID +
    '/rtoAreaRecords/' + this.StrYYYY +  '/'+ this.StrMM + '/' + this.StrDate + '/rtoRecords/').valueChanges();

     this.areaVisitorListSub = this.areaVisitorList.subscribe(
      (response) => {
        this.rtoList = response;
        console.log('RTO LIST:' , this.rtoList);

        const countByAreaCodeAndRtoName = {};
        response.forEach(record => {
          const key = `${record.areaCode}-${record.rtoName}`;
          if (countByAreaCodeAndRtoName[key]) {
            countByAreaCodeAndRtoName[key]++;
          } else {
            countByAreaCodeAndRtoName[key] = 1;
          }
        });
        
       this.areaCounts = new Array();

       //decompose this into individual JSON objects and insert them into the list
        console.log(countByAreaCodeAndRtoName);
       let keys = Object.keys(countByAreaCodeAndRtoName);
       console.log("Keys --> ",keys);

       for (let key of keys){

        let rtoReportItem = {
          "areaCode" : key,
          "count" : countByAreaCodeAndRtoName[key]
        }

        this.areaCounts.push(rtoReportItem);

       }

       console.log(this.areaCounts);


      });
  }

  // sortBy(by: string | any): void {
  //   this.rearrangedReportArray.sort((a: any, b: any) => {
  //     if (a[by] < b[by]) {
  //       return this.sorted ? 1 : -1;
  //     }
  //     if (a[by] > b[by]) {
  //       return this.sorted ? -1 : 1;
  //     }
  //     return 0;
  //   });
  //   this.sorted = !this.sorted;
  // }


  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    console.log('Property:' , property);
    let direction = this.isDesc ? 1 : -1;

    return this.areaCounts.sort(function (a, b) {
  
        if (a[property] < b[property]) {
          return -1 * direction;
        }
        else if (a[property] > b[property]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
    
    });
  };

}