import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatabaseService } from '../../services/database.service';
import { DeviceEditService } from '../deviceEdit.service';
import { PathSetterService } from '../../services/pathSetter.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-edit-devices',
  templateUrl: './edit-devices.component.html',
  styleUrls: ['./edit-devices.component.css']
})
export class EditDevicesComponent implements OnInit, OnDestroy {

  db: AngularFireDatabase;
  groupID: string;
  imeiNoToEdit: string;
  inventoryCodeToEdit: string;
  editDeviceSubs: Subscription;

  constructor(public activeModal: NgbActiveModal, private databaseService: DatabaseService, db: AngularFireDatabase,
    private pathService: PathSetterService, public snackBar: MatSnackBar, private deviceEditService: DeviceEditService) {
    this.db = db;
    this.setGroupID();
    this.getDeviceInformation();
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  getDeviceInformation() {
    this.imeiNoToEdit = this.deviceEditService.getImeiNo();
    this.inventoryCodeToEdit = this.deviceEditService.getInventoryCode();
  }

  onSubmit(Formdata) {
    this.activeModal.close('Close click');
    // console.log('imeiNo' + this.imeiNoToEdit);
    // console.log('inventoryCode' + Formdata.inventoryCode);

    const deviceObj = {
      'imeiNo': this.imeiNoToEdit,
      'inventoryCode': Formdata.inventoryCode,
    };

    const itemRef = this.db.list('/getParking/' + this.pathService.getPathEnvironment() + '/device_details/' + this.groupID);
    itemRef.update(this.imeiNoToEdit, deviceObj);
    this.snackBar.open('Device updated', 'dismiss', {
      duration: 3000
    });
  }


  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
