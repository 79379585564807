import { Injectable } from '@angular/core';
import { Camera } from './camera.model';
import { CameraDetails } from '../camera-details/detail.model';

@Injectable()
export class CameraEditService {
    editCamera: Camera;
    editBusinessID: string;
    editGround: CameraDetails;

    setCameraEditServiceDetails(camInfo: Camera, BusinessId: string) {
        this.editCamera = camInfo;
        this.editBusinessID = BusinessId;
    }

    setCamera(cam: CameraDetails) {
        this.editGround = cam;
    }

    getCameraInfo() {
        return this.editCamera;
    }
    getCamera() {
        return this.editGround;
    }

    getBusinessID() {
        return this.editBusinessID;
    }
}
