import { Injectable } from '@angular/core';
import { Pass } from '../passes/pass.model';

@Injectable()
export class CityPassEditService {

  editPass: Pass;
  editBusinessID: string;

  setPassEditServiceDetails(PassInfo: Pass, BusinessId: string) {
    this.editPass = PassInfo;
    this.editBusinessID = BusinessId;
  }

  getPassInfo() {
    return this.editPass;
  }

  getBusinessID() {
    return this.editBusinessID;
  }

}
