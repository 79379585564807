import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';

import { Pass } from '.././pass.model';
import { PassFireStore } from '../../add-passes/passFireStore.model';
import { DatabaseService } from '../../services/database.service';
import { DownloadService } from '../../services/download.service';
import { PassEditService } from '../passEdit.service';
import { PathSetterService } from '../../services/pathSetter.service';
import { AddPassPopupComponent } from '../add-pass-popup/add-pass-popup.component';
import { EditPassPopupComponent } from '../edit-pass-popup/edit-pass-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
// tslint:disable-next-line:import-blacklist
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase/app';
import { map, switchMap, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { AuthService } from '../../core/auth.service';
import { User } from '../../services/user.model';
import { IssueRfidPopupComponent } from '../issue-rfid-popup/issue-rfid-popup.component';
import { Data } from 'popper.js';
import { tapOnFirstEmit, fromWebSerial } from "@rxjs-ninja/rxjs-utility";
import { fromEvent, merge, Observable, Subject } from "rxjs";
import { delay, finalize, tap } from "rxjs/operators";

// declare const connect: any;
// declare const disconnect:any;


export interface Report {

  a1parkingPassBusinessId: string;
  a2parkingPassDisplayId: string;
  a3passNumberPlate: string;
  a4passVehicleType: string;
  a5passValidFrom: string;
  a6passValidTill: string;
  a7passDurationType: string;
  a8passStatus: string;
  a9passCompanyName: string;
  a10userEmail: string;
  a11userName: string;
  a12userPhone: string;
  a13parkingDedicated: string;
  a14parkingDedicatedSlot: string;
  a15parkingSlot: string;
  a16parkingUnitNo: string;
  a17passAmount: number;
  a18passIssuedById: string;
  a19passIssueTimeStamp: string;
  a20passModificationTimeStamp: string;
  a21passModifiedById: string;
  a22passNumberPlateDigits: string;
  a23passRfidTag: string;
}

@Component({
  selector: 'app-rfid',
  templateUrl: './rfid.component.html',
  styleUrls: ['./rfid.component.css']
})
export class RfidComponent implements OnInit {

  db: AngularFirestore;
  afdb: AngularFireDatabase;
  title = 'RFID Pass';
  message: string = '';
  port: any;

  // Emitter for our message from the input
  sendMessage$ = new Subject<Uint8Array>();
  decoder = new TextDecoder("utf-8");
  encoder = new TextEncoder();
  endCtrl: AbortController = new AbortController();
  tempMessage:string='';
  connection:Observable<Uint8Array> | undefined = undefined;

  data: Pass[];

  // headerData: string[] = [
  //   'Location', 'Pass ID', 'Numberplate', 'Vehicle Type', 'Valid From', 'Valid Till', 'Duration Type', 'Status',
  //   'Company Name', 'User Email', 'User Name', 'User Phone', 'Parking Dedicated', 'Parking Dedicated Slot', 'Parking Slot',
  //   'Parking Unit No', 'Amount', 'Issued By', 'Issue Time', 'Edit Time', 'Edited By'
  // ];

  rearrangedReportArray: Report[] = [];

  dateFilter: BehaviorSubject<number | null>;

  passReportSubs: Subscription;
  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: string;
  progressBarVisible = false;
  p = 1;

  private passCollection: AngularFirestoreCollection<Pass>;
  passes: Observable<Pass[]>;
  user: User;
  userSub: Subscription;

  constructor(private modalService: NgbModal, db: AngularFirestore, private databaseService: DatabaseService, afdb: AngularFireDatabase,
    private downloadService: DownloadService, private pathService: PathSetterService, public snackBar: MatSnackBar,
    private passEditService: PassEditService, public authService: AuthService) {
    this.db = db;
    this.afdb = afdb;
    const date = new Date();
    this.dateFilter = new BehaviorSubject(date.getTime());
    this.setBusinessDropDownList();
  }

  async connect() {
    console.log('connect:');
    await this.startConnection();
  }

  async startConnection() {
    this.endCtrl = new AbortController();
    this.port = await navigator.serial.requestPort();

    this.connection = fromWebSerial(this.port, this.sendMessage$.asObservable(),undefined, this.endCtrl.signal);
    this.connection.subscribe((value) => {
      let msg = this.decoder.decode(value);
      console.log('message:',  msg);
      this.tempMessage += msg;
      if(msg.includes('\r\n') || msg.includes('\r') || msg.includes('\n')){
        this.message = this.tempMessage;
        this.passEditService.setSerialData(this.message.replace('\r\n','').replace('\r','').replace('\n',''));
        this.tempMessage='';
        this.message='';
      }
    });
  }

  close() {
    if(this.port)
    this.port = this.endCtrl.abort();     
  }

  ngOnInit() {
    this.userSub = this.authService.user.pipe(
      take(1)
    ).subscribe(user => this.user = user);
    // console.log('Setting User in dashbord component: ' + this.user.userRole);
    this.loadList();
  }

  canEdit() {
    if (this.authService.canEdit(this.user)) {
      return true;
    } else {
      return false;
    }
  }

  canDelete() {
    if (this.authService.canDelete(this.user)) {
      return true;
    } else {
      return false;
    }
  }

  loadList() {
    this.passCollection = this.db.collection<Pass>('/bizs/' + this.businessID + '/passes/', ref =>
      ref.where('passValidTill', '>=', this.dateFilter.value).where('passStatus', '==', 141));
    this.passes = this.passCollection.valueChanges();

    this.passReportSubs = this.passes
      .pipe(
        take(1),
        map(
          (response) => {
            const Passes: Pass[] = response;
            for (const passItem of Passes) {
              if (!passItem.parkingDedicated) { passItem.parkingDedicated = 'N/A'; }
              if (!passItem.passCompanyName) { passItem.passCompanyName = 'N/A'; }
              if (!passItem.passDurationType) { passItem.passDurationType = 'N/A'; }
              if (!passItem.userEmail) { passItem.userEmail = 'N/A'; }
              if (!passItem.userFirstName) { passItem.userFirstName = 'N/A'; }
              if (!passItem.userPhone) { passItem.userPhone = 'N/A'; }
              if (!passItem.parkingUnitNo) { passItem.parkingUnitNo = 'N/A'; }
              if (!passItem.parkingSlot) { passItem.parkingSlot = 'N/A'; }
              if (!passItem.passModifiedById) { passItem.passModifiedById = 'N/A'; }
              if (!passItem.passNumberPlate) { passItem.passNumberPlate = 'N/A'; }
              if (!passItem.passNumberPlateDigits) { passItem.passNumberPlateDigits = 'N/A'; }
              if (!passItem.passVehicleType) { passItem.passVehicleType = 'N/A'; }
              // if (!passItem.passRfidTag) {passItem.passRfidTag = 'N/A'; }

              if (passItem.passStatus === 141) {
                passItem.passStatus = 'Valid';
              } else if (passItem.passStatus === 142) {
                passItem.passStatus = 'Expired';
              } else if (passItem.passStatus === 143) {
                passItem.passStatus = 'Pending';
              } else if (passItem.passStatus === 144) {
                passItem.passStatus = 'Deleted';
              } else {
                passItem.passStatus = 'Undefined';
              }
              passItem.passIssueTimeStamp = new Date(passItem.passIssueTimeStamp).toLocaleString();
              passItem.passModificationTimeStamp = new Date(passItem.passModificationTimeStamp).toLocaleTimeString();
              passItem.passValidFrom = new Date(passItem.passValidFrom).toLocaleDateString();
              passItem.passValidTill = new Date(passItem.passValidTill).toLocaleDateString();

              for (let k = 0; k < this.businessList.length; k++) {
                if (passItem.parkingPassBusinessId === this.businessList[k].id) {
                  passItem.parkingPassBusinessId = this.businessList[k].name;
                }
              }
            }
            return Passes;
          }))
      .subscribe(
        (response) => {
          this.data = response;
          this.rearrangedReportArray = [];
          // console.log(this.data[0]);
          for (let i = 0; i < this.data.length; i++) {
            const reportItem = this.data[i];
            this.rearrangedReportArray.push({
              a1parkingPassBusinessId: reportItem.parkingPassBusinessId,
              a2parkingPassDisplayId: reportItem.parkingPassDisplayId,
              a3passNumberPlate: reportItem.passNumberPlate,
              a4passVehicleType: reportItem.passVehicleType,
              a5passValidFrom: reportItem.passValidFrom,
              a6passValidTill: reportItem.passValidTill,
              a7passDurationType: reportItem.passDurationType,
              a8passStatus: reportItem.passStatus,
              a9passCompanyName: reportItem.passCompanyName,
              a10userEmail: reportItem.userEmail,
              a11userName: reportItem.userFirstName + ' ' + reportItem.userLastName,
              a12userPhone: reportItem.userPhone,
              a13parkingDedicated: reportItem.parkingDedicated,
              a14parkingDedicatedSlot: reportItem.parkingDedicatedSlot,
              a15parkingSlot: reportItem.parkingSlot,
              a16parkingUnitNo: reportItem.parkingUnitNo,
              a17passAmount: reportItem.passAmount,
              a18passIssuedById: reportItem.passIssuedById,
              a19passIssueTimeStamp: reportItem.passIssueTimeStamp,
              a20passModificationTimeStamp: reportItem.passModificationTimeStamp,
              a21passModifiedById: reportItem.passModifiedById,
              a22passNumberPlateDigits: reportItem.passNumberPlateDigits,
              a23passRfidTag: reportItem.passRfidTag
            });
            this.progressBarVisible = false;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  stopLoader() {
    this.progressBarVisible = false;
  }

  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setBusinessID(biz) {
    this.progressBarVisible = true;
    // this.passReportSubs.unsubscribe();
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.loadList();
  }

  removePassItem(pass: Pass) {
    // deleteing from firestore
    // console.log('pas:', pass);
    const passDelCollection = this.db.collection<PassFireStore>('/bizs/' + this.businessID + '/passes/');
    passDelCollection.doc((pass.passFirestoreDocKey === '' || pass.passFirestoreDocKey === undefined) ?
      pass.passFirebaseId : pass.passFirestoreDocKey).update({ passStatus: 144 }).then(
        () => {
          this.rearrangedReportArray = [];
          this.data = [];
          this.passReportSubs.unsubscribe();
          this.loadList();
        });

    // deleting from firebase
    const itemsDelRef = this.afdb.database.ref('/getParking/' + this.pathService.getPathEnvironment() + '/passes/' + this.businessID
      + '/All/' + pass.passFirebaseId);
    itemsDelRef.update({ passStatus: 144 });
    this.snackBar.open('Pass deleted', 'dismiss', {
      duration: 3000
    });
  }

  editPassItem(index: any) {
    this.loadList();
    this.passEditService.setPassEditServiceDetails(this.data[index], this.businessID);
    const modalRef2 = this.modalService.open(EditPassPopupComponent);
    modalRef2.componentInstance.name = 'World';
  }

  editRfidItem(index: any) {
    this.loadList();
   // console.log('CHECK KARO:', this.data[index]);
    this.passEditService.serialSubject.next('');
    this.passEditService.setRfidEditServiceDetails(this.data[index], this.businessID, this.data); // Call Pass details (data)
    const modalRef2 = this.modalService.open(IssueRfidPopupComponent);
    modalRef2.componentInstance.name = 'World';

  }
  ngOnDestroy() {
  }

}
