import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { NgbDateStruct, NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { DatabaseService } from '../services/database.service';
import { DownloadService } from '../services/download.service';
import { PathSetterService } from '../services/pathSetter.service';
import { AttendantFireStore } from '../attendant/attendant.model';
import { Transactions } from '../transaction-report/transaction.model';
import { take } from 'rxjs/operators';


export interface Report {
  a1location: string; a2name: string; a3date: string; a4attendantOnline: boolean; a5numberOfShifts: number; a6firstShiftStartTime: string;
  a7latestShiftEndTime: string; a8PhoneNumber: string; a9CashInHand: number;

  a10total_dayPassCollection: number; a11total_dayPassCount: number; a12total_dayPassDeletedCollection: number;
  a13total_dayPassDeletedCount: number; a14total_daypass_reprint_count: number;
  // a15total_focCollection: number;
  a16total_focCount: number;
  a17total_inCount: number; a18total_outCount: number; a19total_passCollection: number; a20total_passCount: number;
  a21total_passDeletedCollection: number; a22total_passDeletedCount: number; a23total_pass_reprint_count: number;
  a24total_receipt_reprint_count: number; a25total_ticketCollection: number; a26total_ticketCount: number;
  a27total_ticketDeletedCollection: number; a28total_ticketDeletedCount: number; a29total_ticket_reprint_count: number;
  a30total_lostTicketCollection: number; a31total_lostTicketCount: number; a32total_refundAmount: number; a33total_refundCount: number;

  a34dayPassCollection_2W: number; a35dayPassCount_2W: number; a36dayPassDeletedCollection_2W: number; a37dayPassDeletedCount_2W: number;
  a38daypass_reprint_count_2W: number; a39focCollection_2W: number; a40focCount_2W: number; a41inCount_2W: number; a42outCount_2W: number;
  a43passCollection_2W: number; a44passCount_2W: number; a45passDeletedCollection_2W: number; a46passDeletedCount_2W: number;
  a47pass_reprint_count_2W: number; a48receipt_reprint_count_2W: number; a49ticketCollection_2W: number; a50ticketCount_2W: number;
  a51ticketDeletedCollection_2W: number; a52ticketDeletedCount_2W: number; a53ticket_reprint_count_2W: number;
  a54lostTicketCollection_2W: number; a55lostTicketCount_2W: number; a56refundAmount_2W: number; a57refundCount_2W: number;

  a58dayPassCollection_4W: number; a59dayPassCount_4W: number; a60dayPassDeletedCollection_4W: number; a61dayPassDeletedCount_4W: number;
  a62daypass_reprint_count_4W: number; a63focCollection_4W: number; a64focCount_4W: number; a65inCount_4W: number; a66outCount_4W: number;
  a67passCollection_4W: number; a68passCount_4W: number; a69passDeletedCollection_4W: number; a70passDeletedCount_4W: number;
  a71pass_reprint_count_4W: number; a72receipt_reprint_count_4W: number; a73ticketCollection_4W: number; a74ticketCount_4W: number;
  a75ticketDeletedCollection_4W: number; a76ticketDeletedCount_4W: number; a77ticket_reprint_count_4W: number;
  a78lostTicketCollection_4W: number; a79lostTicketCount_4W: number; a80refundAmount_4W: number; a81refundCount_4W: number;

  a82dayPassCollection_BUS: number; a83dayPassCount_BUS: number; a84dayPassDeletedCollection_BUS: number;
  a85dayPassDeletedCount_BUS: number; a86daypass_reprint_count_BUS: number; a87focCollection_BUS: number; a88focCount_BUS: number;
  a89inCount_BUS: number; a90outCount_BUS: number; a91passCollection_BUS: number; a92passCount_BUS: number;
  a93passDeletedCollection_BUS: number; a94passDeletedCount_BUS: number; a95pass_reprint_count_BUS: number;
  a96receipt_reprint_count_BUS: number; a97ticketCollection_BUS: number; a98ticketCount_BUS: number;
  a99ticketDeletedCollection_BUS: number; a100ticketDeletedCount_BUS: number; a101ticket_reprint_count_BUS: number;
  a102lostTicketCollection_BUS: number; a103lostTicketCount_BUS: number; a104refundAmount_BUS: number; a105refundCount_BUS: number;

  a106dayPassCollection_CV: number; a107dayPassCount_CV: number; a108dayPassDeletedCollection_CV: number;
  a109dayPassDeletedCount_CV: number; a110daypass_reprint_count_CV: number; a111focCollection_CV: number; a112focCount_CV: number;
  a113inCount_CV: number; a114outCount_CV: number; a115passCollection_CV: number; a116passCount_CV: number;
  a117passDeletedCollection_CV: number; a118passDeletedCount_CV: number; a119pass_reprint_count_CV: number;
  a120receipt_reprint_count_CV: number; a121ticketCollection_CV: number; a122ticketCount_CV: number;
  a123ticketDeletedCollection_CV: number; a124ticketDeletedCount_CV: number; a125ticket_reprint_count_CV: number;
  a126lostTicketCollection_CV: number; a127lostTicketCount_CV: number; a128refundAmount_CV: number; a129refundCount_CV: number;

  a130dayPassCollection_CYC: number; a131dayPassCount_CYC: number; a132dayPassDeletedCollection_CYC: number;
  a133dayPassDeletedCount_CYC: number; a134daypass_reprint_count_CYC: number; a135focCollection_CYC: number; a136focCount_CYC: number;
  a137inCount_CYC: number; a138outCount_CYC: number; a139passCollection_CYC: number; a140passCount_CYC: number;
  a141passDeletedCollection_CYC: number; a142passDeletedCount_CYC: number; a143pass_reprint_count_CYC: number;
  a144receipt_reprint_count_CYC: number; a145ticketCollection_CYC: number; a146ticketCount_CYC: number;
  a147ticketDeletedCollection_CYC: number; a148ticketDeletedCount_CYC: number; a149ticket_reprint_count_CYC: number;
  a150lostTicketCollection_CYC: number; a151lostTicketCount_CYC: number; a152refundAmount_CYC: number; a153refundCount_CYC: number;

  a154dayPassCollection_CART: number; a155dayPassCount_CART: number; a156dayPassDeletedCollection_CART: number;
  a157dayPassDeletedCount_CART: number; a158daypass_reprint_count_CART: number; a159focCollection_CART: number; a160focCount_CART: number;
  a161inCount_CART: number; a162outCount_CART: number; a163passCollection_CART: number; a164passCount_CART: number;
  a165passDeletedCollection_CART: number; a166passDeletedCount_CART: number; a167pass_reprint_count_CART: number;
  a168receipt_reprint_count_CART: number; a169ticketCollection_CART: number; a170ticketCount_CART: number;
  a171ticketDeletedCollection_CART: number; a172ticketDeletedCount_CART: number; a173ticket_reprint_count_CART: number;
  a174lostTicketCollection_CART: number; a175lostTicketCount_CART: number; a176refundAmount_CART: number; a177refundCount_CART: number;

  a178dayPassCollection_3W: number; a179dayPassCount_3W: number; a180dayPassDeletedCollection_3W: number;
  a181dayPassDeletedCount_3W: number; a182daypass_reprint_count_3W: number; a183focCollection_3W: number; a184focCount_3W: number;
  a185inCount_3W: number; a186outCount_3W: number; a187passCollection_3W: number; a188passCount_3W: number;
  a189passDeletedCollection_3W: number; a190passDeletedCount_3W: number; a191pass_reprint_count_3W: number;
  a192receipt_reprint_count_3W: number; a193ticketCollection_3W: number; a194ticketCount_3W: number;
  a195ticketDeletedCollection_3W: number; a196ticketDeletedCount_3W: number; a197ticket_reprint_count_3W: number;
  a198lostTicketCollection_3W: number; a199lostTicketCount_3W: number; a200refundAmount_3W: number; a201refundCount_3W: number;

  a203cashCollection: number; a204cashCollection_2W: number; a205cashCollection_4W: number; a206cashPaymentCount: number;
  a207cashPaymentCount_2W: number; a208cashPaymentCount_4W: number;
  a209digitalCollection: number; a210digitalCollection_4W: number;
  a211digitalPaymentCount: number; a212digitalPaymentCount_4W: number;
  a213cardCollection: number; a214cardCollection_4W: number; a215cardPaymentCount: number; a216cardPaymentCount_4W: number; a217cardCollection_2W: number; a218cardPaymentCount_2W: number;
  a219fastagCollection: number; a220fastagCollection_4W: number; a221fastagPaymentCount: number; a222fastagPaymentCount_4W: number; a223fastagCollection_2W: number; a224fastagPaymentCount_2W: number;

}

export class Report1 {
  // a1CreationDate: any;
  a1Amount: number;
}

@Component({
  selector: 'app-attendant-report',
  templateUrl: './attendant-report.component.html',
  styleUrls: ['./attendant-report.component.css']
})
export class AttendantReportComponent implements OnInit {
  title = 'Attendant Shift Summary';
  db: AngularFirestore;
  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: string;
  groupID: string;
  model: NgbDateStruct;
  dateFilter: BehaviorSubject<string | null>;
  success: String;
  StrMMYYYY: String;
  StrDate: string;
  StrYYYY: string;
  StrMM: string;
  selectedDate: Date;
  private sorted = false;
  attendantReportSubs: Subscription;
  private AttendantCollection: AngularFirestoreCollection<AttendantFireStore>;
  Attendants: Observable<AttendantFireStore[]>;
  rearrangedReportArray: Report[] = [];
  p = 1;
  progressBarVisible = false;

  rearrangedTransArray: Report1[] = [];
  transactionReportSubs: Subscription;
  private TransactionCollection: AngularFirestoreCollection<Transactions>;
  transs: Observable<Transactions[]>;
  data: Transactions[];
  // transCount: number;
  totalAmount: any;


  constructor(db: AngularFirestore, private databaseService: DatabaseService, private downloadService: DownloadService,
    private pathService: PathSetterService, calendar: NgbCalendar, private modalService: NgbModal) {
    this.db = db;
    this.success = 'TXN_SUCCESS';
    const date = new Date();
    this.model = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
    const StrToday = this.setDateFormat(this.model);
    this.dateFilter = new BehaviorSubject(StrToday);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    this.StrYYYY = this.setCalenderYear(this.model);
    this.StrMM = this.setCalenderMonth(this.model);
    this.setGroupID();
    this.StrDate = this.setCalenderdate(this.model);
    this.setBusinessDropDownList();
  }

  ngOnInit() {
    this.loadList();
    this.loadTrans();
  }
  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }
  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setBusinessID(biz) {
    this.attendantReportSubs.unsubscribe();
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.loadList();
  }
  setDateFormat(model) {
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    const StrToday = Strdd + '-' + Strmm + '-' + yyyy;
    return StrToday;
  }
  setCalenderdate(model) {
    let Strdd = null;
    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const StrDate = Strdd;
    return StrDate;
  }

  setCalenderMonthYear(model) {
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    const yyyy = model.year;

    const StrToday = Strmm + '-' + yyyy;
    return StrToday;
  }

  setCalenderYear(model) {
    const yyyy = model.year;
    return yyyy;
  }

  setCalenderMonth(model) {
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    return Strmm;
  }

  filterByDate(model) {
    const StrToday = this.setDateFormat(model);
    this.dateFilter.next(StrToday);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    this.StrYYYY = this.setCalenderYear(this.model);
    this.StrMM = this.setCalenderMonth(this.model);
    this.StrDate = this.setCalenderdate(this.model);
    this.loadList();
    this.loadTrans();
  }

  loadList() {
    this.AttendantCollection = this.db.collection<AttendantFireStore>
      ('/reports/' + this.groupID + '/attendants/' + this.dateFilter.value + '/daily_summary/');

    this.Attendants = this.AttendantCollection.valueChanges();

    this.attendantReportSubs = this.Attendants
      .pipe(
        take(1)
      )
      .subscribe(
        (response: AttendantFireStore[]) => {

          this.rearrangedReportArray = [];
          for (let i = 0; i < response.length; i++) {

            // 2W Variables
            response[i].dayPassCollection_2W = isNaN(response[i].dayPassCollection_2W) ? 0 : response[i].dayPassCollection_2W;
            response[i].dayPassCount_2W = isNaN(response[i].dayPassCount_2W) ? 0 : response[i].dayPassCount_2W;
            response[i].dayPassDeletedCollection_2W = isNaN(response[i].dayPassDeletedCollection_2W) ?
              0 : response[i].dayPassDeletedCollection_2W;
            response[i].dayPassDeletedCount_2W = isNaN(response[i].dayPassDeletedCount_2W) ? 0 : response[i].dayPassDeletedCount_2W;
            response[i].daypass_reprint_count_2W = isNaN(response[i].daypass_reprint_count_2W) ? 0 : response[i].daypass_reprint_count_2W;
            response[i].focCollection_2W = isNaN(response[i].focCollection_2W) ? 0 : response[i].focCollection_2W;
            response[i].focCount_2W = isNaN(response[i].focCount_2W) ? 0 : response[i].focCount_2W;
            response[i].inCount_2W = isNaN(response[i].inCount_2W) ? 0 : response[i].inCount_2W;
            response[i].outCount_2W = isNaN(response[i].outCount_2W) ? 0 : response[i].outCount_2W;
            response[i].passCollection_2W = isNaN(response[i].passCollection_2W) ? 0 : response[i].passCollection_2W;
            response[i].passCount_2W = isNaN(response[i].passCount_2W) ? 0 : response[i].passCount_2W;
            response[i].passDeletedCollection_2W = isNaN(response[i].passDeletedCollection_2W) ? 0 : response[i].passDeletedCollection_2W;
            response[i].passDeletedCount_2W = isNaN(response[i].passDeletedCount_2W) ? 0 : response[i].passDeletedCount_2W;
            response[i].pass_reprint_count_2W = isNaN(response[i].pass_reprint_count_2W) ? 0 : response[i].pass_reprint_count_2W;
            response[i].receipt_reprint_count_2W = isNaN(response[i].receipt_reprint_count_2W) ? 0 : response[i].receipt_reprint_count_2W;
            response[i].ticketCollection_2W = isNaN(response[i].ticketCollection_2W) ? 0 : response[i].ticketCollection_2W;
            response[i].ticketCount_2W = isNaN(response[i].ticketCount_2W) ? 0 : response[i].ticketCount_2W;
            response[i].ticketDeletedCollection_2W = isNaN(response[i].ticketDeletedCollection_2W) ?
              0 : response[i].ticketDeletedCollection_2W;
            response[i].ticketDeletedCount_2W = isNaN(response[i].ticketDeletedCount_2W) ? 0 : response[i].ticketDeletedCount_2W;
            response[i].ticket_reprint_count_2W = isNaN(response[i].ticket_reprint_count_2W) ? 0 : response[i].ticket_reprint_count_2W;
            response[i].lostTicketCollection_2W = isNaN(response[i].lostTicketCollection_2W) ? 0 : response[i].lostTicketCollection_2W;
            response[i].lostTicketCount_2W = isNaN(response[i].lostTicketCount_2W) ? 0 : response[i].lostTicketCount_2W;
            response[i].refundAmount_2W = isNaN(response[i].refundAmount_2W) ? 0 : response[i].refundAmount_2W;
            response[i].refundCount_2W = isNaN(response[i].refundCount_2W) ? 0 : response[i].refundCount_2W;

            // 3W Variables
            response[i].dayPassCollection_3W = isNaN(response[i].dayPassCollection_3W) ? 0 : response[i].dayPassCollection_3W;
            response[i].dayPassCount_3W = isNaN(response[i].dayPassCount_3W) ? 0 : response[i].dayPassCount_3W;
            response[i].dayPassDeletedCollection_3W = isNaN(response[i].dayPassDeletedCollection_3W) ?
              0 : response[i].dayPassDeletedCollection_3W;
            response[i].dayPassDeletedCount_3W = isNaN(response[i].dayPassDeletedCount_3W) ? 0 : response[i].dayPassDeletedCount_3W;
            response[i].daypass_reprint_count_3W = isNaN(response[i].daypass_reprint_count_3W) ? 0 : response[i].daypass_reprint_count_3W;
            response[i].focCollection_3W = isNaN(response[i].focCollection_3W) ? 0 : response[i].focCollection_3W;
            response[i].focCount_3W = isNaN(response[i].focCount_3W) ? 0 : response[i].focCount_3W;
            response[i].inCount_3W = isNaN(response[i].inCount_3W) ? 0 : response[i].inCount_3W;
            response[i].outCount_3W = isNaN(response[i].outCount_3W) ? 0 : response[i].outCount_3W;
            response[i].passCollection_3W = isNaN(response[i].passCollection_3W) ? 0 : response[i].passCollection_3W;
            response[i].passCount_3W = isNaN(response[i].passCount_3W) ? 0 : response[i].passCount_3W;
            response[i].passDeletedCollection_3W = isNaN(response[i].passDeletedCollection_3W) ? 0 : response[i].passDeletedCollection_3W;
            response[i].passDeletedCount_3W = isNaN(response[i].passDeletedCount_3W) ? 0 : response[i].passDeletedCount_3W;
            response[i].pass_reprint_count_3W = isNaN(response[i].pass_reprint_count_3W) ? 0 : response[i].pass_reprint_count_3W;
            response[i].receipt_reprint_count_3W = isNaN(response[i].receipt_reprint_count_3W) ? 0 : response[i].receipt_reprint_count_3W;
            response[i].ticketCollection_3W = isNaN(response[i].ticketCollection_3W) ? 0 : response[i].ticketCollection_3W;
            response[i].ticketCount_3W = isNaN(response[i].ticketCount_3W) ? 0 : response[i].ticketCount_3W;
            response[i].ticketDeletedCollection_3W = isNaN(response[i].ticketDeletedCollection_3W) ?
              0 : response[i].ticketDeletedCollection_3W;
            response[i].ticketDeletedCount_3W = isNaN(response[i].ticketDeletedCount_3W) ? 0 : response[i].ticketDeletedCount_3W;
            response[i].ticket_reprint_count_3W = isNaN(response[i].ticket_reprint_count_3W) ? 0 : response[i].ticket_reprint_count_3W;
            response[i].lostTicketCollection_3W = isNaN(response[i].lostTicketCollection_3W) ? 0 : response[i].lostTicketCollection_3W;
            response[i].lostTicketCount_3W = isNaN(response[i].lostTicketCount_3W) ? 0 : response[i].lostTicketCount_3W;
            response[i].refundAmount_3W = isNaN(response[i].refundAmount_3W) ? 0 : response[i].refundAmount_3W;
            response[i].refundCount_3W = isNaN(response[i].refundCount_3W) ? 0 : response[i].refundCount_3W;


            // 4W Variables
            response[i].dayPassCollection_4W = isNaN(response[i].dayPassCollection_4W) ? 0 : response[i].dayPassCollection_4W;
            response[i].dayPassCount_4W = isNaN(response[i].dayPassCount_4W) ? 0 : response[i].dayPassCount_4W;
            response[i].dayPassDeletedCollection_4W = isNaN(response[i].dayPassDeletedCollection_4W) ?
              0 : response[i].dayPassDeletedCollection_4W;
            response[i].dayPassDeletedCount_4W = isNaN(response[i].dayPassDeletedCount_4W) ? 0 : response[i].dayPassDeletedCount_4W;
            response[i].daypass_reprint_count_4W = isNaN(response[i].daypass_reprint_count_4W) ? 0 : response[i].daypass_reprint_count_4W;
            response[i].focCollection_4W = isNaN(response[i].focCollection_4W) ? 0 : response[i].focCollection_4W;
            response[i].focCount_4W = isNaN(response[i].focCount_4W) ? 0 : response[i].focCount_4W;
            response[i].inCount_4W = isNaN(response[i].inCount_4W) ? 0 : response[i].inCount_4W;
            response[i].outCount_4W = isNaN(response[i].outCount_4W) ? 0 : response[i].outCount_4W;
            response[i].passCollection_4W = isNaN(response[i].passCollection_4W) ? 0 : response[i].passCollection_4W;
            response[i].passCount_4W = isNaN(response[i].passCount_4W) ? 0 : response[i].passCount_4W;
            response[i].passDeletedCollection_4W = isNaN(response[i].passDeletedCollection_4W) ? 0 : response[i].passDeletedCollection_4W;
            response[i].passDeletedCount_4W = isNaN(response[i].passDeletedCount_4W) ? 0 : response[i].passDeletedCount_4W;
            response[i].pass_reprint_count_4W = isNaN(response[i].pass_reprint_count_4W) ? 0 : response[i].pass_reprint_count_4W;
            response[i].receipt_reprint_count_4W = isNaN(response[i].receipt_reprint_count_4W) ? 0 : response[i].receipt_reprint_count_4W;
            response[i].ticketCollection_4W = isNaN(response[i].ticketCollection_4W) ? 0 : response[i].ticketCollection_4W;
            response[i].ticketCount_4W = isNaN(response[i].ticketCount_4W) ? 0 : response[i].ticketCount_4W;
            response[i].ticketDeletedCollection_4W = isNaN(response[i].ticketDeletedCollection_4W) ?
              0 : response[i].ticketDeletedCollection_4W;
            response[i].ticketDeletedCount_4W = isNaN(response[i].ticketDeletedCount_4W) ? 0 : response[i].ticketDeletedCount_4W;
            response[i].ticket_reprint_count_4W = isNaN(response[i].ticket_reprint_count_4W) ? 0 : response[i].ticket_reprint_count_4W;
            response[i].lostTicketCollection_4W = isNaN(response[i].lostTicketCollection_4W) ? 0 : response[i].lostTicketCollection_4W;
            response[i].lostTicketCount_4W = isNaN(response[i].lostTicketCount_4W) ? 0 : response[i].lostTicketCount_4W;
            response[i].refundAmount_4W = isNaN(response[i].refundAmount_4W) ? 0 : response[i].refundAmount_4W;
            response[i].refundCount_4W = isNaN(response[i].refundCount_4W) ? 0 : response[i].refundCount_4W;

            // BUS Variables
            response[i].dayPassCollection_BUS = isNaN(response[i].dayPassCollection_BUS) ? 0 : response[i].dayPassCollection_BUS;
            response[i].dayPassCount_BUS = isNaN(response[i].dayPassCount_BUS) ? 0 : response[i].dayPassCount_BUS;
            response[i].dayPassDeletedCollection_BUS = isNaN(response[i].dayPassDeletedCollection_BUS) ?
              0 : response[i].dayPassDeletedCollection_BUS;
            response[i].dayPassDeletedCount_BUS = isNaN(response[i].dayPassDeletedCount_BUS) ? 0 : response[i].dayPassDeletedCount_BUS;
            response[i].daypass_reprint_count_BUS = isNaN(response[i].daypass_reprint_count_BUS) ?
              0 : response[i].daypass_reprint_count_BUS;
            response[i].focCollection_BUS = isNaN(response[i].focCollection_BUS) ? 0 : response[i].focCollection_BUS;
            response[i].focCount_BUS = isNaN(response[i].focCount_BUS) ? 0 : response[i].focCount_BUS;
            response[i].inCount_BUS = isNaN(response[i].inCount_BUS) ? 0 : response[i].inCount_BUS;
            response[i].outCount_BUS = isNaN(response[i].outCount_BUS) ? 0 : response[i].outCount_BUS;
            response[i].passCollection_BUS = isNaN(response[i].passCollection_BUS) ? 0 : response[i].passCollection_BUS;
            response[i].passCount_BUS = isNaN(response[i].passCount_BUS) ? 0 : response[i].passCount_BUS;
            response[i].passDeletedCollection_BUS = isNaN(response[i].passDeletedCollection_BUS) ?
              0 : response[i].passDeletedCollection_BUS;
            response[i].passDeletedCount_BUS = isNaN(response[i].passDeletedCount_BUS) ? 0 : response[i].passDeletedCount_BUS;
            response[i].pass_reprint_count_BUS = isNaN(response[i].pass_reprint_count_BUS) ? 0 : response[i].pass_reprint_count_BUS;
            response[i].receipt_reprint_count_BUS = isNaN(response[i].receipt_reprint_count_BUS) ?
              0 : response[i].receipt_reprint_count_BUS;
            response[i].ticketCollection_BUS = isNaN(response[i].ticketCollection_BUS) ? 0 : response[i].ticketCollection_BUS;
            response[i].ticketCount_BUS = isNaN(response[i].ticketCount_BUS) ? 0 : response[i].ticketCount_BUS;
            response[i].ticketDeletedCollection_BUS = isNaN(response[i].ticketDeletedCollection_BUS) ?
              0 : response[i].ticketDeletedCollection_BUS;
            response[i].ticketDeletedCount_BUS = isNaN(response[i].ticketDeletedCount_BUS) ? 0 : response[i].ticketDeletedCount_BUS;
            response[i].ticket_reprint_count_BUS = isNaN(response[i].ticket_reprint_count_BUS) ? 0 : response[i].ticket_reprint_count_BUS;
            response[i].lostTicketCollection_BUS = isNaN(response[i].lostTicketCollection_BUS) ? 0 : response[i].lostTicketCollection_BUS;
            response[i].lostTicketCount_BUS = isNaN(response[i].lostTicketCount_BUS) ? 0 : response[i].lostTicketCount_BUS;
            response[i].refundAmount_BUS = isNaN(response[i].refundAmount_BUS) ? 0 : response[i].refundAmount_BUS;
            response[i].refundCount_BUS = isNaN(response[i].refundCount_BUS) ? 0 : response[i].refundCount_BUS;

            // CV Variables
            response[i].dayPassCollection_CV = isNaN(response[i].dayPassCollection_CV) ? 0 : response[i].dayPassCollection_CV;
            response[i].dayPassCount_CV = isNaN(response[i].dayPassCount_CV) ? 0 : response[i].dayPassCount_CV;
            response[i].dayPassDeletedCollection_CV = isNaN(response[i].dayPassDeletedCollection_CV) ?
              0 : response[i].dayPassDeletedCollection_CV;
            response[i].dayPassDeletedCount_CV = isNaN(response[i].dayPassDeletedCount_CV) ? 0 : response[i].dayPassDeletedCount_CV;
            response[i].daypass_reprint_count_CV = isNaN(response[i].daypass_reprint_count_CV) ? 0 : response[i].daypass_reprint_count_CV;
            response[i].focCollection_CV = isNaN(response[i].focCollection_CV) ? 0 : response[i].focCollection_CV;
            response[i].focCount_CV = isNaN(response[i].focCount_CV) ? 0 : response[i].focCount_CV;
            response[i].inCount_CV = isNaN(response[i].inCount_CV) ? 0 : response[i].inCount_CV;
            response[i].outCount_CV = isNaN(response[i].outCount_CV) ? 0 : response[i].outCount_CV;
            response[i].passCollection_CV = isNaN(response[i].passCollection_CV) ? 0 : response[i].passCollection_CV;
            response[i].passCount_CV = isNaN(response[i].passCount_CV) ? 0 : response[i].passCount_CV;
            response[i].passDeletedCollection_CV = isNaN(response[i].passDeletedCollection_CV) ? 0 : response[i].passDeletedCollection_CV;
            response[i].passDeletedCount_CV = isNaN(response[i].passDeletedCount_CV) ? 0 : response[i].passDeletedCount_CV;
            response[i].pass_reprint_count_CV = isNaN(response[i].pass_reprint_count_CV) ? 0 : response[i].pass_reprint_count_CV;
            response[i].receipt_reprint_count_CV = isNaN(response[i].receipt_reprint_count_CV) ? 0 : response[i].receipt_reprint_count_CV;
            response[i].ticketCollection_CV = isNaN(response[i].ticketCollection_CV) ? 0 : response[i].ticketCollection_CV;
            response[i].ticketCount_CV = isNaN(response[i].ticketCount_CV) ? 0 : response[i].ticketCount_CV;
            response[i].ticketDeletedCollection_CV = isNaN(response[i].ticketDeletedCollection_CV) ?
              0 : response[i].ticketDeletedCollection_CV;
            response[i].ticketDeletedCount_CV = isNaN(response[i].ticketDeletedCount_CV) ? 0 : response[i].ticketDeletedCount_CV;
            response[i].ticket_reprint_count_CV = isNaN(response[i].ticket_reprint_count_CV) ? 0 : response[i].ticket_reprint_count_CV;
            response[i].lostTicketCollection_CV = isNaN(response[i].lostTicketCollection_CV) ? 0 : response[i].lostTicketCollection_CV;
            response[i].lostTicketCount_CV = isNaN(response[i].lostTicketCount_CV) ? 0 : response[i].lostTicketCount_CV;
            response[i].refundAmount_CV = isNaN(response[i].refundAmount_CV) ? 0 : response[i].refundAmount_CV;
            response[i].refundCount_CV = isNaN(response[i].refundCount_CV) ? 0 : response[i].refundCount_CV;

            // CYC variables
            response[i].dayPassCollection_CYC = isNaN(response[i].dayPassCollection_CYC) ? 0 : response[i].dayPassCollection_CYC;
            response[i].dayPassCount_CYC = isNaN(response[i].dayPassCount_CYC) ? 0 : response[i].dayPassCount_CYC;
            response[i].dayPassDeletedCollection_CYC = isNaN(response[i].dayPassDeletedCollection_CYC) ?
              0 : response[i].dayPassDeletedCollection_CYC;
            response[i].dayPassDeletedCount_CYC = isNaN(response[i].dayPassDeletedCount_CYC) ? 0 : response[i].dayPassDeletedCount_CYC;
            // tslint:disable-next-line:max-line-length
            response[i].daypass_reprint_count_CYC = isNaN(response[i].daypass_reprint_count_CYC) ? 0 : response[i].daypass_reprint_count_CYC;
            response[i].focCollection_CYC = isNaN(response[i].focCollection_CYC) ? 0 : response[i].focCollection_CYC;
            response[i].focCount_CYC = isNaN(response[i].focCount_CYC) ? 0 : response[i].focCount_CYC;
            response[i].inCount_CYC = isNaN(response[i].inCount_CYC) ? 0 : response[i].inCount_CYC;
            response[i].outCount_CYC = isNaN(response[i].outCount_CYC) ? 0 : response[i].outCount_CYC;
            response[i].passCollection_CYC = isNaN(response[i].passCollection_CYC) ? 0 : response[i].passCollection_CYC;
            response[i].passCount_CYC = isNaN(response[i].passCount_CYC) ? 0 : response[i].passCount_CYC;
            // tslint:disable-next-line:max-line-length
            response[i].passDeletedCollection_CYC = isNaN(response[i].passDeletedCollection_CYC) ? 0 : response[i].passDeletedCollection_CYC;
            response[i].passDeletedCount_CYC = isNaN(response[i].passDeletedCount_CYC) ? 0 : response[i].passDeletedCount_CYC;
            response[i].pass_reprint_count_CYC = isNaN(response[i].pass_reprint_count_CYC) ? 0 : response[i].pass_reprint_count_CYC;
            // tslint:disable-next-line:max-line-length
            response[i].receipt_reprint_count_CYC = isNaN(response[i].receipt_reprint_count_CYC) ? 0 : response[i].receipt_reprint_count_CYC;
            response[i].ticketCollection_CYC = isNaN(response[i].ticketCollection_CYC) ? 0 : response[i].ticketCollection_CYC;
            response[i].ticketCount_CYC = isNaN(response[i].ticketCount_CYC) ? 0 : response[i].ticketCount_CYC;
            response[i].ticketDeletedCollection_CYC = isNaN(response[i].ticketDeletedCollection_CYC) ?
              0 : response[i].ticketDeletedCollection_CYC;
            response[i].ticketDeletedCount_CYC = isNaN(response[i].ticketDeletedCount_CYC) ? 0 : response[i].ticketDeletedCount_CYC;
            response[i].ticket_reprint_count_CYC = isNaN(response[i].ticket_reprint_count_CYC) ? 0 : response[i].ticket_reprint_count_CYC;
            response[i].lostTicketCollection_CYC = isNaN(response[i].lostTicketCollection_CYC) ? 0 : response[i].lostTicketCollection_CYC;
            response[i].lostTicketCount_CYC = isNaN(response[i].lostTicketCount_CYC) ? 0 : response[i].lostTicketCount_CYC;
            response[i].refundAmount_CYC = isNaN(response[i].refundAmount_CYC) ? 0 : response[i].refundAmount_CYC;
            response[i].refundCount_CYC = isNaN(response[i].refundCount_CYC) ? 0 : response[i].refundCount_CYC;

            // CART Variables
            response[i].dayPassCollection_CART = isNaN(response[i].dayPassCollection_CART) ? 0 : response[i].dayPassCollection_CART;
            response[i].dayPassCount_CART = isNaN(response[i].dayPassCount_CART) ? 0 : response[i].dayPassCount_CART;
            response[i].dayPassDeletedCollection_CART = isNaN(response[i].dayPassDeletedCollection_CART) ?
              0 : response[i].dayPassDeletedCollection_CART;
            response[i].dayPassDeletedCount_CART = isNaN(response[i].dayPassDeletedCount_CART) ? 0 : response[i].dayPassDeletedCount_CART;
            // tslint:disable-next-line:max-line-length
            response[i].daypass_reprint_count_CART = isNaN(response[i].daypass_reprint_count_CART) ? 0 : response[i].daypass_reprint_count_CART;
            response[i].focCollection_CART = isNaN(response[i].focCollection_CART) ? 0 : response[i].focCollection_CART;
            response[i].focCount_CART = isNaN(response[i].focCount_CART) ? 0 : response[i].focCount_CART;
            response[i].inCount_CART = isNaN(response[i].inCount_CART) ? 0 : response[i].inCount_CART;
            response[i].outCount_CART = isNaN(response[i].outCount_CART) ? 0 : response[i].outCount_CART;
            response[i].passCollection_CART = isNaN(response[i].passCollection_CART) ? 0 : response[i].passCollection_CART;
            response[i].passCount_CART = isNaN(response[i].passCount_CART) ? 0 : response[i].passCount_CART;
            // tslint:disable-next-line:max-line-length
            response[i].passDeletedCollection_CART = isNaN(response[i].passDeletedCollection_CART) ? 0 : response[i].passDeletedCollection_CART;
            response[i].passDeletedCount_CART = isNaN(response[i].passDeletedCount_CART) ? 0 : response[i].passDeletedCount_CART;
            response[i].pass_reprint_count_CART = isNaN(response[i].pass_reprint_count_CART) ? 0 : response[i].pass_reprint_count_CART;
            // tslint:disable-next-line:max-line-length
            response[i].receipt_reprint_count_CART = isNaN(response[i].receipt_reprint_count_CART) ? 0 : response[i].receipt_reprint_count_CART;
            response[i].ticketCollection_CART = isNaN(response[i].ticketCollection_CART) ? 0 : response[i].ticketCollection_CART;
            response[i].ticketCount_CART = isNaN(response[i].ticketCount_CART) ? 0 : response[i].ticketCount_CART;
            response[i].ticketDeletedCollection_CART = isNaN(response[i].ticketDeletedCollection_CART) ?
              0 : response[i].ticketDeletedCollection_CART;
            response[i].ticketDeletedCount_CART = isNaN(response[i].ticketDeletedCount_CART) ? 0 : response[i].ticketDeletedCount_CART;
            // tslint:disable-next-line:max-line-length
            response[i].ticket_reprint_count_CART = isNaN(response[i].ticket_reprint_count_CART) ? 0 : response[i].ticket_reprint_count_CART;
            // tslint:disable-next-line:max-line-length
            response[i].lostTicketCollection_CART = isNaN(response[i].lostTicketCollection_CART) ? 0 : response[i].lostTicketCollection_CART;
            response[i].lostTicketCount_CART = isNaN(response[i].lostTicketCount_CART) ? 0 : response[i].lostTicketCount_CART;
            response[i].refundAmount_CART = isNaN(response[i].refundAmount_CART) ? 0 : response[i].refundAmount_CART;
            response[i].refundCount_CART = isNaN(response[i].refundCount_CART) ? 0 : response[i].refundCount_CART;

            response[i].cashCollection = isNaN(response[i].cashCollection) ? 0 : response[i].cashCollection;
            response[i].cashCollection_2W = isNaN(response[i].cashCollection_2W) ? 0 : response[i].cashCollection_2W;
            response[i].cashCollection_4W = isNaN(response[i].cashCollection_4W) ? 0 : response[i].cashCollection_4W;
            response[i].cashPaymentCount = isNaN(response[i].cashPaymentCount) ? 0 : response[i].cashPaymentCount;
            response[i].cashPaymentCount_2W = isNaN(response[i].cashPaymentCount_2W) ? 0 : response[i].cashPaymentCount_2W;
            response[i].cashPaymentCount_4W = isNaN(response[i].cashPaymentCount_4W) ? 0 : response[i].cashPaymentCount_4W;
            response[i].digitalCollection = isNaN(response[i].digitalCollection) ? 0 : response[i].digitalCollection;
            response[i].digitalCollection_4W = isNaN(response[i].digitalCollection_4W) ? 0 : response[i].digitalCollection_4W;
            response[i].digitalCollection_2W = isNaN(response[i].digitalCollection_2W) ? 0 : response[i].digitalCollection_2W;
            response[i].digitalPaymentCount = isNaN(response[i].digitalPaymentCount) ? 0 : response[i].digitalPaymentCount;
            response[i].digitalPaymentCount_4W = isNaN(response[i].digitalPaymentCount_4W) ? 0 : response[i].digitalPaymentCount_4W;
            response[i].digitalPaymentCount_2W = isNaN(response[i].digitalPaymentCount_2W) ? 0 : response[i].digitalPaymentCount_2W;

            response[i].cardCollection = isNaN(response[i].cardCollection) ? 0 : response[i].cardCollection;
            response[i].cardCollection_4W = isNaN(response[i].cardCollection_4W) ? 0 : response[i].cardCollection_4W;
            response[i].cardCollection_2W = isNaN(response[i].cardCollection_2W) ? 0 : response[i].cardCollection_2W;
            response[i].cardPaymentCount = isNaN(response[i].cardPaymentCount) ? 0 : response[i].cardPaymentCount;
            response[i].cardPaymentCount_4W = isNaN(response[i].cardPaymentCount_4W) ? 0 : response[i].cardPaymentCount_4W;
            response[i].cardPaymentCount_2W = isNaN(response[i].cardPaymentCount_2W) ? 0 : response[i].cardPaymentCount_2W;

            response[i].fastagCollection = isNaN(response[i].fastagCollection) ? 0 : response[i].fastagCollection;
            response[i].fastagCollection_4W = isNaN(response[i].fastagCollection_4W) ? 0 : response[i].fastagCollection_4W;
            response[i].fastagCollection_2W = isNaN(response[i].fastagCollection_2W) ? 0 : response[i].fastagCollection_2W;
            response[i].fastagPaymentCount = isNaN(response[i].fastagPaymentCount) ? 0 : response[i].fastagPaymentCount;
            response[i].fastagPaymentCount_4W = isNaN(response[i].fastagPaymentCount_4W) ? 0 : response[i].fastagPaymentCount_4W;
            response[i].fastagPaymentCount_2W = isNaN(response[i].fastagPaymentCount_2W) ? 0 : response[i].fastagPaymentCount_2W;

          




            const shiftStartTime = new Date(response[i].firstShiftStartTime).toLocaleTimeString();

            const shiftEndTime = isNaN(response[i].latestShiftEndTime) ?
              'N/A' : new Date(response[i].latestShiftEndTime).toLocaleTimeString();

            this.rearrangedReportArray.push({

              a1location: response[i].location,
              a2name: response[i].name,
              a3date: response[i].date,
              a4attendantOnline: response[i].attendantOnline,
              a5numberOfShifts: response[i].numberOfShifts,
              a6firstShiftStartTime: shiftStartTime,
              a7latestShiftEndTime: shiftEndTime,
              a8PhoneNumber: response[i].phoneNumber,
              a9CashInHand: response[i].dayPassCollection_2W + response[i].dayPassCollection_4W
                + response[i].dayPassCollection_3W + response[i].dayPassCollection_BUS + response[i].dayPassCollection_CV
                + response[i].dayPassCollection_CYC + response[i].dayPassCollection_CART
                - response[i].dayPassDeletedCollection_2W - response[i].dayPassDeletedCollection_4W
                - response[i].dayPassDeletedCollection_3W - response[i].dayPassDeletedCollection_BUS
                - response[i].dayPassDeletedCollection_CV - response[i].dayPassCollection_CYC
                - response[i].dayPassCollection_CART
                // Removed FOC collection for cash in Hand
                // - response[i].focCollection_2W - response[i].focCollection_4W - response[i].focCollection_3W
                // - response[i].focCollection_BUS - response[i].focCollection_CV
                // - response[i].focCollection_CYC - response[i].focCollection_CART
                + response[i].passCollection_2W + response[i].passCollection_4W + response[i].passCollection_3W
                + response[i].passCollection_BUS + response[i].passCollection_CV
                + response[i].passCollection_CYC + response[i].passCollection_CART
                - response[i].passDeletedCollection_2W - response[i].passDeletedCollection_4W - response[i].passDeletedCollection_3W
                - response[i].passDeletedCollection_BUS - response[i].passDeletedCollection_CV
                - response[i].passDeletedCollection_CYC - response[i].passDeletedCollection_CART
                + response[i].ticketCollection_2W + response[i].ticketCollection_4W + response[i].ticketCollection_3W
                + response[i].ticketCollection_BUS + response[i].ticketCollection_CV
                + response[i].ticketCollection_CYC + response[i].ticketCollection_CART
                - response[i].ticketDeletedCollection_2W - response[i].ticketDeletedCollection_4W
                - response[i].ticketDeletedCollection_3W
                - response[i].ticketDeletedCollection_BUS - response[i].ticketDeletedCollection_CV
                - response[i].ticketDeletedCollection_CYC - response[i].ticketDeletedCollection_CART
                + response[i].lostTicketCollection_2W + response[i].lostTicketCollection_4W + response[i].lostTicketCollection_3W
                + response[i].lostTicketCollection_BUS + response[i].lostTicketCollection_CV
                + response[i].lostTicketCollection_CYC + response[i].lostTicketCollection_CART
                - response[i].refundAmount_2W - response[i].refundAmount_4W - response[i].refundAmount_3W
                - response[i].refundAmount_BUS - response[i].refundAmount_CV
                - response[i].refundAmount_CYC - response[i].refundAmount_CART,
              a10total_dayPassCollection: response[i].dayPassCollection_2W + response[i].dayPassCollection_4W
                + response[i].dayPassCollection_3W + response[i].dayPassCollection_BUS + response[i].dayPassCollection_CV
                + response[i].dayPassCollection_CYC + response[i].dayPassCollection_CART,
              a11total_dayPassCount: response[i].dayPassCount_2W + response[i].dayPassCount_4W + response[i].dayPassCount_3W
                + response[i].dayPassCount_BUS + response[i].dayPassCount_CV + response[i].dayPassCount_CYC + response[i].dayPassCount_CART,
              a12total_dayPassDeletedCollection: response[i].dayPassDeletedCollection_2W + response[i].dayPassDeletedCollection_4W
                + response[i].dayPassDeletedCollection_3W + response[i].dayPassDeletedCollection_BUS
                + response[i].dayPassDeletedCollection_CV + response[i].dayPassCollection_CYC + response[i].dayPassCollection_CART,
              a13total_dayPassDeletedCount: response[i].dayPassDeletedCount_2W + response[i].dayPassDeletedCount_4W
                + response[i].dayPassDeletedCount_3W + response[i].dayPassDeletedCount_BUS
                + response[i].dayPassDeletedCount_CV + response[i].dayPassDeletedCollection_CYC + response[i].dayPassDeletedCollection_CART,
              a14total_daypass_reprint_count: response[i].daypass_reprint_count_2W + response[i].daypass_reprint_count_4W
                + response[i].daypass_reprint_count_3W + response[i].daypass_reprint_count_BUS
                + response[i].daypass_reprint_count_CV + response[i].daypass_reprint_count_CYC + response[i].daypass_reprint_count_CART,
              // a15total_focCollection: response[i].focCollection_2W + response[i].focCollection_4W + response[i].focCollection_3W
              //   + response[i].focCollection_BUS + response[i].focCollection_CV
              //   + response[i].focCollection_CYC + response[i].focCollection_CART,
              a16total_focCount: response[i].focCount_2W + response[i].focCount_4W + response[i].focCount_3W
                + response[i].focCount_BUS + response[i].focCount_CV + response[i].focCount_CYC + response[i].focCount_CYC,
              a17total_inCount: response[i].inCount_2W + response[i].inCount_4W + response[i].inCount_3W
                + response[i].inCount_BUS + response[i].inCount_CV + response[i].inCount_CYC + response[i].inCount_CART,
              a18total_outCount: response[i].outCount_2W + response[i].outCount_4W + response[i].outCount_3W
                + response[i].outCount_BUS + response[i].outCount_CV + response[i].outCount_CYC + response[i].outCount_CART,
              a19total_passCollection: response[i].passCollection_2W + response[i].passCollection_4W + response[i].passCollection_3W
                + response[i].passCollection_BUS + response[i].passCollection_CV
                + response[i].passCollection_CYC + response[i].passCollection_CART,
              a20total_passCount: response[i].passCount_2W + response[i].passCount_4W + response[i].passCount_3W
                + response[i].passCount_BUS + response[i].passCount_CV + response[i].passCount_CYC + response[i].passCount_CART,
              a21total_passDeletedCollection: response[i].passDeletedCollection_2W + response[i].passDeletedCollection_4W
                + response[i].passDeletedCollection_3W + response[i].passDeletedCollection_BUS + response[i].passDeletedCollection_CV
                + response[i].passDeletedCollection_CYC + response[i].passDeletedCollection_CART,
              a22total_passDeletedCount: response[i].passDeletedCount_2W + response[i].passDeletedCount_4W + response[i].passDeletedCount_3W
                + response[i].passDeletedCount_BUS + response[i].passDeletedCount_CV
                + response[i].passDeletedCount_CYC + response[i].passDeletedCount_CART,
              a23total_pass_reprint_count: response[i].pass_reprint_count_2W + response[i].pass_reprint_count_4W
                + response[i].pass_reprint_count_3W + response[i].pass_reprint_count_BUS
                + response[i].pass_reprint_count_CV + response[i].pass_reprint_count_CYC + response[i].pass_reprint_count_CART,
              a24total_receipt_reprint_count: response[i].receipt_reprint_count_2W + response[i].receipt_reprint_count_4W
                + response[i].receipt_reprint_count_3W + response[i].receipt_reprint_count_BUS
                + response[i].receipt_reprint_count_CV + response[i].receipt_reprint_count_CYC + response[i].receipt_reprint_count_CART,
              a25total_ticketCollection: response[i].ticketCollection_2W + response[i].ticketCollection_4W + response[i].ticketCollection_3W
                + response[i].ticketCollection_BUS + response[i].ticketCollection_CV
                + response[i].ticketCollection_CYC + response[i].ticketCollection_CART,
              a26total_ticketCount: response[i].ticketCount_2W + response[i].ticketCount_4W + response[i].ticketCount_3W
                + response[i].ticketCount_BUS + response[i].ticketCount_CV + response[i].ticketCount_CYC + response[i].ticketCount_CART,
              a27total_ticketDeletedCollection: response[i].ticketDeletedCollection_2W + response[i].ticketDeletedCollection_4W
                + response[i].ticketDeletedCollection_3W + response[i].ticketDeletedCollection_BUS
                + response[i].ticketDeletedCollection_CV + response[i].ticketDeletedCollection_CYC
                + response[i].ticketDeletedCollection_CART,
              a28total_ticketDeletedCount: response[i].ticketDeletedCollection_2W + response[i].ticketDeletedCollection_4W
                + response[i].ticketDeletedCollection_3W + response[i].ticketDeletedCollection_BUS + response[i].ticketDeletedCollection_CV
                + response[i].ticketDeletedCollection_CYC + response[i].ticketDeletedCollection_CART,
              a29total_ticket_reprint_count: response[i].ticket_reprint_count_2W + response[i].ticket_reprint_count_4W
                + response[i].ticket_reprint_count_3W + response[i].ticket_reprint_count_BUS + response[i].ticket_reprint_count_CV
                + response[i].ticket_reprint_count_CYC + response[i].ticket_reprint_count_CART,
              a30total_lostTicketCollection: response[i].lostTicketCollection_2W + response[i].lostTicketCollection_4W
                + response[i].lostTicketCollection_3W + response[i].lostTicketCollection_BUS +
                response[i].lostTicketCollection_CV + response[i].lostTicketCollection_CYC + response[i].lostTicketCollection_CART,
              a31total_lostTicketCount: response[i].lostTicketCount_2W + response[i].lostTicketCount_4W + response[i].lostTicketCount_3W
                + response[i].lostTicketCount_BUS + response[i].lostTicketCount_CV
                + response[i].lostTicketCount_CYC + response[i].lostTicketCount_CART,
              a32total_refundAmount: response[i].refundAmount_2W + response[i].refundAmount_4W + response[i].refundAmount_3W
                + response[i].refundAmount_BUS + response[i].refundAmount_CV + response[i].refundAmount_CYC + response[i].refundAmount_CART,
              a33total_refundCount: response[i].refundCount_2W + response[i].refundCount_4W + response[i].refundCount_3W
                + response[i].refundCount_BUS + response[i].refundCount_CV + response[i].refundCount_CYC + response[i].refundCount_CART,


              // a1location: response[i].location,
              // a2name: response[i].name,
              // a3date: response[i].date,
              // a4attendantOnline: response[i].attendantOnline,
              // a5numberOfShifts: response[i].numberOfShifts,
              // a6firstShiftStartTime: shiftStartTime,
              // a7latestShiftEndTime: shiftEndTime,
              // a8PhoneNumber: response[i].phoneNumber,
              // a9CashInHand: response[i].dayPassCollection_2W + response[i].dayPassCollection_4W
              //   + response[i].dayPassCollection_BUS + response[i].dayPassCollection_CV
              //   + response[i].dayPassCollection_CYC + response[i].dayPassCollection_CART
              //   - response[i].dayPassDeletedCollection_2W - response[i].dayPassDeletedCollection_4W
              //   - response[i].dayPassDeletedCollection_BUS - response[i].dayPassDeletedCollection_CV
              //   - response[i].dayPassCollection_CYC - response[i].dayPassCollection_CART
              //   // - response[i].focCollection_2W - response[i].focCollection_4W
              //   // - response[i].focCollection_BUS - response[i].focCollection_CV
              //   // - response[i].focCollection_CYC - response[i].focCollection_CART
              //   + response[i].passCollection_2W + response[i].passCollection_4W
              //   + response[i].passCollection_BUS + response[i].passCollection_CV
              //   + response[i].passCollection_CYC + response[i].passCollection_CART
              //   - response[i].passDeletedCollection_2W - response[i].passDeletedCollection_4W
              //   - response[i].passDeletedCollection_BUS - response[i].passDeletedCollection_CV
              //   - response[i].passDeletedCollection_CYC - response[i].passDeletedCollection_CART
              //   + response[i].ticketCollection_2W + response[i].ticketCollection_4W
              //   + response[i].ticketCollection_BUS + response[i].ticketCollection_CV
              //   + response[i].ticketCollection_CYC + response[i].ticketCollection_CART
              //   - response[i].ticketDeletedCollection_2W - response[i].ticketDeletedCollection_4W
              //   - response[i].ticketDeletedCollection_BUS - response[i].ticketDeletedCollection_CV
              //   - response[i].ticketDeletedCollection_CYC - response[i].ticketDeletedCollection_CART
              //   + response[i].lostTicketCollection_2W + response[i].lostTicketCollection_4W
              //   + response[i].lostTicketCollection_BUS + response[i].lostTicketCollection_CV
              //   + response[i].lostTicketCollection_CYC + response[i].lostTicketCollection_CART
              //   - response[i].refundAmount_2W - response[i].refundAmount_4W
              //   - response[i].refundAmount_BUS - response[i].refundAmount_CV
              //   - response[i].refundAmount_CYC - response[i].refundAmount_CART,
              // a10total_dayPassCollection: response[i].dayPassCollection_2W + response[i].dayPassCollection_4W
              //   + response[i].dayPassCollection_BUS + response[i].dayPassCollection_CV
              //   + response[i].dayPassCollection_CYC + response[i].dayPassCollection_CART,
              // a11total_dayPassCount: response[i].dayPassCount_2W + response[i].dayPassCount_4W
              //   + response[i].dayPassCount_BUS + response[i].dayPassCount_CV
              //   + response[i].dayPassCount_CYC + response[i].dayPassCount_CART,
              // a12total_dayPassDeletedCollection: response[i].dayPassDeletedCollection_2W
              //  + response[i].dayPassDeletedCollection_4W + response[i].dayPassDeletedCollection_BUS
              //  + response[i].dayPassDeletedCollection_CV + response[i].dayPassCollection_CYC + response[i].dayPassCollection_CART,
              // a13total_dayPassDeletedCount: response[i].dayPassDeletedCount_2W + response[i].dayPassDeletedCount_4W
              //   + response[i].dayPassDeletedCount_BUS + response[i].dayPassDeletedCount_CV
              //   + response[i].dayPassDeletedCollection_CYC + response[i].dayPassDeletedCollection_CART,
              // a14total_daypass_reprint_count: response[i].daypass_reprint_count_2W + response[i].daypass_reprint_count_4W
              //   // tslint:disable-next-line:max-line-length
              // tslint:disable-next-line:max-line-length
              //   + response[i].daypass_reprint_count_BUS + response[i].daypass_reprint_count_CV + response[i].daypass_reprint_count_CYC + response[i].daypass_reprint_count_CART,
              // // a15total_focCollection: response[i].focCollection_2W + response[i].focCollection_4W
              // //   + response[i].focCollection_BUS + response[i].focCollection_CV
              // //   + response[i].focCollection_CYC + response[i].focCollection_CART,
              // a16total_focCount: response[i].focCount_2W + response[i].focCount_4W
              //   + response[i].focCount_BUS + response[i].focCount_CV + response[i].focCount_CYC + response[i].focCount_CYC ,
              // a17total_inCount: response[i].inCount_2W + response[i].inCount_4W
              //   + response[i].inCount_BUS + response[i].inCount_CV + response[i].inCount_CYC + response[i].inCount_CART,
              // a18total_outCount: response[i].outCount_2W + response[i].outCount_4W
              //   + response[i].outCount_BUS + response[i].outCount_CV + response[i].outCount_CYC + response[i].outCount_CART,
              // a19total_passCollection: response[i].passCollection_2W + response[i].passCollection_4W
              //   // tslint:disable-next-line:max-line-length
              // tslint:disable-next-line:max-line-length
              //   + response[i].passCollection_BUS + response[i].passCollection_CV + response[i].passCollection_CYC + response[i].passCollection_CART,
              // a20total_passCount: response[i].passCount_2W + response[i].passCount_4W
              //   + response[i].passCount_BUS + response[i].passCount_CV + response[i].passCount_CYC + response[i].passCount_CART,
              // a21total_passDeletedCollection: response[i].passDeletedCollection_2W + response[i].passDeletedCollection_4W
              //   // tslint:disable-next-line:max-line-length
              // tslint:disable-next-line:max-line-length
              //   + response[i].passDeletedCollection_BUS + response[i].passDeletedCollection_CV + response[i].passDeletedCollection_CYC + response[i].passDeletedCollection_CART,
              // a22total_passDeletedCount: response[i].passDeletedCount_2W + response[i].passDeletedCount_4W
              //   // tslint:disable-next-line:max-line-length
              // tslint:disable-next-line:max-line-length
              //   + response[i].passDeletedCount_BUS + response[i].passDeletedCount_CV + response[i].passDeletedCount_CYC + response[i].passDeletedCount_CART,
              // a23total_pass_reprint_count: response[i].pass_reprint_count_2W + response[i].pass_reprint_count_2W
              //   // tslint:disable-next-line:max-line-length
              // tslint:disable-next-line:max-line-length
              //   + response[i].pass_reprint_count_BUS + response[i].pass_reprint_count_CV + response[i].pass_reprint_count_CYC + response[i].pass_reprint_count_CART,
              // a24total_receipt_reprint_count: response[i].receipt_reprint_count_2W + response[i].receipt_reprint_count_4W
              //   // tslint:disable-next-line:max-line-length
              // tslint:disable-next-line:max-line-length
              //   + response[i].receipt_reprint_count_BUS + response[i].receipt_reprint_count_CV + response[i].receipt_reprint_count_CYC + response[i].receipt_reprint_count_CART,
              // a25total_ticketCollection: response[i].ticketCollection_2W + response[i].ticketCollection_4W
              //   // tslint:disable-next-line:max-line-length
              // tslint:disable-next-line:max-line-length
              //   + response[i].ticketCollection_BUS + response[i].ticketCollection_CV + response[i].ticketCollection_CYC + response[i].ticketCollection_CART,
              // a26total_ticketCount: response[i].ticketCount_2W + response[i].ticketCount_4W
              //   + response[i].ticketCount_BUS + response[i].ticketCount_CV + response[i].ticketCount_CYC + response[i].ticketCount_CART,
              // a27total_ticketDeletedCollection: response[i].ticketDeletedCollection_2W + response[i].ticketDeletedCollection_4W
              //   // tslint:disable-next-line:max-line-length
              // tslint:disable-next-line:max-line-length
              //   + response[i].ticketDeletedCollection_BUS + response[i].ticketDeletedCollection_CV + response[i].ticketDeletedCollection_CYC + + response[i].ticketDeletedCollection_CART,
              // a28total_ticketDeletedCount: response[i].ticketDeletedCollection_2W + response[i].ticketDeletedCollection_4W
              //   // tslint:disable-next-line:max-line-length
              // tslint:disable-next-line:max-line-length
              //   + response[i].ticketDeletedCollection_BUS + response[i].ticketDeletedCollection_CV + response[i].ticketDeletedCollection_CYC + response[i].ticketDeletedCollection_CART,
              // a29total_ticket_reprint_count: response[i].ticket_reprint_count_2W + response[i].ticket_reprint_count_4W
              //   // tslint:disable-next-line:max-line-length
              // tslint:disable-next-line:max-line-length
              //   + response[i].ticket_reprint_count_BUS + response[i].ticket_reprint_count_CV + response[i].ticket_reprint_count_CYC + response[i].ticket_reprint_count_CART,
              // a30total_lostTicketCollection: response[i].lostTicketCollection_2W + response[i].lostTicketCollection_4W
              //   // tslint:disable-next-line:max-line-length
              // tslint:disable-next-line:max-line-length
              //   + response[i].lostTicketCollection_BUS + response[i].lostTicketCollection_CV + response[i].lostTicketCollection_CYC + response[i].lostTicketCollection_CART,
              // a31total_lostTicketCount: response[i].lostTicketCount_2W + response[i].lostTicketCount_4W
              //   // tslint:disable-next-line:max-line-length
              // tslint:disable-next-line:max-line-length
              //   + response[i].lostTicketCount_BUS + response[i].lostTicketCount_CV + response[i].lostTicketCount_CYC + response[i].lostTicketCount_CART,
              // a32total_refundAmount: response[i].refundAmount_2W + response[i].refundAmount_4W
              // tslint:disable-next-line:max-line-length
              //   + response[i].refundAmount_BUS + response[i].refundAmount_CV + response[i].refundAmount_CYC + response[i].refundAmount_CART,
              // a33total_refundCount: response[i].refundCount_2W + response[i].refundCount_4W
              //   + response[i].refundCount_BUS + response[i].refundCount_CV + response[i].refundCount_CYC + response[i].refundCount_CART,

              a34dayPassCollection_2W: response[i].dayPassCollection_2W,
              a35dayPassCount_2W: response[i].dayPassCount_2W,
              a36dayPassDeletedCollection_2W: response[i].dayPassDeletedCollection_2W,
              a37dayPassDeletedCount_2W: response[i].dayPassDeletedCount_2W,
              a38daypass_reprint_count_2W: response[i].daypass_reprint_count_2W,
              a39focCollection_2W: response[i].focCollection_2W,
              a40focCount_2W: response[i].focCount_2W,
              a41inCount_2W: response[i].inCount_2W,
              a42outCount_2W: response[i].outCount_2W,
              a43passCollection_2W: response[i].passCollection_2W,
              a44passCount_2W: response[i].passCount_2W,
              a45passDeletedCollection_2W: response[i].passDeletedCollection_2W,
              a46passDeletedCount_2W: response[i].passDeletedCount_2W,
              a47pass_reprint_count_2W: response[i].pass_reprint_count_2W,
              a48receipt_reprint_count_2W: response[i].receipt_reprint_count_2W,
              a49ticketCollection_2W: response[i].ticketCollection_2W,
              a50ticketCount_2W: response[i].ticketCount_2W,
              a51ticketDeletedCollection_2W: response[i].ticketDeletedCollection_2W,
              a52ticketDeletedCount_2W: response[i].ticketDeletedCount_2W,
              a53ticket_reprint_count_2W: response[i].ticket_reprint_count_2W,
              a54lostTicketCollection_2W: response[i].lostTicketCollection_2W,
              a55lostTicketCount_2W: response[i].lostTicketCount_2W,
              a56refundAmount_2W: response[i].refundAmount_2W,
              a57refundCount_2W: response[i].refundCount_2W,

              a58dayPassCollection_4W: response[i].dayPassCollection_4W,
              a59dayPassCount_4W: response[i].dayPassCount_4W,
              a60dayPassDeletedCollection_4W: response[i].dayPassDeletedCollection_4W,
              a61dayPassDeletedCount_4W: response[i].dayPassDeletedCount_4W,
              a62daypass_reprint_count_4W: response[i].daypass_reprint_count_4W,
              a63focCollection_4W: response[i].focCollection_4W,
              a64focCount_4W: response[i].focCount_4W,
              a65inCount_4W: response[i].inCount_4W,
              a66outCount_4W: response[i].outCount_4W,
              a67passCollection_4W: response[i].passCollection_4W,
              a68passCount_4W: response[i].passCount_4W,
              a69passDeletedCollection_4W: response[i].passDeletedCollection_4W,
              a70passDeletedCount_4W: response[i].passDeletedCount_4W,
              a71pass_reprint_count_4W: response[i].pass_reprint_count_4W,
              a72receipt_reprint_count_4W: response[i].receipt_reprint_count_4W,
              a73ticketCollection_4W: response[i].ticketCollection_4W,
              a74ticketCount_4W: response[i].ticketCount_4W,
              a75ticketDeletedCollection_4W: response[i].ticketDeletedCollection_4W,
              a76ticketDeletedCount_4W: response[i].ticketDeletedCount_4W,
              a77ticket_reprint_count_4W: response[i].ticket_reprint_count_4W,
              a78lostTicketCollection_4W: response[i].lostTicketCollection_4W,
              a79lostTicketCount_4W: response[i].lostTicketCount_4W,
              a80refundAmount_4W: response[i].refundAmount_4W,
              a81refundCount_4W: response[i].refundCount_4W,

              a82dayPassCollection_BUS: response[i].dayPassCollection_BUS,
              a83dayPassCount_BUS: response[i].dayPassCount_BUS,
              a84dayPassDeletedCollection_BUS: response[i].dayPassDeletedCollection_BUS,
              a85dayPassDeletedCount_BUS: response[i].dayPassDeletedCount_BUS,
              a86daypass_reprint_count_BUS: response[i].daypass_reprint_count_BUS,
              a87focCollection_BUS: response[i].focCollection_BUS,
              a88focCount_BUS: response[i].focCount_BUS,
              a89inCount_BUS: response[i].inCount_BUS,
              a90outCount_BUS: response[i].outCount_BUS,
              a91passCollection_BUS: response[i].passCollection_BUS,
              a92passCount_BUS: response[i].passCount_BUS,
              a93passDeletedCollection_BUS: response[i].passDeletedCollection_BUS,
              a94passDeletedCount_BUS: response[i].passDeletedCount_BUS,
              a95pass_reprint_count_BUS: response[i].pass_reprint_count_BUS,
              a96receipt_reprint_count_BUS: response[i].receipt_reprint_count_BUS,
              a97ticketCollection_BUS: response[i].ticketCollection_BUS,
              a98ticketCount_BUS: response[i].ticketCount_BUS,
              a99ticketDeletedCollection_BUS: response[i].ticketDeletedCollection_BUS,
              a100ticketDeletedCount_BUS: response[i].ticketDeletedCount_BUS,
              a101ticket_reprint_count_BUS: response[i].ticket_reprint_count_BUS,
              a102lostTicketCollection_BUS: response[i].lostTicketCollection_BUS,
              a103lostTicketCount_BUS: response[i].lostTicketCount_BUS,
              a104refundAmount_BUS: response[i].refundAmount_BUS,
              a105refundCount_BUS: response[i].refundCount_BUS,

              a106dayPassCollection_CV: response[i].dayPassCollection_CV,
              a107dayPassCount_CV: response[i].dayPassCount_CV,
              a108dayPassDeletedCollection_CV: response[i].dayPassDeletedCollection_CV,
              a109dayPassDeletedCount_CV: response[i].dayPassDeletedCount_CV,
              a110daypass_reprint_count_CV: response[i].daypass_reprint_count_CV,
              a111focCollection_CV: response[i].focCollection_CV,
              a112focCount_CV: response[i].focCount_CV,
              a113inCount_CV: response[i].inCount_CV,
              a114outCount_CV: response[i].outCount_CV,
              a115passCollection_CV: response[i].passCollection_CV,
              a116passCount_CV: response[i].passCount_CV,
              a117passDeletedCollection_CV: response[i].passDeletedCollection_CV,
              a118passDeletedCount_CV: response[i].passDeletedCount_CV,
              a119pass_reprint_count_CV: response[i].pass_reprint_count_CV,
              a120receipt_reprint_count_CV: response[i].receipt_reprint_count_CV,
              a121ticketCollection_CV: response[i].ticketCollection_CV,
              a122ticketCount_CV: response[i].ticketCount_CV,
              a123ticketDeletedCollection_CV: response[i].ticketDeletedCollection_CV,
              a124ticketDeletedCount_CV: response[i].ticketDeletedCount_CV,
              a125ticket_reprint_count_CV: response[i].ticket_reprint_count_CV,
              a126lostTicketCollection_CV: response[i].lostTicketCollection_CV,
              a127lostTicketCount_CV: response[i].lostTicketCount_CV,
              a128refundAmount_CV: response[i].refundAmount_CV,
              a129refundCount_CV: response[i].refundCount_CV,

              a130dayPassCollection_CYC: response[i].dayPassCollection_CYC,
              a131dayPassCount_CYC: response[i].dayPassCount_CYC,
              a132dayPassDeletedCollection_CYC: response[i].dayPassDeletedCollection_CYC,
              a133dayPassDeletedCount_CYC: response[i].dayPassDeletedCount_CYC,
              a134daypass_reprint_count_CYC: response[i].daypass_reprint_count_CYC,
              a135focCollection_CYC: response[i].focCollection_CYC,
              a136focCount_CYC: response[i].focCount_CYC,
              a137inCount_CYC: response[i].inCount_CYC,
              a138outCount_CYC: response[i].outCount_CYC,
              a139passCollection_CYC: response[i].passCollection_CYC,
              a140passCount_CYC: response[i].passCount_CYC,
              a141passDeletedCollection_CYC: response[i].passDeletedCollection_CYC,
              a142passDeletedCount_CYC: response[i].passDeletedCount_CYC,
              a143pass_reprint_count_CYC: response[i].pass_reprint_count_CYC,
              a144receipt_reprint_count_CYC: response[i].receipt_reprint_count_CYC,
              a145ticketCollection_CYC: response[i].ticketCollection_CYC,
              a146ticketCount_CYC: response[i].ticketCount_CYC,
              a147ticketDeletedCollection_CYC: response[i].ticketDeletedCollection_CYC,
              a148ticketDeletedCount_CYC: response[i].ticketDeletedCount_CYC,
              a149ticket_reprint_count_CYC: response[i].ticket_reprint_count_CYC,
              a150lostTicketCollection_CYC: response[i].lostTicketCollection_CYC,
              a151lostTicketCount_CYC: response[i].lostTicketCount_CYC,
              a152refundAmount_CYC: response[i].refundAmount_CYC,
              a153refundCount_CYC: response[i].refundCount_CYC,

              a154dayPassCollection_CART: response[i].dayPassCollection_CART,
              a155dayPassCount_CART: response[i].dayPassCount_CART,
              a156dayPassDeletedCollection_CART: response[i].dayPassDeletedCollection_CART,
              a157dayPassDeletedCount_CART: response[i].dayPassDeletedCount_CART,
              a158daypass_reprint_count_CART: response[i].daypass_reprint_count_CART,
              a159focCollection_CART: response[i].focCollection_CART,
              a160focCount_CART: response[i].focCount_CART,
              a161inCount_CART: response[i].inCount_CART,
              a162outCount_CART: response[i].outCount_CART,
              a163passCollection_CART: response[i].passCollection_CART,
              a164passCount_CART: response[i].passCount_CART,
              a165passDeletedCollection_CART: response[i].passDeletedCollection_CART,
              a166passDeletedCount_CART: response[i].passDeletedCount_CART,
              a167pass_reprint_count_CART: response[i].pass_reprint_count_CART,
              a168receipt_reprint_count_CART: response[i].receipt_reprint_count_CART,
              a169ticketCollection_CART: response[i].ticketCollection_CART,
              a170ticketCount_CART: response[i].ticketCount_CART,
              a171ticketDeletedCollection_CART: response[i].ticketDeletedCollection_CART,
              a172ticketDeletedCount_CART: response[i].ticketDeletedCount_CART,
              a173ticket_reprint_count_CART: response[i].ticket_reprint_count_CART,
              a174lostTicketCollection_CART: response[i].lostTicketCollection_CART,
              a175lostTicketCount_CART: response[i].lostTicketCount_CART,
              a176refundAmount_CART: response[i].refundAmount_CART,
              a177refundCount_CART: response[i].refundCount_CART,

              a178dayPassCollection_3W: response[i].dayPassCollection_3W,
              a179dayPassCount_3W: response[i].dayPassCount_3W,
              a180dayPassDeletedCollection_3W: response[i].dayPassDeletedCollection_3W,
              a181dayPassDeletedCount_3W: response[i].dayPassDeletedCount_3W,
              a182daypass_reprint_count_3W: response[i].daypass_reprint_count_3W,
              a183focCollection_3W: response[i].focCollection_3W,
              a184focCount_3W: response[i].focCount_3W,
              a185inCount_3W: response[i].inCount_3W,
              a186outCount_3W: response[i].outCount_3W,
              a187passCollection_3W: response[i].passCollection_3W,
              a188passCount_3W: response[i].passCount_3W,
              a189passDeletedCollection_3W: response[i].passDeletedCollection_3W,
              a190passDeletedCount_3W: response[i].passDeletedCount_3W,
              a191pass_reprint_count_3W: response[i].pass_reprint_count_3W,
              a192receipt_reprint_count_3W: response[i].receipt_reprint_count_3W,
              a193ticketCollection_3W: response[i].ticketCollection_3W,
              a194ticketCount_3W: response[i].ticketCount_3W,
              a195ticketDeletedCollection_3W: response[i].ticketDeletedCollection_3W,
              a196ticketDeletedCount_3W: response[i].ticketDeletedCount_3W,
              a197ticket_reprint_count_3W: response[i].ticket_reprint_count_3W,
              a198lostTicketCollection_3W: response[i].lostTicketCollection_3W,
              a199lostTicketCount_3W: response[i].lostTicketCount_3W,
              a200refundAmount_3W: response[i].refundAmount_3W,
              a201refundCount_3W: response[i].refundCount_3W,

              a203cashCollection: response[i].cashCollection,
              a204cashCollection_2W: response[i].cashCollection_2W,
              a205cashCollection_4W: response[i].cashCollection_4W,
              a206cashPaymentCount: response[i].cashPaymentCount,
              a207cashPaymentCount_2W: response[i].cashPaymentCount_2W,
              a208cashPaymentCount_4W: response[i].cashPaymentCount_4W,
              a209digitalCollection: response[i].digitalCollection,
              a210digitalCollection_4W: response[i].digitalCollection_4W,
              a211digitalPaymentCount: response[i].digitalPaymentCount,
              a212digitalPaymentCount_4W: response[i].digitalPaymentCount_4W,
              a213cardCollection: response[i].cardCollection,
              a214cardCollection_4W: response[i].cardCollection_4W,
              a215cardPaymentCount: response[i].cardPaymentCount,
              a216cardPaymentCount_4W: response[i].cardPaymentCount_4W,
              a217cardCollection_2W: response[i].cardCollection_2W,
              a218cardPaymentCount_2W: response[i].cardPaymentCount_2W,
              a219fastagCollection: response[i].fastagCollection,
              a220fastagCollection_4W: response[i].fastagCollection_4W,
              a221fastagPaymentCount: response[i].fastagPaymentCount,
              a222fastagPaymentCount_4W: response[i].fastagPaymentCount_4W,
              a223fastagCollection_2W: response[i].fastagCollection_2W,
              a224fastagPaymentCount_2W: response[i].fastagPaymentCount_2W,

            });
            // console.log('attendant check karo:' , this.rearrangedReportArray);
          }
        }
      );
  }

  loadTrans() {
    this.transs = this.db.collection<Transactions>('/groups/' + this.groupID + '/bizs/' + this.businessID + '/ledgers/'
      + this.StrYYYY + '/' + this.StrMM + '/' + this.StrDate + '/' + 'payments'
      , ref => ref.where('status', '==', this.success)).valueChanges();
    this.transactionReportSubs = this.transs.subscribe(
      (response) => {
        this.data = response;
        console.log('sbfhsf:', this.data);
        this.rearrangedTransArray = [];
        this.totalAmount = 0;
        for (let i = 0; i < this.data.length; i++) {
          const reportItem = this.data[i];
          this.totalAmount += reportItem.amount;
          console.log('contsdjfbnfjks:', reportItem.amount);
          // this.rearrangedTransArray.push({
          //   a1Amount: reportItem.amount
          // });
        }
      },
      (error) => { console.log(error); }
    );
  }

  // sortBy(by: number | any): void {

  //   this.rearrangedReportArray.sort((a: any, b: any) => {
  //     if (a[by] < b[by]) {
  //       return this.sorted ? 1 : -1;
  //     }
  //     if (a[by] > b[by]) {
  //       return this.sorted ? -1 : 1;
  //     }
  //     return 0;
  //   });

  //   this.sorted = !this.sorted;
  // }

  // downloadExcelReport() { }

  // downloadPDFReport() { }
}
