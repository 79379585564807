import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';

import { NgbDateStruct, NgbCalendar, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

import { Pass } from '.././pass.model';
import { PassFireStore } from '../../add-passes/passFireStore.model';
import { DatabaseService } from '../../services/database.service';
import { DownloadService } from '../../services/download.service';
import { PassEditService } from '../passEdit.service';
import { PathSetterService } from '../../services/pathSetter.service';
import { AddPassPopupComponent } from '../add-pass-popup/add-pass-popup.component';
import { EditPassPopupComponent } from '../edit-pass-popup/edit-pass-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
// tslint:disable-next-line:import-blacklist
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase/app';
import { map, switchMap, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { AuthService } from '../../core/auth.service';
import { User } from '../../services/user.model';

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

export interface Report {

  a1parkingPassBusinessId: string;
  a2parkingPassDisplayId: string;
  a3passNumberPlate: string;
  a4passVehicleType: string;
  a5passValidFrom: string;
  a6passValidTill: string;
  a7passDurationType: string;
  a8passStatus: string;
  a9passCompanyName: string;
  a10userEmail: string;
  a11userName: string;
  a12userPhone: string;
  a13parkingDedicated: string;
  a14parkingDedicatedSlot: string;
  a15parkingSlot: string;
  a16parkingUnitNo: string;
  a17passAmount: number;
  a18passIssuedById: string;
  a19passIssueTimeStamp: string;
  a20passModificationTimeStamp: string;
  a21passModifiedById: string;
}

@Component({
  selector: 'app-expired-passes',
  templateUrl: './expired-passes.component.html',
  styleUrls: ['./expired-passes.component.css']
})
export class ExpiredPassesComponent implements OnInit, OnDestroy {
  @ViewChild('d') datePicker: NgbInputDatepicker;

  db: AngularFirestore;
  afdb: AngularFireDatabase;
  title = 'Expired Pass Summary';

  data: Pass[];
  headerData: string[] = [
    'Location', 'Pass ID', 'Numberplate', 'Vehicle Type', 'Valid From', 'Valid Till', 'Duration Type', 'Status',
    'Company Name', 'User Email', 'User Name', 'User Phone', 'Parking Dedicated', 'Parking Dedicated Slot', 'Parking Slot',
    'Parking Unit No', 'Amount', 'Issued By', 'Issue Time', 'Edit Time', 'Edited By'
  ];

  rearrangedReportArray: Report[] = [];

  passReportSubs: Subscription;
  businessList: any[];
  businessID: string;
  businessName: string;
  progressBarVisible = false;
  selectedValue: string;
  p = 1;

  user: User;
  userSub: Subscription;

  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  dateFilterFrom: BehaviorSubject<number | null>;
  dateFilterTo: BehaviorSubject<number | null>;

  private passCollection: AngularFirestoreCollection<Pass>;
  passes: Observable<Pass[]>;

  constructor(db: AngularFirestore, private databaseService: DatabaseService, private modalService: NgbModal, afdb: AngularFireDatabase,
    private pathService: PathSetterService, public snackBar: MatSnackBar, private passEditService: PassEditService,
    private downloadService: DownloadService, calendar: NgbCalendar, public authService: AuthService) {
    this.db = db;

    this.dateFilterFrom = new BehaviorSubject(new Date().getTime());
    this.dateFilterTo = new BehaviorSubject(new Date().getTime());
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);

    this.setBusinessDropDownList();
  }

  ngOnInit() {
    this.userSub = this.authService.user.pipe(
      take(1)
    ).subscribe(user => this.user = user);
    // console.log('Setting User in dashbord component: ' + this.user.userRole);

    this.loadList();
  }

  canEdit() {
    if (this.authService.canEdit(this.user)) {
      return true;
    } else {
      return false;
    }
  }

  canDelete() {
    if (this.authService.canDelete(this.user)) {
      return true;
    } else {
      return false;
    }
  }

  loadList() {

    this.passes = combineLatest([
      this.dateFilterFrom,
      this.dateFilterTo
    ]).pipe(switchMap(([filterFromDate, filterToDate]) =>
      this.db.collection<Pass>('/bizs/' + this.businessID + '/passes', ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        if (filterFromDate) { query = query.where('passValidTill', '>=', filterFromDate).orderBy('passValidTill', 'asc'); }
        if (filterToDate) { query = query.where('passValidTill', '<=', filterToDate); }
        query = query.where('parkingPassGroupId', '==', '');
        return query;
      }).valueChanges())
    );

    this.passReportSubs = this.passes
      .pipe(
        take(1),
        map(
          (response) => {
            const Passes: Pass[] = response;
            for (const passItem of Passes) {

              if (!passItem.parkingDedicated) { passItem.parkingDedicated = 'N/A'; }
              if (!passItem.passCompanyName) { passItem.passCompanyName = 'N/A'; }
              if (!passItem.passDurationType) { passItem.passDurationType = 'N/A'; }
              if (!passItem.userEmail) { passItem.userEmail = 'N/A'; }
              if (!passItem.userFirstName) { passItem.userFirstName = 'N/A'; }
              if (!passItem.userPhone) { passItem.userPhone = 'N/A'; }
              if (!passItem.parkingUnitNo) { passItem.parkingUnitNo = 'N/A'; }
              if (!passItem.parkingSlot) { passItem.parkingSlot = 'N/A'; }
              if (!passItem.passModifiedById) { passItem.passModifiedById = 'N/A'; }
              if (!passItem.passNumberPlate) { passItem.passNumberPlate = 'N/A'; }
              if (!passItem.passNumberPlateDigits) { passItem.passNumberPlateDigits = 'N/A'; }
              if (!passItem.passVehicleType) { passItem.passVehicleType = 'N/A'; }

              if (passItem.passStatus === 142) {
                passItem.passStatus = 'Expired';
              // } else if (passItem.passStatus === 142) {
              //   passItem.passStatus = 'Expired';
              // } else if (passItem.passStatus === 143) {
              //   passItem.passStatus = 'Pending';
              // } else if (passItem.passStatus === 144) {
              //   passItem.passStatus = 'Deleted';
              } else {
                passItem.passStatus = 'Undefined';
              }
              passItem.passIssueTimeStamp = new Date(passItem.passIssueTimeStamp).toLocaleString();
              passItem.passModificationTimeStamp = new Date(passItem.passModificationTimeStamp).toLocaleTimeString();
              passItem.passValidFrom = new Date(passItem.passValidFrom).toLocaleDateString();
              passItem.passValidTill = new Date(passItem.passValidTill).toLocaleDateString();

              for (let k = 0; k < this.businessList.length; k++) {
                if (passItem.parkingPassBusinessId === this.businessList[k].id) {
                  passItem.parkingPassBusinessId = this.businessList[k].name;
                }
              }

            }
            return Passes;
          }))
      .subscribe(
        (response) => {
          this.data = response;
          // console.log(this.data[0]);
          this.rearrangedReportArray = [];
          for (let i = 0; i < this.data.length; i++) {
            const reportItem = this.data[i];
            this.rearrangedReportArray.push({
              a1parkingPassBusinessId: reportItem.parkingPassBusinessId,
              a2parkingPassDisplayId: reportItem.parkingPassDisplayId,
              a3passNumberPlate: reportItem.passNumberPlate,
              a4passVehicleType: reportItem.passVehicleType,
              a5passValidFrom: reportItem.passValidFrom,
              a6passValidTill: reportItem.passValidTill,
              a7passDurationType: reportItem.passDurationType,
              a8passStatus: reportItem.passStatus,
              a9passCompanyName: reportItem.passCompanyName,
              a10userEmail: reportItem.userEmail,
              a11userName: reportItem.userFirstName + ' ' + reportItem.userLastName,
              a12userPhone: reportItem.userPhone,
              a13parkingDedicated: reportItem.parkingDedicated,
              a14parkingDedicatedSlot: reportItem.parkingDedicatedSlot,
              a15parkingSlot: reportItem.parkingSlot,
              a16parkingUnitNo: reportItem.parkingUnitNo,
              a17passAmount: reportItem.passAmount,
              a18passIssuedById: reportItem.passIssuedById,
              a19passIssueTimeStamp: reportItem.passIssueTimeStamp,
              a20passModificationTimeStamp: reportItem.passModificationTimeStamp,
              a21passModifiedById: reportItem.passModifiedById,
            });
            this.progressBarVisible = false;

          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  downloadExcelReport() {
    const reportLabel = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilterFrom.value).toLocaleDateString()
      + ' to ' + new Date(this.dateFilterTo.value).toLocaleDateString() + ' Report';
    this.downloadService.getExcelReport(this.rearrangedReportArray, reportLabel, this.headerData);
  }

  downloadPDFReport() {
    const reportLabel = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilterFrom.value).toLocaleDateString()
      + ' to ' + new Date(this.dateFilterTo.value).toLocaleDateString() + ' Report';

    const options = {
      styles: {
        fontSize: 2.8,
        font: 'helvetica', // helvetica, times, courier
      },
      startY: true, // false (indicates margin top value) or a number
      margin: { left: 1 },
      theme: 'striped',
      fillColor: false, // false for transparent or a color as described below
      textColor: 20,
      columnWidth: 'auto', // 'auto', 'wrap' or a number
      tableWidth: 'wrap', // 'auto', 'wrap' or a number,

    };

    this.downloadService.getPDFReport(this.rearrangedReportArray, reportLabel, this.headerData, options);
  }

  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setBusinessID(biz) {
    this.progressBarVisible = true;
    this.passReportSubs.unsubscribe();
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.loadList();
  }

  stopLoader() {
    this.progressBarVisible = false;
  }

  onDateSelection(date: NgbDateStruct) {
    this.progressBarVisible = true;
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
      this.datePicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.fromDate != null) {
      const newDateFrom = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      this.dateFilterFrom.next(newDateFrom.getTime());
    }

    if (this.toDate != null) {
      const newDateTo = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
      newDateTo.setHours(23, 59, 59);
      this.dateFilterTo.next(newDateTo.getTime());
    } else {
      const newDateTo = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      newDateTo.setHours(23, 59, 59);
      this.dateFilterTo.next(newDateTo.getTime());
    }
  }

  openModal() {
    const modalRef = this.modalService.open(AddPassPopupComponent);
    modalRef.componentInstance.name = 'World';
    modalRef.result.then(
      () => {
        this.rearrangedReportArray = [];
        this.data = [];
        this.passReportSubs.unsubscribe();
        this.loadList();
      }
    ).catch((error) => {
      console.log(error);
      console.log('cancelling');
    });

  }

  removePassItem(id: any) {
    // deleteing from firestore
    const passDelCollection = this.db.collection<PassFireStore>('/bizs/' + this.businessID + '/passes/');
    passDelCollection.doc(this.data[id]['passFirestoreDocKey']).delete().then(
      () => {
        this.rearrangedReportArray = [];
        this.data = [];
        this.passReportSubs.unsubscribe();
        this.loadList();
      });

    // deleting from firebase
    const itemsDelRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() + '/passes/' + this.businessID + '/All');
    itemsDelRef.remove(this.data[id]['passFirebaseId']);
    this.snackBar.open('Pass deleted', 'dismiss', {
      duration: 3000
    });


  }

  editPassItem(index: any) {
    this.passReportSubs.unsubscribe();
    this.loadList();
    // console.log(this.data[index]);
    this.passEditService.setPassEditServiceDetails(this.data[index], this.businessID);
    const modalRef2 = this.modalService.open(EditPassPopupComponent);
    modalRef2.componentInstance.name = 'World';
  }

  ngOnDestroy() {
    this.passReportSubs.unsubscribe();
  }

  isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);

}
