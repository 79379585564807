import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { DemoCount } from './demo.model';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { DatabaseService } from '../services/database.service';
import { DownloadService } from '../services/download.service';
import { PathSetterService } from '../services/pathSetter.service';
import { ReportingTagsComponent } from '../tags/reporting-tags/reporting-tags.component';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { Moment } from 'moment';



export interface Report {
  a1twoWheelerCount: number;
  a2twoWheelerAmount: number;
  a3cartCount: number;
  a4cartAmount: number;
  a5cvCount: number;
  a6cvAmount: number;
  a7cycCount: number;
  a8cycAmount: number;
  a9fourWheelerCount: number;
  a10fourWheelerAmount: number;
  a11threeWheelerCount: number;
  a12threeWheelerAmount: number;
  a13Date: string;
  a14total: number;
  a15twoWheelerPassCount: number;
  a16twoWheelerPassAmount: number;
  a17fourWheelerPassCount: number;
  a18fourWheelerPassAmount: number;
  a19threeWheelerPassCount: number;
  a20threeWheelerPassAmount: number;
}

export interface Report1 {
  a1twoWheelerCount: number;
  a2twoWheelerAmount: number;
  a9fourWheelerCount: number;
  a10fourWheelerAmount: number;
  a13Date: string;
  a14total: number;
  a15twoWheelerPassCount: number;
  a16twoWheelerPassAmount: number;
  a17fourWheelerPassCount: number;
  a18fourWheelerPassAmount: number;
}

@Component({
  selector: 'app-demo-collection',
  templateUrl: './demo-collection.component.html',
  styleUrls: ['./demo-collection.component.css']
})
export class DemoCollectionComponent implements OnInit {
  db: AngularFirestore;
  afdb: AngularFireDatabase;
  title = 'Date Wise Report';

  data: DemoCount[];
  demoReportSubs: Subscription;
  counts: Observable<DemoCount[]>;
  rearrangedReportArray: Report[] = [];
  rearrangedReportArray1: Report1[] = [];
  private demoCollection: AngularFirestoreCollection<DemoCount>;
  groupID: string;
  businessList: any[];
  businessID: string;
  businessName: string;
  StrYYYY: string;
  StrMM: string;
  StrDate: string;
  model: NgbDateStruct;
  dateFilter: BehaviorSubject<number | null>;
  StrMMYYYY: String;
  dateList: string[] = [];
  private sorted = false;
  date: string;

  headerData: string[] = [
    'Date', '2W Count', '2W Amount', '2W Pass Count', '2W Pass Amount', '3W Count',
    '3W Amount', '3W Pass Count', '3W Pass Amount', '4W Count', '4W Amount', '4W Pass Count',
    '4W Pass Amount', 'cv count', 'cv Amount', 'CYC Count', 'CYC Amount',
    'Pushcart Count', 'Pushcart Amount', 'Total',
  ];

  headerData1: string[] = [
    'Date', '2W Count', '2W Amount', '4W Count', '4W Amount', '2W Pass Count',
    '2W Pass Amount', '4W Pass Count', '4W Pass Amount', 'Total',
  ];


  constructor(db: AngularFirestore, private databaseService: DatabaseService, private downloadService: DownloadService,
    private pathService: PathSetterService, afdb: AngularFireDatabase) {
    this.afdb = afdb;
    this.db = db;
    this.setBusinessDropDownList();
    this.setGroupID();
    const date = new Date();
    this.model = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
    this.dateFilter = new BehaviorSubject(new Date().getTime());
    const StrToday = this.setDateFormat(this.model);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }
  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setDateFormat(model) {
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    const StrToday = Strdd + '-' + Strmm + '-' + yyyy;
    return StrToday;
  }
  setCalenderdate(model) {
    let Strdd = null;
    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const StrDate = Strdd;
    return StrDate;
  }

  setCalenderMonthYear(model) {
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    const yyyy = model.year;

    // const StrToday = Strmm + '-' + yyyy;
    const StrToday = yyyy + Strmm;
    return StrToday;
  }
  filterByMonth(model) {
    // console.log('date:', model);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    // this.StrDate = this.setCalenderdate(this.model);

  }

  handleYearSelected(normalizedYear: Moment) {
  console.log('normalizedYear: ', normalizedYear.toDate());
}
handleMonthSelected(normalizedMonth: Moment) {
  console.log('normalizedMonth: ', normalizedMonth.toDate());
}

  setBusinessID(biz) {
    this.demoReportSubs.unsubscribe();
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.loadList();
    this.getDateList();
    // this.loadList1();
  }

  ngOnInit() {
    this.loadList();
    this.getDateList();
  }

  getDateList() {
    let dates;
    this.demoCollection = this.db.collection<DemoCount>('/reports/' + this.groupID + '/bizs/' + this.businessID + '/daily_summary_from_vr');
    this.counts = this.demoCollection.valueChanges();
    dates = this.demoCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const id = a.payload.doc.id;
        // data.id = id;
        this.dateList.push(id);
        // console.log('Id:', this.dateList); // Get all the ID from firestore and store it in an array
        return id;
      }))
    ).subscribe(
      (response) => {
        // console.log('sdf:', response);
      });
  }

  loadList() {
    // console.log('Dashuahsdf:', this.dateFilter.value);
    // tslint:disable-next-line:max-line-length
    this.counts = this.db.collection<DemoCount>('/reports/' + this.groupID + '/bizs/' + this.businessID + '/daily_summary_from_vr' + this.dateList
      // , ref =>
      // ref.where('timeStamp' , '<=' , this.dateFilter.value).orderBy('timeStamp' , 'desc')
    ).valueChanges();
    this.counts = this.db.collection<DemoCount>('/reports/' + this.groupID + '/bizs/' + this.businessID +
      '/daily_summary_from_vr', ref =>
      ref.orderBy('reportDateTimestamp', 'desc')).valueChanges();
    this.demoReportSubs = this.counts.subscribe(
      (response) => {
        this.data = response;
        // console.log('Deko', response);
        this.rearrangedReportArray = [];
        for (let i = 0; i < this.data.length; i++) {
          const reportItem = this.data[i];
          reportItem.fourWheelerPassAmount = isNaN(reportItem.fourWheelerPassAmount) ? 0 : reportItem.fourWheelerPassAmount;
          reportItem.fourWheelerPassCount = isNaN(reportItem.fourWheelerPassCount) ? 0 : reportItem.fourWheelerPassCount;
          reportItem.twoWheelerPassAmount = isNaN(reportItem.twoWheelerPassAmount) ? 0 : reportItem.twoWheelerPassAmount;
          reportItem.twoWheelerPassCount = isNaN(reportItem.twoWheelerPassCount) ? 0 : reportItem.twoWheelerPassCount;
          reportItem.threeWheelerPassAmount = isNaN(reportItem.threeWheelerPassAmount) ? 0 : reportItem.threeWheelerPassAmount;
          reportItem.threeWheelerPassCount = isNaN(reportItem.threeWheelerPassCount) ? 0 : reportItem.threeWheelerPassCount;

          this.rearrangedReportArray.push({
            a13Date: moment(new Date(reportItem.reportDateTimestamp)).format('DD/MM/YYYY'),
            a1twoWheelerCount: reportItem.twoWheelerCount,
            a2twoWheelerAmount: reportItem.twoWheelerAmount,
            a15twoWheelerPassCount: reportItem.twoWheelerPassCount,
            a16twoWheelerPassAmount: reportItem.twoWheelerPassAmount,
            a11threeWheelerCount: reportItem.threeWheelerCount,
            a12threeWheelerAmount: reportItem.threeWheelerAmount,
            a19threeWheelerPassCount: reportItem.threeWheelerPassCount,
            a20threeWheelerPassAmount: reportItem.threeWheelerPassAmount,
            a9fourWheelerCount: reportItem.fourWheelerCount,
            a10fourWheelerAmount: reportItem.fourWheelerAmount,
            a17fourWheelerPassCount: reportItem.fourWheelerPassCount,
            a18fourWheelerPassAmount: reportItem.fourWheelerPassAmount,
            a5cvCount: reportItem.cvCount,
            a6cvAmount: reportItem.cvAmount,
            a7cycCount: reportItem.cycCount,
            a8cycAmount: reportItem.cycAmount,
            a3cartCount: reportItem.cartCount,
            a4cartAmount: reportItem.cartAmount,
            // a14total: reportItem.twoWheelerAmount + reportItem.threeWheelerAmount + reportItem.fourWheelerAmount
            //   + reportItem.cycAmount + reportItem.cartAmount + reportItem.cvAmount,
            // a15Date: new Date (reportItem.timeStamp).toLocaleDateString(),
            a14total: reportItem.twoWheelerAmount + reportItem.threeWheelerAmount + reportItem.fourWheelerAmount
              + reportItem.cycAmount + reportItem.cartAmount + reportItem.cvAmount + reportItem.twoWheelerPassAmount
              + reportItem.fourWheelerPassAmount + reportItem.threeWheelerPassAmount,
          });

          this.rearrangedReportArray1.push({
            a13Date: moment(new Date(reportItem.reportDateTimestamp)).format('DD/MM/YYYY'),
            a1twoWheelerCount: reportItem.twoWheelerCount,
            a2twoWheelerAmount: reportItem.twoWheelerAmount,
            a9fourWheelerCount: reportItem.fourWheelerCount,
            a10fourWheelerAmount: reportItem.fourWheelerAmount,
            a15twoWheelerPassCount: reportItem.twoWheelerPassCount,
            a16twoWheelerPassAmount: reportItem.twoWheelerPassAmount,
            a17fourWheelerPassCount: reportItem.fourWheelerPassCount,
            a18fourWheelerPassAmount: reportItem.fourWheelerPassAmount,
            a14total: reportItem.twoWheelerAmount + reportItem.threeWheelerAmount + reportItem.fourWheelerAmount
              + reportItem.twoWheelerPassAmount + reportItem.fourWheelerPassAmount,
          });
        }

      },
      (error) => { console.log(error); }
    );
  }

  // sortBy(by: string | any): void {

  //       this.dateList.sort((b: any, a: any) => {
  //         if (b[by] < a[by]) {
  //           return this.sorted ? 1 : -1;
  //         }
  //         if (b[by] > a[by]) {
  //           return this.sorted ? -1 : 1;
  //         }
  //         return 0;
  //       });
  //       this.sorted = !this.sorted;

  //       console.log('fsjdfsjkn:' , this.sorted);
  //     }

  downloadExcelReport() {
    const reportLabel = this.businessName + ' ' + this.title + '' + ' Report';
    this.downloadService.getExcelReport(this.rearrangedReportArray, reportLabel, this.headerData);
  }
  downloadExcelReport1() {
    const reportLabel = this.businessName + ' ' + this.title + '' + ' Report';
    this.downloadService.getExcelReport1(this.rearrangedReportArray1, reportLabel, this.headerData1);
  }
  downloadPDFReport() {
    const reportLabel = this.businessName + ' ' + this.title + ' ' + ' Report';
    const options = {
      styles: {
        fontSize: 1.5,
        font: 'helvetica', // helvetica, times, courier
      },
      startY: true, // false (indicates margin top value) or a number
      margin: { left: 1 },
      theme: 'striped',
      fillColor: false, // false for transparent or a color as described below
      textColor: 20,
      columnWidth: 'auto', // 'auto', 'wrap' or a number
      tableWidth: 'wrap', // 'auto', 'wrap' or a number,
    };
    this.downloadService.getPDFReport(this.rearrangedReportArray, reportLabel, this.headerData, options);
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    this.demoReportSubs.unsubscribe();
  }

}
