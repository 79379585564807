import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()


export class UtilService {

    model: NgbDateStruct;

    constructor() {
        const date = new Date();
        this.model = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
    }

    getEpochDay(selectedDate: Date) {
        const today = new Date(selectedDate);
        today.setHours(15);
        const todayEpochDay = Math.floor((today.getTime() + 330 * 60 * 1000) / (1000 * 60 * 60 * 24));
        return todayEpochDay;
    }

    getEpochDayFromTimestamp(epochTimestamp: number) {
        const today = new Date(epochTimestamp);
        today.setHours(15);
        const todayEpochDay = Math.floor((today.getTime() + 330 * 60 * 1000) / (1000 * 60 * 60 * 24));
        return todayEpochDay;
    }


    getTimeStamp(model) {
        const date = new Date();
        date.setMonth(model.month - 1);
        date.setFullYear(model.year);
        date.setDate(model.day);
        return date;
    }
}
