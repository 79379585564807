import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AddGroupComponent } from '../add-group/add-group.component';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { GroupFireStore } from './groupFirestore.model';
import { MatSnackBar } from '@angular/material';
import { map, switchMap, take } from 'rxjs/operators';
import { DatabaseService } from '../services/database.service';

export interface Report {
  a1groupName: string;
}

@Component({
  selector: 'app-group-setup',
  templateUrl: './group-setup.component.html',
  styleUrls: ['./group-setup.component.css']
})
export class GroupSetupComponent implements OnInit {
  title: 'Group List';
  dbf: AngularFirestore;
  groupReportSubs: Subscription;
  groupID: string;
  // groupName: string;
  rearrangedReportArray: Report[] = [];
  data: GroupFireStore[];
  // afdb: AngularFireDatabase;

  private reportingGroupCollection: AngularFirestoreCollection<GroupFireStore>;
  reportingGroups: Observable<GroupFireStore[]>;

  constructor(private modalService: NgbModal, dbf: AngularFirestore, private router: ActivatedRoute,
    private databaseService: DatabaseService, public snackBar: MatSnackBar) {
    this.dbf = dbf;
    // this.afdb = afdb;
    this.setGroupID();
    // this.loadList();
  }
  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }
  ngOnInit() {
    this.loadList();
  }

  loadList() {
    // this.reportingGroupCollection = this.db.collection<GroupFireStore>('/groups/' + this.groupID + '/');
    this.reportingGroupCollection = this.dbf.collection<GroupFireStore>('/groups/');

    this.reportingGroups = this.reportingGroupCollection.valueChanges();

    this.groupReportSubs = this.reportingGroups.pipe(
      take(1)
    ).subscribe(
      (response) => {
        this.data = response;
        // console.log('deds', response);
        this.rearrangedReportArray = [];
        for (let i = 0; i < this.data.length; i++) {
          const reportItem = this.data[i];

          this.rearrangedReportArray.push({
            a1groupName: reportItem.groupName,
          });
        }
      });
  }
  openModal() {
    const modalRef = this.modalService.open(AddGroupComponent);
    modalRef.componentInstance.name = 'World';
  }
}
