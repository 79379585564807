import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DownloadService } from '../services/download.service';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { DatabaseService } from '../services/database.service';
import { PathSetterService } from '../services/pathSetter.service';
import { CapacityTags } from '../tags/capacity-tags/capacityTags.model';
import * as XLSX from 'xlsx';
import { AngularFireDatabase } from '@angular/fire/database';
import { tap } from 'rxjs/operators';

export interface CapacityTagUpload {
  location: string;
  TagFullTitle: string;
  TagTitle: string;
  TwoWheelerCapacity: number;
  FourWheelerCapacity: number;
}

@Component({
  selector: 'app-add-capacity-tag',
  templateUrl: './add-capacity-tag.component.html',
  styleUrls: ['./add-capacity-tag.component.css']
})
export class AddCapacityTagComponent implements OnInit, OnDestroy {

  @ViewChild('input') fileInputVariable: any;
  @ViewChild('stepper') stepper: any;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isOptional = false;
  showTable = false;
  // for updating the table value
  editField: string;
  // isEditable: boolean;
  arrayBuffer: any;
  file: File;
  capacityTagUploadList: CapacityTagUpload[] = []; // change
  groupID: string;
  businessList: any[];
  afdb: AngularFireDatabase;
  afs: AngularFirestore;
  localFirebaseID: string;
  data = [];
  progressbarValue: number;
  isProgressvarVisible: boolean;
  headerData: string[] = ['Location', 'Full Title', 'Short Title', 'Tag Type', '2W Capacity', '4W Capacity'];
  isValid = false;

  constructor(private _formBuilder: FormBuilder, private downloadService: DownloadService, afdb: AngularFireDatabase,
    afs: AngularFirestore, private databaseService: DatabaseService, private pathService: PathSetterService) {
    this.afdb = afdb;
    this.afs = afs;
    this.formatData = this.formatData.bind(this);
    this.isProgressvarVisible = false;
    // this.isEditable = true;
    this.setGroupID();
    this.setBusinessList();
  }

  ngOnInit() {

    this.firstFormGroup = this._formBuilder.group({
      // firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ''
    });
  }

  isValidForm() {
    return this.isValid;
  }

  downloadTemplate() {
    const reportLabel = 'Capacity-Tag Upload Template';
    this.downloadService.getExcelReport(this.data, reportLabel, this.headerData);
  }


  formatData() {

    this.isValid = true;
    // this.isEditable = true;
    this.isProgressvarVisible = false;

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      this.capacityTagUploadList = [];
      for (let i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      const listData = XLSX.utils.sheet_to_json(worksheet, { raw: true });

      for (let i = 0; i < listData.length; i++) {
        const listItem = listData[i];


        // Checking for empty optional fields
        // if (!listItem['User Email']) { listItem['User Email'] = ''; }
        // if (!listItem['User LastName']) { listItem['User LastName'] = ''; }
        // if (!listItem['User Phone']) { listItem['User Phone'] = ''; }


        // assiging group pass
        this.capacityTagUploadList.push({
          location: listItem['Location'],
          TagFullTitle: listItem['Full Title'],
          TagTitle: listItem['Short Title'],
          TwoWheelerCapacity: listItem['2W Capacity'],
          FourWheelerCapacity: listItem['4W Capacity'],
        });
      }
    };
    fileReader.readAsArrayBuffer(this.file);
  }

  changeListener(event) {
    this.clearData();
    this.file = event.target.files[0];
  }

  clearData() {
    this.isValid = false;
    this.capacityTagUploadList = [];
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  setBusinessList() {
    this.businessList = this.databaseService.getBusinessList();
  }

  removePassItem(id: any) {
    // this.awaitingPersonList.push(this.personList[id]);
    this.capacityTagUploadList.splice(id, 1);
  }

  changePassItemValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent;
    this.capacityTagUploadList[id][property] = this.editField;
  }

  uploadTemplate() {
    this.isValid = false;
    this.isProgressvarVisible = true;
    let FBSuccessfulUploadCount = 0;
    this.progressbarValue = 0;
    let businessKey = null;

    for (let i = 0; i < this.capacityTagUploadList.length; i++) {

      // finding business key for the uploaded passes
      for (let k = 0; k < this.businessList.length; k++) {
        if (this.capacityTagUploadList[i]['location'] === this.businessList[k].name) {
          businessKey = this.businessList[k].id;
          // console.log('Business Key: ' + businessKey);
          break;
        }
      }

      let itemkey = null;
      // setting the path and getting the ref from Firebase if businesskey isn't null
      if (businessKey !== null) {
        // Firestore URL
        const capacityTagsCollection = this.afs.collection<CapacityTags>('/groups/' + this.groupID + '/bizs/' + businessKey +
          '/corporate_tags/');

        Promise.resolve(
          capacityTagsCollection.add({
            gpTagCreatedById: '',
            gpTagCreatedByName: 'Dashboard Admin',
            gpTagCreatedOn: new Date().getTime(),
            gpTagCreatedOnSorter: 0 - new Date().getTime(),
            gpTagFullTitle: this.capacityTagUploadList[i]['TagFullTitle'],
            gpTagId: '',
            gpTagParkingCapacity: null,
            gpTagTitle: this.capacityTagUploadList[i]['TagTitle'],
            gpTagType: 'corporate',
            selected: false,
            tagColor: -1
          })
        ).then((fsitem) => {
          itemkey = fsitem.id;
          capacityTagsCollection.doc(itemkey)
            .update({
              gpTagId: itemkey,
              gpTagParkingCapacity: {
                '2W': this.capacityTagUploadList[i]['TwoWheelerCapacity'],
                '4W': this.capacityTagUploadList[i]['FourWheelerCapacity']
              }
            });
          FBSuccessfulUploadCount++;
          // Firebase URL
          const itemsRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() + '/gpTags/' + businessKey + '/');
          Promise.resolve(
            itemsRef.set(itemkey, {
              gpTagCreatedById: '',
              gpTagCreatedByName: 'Dashboard Admin',
              gpTagCreatedOn: new Date().getTime(),
              gpTagCreatedOnSorter: 0 - new Date().getTime(),
              gpTagFullTitle: this.capacityTagUploadList[i]['TagFullTitle'],
              gpTagId: itemkey,
              gpTagParkingCapacity: {
                '2W': this.capacityTagUploadList[i]['TwoWheelerCapacity'],
                '4W': this.capacityTagUploadList[i]['FourWheelerCapacity']
              },
              gpTagTitle: this.capacityTagUploadList[i]['TagTitle'],
              gpTagType: 'corporate',
              selected: false,
              tagColor: -1
            })
          );

          // Added Tag to counter node
          const counterRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() +
          '/Counter/' + businessKey + '/corporate_park/');
          Promise.resolve(
            counterRef.set(itemkey, {
              'title': this.capacityTagUploadList[i]['TagFullTitle'],
              'sTitle': this.capacityTagUploadList[i]['TagTitle'],
              '2W': {
                'capacity': this.capacityTagUploadList[i]['TwoWheelerCapacity'],
                'occupancy': 0
              },
              '4W': {
                'capacity': this.capacityTagUploadList[i]['FourWheelerCapacity'],
                'occupancy': 0
              }
            })
          );

          // Add to Counter Database.
          const counterRef1 = this.afdb.database.app.database(this.pathService.getOtherPathEnvi())
          .ref('/getParking/' + this.pathService.getPathEnvironment() + '/Counter/' + businessKey + '/corporate_park/' + itemkey);
        counterRef1.set({
          'title': this.capacityTagUploadList[i]['TagFullTitle'],
              'sTitle': this.capacityTagUploadList[i]['TagTitle'],
          '2W': {
            'capacity': this.capacityTagUploadList[i]['TwoWheelerCapacity'],
            'total': this.capacityTagUploadList[i]['TwoWheelerCapacity'],
          },
          '4W': {
            'capacity': this.capacityTagUploadList[i]['FourWheelerCapacity'],
            'total': this.capacityTagUploadList[i]['FourWheelerCapacity'],
          }
        });

          this.progressbarValue = Math.round((FBSuccessfulUploadCount / this.capacityTagUploadList.length) * 100);
          if (FBSuccessfulUploadCount === (this.capacityTagUploadList.length)) {
            setTimeout(() => {
              // console.log('finished upload');
              this.stepper.selectedIndex = 0;
              this.clearData();
            }, 2000);
          }
        }).catch(err => {
          // console.log(err);
        });
      }
    }
  }

  ngOnDestroy() {
    this.capacityTagUploadList = [];
  }
}

