import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter2'
})

export class NumberplateFilterPipe implements PipeTransform {
    transform(items: any, filter: string): any {
        if (!items) {
            return [];
        }
        if (!filter) {
            return items;
         }
        filter = filter.toString().replace('[\D_]+/g', '');
        return items.filter(it => {
            return it.name.replace('[\D_]+/g').includes(filter);
        });
    }
}
