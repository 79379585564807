// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import 'zone.js/dist/zone-error';
export const environment = {
  production: false,
  firebase: {
    // OC Production Database
    apiKey: 'AIzaSyCKlBUhEk2Bzb9dLEQTju8804btS5K6Vtg',
    authDomain: 'get-parking-7b3d5.firebaseapp.com',
    databaseURL: 'https://get-parking-other-customers.firebaseio.com/',
    projectId: 'get-parking-7b3d5',
    storageBucket: 'get-parking-7b3d5.appspot.com',
    messagingSenderId: '630380373924',

    // OC Dev Database
    // apiKey: 'AIzaSyAXQ31dqrGIScnnlDkpm5Fyg96LsqouSw8',
    // authDomain: 'getparking-development.firebaseapp.com',
    // databaseURL: 'https://getparking-development-other-customers.firebaseio.com/',
    // projectId: 'getparking-development',
    // storageBucket: 'getparking-development.appspot.com',
    // messagingSenderId: '200145100061'

    // Chandigarh Dev Database
    // apiKey: 'AIzaSyAXQ31dqrGIScnnlDkpm5Fyg96LsqouSw8',
    // authDomain: 'getparking-development.firebaseapp.com',
    // databaseURL: 'https://getparking-development.firebaseio.com',
    // projectId: 'getparking-development',
    // storageBucket: 'getparking-development.appspot.com',
    // messagingSenderId: '200145100061'

    // Chandigargh Production Database
    // apiKey: 'AIzaSyCKlBUhEk2Bzb9dLEQTju8804btS5K6Vtg',
    // authDomain: 'get-parking-7b3d5.firebaseapp.com',
    // databaseURL: 'https://get-parking-7b3d5.firebaseio.com',
    // projectId: 'get-parking-7b3d5',
    // storageBucket: 'get-parking-7b3d5.appspot.com',
    // messagingSenderId: '630380373924'
  }
};
