import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { take } from 'rxjs/operators';
import { AttendantInfo } from '../attendant-management/attendantInfo.model';
import { AttendantFireStore } from '../attendant/attendant.model';
import { DemoCount } from '../demo-collection/demo.model';
import { Pass } from '../passes/pass.model';
import { DatabaseService } from '../services/database.service';
import { PathSetterService } from '../services/pathSetter.service';
import { Vehicle } from '../vehicles/vehicle.model';



export interface Counters {
  a1capacity2W: any;
  a2occupancy2W: any;
  a3capacity4W: any;
  a4occupancy4W: any;
}

export interface Reports {
  a1name: string;
  a2businessId: string;
  a3phoneNumber: string;
}
interface AttendantPresence {
  // active: Boolean;
  // name: string;
  // phoneNumber: string;
  // businessId: string;
  // a2name: string;
  a4attendantOnline: boolean;
  // a5numberOfShifts: number;
  // a6firstShiftStartTime: string;
  // a7latestShiftEndTime: string;
  // a8PhoneNumber: string;
}
export interface Report1 {
  a1twoWheelerCount: number;
  a2twoWheelerAmount: number;
  a3cartCount: number;
  a4cartAmount: number;
  a5cvCount: number;
  a6cvAmount: number;
  a7cycCount: number;
  a8cycAmount: number;
  a9fourWheelerCount: number;
  a10fourWheelerAmount: number;
  a11threeWheelerCount: number;
  a12threeWheelerAmount: number;
  a13Date: string;
  a14total: number;
}

@Component({
  selector: 'app-business-summary',
  templateUrl: './business-summary.component.html',
  styleUrls: ['./business-summary.component.css']
})
export class BusinessSummaryComponent implements OnInit, OnDestroy {

  db: AngularFirestore;
  adb: AngularFireDatabase;
  title = 'Business Summary';

  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: string;
  groupID: string;
  public isCollapsed = true;
  attendantList: AttendantInfo[] = [];
  rearrangedAttendantArray: AttendantPresence[];
  vehCount: number;
  counterReportSubs: Subscription;
  counterReportSubs1: Subscription;
  counterReportSubs2: Subscription;
  vehicles: Observable<any[]>;
  in2Counter: number;
  in4Counter: number;
  occ2Counter: number;
  occ4Counter: number;
  entrycount: number;
  exitcount: number;
  activpasses: number;
  activeattendant = 0;
  foc: number;
  twoWheelerTicket = 0;
  fourWheelerTicket = 0;
  threeWheelerTicket = 0;
  twoWheelerPass = 0;
  fourWheelerPass = 0;
  threeWheelerPass = 0;
  twoWheelerexTerminal = 0;
  fourWheelerexTerminal = 0;
  threeWheelerexTerminal = 0;
  twoWheelerexPhone = 0;
  fourWheelerexPhone = 0;
  threeWheelerexPhone = 0;
  parkingTotalFare: number;
  exitedByName: string;
  model: NgbDateStruct;
  dateFilter: BehaviorSubject<number | null>;
  StrMMYYYY: String;
  StrDate: string;
  // total = 0;
  vrParkingTotalFare: number;
  amount: string;
  data: Vehicle[] = [];
  private passCollection: AngularFirestoreCollection<Pass>;
  custom: string;
  StrYYYY: string;
  StrMM: string;
  selectedDate: Date;
  data1: DemoCount[];
  demoReportSubs: Subscription;
  counts: Observable<DemoCount>;
  rearrangedReport1Array: Report1[] = [];
  private demoCollection: AngularFirestoreCollection<DemoCount>;
  dateFilter1: BehaviorSubject<string | null>;
  attendantReportSubs: Subscription;
  private AttendantCollection: AngularFirestoreCollection<AttendantFireStore>;
  Attendants: Observable<AttendantFireStore[]>;
  attendantCount: string[] = [];
  regularList: Observable<any[]>;
  regularReportSub: Subscription;
  rearrangedRegularArray: Counters[] = [];



  constructor(private databaseService: DatabaseService, db: AngularFirestore, adb: AngularFireDatabase,
    private pathService: PathSetterService, calendar: NgbCalendar, private modalService: NgbModal) {
    this.db = db;
    this.adb = adb;
    this.custom = '100% OFF';
    // this.amount = 0;
    this.exitedByName = 'Ticketing Terminal , Get Parking';
    this.parkingTotalFare = 0;
    const date = new Date();
    this.model = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
    this.dateFilter = new BehaviorSubject(new Date().getTime());
    const StrToday = this.setDateFormat(this.model);
    this.dateFilter1 = new BehaviorSubject(StrToday);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    this.StrYYYY = this.setCalenderYear(this.model);
    this.StrMM = this.setCalenderMonth(this.model);
    this.selectedDate = this.getTimeStamp(this.model);
    this.setGroupID();
    this.StrDate = this.setCalenderdate(this.model);
    this.setBusinessDropDownList();

  }

  ngOnInit() {
    this.resetCounters();
    this.vehicleEnteredReports();
    this.generateExit();
    this.attendantInfo();
    this.passesInfo();
    this.focCount();
    this.loadCollection();
    this.loadList1();
  }

  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  setDateFormat(model) {
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    const StrToday = Strdd + '-' + Strmm + '-' + yyyy;
    return StrToday;
  }
  setCalenderdate(model) {
    let Strdd = null;
    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const StrDate = Strdd;
    return StrDate;
  }

  setCalenderMonthYear(model) {
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    const yyyy = model.year;

    // const StrToday = Strmm + '-' + yyyy;
    const StrToday = yyyy + Strmm;
    return StrToday;
  }
  setCalenderYear(model) {
    const yyyy = model.year;
    return yyyy;
  }

  setCalenderMonth(model) {
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    return Strmm;
  }

  filterByDate(model) {
    // console.log('date:', model);
    // this.resetCounters();
    this.counterReportSubs.unsubscribe();
    this.counterReportSubs1.unsubscribe();
    this.counterReportSubs2.unsubscribe();
    this.dateFilter.next(new Date().getTime());
    const StrToday = this.setDateFormat(model);
    this.dateFilter1.next(StrToday);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    this.StrYYYY = this.setCalenderYear(this.model);
    this.StrMM = this.setCalenderMonth(this.model);
    this.StrDate = this.setCalenderdate(this.model);
    this.selectedDate = this.getTimeStamp(this.model);
    this.vehicleEnteredReports();
    this.generateExit();
    this.attendantInfo();
    this.focCount();
    this.passesInfo();
    this.loadCollection();
  }

  // Set dates filter with dates datatypes
  getTimeStamp(model) {
    const date = new Date();
    date.setMonth(model.month - 1);
    date.setFullYear(model.year);
    date.setDate(model.day);
    return date;
  }


  setBusinessID(biz) {
    // this.resetCounters();
    this.counterReportSubs.unsubscribe();
    this.counterReportSubs1.unsubscribe();
    this.counterReportSubs2.unsubscribe();
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.vehicleEnteredReports();
    this.generateExit();
    this.attendantInfo();
    this.passesInfo();
    this.focCount();
    this.loadCollection();
  }

  resetCounters() {
    this.loadList1();
    this.twoWheelerPass = 0;
    this.fourWheelerPass = 0;
    this.threeWheelerPass = 0;
    this.twoWheelerTicket = 0;
    this.fourWheelerTicket = 0;
    this.threeWheelerTicket = 0;
    this.fourWheelerexPhone = 0;
    this.fourWheelerexTerminal = 0;
    this.twoWheelerexPhone = 0;
    this.twoWheelerexTerminal = 0;
    this.threeWheelerexPhone = 0;
    this.threeWheelerexTerminal = 0;
  }

  loadList1() {
    this.regularList = this.adb.list('/getParking/' + this.pathService.getPathEnvironment() +
      '/Counter/' + this.businessID + '/regular_park/').valueChanges();

    this.regularReportSub = this.regularList
      .pipe(take(1))
      .subscribe((response) => {
        this.data = response;
        // console.log('Saket pagali:', response[0].capacity);
        this.rearrangedRegularArray = [];
        for (let i = 0; i < this.data.length; i++) {
          const regularItem = this.data[i];
          // console.log('Ami', this.data[0]['capacity']);

          let tempCapacity4W = 0;
          let tempCapacity2W = 0;
          let tempOccupancy2W = 0;
          let tempOccupancy4W = 0;
            tempCapacity2W = this.data[0]['capacity'];
            tempOccupancy2W = this.data[0]['occupancy'];
            tempCapacity4W = this.data[1]['capacity'];
            tempOccupancy4W = this.data[1]['occupancy'];

          // title = companyItem['title'];
            this.rearrangedRegularArray.push({
            a1capacity2W: isNaN(tempCapacity2W) ? 0 : tempCapacity2W,
            a2occupancy2W: isNaN(tempOccupancy2W) ? 0 : tempOccupancy2W,
            a3capacity4W: isNaN(tempCapacity4W) ? 0 : tempCapacity4W,
            a4occupancy4W: isNaN(tempOccupancy4W) ? 0 : tempOccupancy4W,
          });


          // this.progressBarVisible = false;
        }
        // console.log(this.rearrangedRegularArray);
      },
        (error) => {
          console.log(error);
        }
      );
  }

  loadCollection() {
    // When there is odd path in firestore and you want to fetch it then, take doc and
    this.counts = this.db.doc<DemoCount>('/reports/' + this.groupID + '/bizs/' + this.businessID +
      '/daily_summary_from_vr/' + this.dateFilter1.value).valueChanges();
    this.demoReportSubs = this.counts.subscribe(
      (response) => {
        this.resetCounters();
        // console.log('Deko', response);
        this.rearrangedReport1Array = [];
        const reportItem = response;
        this.rearrangedReport1Array.push({
          a13Date: new Date(reportItem.timeStamp).toLocaleDateString(),
          a1twoWheelerCount: reportItem.twoWheelerCount,
          a2twoWheelerAmount: reportItem.twoWheelerAmount,
          a11threeWheelerCount: reportItem.threeWheelerCount,
          a12threeWheelerAmount: reportItem.threeWheelerAmount,
          a9fourWheelerCount: reportItem.fourWheelerCount,
          a10fourWheelerAmount: reportItem.fourWheelerAmount,
          a7cycCount: reportItem.cycCount,
          a8cycAmount: reportItem.cycAmount,
          a3cartCount: reportItem.cartCount,
          a4cartAmount: reportItem.cartAmount,
          a5cvCount: reportItem.cvCount,
          a6cvAmount: reportItem.cvAmount,
          a14total: reportItem.twoWheelerAmount + reportItem.threeWheelerAmount + reportItem.fourWheelerAmount
            + reportItem.cycAmount + reportItem.cartAmount + reportItem.cvAmount,
        });
      },
      (error) => { console.log(error); }
    );
  }


  attendantInfo() {
    this.vehicles = this.adb.list('/getParking/' + this.pathService.getPathEnvironment() + '/operator_presence/', ref =>
      ref.orderByChild('business_id').equalTo(this.businessID)).valueChanges();
    this.counterReportSubs = this.vehicles.subscribe(
      (data) => {
        this.activeattendant = data.length;
    // console.log('attendants count', this.activeattendant);
    });
  }

  focCount() {
    this.vehicles = this.db.collection<Vehicle>('/groups/' + this.groupID + '/bizs/' + this.businessID +
      '/parkingRecords/' + this.StrYYYY + '/' + this.StrMM + '/' + this.StrDate + '/' + 'records'
      , ref => ref.where('customExitType', '==', this.custom)).valueChanges();
    this.counterReportSubs = this.vehicles.subscribe(
      (response) => {
        this.foc = response.length;
        // console.log('FOC', this.foc);
      });
  }

  generateExit() {
    this.resetCounters();
    const today = new Date(this.selectedDate);
    today.setHours(15);
    const todayEpochDay = Math.floor((today.getTime() + 330 * 60 * 1000) / (1000 * 60 * 60 * 24));
    const filterReportDate = this.selectedDate;
    // filterReportDate.setHours(0, 0, 0);
    this.vehicles = this.db.collectionGroup<Vehicle>('records', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;

      query = query.where('vrEndEpochDay', '==', todayEpochDay);
      query = query.where('vrBusinessId', '==', this.businessID);
      return query;
    }).valueChanges();

    this.counterReportSubs2 = this.vehicles.subscribe(
      (response) => {
        this.resetCounters();
        // console.log('ecit res:' , response);
        this.exitcount = response.length;
         console.log('Exit Count dekh:' , this.exitcount);
      }
    );
  }

  passesInfo() {
    // console.log('cegsdfF:' , this.dateFilter);
    // console.log('retrteddd', '/bizs/' + this.businessID + '/passes/');
    // this.vehicles = this.db.collection<Pass>('/bizs/' + this.businessID + '/passes/').valueChanges();
    this.passCollection = this.db.collection<Pass>('/bizs/' + this.businessID + '/passes/', ref =>
      ref.where('passValidTill', '>=', this.dateFilter.value));
    this.vehicles = this.passCollection.valueChanges();
    this.counterReportSubs = this.vehicles.subscribe(
      (response) => {
        // console.log('responce', response);
        this.activpasses = response.length;
        //  console.log('check for passes', response.length);
      }
    );
  }

  vehicleEnteredReports() {
    this.resetCounters();
    const today = new Date(this.selectedDate);
    today.setHours(15);
    const todayEpochDay = Math.floor((today.getTime() + 330 * 60 * 1000) / (1000 * 60 * 60 * 24));

    let filterReportDate = this.selectedDate;
    filterReportDate = this.selectedDate;
    this.vehicles = this.db.collectionGroup<Vehicle>('records', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('vrStartEpochDay', '==', todayEpochDay);
      query = query.where('vrBusinessId', '==', this.businessID);
      return query;
    }).valueChanges();

    this.counterReportSubs1 = this.vehicles.subscribe(
      (response) => {
        this.resetCounters();
        this.entrycount = response.length;
      }
    );
  }

  // load2WList() {
  //   // console.log('check', '/getParking/' + this.pathService.getPathEnvironment() +
  //   //   '/Counter/' + this.businessID + '/regular_park/2W/capacity');
  //   // URL
  //   const dbCount = this.adb.database.ref('/getParking/' + this.pathService.getPathEnvironment() +
  //     '/Counter/' + this.businessID + '/regular_park/2W/capacity');
  //   // tslint:disable-next-line:semicolon
  //   this.counterReportSubs = this.adb.object(dbCount).valueChanges()
  //     .subscribe((response) => {
  //       // console.log('2W count', response);
  //       const jsonStr = JSON.stringify(response);
  //       // console.log('ifj', jsonStr);
  //       const jsonObj = JSON.parse(jsonStr);
  //       this.in2Counter = jsonObj;
  //       // console.log('check loop counts' + this.in2Counter);
  //     }
  //     );
  // }
  // load4WList() {
  //   // console.log('check', '/getParking/' + this.pathService.getPathEnvironment() +
  //   //   '/Counter/' + this.businessID + '/regular_park/2W/capacity');
  //   // URL
  //   const dbCount = this.adb.database.ref('/getParking/' + this.pathService.getPathEnvironment() +
  //     '/Counter/' + this.businessID + '/regular_park/4W/capacity');
  //   // tslint:disable-next-line:semicolon
  //   this.counterReportSubs = this.adb.object(dbCount).valueChanges()
  //     .subscribe((response) => {
  //       // console.log('4W count', response);
  //       const jsonStr = JSON.stringify(response);
  //       // console.log('ifj', jsonStr);
  //       const jsonObj = JSON.parse(jsonStr);
  //       this.in4Counter = jsonObj;
  //       // console.log('check loop counts' + this.in4Counter);
  //     }
  //     );
  // }
  // load2WOcc() {
  //   // URL
  //   const dbCount = this.adb.database.ref('/getParking/' + this.pathService.getPathEnvironment() +
  //     '/Counter/' + this.businessID + '/regular_park/2W/occupancy');
  //   // tslint:disable-next-line:semicolon
  //   this.counterReportSubs = this.adb.object(dbCount).valueChanges()
  //     .subscribe((response) => {
  //       // console.log('2W occcount', response);
  //       const jsonStr = JSON.stringify(response);
  //       // console.log('ifj', jsonStr);
  //       const jsonObj = JSON.parse(jsonStr);
  //       this.occ2Counter = jsonObj;
  //       // console.log('s2occ' + this.occ2Counter);
  //     }
  //     );
  // }
  // load4WOcc() {
  //   // URL
  //   const dbCount = this.adb.database.ref('/getParking/' + this.pathService.getPathEnvironment() +
  //     '/Counter/' + this.businessID + '/regular_park/4W/occupancy');
  //   // tslint:disable-next-line:semicolon
  //   this.counterReportSubs = this.adb.object(dbCount).valueChanges()
  //     .subscribe((response) => {
  //       // console.log('4W occcount', response);
  //       const jsonStr = JSON.stringify(response);
  //       // console.log('ifj', jsonStr);
  //       const jsonObj = JSON.parse(jsonStr);
  //       this.occ4Counter = jsonObj;
  //       // console.log('s4occ' + this.occ4Counter);
  //     }
  //     );
  // }
  ngOnDestroy() {
    this.resetCounters();
    this.counterReportSubs.unsubscribe();
    this.counterReportSubs1.unsubscribe();
    this.counterReportSubs2.unsubscribe();
  }
}

