import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';

import { Pass } from '../../passes/pass.model';
import { PassFireStore } from '../../add-passes/passFireStore.model';
import { DatabaseService } from '../../services/database.service';
import { DownloadService } from '../../services/download.service';
import { CityPassEditService } from '../citypassEdit.service';
import { PathSetterService } from '../../services/pathSetter.service';
import { AddCitypassPopupComponent } from '../add-citypass-popup/add-citypass-popup.component';
import { EditCitypassPopupComponent } from '../edit-citypass-popup/edit-citypass-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase/app';
import { map, switchMap, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

export interface Report {

  a1parkingPassBusinessId: string;
  a2parkingPassDisplayId: string;
  a3passNumberPlate: string;
  a4passVehicleType: string;
  a5passValidFrom: string;
  a6passValidTill: string;
  a7passDurationType: string;
  a8passStatus: string;
  a9passCompanyName: string;
  a10userEmail: string;
  a11userName: string;
  a12userPhone: string;
  a13parkingDedicated: string;
  a14parkingDedicatedSlot: string;
  a15parkingSlot: string;
  a16parkingUnitNo: string;
  a17passAmount: number;
  a18passIssuedById: string;
  a19passIssueTimeStamp: string;
  a20passModificationTimeStamp: string;
  a21passModifiedById: string;
}


@Component({
  selector: 'app-valid-city-passes',
  templateUrl: './valid-city-passes.component.html',
  styleUrls: ['./valid-city-passes.component.css']
})
export class ValidCityPassesComponent implements OnInit, OnDestroy {

  db: AngularFirestore;
  afdb: AngularFireDatabase;
  title = 'Valid City Pass Summary';

  data: Pass[];

  headerData: string[] = [
    'Location', 'Pass ID', 'Numberplate', 'Vehicle Type', 'Valid From', 'Valid Till', 'Duration Type', 'Status',
    'Company Name', 'User Email', 'User Name', 'User Phone', 'Parking Dedicated', 'Parking Dedicated Slot', 'Parking Slot',
    'Parking Unit No', 'Amount', 'Issued By', 'Issue Time', 'Edit Time', 'Edited By'
  ];

  rearrangedReportArray: Report[] = [];

  dateFilter: BehaviorSubject<number | null>;

  passReportSubs: Subscription;
  groupID: string;
  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: string;
  progressBarVisible = false;
  p = 1;

  private passCollection: AngularFirestoreCollection<Pass>;
  passes: Observable<Pass[]>;

  constructor(private modalService: NgbModal, db: AngularFirestore, private databaseService: DatabaseService, afdb: AngularFireDatabase,
    private downloadService: DownloadService, private pathService: PathSetterService, public snackBar: MatSnackBar,
    private citypassEditService: CityPassEditService) {
    this.db = db;
    this.afdb = afdb;

    const date = new Date();
    this.dateFilter = new BehaviorSubject(date.getTime());
    this.setGroupID();
    this.setBusinessDropDownList();

  }

  ngOnInit() {
    this.loadList();
  }

  loadList() {
    // console.log(this.dateFilter.value);
    this.passCollection = this.db.collection<Pass>('/bizs/' + this.businessID + '/passes', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('passValidTill', '>=', this.dateFilter.value);
      query = query.where('parkingPassGroupId', '==', this.groupID);
      return query;
    });

    this.passes = this.passCollection.valueChanges();


    this.passReportSubs = this.passes
      .pipe(
        take(1),
        map(
          (response) => {
            const Passes: Pass[] = response;
            for (const passItem of Passes) {

              if (!passItem.parkingDedicated) { passItem.parkingDedicated = 'N/A'; }
              if (!passItem.passCompanyName) { passItem.passCompanyName = 'N/A'; }
              if (!passItem.passDurationType) { passItem.passDurationType = 'N/A'; }
              if (!passItem.userEmail) { passItem.userEmail = 'N/A'; }
              if (!passItem.userFirstName) { passItem.userFirstName = 'N/A'; }
              if (!passItem.userPhone) { passItem.userPhone = 'N/A'; }
              if (!passItem.parkingUnitNo) { passItem.parkingUnitNo = 'N/A'; }
              if (!passItem.parkingSlot) { passItem.parkingSlot = 'N/A'; }
              if (!passItem.passModifiedById) { passItem.passModifiedById = 'N/A'; }
              if (!passItem.passNumberPlate) { passItem.passNumberPlate = 'N/A'; }
              if (!passItem.passNumberPlateDigits) { passItem.passNumberPlateDigits = 'N/A'; }
              if (!passItem.passVehicleType) { passItem.passVehicleType = 'N/A'; }

              if (passItem.passStatus === 141) {
                passItem.passStatus = 'Valid';
              } else if (passItem.passStatus === 142) {
                passItem.passStatus = 'Expired';
              } else if (passItem.passStatus === 143) {
                passItem.passStatus = 'Pending';
              } else if (passItem.passStatus === 144) {
                passItem.passStatus = 'Deleted';
              } else {
                passItem.passStatus = 'Undefined';
              }
              passItem.passIssueTimeStamp = new Date(passItem.passIssueTimeStamp).toLocaleString();
              passItem.passModificationTimeStamp = new Date(passItem.passModificationTimeStamp).toLocaleTimeString();
              passItem.passValidFrom = new Date(passItem.passValidFrom).toLocaleDateString();
              passItem.passValidTill = new Date(passItem.passValidTill).toLocaleDateString();

              for (let k = 0; k < this.businessList.length; k++) {
                if (passItem.parkingPassBusinessId === this.businessList[k].id) {
                  passItem.parkingPassBusinessId = this.businessList[k].name;
                }
              }

            }
            return Passes;
          }))
      .subscribe(
        (response) => {
          this.data = response;
          this.rearrangedReportArray = [];
          // console.log(this.data[0]);
          for (let i = 0; i < this.data.length; i++) {
            const reportItem = this.data[i];
            this.rearrangedReportArray.push({
              a1parkingPassBusinessId: reportItem.parkingPassBusinessId,
              a2parkingPassDisplayId: reportItem.parkingPassDisplayId,
              a3passNumberPlate: reportItem.passNumberPlate,
              a4passVehicleType: reportItem.passVehicleType,
              a5passValidFrom: reportItem.passValidFrom,
              a6passValidTill: reportItem.passValidTill,
              a7passDurationType: reportItem.passDurationType,
              a8passStatus: reportItem.passStatus,
              a9passCompanyName: reportItem.passCompanyName,
              a10userEmail: reportItem.userEmail,
              a11userName: reportItem.userFirstName + ' ' + reportItem.userLastName,
              a12userPhone: reportItem.userPhone,
              a13parkingDedicated: reportItem.parkingDedicated,
              a14parkingDedicatedSlot: reportItem.parkingDedicatedSlot,
              a15parkingSlot: reportItem.parkingSlot,
              a16parkingUnitNo: reportItem.parkingUnitNo,
              a17passAmount: reportItem.passAmount,
              a18passIssuedById: reportItem.passIssuedById,
              a19passIssueTimeStamp: reportItem.passIssueTimeStamp,
              a20passModificationTimeStamp: reportItem.passModificationTimeStamp,
              a21passModifiedById: reportItem.passModifiedById,
            });
            this.progressBarVisible = false;

          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  stopLoader() {
    this.progressBarVisible = false;
  }

  downloadExcelReport() {
    const reportLabel = this.title + ' ' + this.dateFilter.value + ' Report';
    this.downloadService.getExcelReport(this.rearrangedReportArray, reportLabel, this.headerData);
  }

  downloadPDFReport() {
    const reportLabel = this.title + ' ' + this.dateFilter.value + ' Report';

    const options = {
      styles: {
        fontSize: 2.8,
        font: 'helvetica', // helvetica, times, courier
      },
      startY: true, // false (indicates margin top value) or a number
      margin: { left: 1 },
      theme: 'striped',
      fillColor: false, // false for transparent or a color as described below
      textColor: 20,
      columnWidth: 'auto', // 'auto', 'wrap' or a number
      tableWidth: 'wrap', // 'auto', 'wrap' or a number,

    };

    this.downloadService.getPDFReport(this.rearrangedReportArray, reportLabel, this.headerData, options);
  }

  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setBusinessID(biz) {
    this.progressBarVisible = true;
    this.passReportSubs.unsubscribe();
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.loadList();
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  openModal() {
    const modalRef = this.modalService.open(AddCitypassPopupComponent);
    modalRef.componentInstance.name = 'World';
    modalRef.result.then(
      () => {
        this.rearrangedReportArray = [];
        this.data = [];
        this.passReportSubs.unsubscribe();
        this.loadList();
      }
    ).catch((error) => {
      console.log(error);
      console.log('cancelling');
    });

  }

  removePassItem(id: any) {
    // deleteing from firestore
    const passDelCollection = this.db.collection<PassFireStore>('/bizs/' + this.businessID + '/passes/');
    passDelCollection.doc(this.data[id]['passFirestoreDocKey']).delete().then(
      () => {
        this.rearrangedReportArray = [];
        this.data = [];
        this.passReportSubs.unsubscribe();
        this.loadList();
      });

    // deleting from firebase
    const itemsDelRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() + '/gpGroupPasses/' + this.groupID + '/All/');
    itemsDelRef.remove(this.data[id]['passFirebaseId']);
    this.snackBar.open('City-pass deleted', 'dismiss', {
      duration: 3000
    });


  }

  editPassItem(index: any) {
    this.passReportSubs.unsubscribe();
    this.loadList();
    // console.log(this.data[index]);
    this.citypassEditService.setPassEditServiceDetails(this.data[index], this.businessID);
    const modalRef2 = this.modalService.open(EditCitypassPopupComponent);
    modalRef2.componentInstance.name = 'World';
  }

  ngOnDestroy() {
    this.passReportSubs.unsubscribe();
  }

}
