import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { Router } from '@angular/router';
import { PathSetterService } from '../services/pathSetter.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { User } from '../services/user.model';
import { DatabaseService } from '../services/database.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit, OnDestroy {
  public navbarCollapsed = true;
  public IsArya: boolean;
  public IsProduction: boolean;
  public IsDemo: boolean;
  public IsBig: boolean;
  public IsMcgm: boolean;
  
  user: User;
  userSub: Subscription;
  businessList: any[];
  businessID: string;
  businessName: string;
  constructor(private databaseService: DatabaseService,public authService: AuthService, private router: Router, private pathService: PathSetterService,) { 
    
  }

  ngOnInit() {
    this.setHeaderTabs();

    this.userSub = this.authService.user.pipe(
      take(1)
    ).subscribe(user => this.user = user);
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  logout() {
    this.userSub.unsubscribe();
    this.authService.signOut();
    // console.log('logged out');
  }

  canView() {
    if (this.authService.canRead(this.user)) {
      return true;
    } else {
      return false;
    }
  }

  setHeaderTabs() {
    this.IsArya = this.pathService.getAryaFeatures();
    this.IsDemo = this.pathService.getDemoInfo();
    // this.IsMcgm = this.pathService.getMcgmInfo();
    // this.IsBig = this.pathService.getBigInfo();
    if (this.pathService.getPathEnvironment() === 'production') {
      this.IsProduction = true;
    } else {
      this.IsProduction = false;
    }
  }


  isCapital() {
    if(this.databaseService.getGroupID() === '-MnyrdXu9zbwu-lPCqp5'){
      return true;
    }
    return false;
  }

}
