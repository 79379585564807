import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DatabaseService } from '../../services/database.service';
import { PathSetterService } from '../../services/pathSetter.service';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { PassFireStore } from '../../add-passes/passFireStore.model';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { CapacityTags } from '../../tags/capacity-tags/capacityTags.model';
import { take, timestamp } from 'rxjs/operators';
import * as firebase from 'firebase';
import { Timestamp } from 'rxjs';
import { toDate } from '@angular/common/src/i18n/format_date';
import { PassMaster } from '../passmaster.model';
import { PassMasterFireStore } from '../../add-passes/passMasterFirestore.model';

export interface CompanyTags {
  companyName: string;
  companyID: string;
}


@Component({
  selector: 'app-add-pass-popup',
  templateUrl: './add-pass-popup.component.html',
  styleUrls: ['./add-pass-popup.component.css']
})
export class AddPassPopupComponent implements OnInit {

  groupID: string;
  businessList: any[];
  businessID: string;
  businessName: string;
  model = 1;
  isCityPass = false;
  ischecked = false;
  isArya: boolean;
  FromDateModel;
  ToDateModel;
  localFirebaseID: string;
  localFirebaseMasterID: string;
  afdb: AngularFireDatabase;
  afs: AngularFirestore;
  minDate: any;
  capacityID: string;
  capacityName: string;
  companyTagsArray: CompanyTags[] = [];
  data: CapacityTags[];
  tagsReportSubs: Subscription;
  private capacityTagsCollection: AngularFirestoreCollection<CapacityTags>;
  capacityTags: Observable<CapacityTags[]>;
  passServerTime: Date;
  serverTimestamp: any;

  registrationProof = [
    {id: 0, name: 'Select Proof'},
    {id: 1, name: 'IDCard'},
    {id: 2, name: 'DrivingLicence'},
    {id: 3, name: 'RegistrationCertificate'},
  ];
  selectedValue = 0;



  constructor(public activeModal: NgbActiveModal, private databaseService: DatabaseService,
    afdb: AngularFireDatabase, afs: AngularFirestore, private pathService: PathSetterService, public snackBar: MatSnackBar) {
    //  .serverTimestamp = firebase.firestore.Timestamp.fromDate(new Date().toString());
    //       firestore().collection("item")
    //  .add({...item, created: firebase.firestore.Timestamp.fromDate(new Date()) })
    this.minDate = { year: new Date().getFullYear(), month: (new Date().getMonth()) + 1, day: new Date().getDate() };
    this.FromDateModel = { year: new Date().getFullYear(), month: (new Date().getMonth()) + 1, day: new Date().getDate() };

    if (this.FromDateModel.month === 12) {
      this.ToDateModel = { year: new Date().getFullYear() + 1, month: 1, day: new Date().getDate() };
    } else {
      this.ToDateModel = { year: new Date().getFullYear(), month: (new Date().getMonth()) + 2, day: new Date().getDate() };
    }

    this.afdb = afdb;
    this.afs = afs;
    this.isArya = this.pathService.getAryaFeatures();
    this.capacityName = 'select';
    this.setGroupID();
    this.setBusinessList();

  }

  ngOnInit() { }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  setCompanyTags() {

    this.capacityTagsCollection = this.afs.collection<CapacityTags>('/groups/' + this.groupID + '/bizs/' + this.businessID +
      '/corporate_tags/');
    this.capacityTags = this.capacityTagsCollection.valueChanges();

    this.tagsReportSubs = this.capacityTags.pipe(
      take(1)
    ).subscribe(
      (response) => {
        this.data = response;
        this.companyTagsArray = [
          {
            companyID: '',
            companyName: 'NA'
          }
        ];
        for (let i = 0; i < this.data.length; i++) {
          const reportItem = this.data[i];
          // console.log(reportItem);
          this.companyTagsArray.push({
            companyName: reportItem.gpTagFullTitle,
            companyID: reportItem.gpTagId
          });
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }

  setCompanyID(tag) {
    this.capacityID = tag.companyID;
    this.capacityName = tag.companyName;
    // console.log(this.capacityID);
    // console.log(this.capacityName);
  }

  setBusinessList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
    this.setCompanyTags();
  }

  setBusinessID(biz) {
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.setCompanyTags();
  }

  setDateFormat(model, isTillDate: boolean) {
    // console.log(model);
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    const StrToday = Strmm + '/' + Strdd + '/' + yyyy;
    const convertedDate = new Date(StrToday);

    if (isTillDate) {
      convertedDate.setHours(23);
      convertedDate.setMinutes(59);
      convertedDate.setSeconds(59);
    }

    return convertedDate.getTime();
  }

  onSubmit(Formdata) {
    this.activeModal.close('Close click');

    // console.log(this.companyTagsArray);

    let vehType: string;
    let passGroupID: string;
    let passTitle: string;
    let itemkey = null;
    let itemKey1 = null;
    let parkingSlotNum: string;
    let unitNum: string;
    let regiProof: string;
    // let dayMonth: boolean;
    // let dayofWeek: boolean;
    // let maxNumber: number;

    if (!this.isArya) {
      parkingSlotNum = Formdata.parkingSlot;
      unitNum = Formdata.unitNum;
    }
    const validFromTimeStamp = this.setDateFormat(Formdata.dp1, false);
    const validTillTimeStamp = this.setDateFormat(Formdata.dp2, true);
    const validFromEpochDate = (validFromTimeStamp + 330 * 60 * 1000) / (1000 * 60 * 60 * 24);
    const validTillEpochDate = (validTillTimeStamp + 330 * 60 * 1000) / (1000 * 60 * 60 * 24);
    const serverTimestamp = firebase.firestore.Timestamp.fromDate(new Date());
    this.serverTimestamp = serverTimestamp.seconds;
    // console.log('Dagli' , serverTimestamp.seconds);


    if (Formdata.radioVehType === 1) {
      vehType = '4W';
    } else {
      vehType = '2W';
    }

    if (Formdata.dropRegisProof === "0") {
      regiProof = 'Select Proof'; 
    } else if (Formdata.dropRegisProof === "1"){
      regiProof = 'IDCard';
    } else if (Formdata.dropRegisProof === "2"){
      regiProof = 'DrivingLicence';
    } else {
      regiProof = 'RegistrationCertificate';
    }
    passGroupID = '';
    passGroupID = '';
    passTitle = 'normal';

    if (this.capacityName === 'select' || this.capacityName === 'NA') {
      this.capacityID = '';
      this.capacityName = '';
    }

    if (this.businessID !== null) {

      // Firebase URL
      const itemsRef = this.afdb.database.ref()
      .child('/getParking/' + this.pathService.getPathEnvironment() + '/passes/' + this.businessID + '/All').push();
      const passKey = itemsRef.key;
      // Firestore URL
      // tslint:disable-next-line:prefer-const
      let passCollection = this.afs.collection<PassFireStore>('/bizs/' + this.businessID + '/passes/');

      // Firebase PassMaster URL
      // tslint:disable-next-line:max-line-length

      const itemsRef1 = this.afdb.database.ref().child('/getParking/' + this.pathService.getPathEnvironment() +
        '/passes/' + this.businessID + '/passMaster').push();
      const passMasterKey = itemsRef1.key;
      //     const itemsRef1 = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() +
      // '/passes/' + this.businessID + '/passMaster');

      // Firestore PassMaster URL
       const passMasterCollection = this.afs.collection<PassMasterFireStore>('/bizs/' + this.businessID + '/passMaster/');

      // if (!Formdata.paymentCheckbox) {
      //   passCollection = this.afs.collection<PassFireStore>('/groups/' + this.groupID + '/bizs/' + this.businessID + '/pendingPasses/');
      // }

      this.localFirebaseMasterID = '';
      console.log('uheru:' , passKey);
      Promise.resolve(
        itemsRef1.update({
          childPasses: ({[passKey]: vehType }),
          parkingPassDisplayId: '', // NA
          parkingPassBusinessId: this.businessID,
          parkingPassGroupId: passGroupID,
          passAmount: Formdata.amt,
          passCompanyName: this.capacityName, // add for OC flavour
          passDurationType: '', // fix later
          passFirestoreDocKey: '', // fix later
          passImageUrl: '', // NA
          passIssueTimeStamp: new Date().getTime(),
          passIssuedById: 'Manager',
          passLocalTimeReverse: 0 - new Date().getTime(),
          passMasterFirebaseId: passMasterKey,
          passModificationTimeStamp: new Date().getTime(),
          passModifiedById: 'Dashboard Admin',
          passNumberPlate: Formdata.numplate,
          passNumberPlateDigits: Formdata.numplate.substring(Formdata.numplate.length - 4),
          passOriginalAmount: Formdata.amt,
          passRegistrationProof: regiProof,
          passRegistrationId: Formdata.regid,
          passReceiptId: '', // NA
          passRfidTag: '', // NA
          passStatus: 141,
          passTitle: passTitle,
          passValidFrom: validFromTimeStamp,
          passValidFromEpochDay: validFromEpochDate,  // add now
          passValidTill: validTillTimeStamp,
          passValidTillEpochDay: validTillEpochDate, // add now
          passVehicleId: '', // NA
          passVehicleOwnerId: '', // NA
          passVehicleType: vehType,
          userEmail: Formdata.email,
          userFirstName: Formdata.firstname,
          userLastName: Formdata.lastname,
          userPhone: Formdata.phone,
        })
        .then((item) => {
          // console.log(item);
          itemKey1 = passMasterKey;
          this.localFirebaseMasterID = itemKey1;
          // const obj: Map<string, string> = new Map();
          // //obj.set()
          Promise.resolve(
            passMasterCollection.doc(itemKey1).set({
              childPasses: ({[passKey]: vehType }),
              dailyDurationLimitMins: null,
              maxConcurrentEntries: 1,
              firebaseID: this.localFirebaseMasterID,
              id: '',
              parkingDedicated: null, // fix
              parkingDedicatedSlot: 148, // fix
              parkingPassBusinessId: this.businessID,
              parkingPassDisplayId: '', // NA
              parkingPassGroupId: passGroupID,
              parkingSlot: '' + parkingSlotNum, // fix
              parkingUnitNo: '' + unitNum, // fix
              passAmount: Formdata.amt,
              passCompanyName: this.capacityName, // fix
              passCompanyTagId: this.capacityID, // fix
              passDurationTillMonthEnd: false, // fix
              passDurationType: '', // fix
              passMasterFirebaseId: passMasterKey, // fix
              passImageUrl: '', // NA
              passIsPrimary: 0, // fix
              passIssueTimeStamp: new Date().getTime(),
              passIssuedById: 'Dashboard Admin',
              passLocalTimeReverse: 0 - new Date().getTime(),
              // passMasterId: passMasterKey, // TODO
              passModificationTimeStamp: new Date().getTime(),
              passModifiedById: 'Dashboard Admin',
              passNumberPlate: Formdata.numplate,
              passNumberPlateDigits: Formdata.numplate.substring(Formdata.numplate.length - 4),
              passOriginalAmount: Formdata.amt,
              passRegistrationProof: regiProof,
              passRegistrationId: Formdata.regid,
              passParkingRestrictions: null, // fix
              passReceiptId: '', // NA
              passRfidTag: '', // NA
              passStatus: 141,
              passTitle: passTitle,
              passValidFrom: validFromTimeStamp,
              passValidFromEpochDay: validFromEpochDate,  // add now
              passValidTill: validTillTimeStamp,
              passValidTillEpochDay: validTillEpochDate,  // add now
              passVehicleBrandId: '', // NA
              passVehicleBrandName: '', // NA
              passVehicleId: '', // NA
              passVehicleModelId: '', // NA
              passVehicleModelName: '', // NA
              passVehicleOwnerId: '', // NA
              passVehicleType: vehType,
              userEmail: Formdata.email,
              userFirstName: Formdata.firstname,
              userLastName: Formdata.lastname,
              userPhone: Formdata.phone,
              validityDetails: null,
              serverTimestamp: serverTimestamp, // fix
              serverModificationTimeStamp: ({ 'sv' : 'timestamp' }),
              passFirestoreDocKey: itemKey1,
            }))
            // .then((fsitem) => {
            //   itemKey1 = fsitem.id;
            //   passMasterCollection.doc(itemKey1).update({ passFirestoreDocKey: itemkey });
            // })
            .catch(err => {
              console.log(err);
            });
        })
      );

      this.localFirebaseID = '';
      Promise.resolve(
        itemsRef.update({
          parkingPassDisplayId: '', // NA
          parkingPassBusinessId: this.businessID,
          parkingPassGroupId: passGroupID,
          passAmount: Formdata.amt,
          passCompanyName: this.capacityName, // add for OC flavour
          passDurationType: '', // fix later
          passFirebaseId: '',
          passFirestoreDocKey: '', // fix later
          passImageUrl: '', // NA
          passIssueTimeStamp: new Date().getTime(),
          passIssuedById: 'Manager',
          passLocalTimeReverse: 0 - new Date().getTime(),
          passMasterId: passMasterKey,
          passModificationTimeStamp: new Date().getTime(),
          passModifiedById: 'Dashboard Admin',
          passNumberPlate: Formdata.numplate,
          passNumberPlateDigits: Formdata.numplate.substring(Formdata.numplate.length - 4),
          passOriginalAmount: Formdata.amt,
          passRegistrationProof: regiProof,
          passRegistrationId: Formdata.regid,
          passReceiptId: '', // NA
          passRfidTag: '', // NA
          passStatus: 141,
          passTitle: passTitle,
          passValidFrom: validFromTimeStamp,
          passValidFromEpochDay: validFromEpochDate,  // add now
          passValidTill: validTillTimeStamp,
          passValidTillEpochDay: validTillEpochDate, // add now
          passVehicleId: '', // NA
          passVehicleOwnerId: '', // NA
          passVehicleType: vehType,
          userEmail: Formdata.email,
          userFirstName: Formdata.firstname,
          userLastName: Formdata.lastname,
          userPhone: Formdata.phone,
          serverTimestamp: serverTimestamp, // fix

        }))
        .then((item) => {
          itemkey = passKey;
          this.localFirebaseID = itemkey;
          Promise.resolve(
            passCollection.doc(itemkey).set({
              firebaseId: this.localFirebaseID,
              id: '',
              parkingDedicated: null, // fix
              parkingDedicatedSlot: 148, // fix
              parkingPassBusinessId: this.businessID,
              parkingPassDisplayId: '', // NA
              parkingPassGroupId: passGroupID,
              parkingSlot: '' + parkingSlotNum, // fix
              parkingUnitNo: '' + unitNum, // fix
              passAmount: Formdata.amt,
              passCompanyName: this.capacityName, // fix
              passCompanyTagId: this.capacityID, // fix
              passDurationTillMonthEnd: false, // fix
              passDurationType: '', // fix
              passFirebaseId: this.localFirebaseID, // fix
              passImageUrl: '', // NA
              passIsPrimary: 0, // fix
              passIssueTimeStamp: new Date().getTime(),
              passIssuedById: 'Dashboard Admin',
              passLocalTimeReverse: 0 - new Date().getTime(),
              passMasterId: passMasterKey, // TODO
              passModificationTimeStamp: new Date().getTime(),
              passModifiedById: 'Dashboard Admin',
              passNumberPlate: Formdata.numplate,
              passNumberPlateDigits: Formdata.numplate.substring(Formdata.numplate.length - 4),
              passOriginalAmount: Formdata.amt,
              passRegistrationProof: regiProof,
              passRegistrationId: Formdata.regid,
              passParkingRestrictions: null, // fix
              passReceiptId: '', // NA
              passRfidTag: '', // NA
              passStatus: 141,
              passTitle: passTitle,
              passValidFrom: validFromTimeStamp,
              passValidFromEpochDay: validFromEpochDate,  // add now
              passValidTill: validTillTimeStamp,
              passValidTillEpochDay: validTillEpochDate,  // add now
              passVehicleBrandId: '', // NA
              passVehicleBrandName: '', // NA
              passVehicleId: '', // NA
              passVehicleModelId: '', // NA
              passVehicleModelName: '', // NA
              passVehicleOwnerId: '', // NA
              passVehicleType: vehType,
              userEmail: Formdata.email,
              userFirstName: Formdata.firstname,
              userLastName: Formdata.lastname,
              userPhone: Formdata.phone,
              validityDetails: null,
              serverTimestamp: serverTimestamp, // fix
              passFirestoreDocKey: itemkey

            }))
            // .then((fsitem) => {
            //   itemkey = fsitem.id;
            //   passCollection.doc(itemkey).update({ passFirestoreDocKey: itemkey });
            // })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });

    }

  }
}
