import { Injectable } from '@angular/core';
import { AttendantInfo } from './attendantInfo.model';

@Injectable()
export class AttendantEditService {

  editAttendant: AttendantInfo;
  editBusinessID: string;

  setDeviceEditServiceDetails(Attendant: AttendantInfo, BusinessId: string) {
    this.editAttendant = Attendant;
    this.editBusinessID = BusinessId;
  }

  getAttendantInfo() {
    return this.editAttendant;
  }

  getBusinessID() {
    return this.editBusinessID;
  }
}
