import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { User } from '../services/user.model';
import { map, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  user: User;
  userSub: Subscription;

  constructor(public authService: AuthService) { }

  canView() {
    if (this.authService.canRead(this.user)) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.userSub = this.authService.user.pipe(
      take(1)
    ).subscribe(user => this.user = user);
    // console.log('Setting User in dashbord component: ' + this.user.userRole);
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

}
