

import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatabaseService } from '../services/database.service';
import { DownloadService } from '../services/download.service';
import { PathSetterService } from '../services/pathSetter.service';
import { Vehicle } from '../vehicles/vehicle.model';

@Component({
  selector: 'app-dwell',
  templateUrl: './dwell.component.html',
  styleUrls: ['./dwell.component.css']
})
export class DwellComponent implements OnInit {

   
  db: AngularFirestore;
  title = 'Dwell Report';

  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: string;
  groupID: string;

  model: NgbDateStruct;
  dateFilter: BehaviorSubject<string | null>;
  // dateFilterFrom: BehaviorSubject<number | null>;
  // dateFilterTo: BehaviorSubject<number | null>;
  StrMMYYYY: String;
  StrDate: string;
  StrYYYY: string;
  StrMM: string;
  selectedDate: Date;

  fromDate: any;
  toDate: any;
  fromEpoc: number;
  toEpoc: number;

  vehiclesReportSubs: Subscription;
  private vehicles: AngularFirestoreCollection<Vehicle>;
  vehicles2: Observable<Vehicle[]>;
  vehicles1: Observable<Vehicle[]>;
  data: any[] = [];


  cn2w15 = 0;
  cn2w1 = 0;
  cn2w2 = 0;
  cn2w3 = 0;
  cn2w4 = 0;
  cn2w5 = 0;
  cn2w6 = 0;
  cn2w7 = 0;
  cn2w8 = 0;
  cn2w9 = 0;
  cn2w10 = 0;
  cn2w101 = 0;
  tot2w = 0;
  bay2w = 364;
  turn2w = 0;


  cn4w15 = 0;
  cn4w1 = 0;
  cn4w2 = 0;
  cn4w3 = 0;
  cn4w4 = 0;
  cn4w5 = 0;
  cn4w6 = 0;
  cn4w7 = 0;
  cn4w8 = 0;
  cn4w9 = 0;
  cn4w10 = 0;
  cn4w101 = 0;
  tot4w = 0;
  bay4w = 393;
  turn4w = 0;



  headerData: string[] = [
    'Time Period' , '2W Count', '4W Count'
  ];


  constructor(private databaseService: DatabaseService, db: AngularFirestore, adb: AngularFireDatabase, private downloadService: DownloadService,
    private pathService: PathSetterService, calendar: NgbCalendar) {

    this.db = db;
    const date = new Date();
    this.model = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
    const StrToday = this.setDateFormat(this.model);
    this.dateFilter = new BehaviorSubject(StrToday);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    this.StrYYYY = this.setCalenderYear(this.model);
    this.StrMM = this.setCalenderMonth(this.model);
    this.selectedDate = this.getTimeStamp(this.model);
    this.setGroupID();
    this.StrDate = this.setCalenderdate(this.model);
    this.setBusinessDropDownList();
  }


  ngOnInit() {
    this.loadList();
  }

  resetCounter() {
    this.cn2w15 = 0;
    this.cn2w1 = 0;
    this.cn2w2 = 0;
    this.cn2w3 = 0;
    this.cn2w4 = 0;
    this.cn2w5 = 0;
    this.cn2w6 = 0;
    this.cn2w7 = 0;
    this.cn2w8 = 0;
    this.cn2w9 = 0;
    this.cn2w10 = 0;
    this.cn2w101 = 0;
    this.tot2w = 0;
    this.bay2w = 364;
    this.turn2w = 0;
  
  
    this.cn4w15 = 0;
    this.cn4w1 = 0;
    this.cn4w2 = 0;
    this.cn4w3 = 0;
    this.cn4w4 = 0;
    this.cn4w5 = 0;
    this.cn4w6 = 0;
    this.cn4w7 = 0;
    this.cn4w8 = 0;
    this.cn4w9 = 0;
    this.cn4w10 = 0;
    this.cn4w101 = 0;
    this.tot4w = 0;
    this.bay4w = 393;
    this.turn4w = 0;
  }

  loadList() {
    let today = new Date(this.selectedDate);
   // console.log('Date:', today, this.selectedDate);
    today.setHours(0);
    let todayEpochDay = Math.floor((today.getTime() + 330 * 60 * 1000) / (1000 * 60 * 60 * 24));

    this.resetCounter();

    this.vehicles2 = this.db.collectionGroup<Vehicle>('records', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('vrStartEpochDay', '==', todayEpochDay).where('vrBusinessId', '==', this.businessID);
      return query;
    }).valueChanges();

    this.vehicles1 = this.db.collectionGroup<Vehicle>('records', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('vrEndEpochDay', '==', todayEpochDay).where('vrBusinessId', '==', this.businessID);
      return query;
    }).valueChanges();

    const tempArray = [this.vehicles2, this.vehicles1];

    const allVehicles = combineLatest(tempArray)
      .pipe(
        map(adArray => adArray.reduce((acc, curr) =>
          [...acc, ...curr], []))
      );

    this.vehiclesReportSubs = allVehicles.subscribe(
      (response) => {
        this.resetCounter();
       // console.log('DATA SIZE ',this.data1)

        const uniqHashMap = {};

        for (const vehicleListData of response) {

          if (vehicleListData.vrFirebaseId in uniqHashMap) continue;
          uniqHashMap[vehicleListData.vrFirebaseId] = true;
          //Calculate the duration of parking and check if total duration falls in bucket of time period like within 15 mins, between 1 hour to 2 hour etc. If yes then increment the counter for that bucket for that vehicle type

          let duration = 0;

          if (vehicleListData.vrParkingStatus === 'IN') {
            duration = (Date.now() - vehicleListData.vrParkingStartTimeL)/1000;
          }else{
            duration = (vehicleListData.vrParkingEndTimeL - vehicleListData.vrParkingStartTimeL)/1000;
          }

          if(duration <= (15 * 60)){
            if(vehicleListData.vrVehicleType === '2W'){
              this.cn2w15++;
            }else{
              this.cn4w15++;
            }
          }else if(duration > (15*60) && duration <= (60*60)){
            if(vehicleListData.vrVehicleType === '2W'){
              this.cn2w1++;
            }else{
              this.cn4w1++;
            }
          }else if(duration > (60*60) && duration <= (2*60*60)){
            if(vehicleListData.vrVehicleType === '2W'){
              this.cn2w2++;
            }else{
              this.cn4w2++;
            }
          }else if(duration > (2*60*60) && duration <= (3*60*60)){
            if(vehicleListData.vrVehicleType === '2W'){
              this.cn2w3++;
            }else{
              this.cn4w3++;
            }
          }else if(duration > (3*60*60) && duration <= (4*60*60)){
            if(vehicleListData.vrVehicleType === '2W'){
              this.cn2w4++;
            }else{
              this.cn4w4++;
            }
          }else if(duration > (4*60*60) && duration <= (5*60*60)){  
            if(vehicleListData.vrVehicleType === '2W'){
              this.cn2w5++;
            }else{
              this.cn4w5++;
            } 
          }else if(duration > (5*60*60) && duration <= (6*60*60)){
            if(vehicleListData.vrVehicleType === '2W'){
              this.cn2w6++;
            }else{
              this.cn4w6++;
            }
          }else if(duration > (6*60*60) && duration <= (7*60*60)){
            if(vehicleListData.vrVehicleType === '2W'){
              this.cn2w7++;
            }else{
              this.cn4w7++;
            }
          }else if(duration > (7*60*60) && duration <= (8*60*60)){
            if(vehicleListData.vrVehicleType === '2W'){
              this.cn2w8++;
            }else{
              this.cn4w8++;
            }
          }else if(duration > (8*60*60) && duration <= (9*60*60)){
            if(vehicleListData.vrVehicleType === '2W'){
              this.cn2w9++;
            }else{
              this.cn4w9++;
            }
          }else if(duration > (9*60*60) && duration <= (10*60*60)){
            if(vehicleListData.vrVehicleType === '2W'){
              this.cn2w10++;
            }else{
              this.cn4w10++;
            }
          }else if(duration > (10*60*60)){
            if(vehicleListData.vrVehicleType === '2W'){
              this.cn2w101++;
            }else{
              this.cn4w101++;
            }
          }

          if(vehicleListData.vrVehicleType === '2W'){
            this.tot2w++;
          }else{
            this.tot4w++;
          }

        }

        this.turn2w = Number((this.tot2w/this.bay2w ).toFixed(2));
        this.turn4w = Number((this.tot4w/this.bay4w ).toFixed(2));
        
      }
    );
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }
  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setBusinessID(biz) {
    this.resetCounter();
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.loadList();
    // this.resetCounter();
  }
  setDateFormat(model) {
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    const StrToday = yyyy + Strmm;
    return StrToday;
  }
  setCalenderdate(model) {
    let Strdd = null;
    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const StrDate = Strdd;
    return StrDate;
  }

  setCalenderMonthYear(model) {
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    const yyyy = model.year;

    const StrToday = Strmm + '-' + yyyy;
    return StrToday;
  }
  setCalenderYear(model) {
    const yyyy = model.year;
    return yyyy;
  }

  setCalenderMonth(model) {
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    return Strmm;
  }

  getTimeStamp(model) {
    const date = new Date();
    date.setMonth(model.month - 1);
    date.setFullYear(model.year);
    date.setDate(model.day);
    return date;
  }


  filterByDate(model) {
    this.resetCounter();
    this.vehiclesReportSubs.unsubscribe();
    const StrToday = this.setDateFormat(model);
    this.dateFilter.next(StrToday);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    this.StrYYYY = this.setCalenderYear(this.model);
    this.StrMM = this.setCalenderMonth(this.model);
    this.StrDate = this.setCalenderdate(this.model);
    this.selectedDate = this.getTimeStamp(this.model);
    this.loadList();
  }

  ngOnDestroy() {
    this.vehiclesReportSubs.unsubscribe();
    this.resetCounter();
  }

  downloadExcelReport() {
    const reportLabel = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilter.value).toLocaleDateString()
      + ' to ' + new Date(this.dateFilter.value).toLocaleDateString() + ' Report';
    this.downloadService.getExcelReport(this.data, reportLabel, this.headerData);
  }
  
}
