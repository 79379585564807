import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DownloadService } from '../services/download.service';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { DatabaseService } from '../services/database.service';
import { PathSetterService } from '../services/pathSetter.service';
import { Rto } from './rto.model';
import * as XLSX from 'xlsx';
import { AngularFireDatabase } from '@angular/fire/database';
import { tap } from 'rxjs/operators';

import { CapacityTags } from '../tags/capacity-tags/capacityTags.model';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { take } from 'rxjs/operators';
import * as firebase from 'firebase';
import { st } from '@angular/core/src/render3';

export class RtoUpload {
  name: string;
  areacode: string;
  statecode: string;
}

@Component({
  selector: 'app-rto',
  templateUrl: './rto.component.html',
  styleUrls: ['./rto.component.css']
})
export class RtoComponent implements OnInit {
  @ViewChild('input') fileInputVariable: any;
  @ViewChild('stepper') stepper;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isOptional = false;
  showTable = false;
  // for updating the table value
  editField: string;
  // isEditable: boolean;
  arrayBuffer: any;
  file: File;
  rtoUploadList: RtoUpload[] = [];

  groupID: string;
  businessList: any[];
  afdb: AngularFireDatabase;
  afs: AngularFirestore;
  businessID: string;
  localFirebaseID: string;
  data = [];
  progressbarValue: number;
  isProgressvarVisible: boolean;
  serverTimestamp: any;

  headerData: string[] = [
    'Name', 'AreaCode', 'StateCode'];

    isValid = false;

    constructor(private _formBuilder: FormBuilder, private downloadService: DownloadService, afdb: AngularFireDatabase,
      afs: AngularFirestore, private databaseService: DatabaseService, private pathService: PathSetterService) {
      this.afdb = afdb;
      this.afs = afs;
      this.formatData = this.formatData.bind(this);
      this.isProgressvarVisible = false;
      // this.isEditable = true;

    } 

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      // firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ''
    });
  }

  isValidForm() {
    return this.isValid;
  }

  downloadTemplate() {
    const reportLabel = 'Rto Upload Template';
    this.downloadService.getExcelReport(this.data, reportLabel, this.headerData);
  }

  formatData() {
    this.isValid = true;
    this.isProgressvarVisible = false;

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      this.rtoUploadList = [];
      for (let i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
      const bstr = arr.join('') || arr.join(' ') || arr.join('()') || arr.join('-');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      const listData = XLSX.utils.sheet_to_json(worksheet, { raw: true });

      for (let i = 0; i < listData.length; i++) {
        const listItem = listData[i];

        this.rtoUploadList.push({
          name: listItem['Name'],
          areacode: listItem['AreaCode'],
          statecode: listItem['StateCode']
        });
      }
    };
    fileReader.readAsArrayBuffer(this.file);
  }

  changeListener(event) {
    this.clearData();
    this.file = event.target.files[0];
  }

  clearData() {
    this.isValid = false;
    this.rtoUploadList = [];
  }

  removePassItem(id: any) {
    // this.awaitingPersonList.push(this.personList[id]);
    this.rtoUploadList.splice(id, 1);
  }

  changePassItemValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent;
    this.rtoUploadList[id][property] = this.editField;
  }

  uploadTemplate(){
    this.isValid = false;
    this.isProgressvarVisible = true;
    let FSSuccessfulUploadCount = 0;
    this.progressbarValue = 0;
    let name = [];
    let areaCode = [];
    let stateCode = [];

    for(let i = 0; i < this.rtoUploadList.length; i++) {
      name = [];
      areaCode = [];
      stateCode = [];

      const rtoCollection = this.afs.collection<Rto>('/rto_list/');
      Promise.resolve(
        rtoCollection.add({
          name: this.rtoUploadList[i]['name'],
          areaCode: this.rtoUploadList[i]['areacode'],
          stateCode: this.rtoUploadList[i]['statecode'],})).then((fsitem) => {
            // itemkey = fsitem.id;
            // rtoCollection.doc(itemkey).update({});
            FSSuccessfulUploadCount++;

            this.progressbarValue = Math.round((FSSuccessfulUploadCount / this.rtoUploadList.length) * 100);

            if (FSSuccessfulUploadCount === (this.rtoUploadList.length)) {
              setTimeout(() => {
                alert('Uploaded ' + FSSuccessfulUploadCount + ' RTO');
                // console.log('finished upload');
                this.stepper.selectedIndex = 0;
                this.clearData();
              }, 2000);
            }
          }).catch(err => {
              console.log(err);
            });
    }
  }

}
