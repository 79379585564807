import { Injectable } from '@angular/core';


@Injectable()
export class PathSetterService {

  env: string;
  isArya: boolean;
  isDemo: boolean;
  isMcgm: boolean;
  otherenv: string;

  getPathEnvironment() {
    // for Production
       this.env = 'production';
    // for dev
      //  this.env = 'dev';

    return this.env;
  }


  getOtherPathEnvi() {
    // for Production
     this.otherenv = 'https://get-parking-counters.firebaseio.com/';
  // for dev
  // this.otherenv = 'https://getparking-development-counters.firebaseio.com/';

  return this.otherenv;
}

  // getBigInfo() {
  //    // return true if demo
  //  if (this.isBig === true) {
  //    return this.isBig;
  //  } else {
  //    return this.isBig;
  //  }
  // }

  // getMcgmInfo() {
  //   // return true if mcgm
  //        this.isMcgm = true;

  //       // this.isMcgm = false;

  //       return this.isMcgm;
  // }

  getDemoInfo() {
    // return true if demo
        // this.isDemo = true;

     this.isDemo = false;

    return this.isDemo;
  }
  getAryaFeatures() {
    // for other customers
     this.isArya = false;

    // for Arya
      // this.isArya = true;

    return this.isArya;
  }

}
