import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { DatabaseService } from '../services/database.service';
import { PathSetterService } from '../services/pathSetter.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { timestamp } from 'rxjs/operators';
// import { Subscription, Observable } from 'rxjs';

export interface Count {
  serialNo: string;
  timeStamp: string;
  vehicleType: string;
}

export interface Report {
  a1serialNo: string;
  a2timeStamp: string;
  a3timeStamp: string;
  a4vehicleType: string;
}

@Component({
  selector: 'app-loop-counts',
  templateUrl: './loop-counts.component.html',
  styleUrls: ['./loop-counts.component.css']
})
export class LoopCountsComponent implements OnInit {

  adb: AngularFireDatabase;
  title = 'Loop Counts Details';
  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: string;
  groupID: string;

  vehCount: Count[] = [];
  counterReportSubs: Subscription;
  counterList: Observable<any[]>;
  // intwoLoops: Count[] = [];
  // infourLoops: Count[] = [];
  // outtwoLoops: Count[] = [];
  // outfourLoops: Count[] = [];

  model: NgbDateStruct;
  dateFilter: BehaviorSubject<string | null>;

  constructor(private databaseService: DatabaseService, adb: AngularFireDatabase,
    private pathService: PathSetterService) {
    this.adb = adb;
    const date = new Date();
    this.model = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
    const StrToday = this.setDateFormat(this.model);
    this.dateFilter = new BehaviorSubject(StrToday);
    // this.setBusinessList();
    this.setBusinessDropDownList();
  }

  ngOnInit() {
  }
  setBusinessList() {
    this.businessList = this.databaseService.getBusinessList();
  }
  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }
  setDateFormat(model) {
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    const StrToday = Strdd + '-' + Strmm + '-' + yyyy;
    return StrToday;
  }

  filterByDate(model) {
     this.counterReportSubs.unsubscribe();
    // this.intwoLoops = [];
    // this.infourLoops = [];
    // this.outtwoLoops = [];
    // this.outfourLoops = [];
    const StrToday = this.setDateFormat(model);
    this.dateFilter.next(StrToday);
    // this.twoInCount();
    // this.fourInCount();
    // this.twoOutCount();
    // this.fourOutCount();
  }

  setBusinessID(biz) {
    // this.counterReportSubs.unsubscribe();
    this.businessName = biz.name;
    this.businessID = biz.id;
    // this.intwoLoops = [];
    // this.infourLoops = [];
    // this.outtwoLoops = [];
    // this.outfourLoops = [];
    // this.twoInCount();
    // this.fourInCount();
    // this.twoOutCount();
    // this.fourOutCount();
  }

  loadList() {
    this.counterList = this.adb.list('/getParking/' + this.pathService.getPathEnvironment() +
         '/loopDetector/' + this.businessID + '/DetailCount/Entry/Lane1/' + this.dateFilter.value).valueChanges();


  }

  // twoInCount() {
  //   // URL
  //   console.log();
  //   let mBusinessId: String = this.businessID;
  //   if (mBusinessId === '-LMaUT92z4NJ3sllS-rg') {
  //     mBusinessId = 'LMaUT92z4NJ3sllS-rg';
  //   }
  //   const dbRef = this.adb.database.ref('/getParking/' + this.pathService.getPathEnvironment() +
  //     '/loopDetector/' + this.businessID + '/DetailCount/Entry/Lane1/' + this.dateFilter.value);
  //          this.counterReportSubs = this.adb.list(dbRef, ref => ref.orderByChild('vehicleType').equalTo('2W')).valueChanges().subscribe(
  //     (response) => {
  //       //  this.vehCount = response;
  //        const jsonStr = JSON.stringify(response);
  //        console.log('uf', jsonStr);
  //       // const jsonObj = JSON.parse(jsonStr);
  //       // this.intwoLoops = jsonObj;
  //       console.log('check loop counts' );
  //     }
  //   );
  // }
  // fourInCount() {
  //   // URL
  //   let mBusinessId: String = this.businessID;
  //   if ( mBusinessId === '-LMaUT92z4NJ3sllS-rg') {
  //     mBusinessId = 'LMaUT92z4NJ3sllS-rg';
  //   }
  //   const dbRef = this.adb.database.ref('/getParking/' + this.pathService.getPathEnvironment() +
  //     '/loopDetector/' + mBusinessId + '/Entry/Lane1/' + this.dateFilter.value);
  //   this.counterReportSubs = this.adb.list(dbRef, ref => ref.orderByChild('vehicleType').equalTo('4W')).valueChanges().subscribe(
  //     (response) => {
  //       const jsonStr = JSON.stringify(response.length);
  //       const jsonObj = JSON.parse(jsonStr);
  //       this.infourLoops = jsonObj;
  //       console.log('check 4loop counts' + this.infourLoops);
  //     }
  //   );
  // }

  // twoOutCount() {
  //   // URL
  //   let mBusinessId: String = this.businessID;
  //   if ( mBusinessId === '-LMaUT92z4NJ3sllS-rg') {
  //     mBusinessId = 'LMaUT92z4NJ3sllS-rg';
  //   }
  //   const dbRef = this.adb.database.ref('/getParking/' + this.pathService.getPathEnvironment() +
  //     '/loopDetector/' + mBusinessId + '/Exit/Lane1/' + this.dateFilter.value);
  //   this.counterReportSubs = this.adb.list(dbRef, ref => ref.orderByChild('vehicleType').equalTo('2W')).valueChanges().subscribe(
  //     (response) => {
  //       const jsonStr = JSON.stringify(response.length);
  //       const jsonObj = JSON.parse(jsonStr);
  //       this.outtwoLoops = jsonObj;
  //     }
  //   );
  // }

  // fourOutCount() {
  //   // URL
  //   let mBusinessId: String = this.businessID;
  //   if ( mBusinessId === '-LMaUT92z4NJ3sllS-rg') {
  //     mBusinessId = 'LMaUT92z4NJ3sllS-rg';
  //   }
  //   const dbRef = this.adb.database.ref('/getParking/' + this.pathService.getPathEnvironment() +
  //     '/loopDetector/' + mBusinessId + '/Exit/Lane1/' + this.dateFilter.value);
  //   this.counterReportSubs = this.adb.list(dbRef, ref => ref.orderByChild('vehicleType').equalTo('4W')).valueChanges().subscribe(
  //     (response) => {
  //       const jsonStr = JSON.stringify(response.length);
  //       const jsonObj = JSON.parse(jsonStr);
  //       this.outfourLoops = jsonObj;
  //     }
  //   );
  // }
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    this.counterReportSubs.unsubscribe();
    // this.intwoLoops = [];
    // this.infourLoops = [];
    // this.outtwoLoops = [];
    // this.outfourLoops = [];
    // this.twoInCount();
  }
}
