import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { PassFireStore } from '../../add-passes/passFireStore.model';

import { DatabaseService } from '../../services/database.service';
import { PassEditService } from '../passEdit.service';
import { PathSetterService } from '../../services/pathSetter.service';
import { Pass } from '../pass.model';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';


export interface Report {

  a1parkingPassBusinessId: string;
  a2parkingPassDisplayId: string;
  a3passNumberPlate: string;
  a4passVehicleType: string;
  a5passValidFrom: string;
  a6passValidTill: string;
  a7passDurationType: string;
  a8passStatus: string;
  a9passCompanyName: string;
  a10userEmail: string;
  a11userName: string;
  a12userPhone: string;
  a13parkingDedicated: string;
  a14parkingDedicatedSlot: string;
  a15parkingSlot: string;
  a16parkingUnitNo: string;
  a17passAmount: number;
  a18passIssuedById: string;
  a19passIssueTimeStamp: string;
  a20passModificationTimeStamp: string;
  a21passModifiedById: string;
  a22passNumberPlateDigits: string;
  a23passRfidTag: string;
  a24passRegistrationProof: string;
}

@Component({
  selector: 'app-issue-rfid-popup',
  templateUrl: './issue-rfid-popup.component.html',
  styleUrls: ['./issue-rfid-popup.component.css']
})
export class IssueRfidPopupComponent implements OnInit {
  db: AngularFirestore;
  passInfo: Pass;
  passFireStoreInfo: PassFireStore;
  afdb: AngularFireDatabase;
  afs: AngularFirestore;
  groupID: string;
  rearrangedReportArray: Report[] = [];

  model: number;
  numberPlate: string;
  userFirstName: string;
  userLastName: string;
  userPhone: string;
  userEmail: string;
  passAmount: number;
  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: any;
  vehType: string;
  rfidID: string = '';
  passRegistrationProof: string;

  existingPass: Pass;
  error: boolean = false;
  showMsg: boolean = false;
i: any =0;

  constructor(public activeModal: NgbActiveModal, private databaseService: DatabaseService, route: Router, private pathService: PathSetterService,
    public snackBar: MatSnackBar, private passEditService: PassEditService, afdb: AngularFireDatabase, afs: AngularFirestore) {
    this.afdb = afdb;
    this.afs = afs;
    this.setGroupID();
    this.setBusinessDropDownList();
    this.getRfidInformation();
    this.createObservableSerial();

  }


  ngOnInit() {
    // this.getRfidInformation();
  }


  createObservableSerial() {
    this.passEditService.serialSubject.subscribe({
      next: async (data) => {
        console.log("Amaa", data);
        this.rfidID = data;
        this.error = false;
        // console.log('PassArr',  this.passEditService.passesArr);
        let today = new Date();
        today.setHours(15);
        let todayEpochDay = Math.floor((today.getTime() + 330 * 60 * 1000) / (1000 * 60 * 60 * 24));
        for (let i = 0; i < this.passEditService.passesArr.length; i++) {
          let passAr = this.passEditService.passesArr[i];
          // console.log('Mess1:', passAr.passRfidTag);
          //if (passAr.passRfidTag && this.rfidID && passAr.passRfidTag === this.rfidID && passAr.passValidTillEpochDay > todayEpochDay) {
            if (passAr.passRfidTag && this.rfidID && passAr.passRfidTag === this.rfidID && passAr.passMasterId && passAr.passValidTillEpochDay > todayEpochDay)
            {
            this.error = true;
            this.existingPass = passAr;
            // console.log('Mess2:',this.rfidID);
            this.showMsg = true;
            break;
          }
        }
        //this.passEditService.passesArr.filter;    
      },
    });
  }
  setBusinessDropDownList() {
    // console.log('inside set dropdown list');
    this.businessList = this.databaseService.getBusinessList();
    this.businessID = this.passEditService.getBusinessID();
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  getRfidInformation() {
    this.passInfo = this.passEditService.getRfidInfo();
    this.numberPlate = this.passInfo.passNumberPlate;
    this.userFirstName = this.passInfo.userFirstName;
    this.userLastName = this.passInfo.userLastName;
    this.userPhone = this.passInfo.userPhone;
    this.userEmail = this.passInfo.userEmail;
    this.passAmount = this.passInfo.passAmount;
    this.vehType = this.passInfo.passVehicleType;
    this.rfidID = this.passInfo.passRfidTag;
    this.passRegistrationProof = this.passInfo.passRegistrationProof;
  }

  async onSubmitRfid(Formdata) {

    console.log('WHOLE', Formdata);

    this.activeModal.close('Close click');
    this.passInfo.passRfidTag = Formdata.rfidID;
    this.passInfo.passModificationTimeStamp = new Date().getTime(),
    this.passInfo.passModifiedById = 'Dashboard Admin';


    // Realtime time database URL
    const itemsRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() + '/passes/' + this.businessID + '/All/');
    itemsRef.update(this.passInfo.passFirebaseId, this.passInfo);
    this.snackBar.open('Pass updated', 'dismiss', {
      duration: 3000
    });

    // Firestore URL
    const passCollection1 = this.afs.collection<PassFireStore>('/bizs/' + this.businessID + '/passMaster/');
    passCollection1.doc(this.passInfo.passMasterId)
      .update({
        passRfidTag: this.passInfo.passRfidTag,
        passModificationTimeStamp: new Date().getTime(),
        passModifiedById: 'Dashboard Admin',
      });
    this.passEditService.setSerialData('');
    // this.showMsg= true;

    const passCollection = this.afs.collection<PassFireStore>('/bizs/' + this.businessID + '/passes/');

    let querySnap = await passCollection.ref.where("passMasterId","==",this.passInfo.passMasterId).get();

    for(let i=0;i<querySnap.size;i++){
     await querySnap.docs[i].ref.set({
            passRfidTag: this.passInfo.passRfidTag,
            passModificationTimeStamp: new Date().getTime(),
            passModifiedById: 'Dashboard Admin',
          },{merge:true});
    }
    
  }
}
