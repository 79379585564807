import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { DownloadService } from '../services/download.service';
import { DatabaseService } from '../services/database.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AttendantFireStore } from './attendant.model';

import { BehaviorSubject, combineLatest } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../core/auth.service';
import { User } from '../services/user.model';
import { Vehicle } from '../vehicles/vehicle.model';


export interface Report {
  location: string;
  name: string;
  date: string;
  numberOfShifts: number;
  firstShiftStartTime: string;
  latestShiftEndTime: string;
  PhoneNumber: string;
  CashInHand: number;
  total_focCollection: number;
  total_focCount: number;
  total_inCount: number;
  total_outCount: number;
  total_passCollection: number;
  total_passCount: number;
  total_ticketCollection: number;
  total_ticketDeletedCollection: number;
  total_ticketDeletedCount: number;
  total_lostTicketCollection: number;
  total_lostTicketCount: number;
  cashCollection: number;
  digitalCollection: number;
  cardCollection: number;
  fastagCollection: number;
}


@Component({
  selector: 'app-attendant',
  templateUrl: './attendant.component.html',
  styleUrls: ['./attendant.component.css']
})
export class AttendantComponent implements OnInit, OnDestroy {

  db: AngularFirestore;
  title = 'Attendant Details';
  private sorted = false;
  public searchText: string;

  user: User;
  userSub: Subscription;

  // headerData: string[] = [
  //   'location', 'Name', 'Date', 'Online', 'No Of Shifts', 'FirstShift Start Time', 'Latest Shift Start Time',
  //   'Phone Number', 'Cash In Hand',

  //   'Total Daypass Collection', 'Total Daypass Count', 'Total Daypass Deleted Collection', 'Total Daypass Deleted Count',
  //   'Total Daypass Reprint count', 'Total FOC Count', 'Total In Count', 'Total Out Count',
  //   'Total Pass Collection', 'Total Pass Count', 'Total Pass Deleted Collection', 'Total Pass Deleted Count',
  //   'Total Pass Reprint Count', 'Total Receipt Reprint Count', 'Total Ticket Collection', 'Total Ticket Count',
  //   'Total Ticket Deleted Collection', 'Total Ticket Deleted Count', 'Total Ticket Reprint Count',
  //   'Total Lost Ticket Collection', 'Total Lost Ticket Count', 'Total Refund Amount', 'Total Refund Count',

  //   '2W Daypass Collection', '2W Daypass Count', '2W Daypass Deleted Collection', '2W Daypass Deleted Count',
  //   '2W Daypass Reprint count', '2W FOC Collection', '2W FOC Count', '2W In Count', '2W Out Count',
  //   '2W Pass Collection', '2W Pass Count', '2W Pass Deleted Collection', '2W Pass Deleted Count',
  //   '2W Pass Reprint Count', '2W Receipt Reprint Count', '2W Ticket Collection', '2W Ticket Count',
  //   '2W Ticket Deleted Collection', '2W Ticket Deleted Count', '2W Ticket Reprint Count',
  //   '2W Lost Ticket Collection', '2W Lost Ticket Count', '2W Refund Amount', '2W Refund Count',

  //   '4W Daypass Collection', '4W Daypass Count', '4W Daypass Deleted Collection', '4W Daypass Deleted Count',
  //   '4W Daypass Reprint count', '4W FOC Collection', '4W FOC Count', '4W In Count', '4W Out Count',
  //   '4W Pass Collection', '4W Pass Count', '4W Pass Deleted Collection', '4W Pass Deleted Count',
  //   '4W Pass Reprint Count', '4W Receipt Reprint Count', '4W Ticket Collection', '4W Ticket Count',
  //   '4W Ticket Deleted Collection', '4W Ticket Deleted Count', '4W Ticket Reprint Count',
  //   '4W Lost Ticket Collection', '4W Lost Ticket Count', '4W Refund Amount', '4W Refund Count',

  //   '3W Daypass Collection', '3W Daypass Count', '3W Daypass Deleted Collection', '3W Daypass Deleted Count',
  //   '3W Daypass Reprint count', '3W FOC Collection', '3W FOC Count', '3W In Count', '3W Out Count',
  //   '3W Pass Collection', '3W Pass Count', '3W Pass Deleted Collection', '3W Pass Deleted Count',
  //   '3W Pass Reprint Count', '3W Receipt Reprint Count', '3W Ticket Collection', '3W Ticket Count',
  //   '3W Ticket Deleted Collection', '3W Ticket Deleted Count', '3W Ticket Reprint Count',
  //   '3W Lost Ticket Collection', '3W Lost Ticket Count', '3W Refund Amount', '3W Refund Count',

  //   'BUS Daypass Collection', 'BUS Daypass Count', 'BUS Daypass Deleted Collection', 'BUS Daypass Deleted Count',
  //   'BUS Daypass Reprint count', 'BUS FOC Collection', 'BUS FOC Count', 'BUS In Count', 'BUS Out Count',
  //   'BUS Pass Collection', 'BUS Pass Count', 'BUS Pass Deleted Collection', 'BUS Pass Deleted Count',
  //   'BUS Pass Reprint Count', 'BUS Receipt Reprint Count', 'BUS Ticket Collection', 'BUS Ticket Count',
  //   'BUS Ticket Deleted Collection', 'BUS Ticket Deleted Count', 'BUS Ticket Reprint Count',
  //   'BUS Lost Ticket Collection', 'BUS Lost Ticket Count', 'BUS Refund Amount', 'BUS Refund Count',

  //   'CV Daypass Collection', 'CV Daypass Count', 'CV Daypass Deleted Collection', 'CV Daypass Deleted Count',
  //   'CV Daypass Reprint count', 'CV FOC Collection', 'CV FOC Count', 'CV In Count', 'CV Out Count',
  //   'CV Pass Collection', 'CV Pass Count', 'CV Pass Deleted Collection', 'CV Pass Deleted Count',
  //   'CV Pass Reprint Count', 'CV Receipt Reprint Count', 'CV Ticket Collection', 'CV Ticket Count',
  //   'CV Ticket Deleted Collection', 'CV Ticket Deleted Count', 'CV Ticket Reprint Count',
  //   'CV Lost Ticket Collection', 'CV Lost Ticket Count', 'CV Refund Amount', 'CV Refund Count',

  //   'CYC Daypass Collection', 'CYC Daypass Count', 'CYC Daypass Deleted Collection', 'CYC Daypass Deleted Count',
  //   'CYC Daypass Reprint count', 'CYC FOC Collection', 'CYC FOC Count', 'CYC In Count', 'CYC Out Count',
  //   'CYC Pass Collection', 'CYC Pass Count', 'CYC Pass Deleted Collection', 'CYC Pass Deleted Count',
  //   'CYC Pass Reprint Count', 'CYC Receipt Reprint Count', 'CYC Ticket Collection', 'CYC Ticket Count',
  //   'CYC Ticket Deleted Collection', 'CYC Ticket Deleted Count', 'CYC Ticket Reprint Count',
  //   'CYC Lost Ticket Collection', 'CYC Lost Ticket Count', 'CYC Refund Amount', 'CYC Refund Count',

  //   'CART Daypass Collection', 'CART Daypass Count', 'CART Daypass Deleted Collection', 'CART Daypass Deleted Count',
  //   'CART Daypass Reprint count', 'CART FOC Collection', 'CART FOC Count', 'CART In Count', 'CART Out Count',
  //   'CART Pass Collection', 'CART Pass Count', 'CART Pass Deleted Collection', 'CART Pass Deleted Count',
  //   'CART Pass Reprint Count', 'CART Receipt Reprint Count', 'CART Ticket Collection', 'CART Ticket Count',
  //   'CART Ticket Deleted Collection', 'CART Ticket Deleted Count', 'CART Ticket Reprint Count',
  //   'CART Lost Ticket Collection', 'CART Lost Ticket Count', 'CART Refund Amount', 'CART Refund Count',
  // ];

  headerData: string[] = [
    'Location',
    'Name',
    'Date',
    'No Of Shifts',
    'FirstShift Start Time',
    'Latest Shift Start Time',
    'Phone Number',
    'Total Collection',
    'Cash Collection',
    'UPI Collection',
    'Card Collection',
    'Fastag Collection',
    'Pass Creation',
    'Pass Collection',
    'Vehicle Entered',
    'Vehicle Exited',
    'Ticket Collection',
    'Lost Ticket Count',
    'Lost Ticket Amount',
    'Complimentary Count',
    'Complimentary Amount ',
    'Deleted Ticket Count',
    'Deleted Ticket Collection'
  ]

  groupID: string;
  businessList: any[];
  attendantReportSubs: Subscription;
  private AttendantCollection: AngularFirestoreCollection<AttendantFireStore>;
  Attendants: Observable<AttendantFireStore[]>;
  rearrangedReportArray: Report[] = [];
  p = 1;
  progressBarVisible = false;
  model: NgbDateStruct;
  dateFilter: BehaviorSubject<string | null>;
  businessName: any;
  businessID: any;
  vehicles2: Observable<Vehicle[]>;
  vehicles1: Observable<Vehicle[]>;
  selectedDate: string | number | Date;
  attendantDetailsSub: Subscription;


  constructor(db: AngularFirestore, private databaseService: DatabaseService, private downloadService: DownloadService,
    public authService: AuthService) {
    this.db = db;
    const date = new Date();
    this.model = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
    this.selectedDate = this.getTimeStamp(this.model);
    const StrToday = this.setDateFormat(this.model);
    this.dateFilter = new BehaviorSubject(StrToday);
    this.setGroupID();
    this.setBusinessList();
  }

  canEdit() {
    if (this.authService.canEdit(this.user)) {
      return true;
    } else {
      return false;
    }
  }


  getTimeStamp(model) {
    const date = new Date();
    date.setMonth(model.month - 1);
    date.setFullYear(model.year);
    date.setDate(model.day);
    return date;
  }


  canDelete() {
    if (this.authService.canDelete(this.user)) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.userSub = this.authService.user.pipe(
      take(1)
    ).subscribe(user => this.user = user);
    this.loadList();
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  setBusinessList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setDateFormat(model) {
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    const StrToday = Strdd + '-' + Strmm + '-' + yyyy;
    return StrToday;
  }

  filterByDate(model) {
    this.progressBarVisible = true;
    this.attendantReportSubs.unsubscribe();
    this.attendantDetailsSub.unsubscribe();
    this.rearrangedReportArray = [];
    const StrToday = this.setDateFormat(model);
    this.dateFilter.next(StrToday);
    this.selectedDate = this.getTimeStamp(this.model);
    this.loadList();
  }


  loadList() {
    let today = new Date(this.selectedDate);
    console.log('Date:', today, this.dateFilter.value);
    today.setHours(0);
    let todayEpochDay = Math.floor((today.getTime() + 330 * 60 * 1000) / (1000 * 60 * 60 * 24));
    // console.log("RS-->",todayEpochDay);
    // console.log("RS-->",this.businessID);

    this.vehicles2 = this.db.collectionGroup<Vehicle>('records', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('vrStartEpochDay', '==', todayEpochDay).where('vrBusinessId', '==', this.businessID);
      return query;
    }).valueChanges();

    this.vehicles1 = this.db.collectionGroup<Vehicle>('records', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('vrEndEpochDay', '==', todayEpochDay).where('vrBusinessId', '==', this.businessID);
      return query;
    }).valueChanges();

    const tempArray = [this.vehicles2, this.vehicles1];

    const allVehicles = combineLatest(tempArray)
      .pipe(
        map(adArray => adArray.reduce((acc, curr) =>
          [...acc, ...curr], []))
      );

    this.attendantReportSubs = allVehicles.subscribe(
      (response) => {
        this.rearrangedReportArray = [];


        const uniqHashMap = {};
        const reportMap = {};

        for (const vehicleListData of response) {
          //  console.log("RS-->",vehicleListData);
          if (vehicleListData.vrFirebaseId in uniqHashMap) continue;
          uniqHashMap[vehicleListData.vrFirebaseId] = true;
          if (vehicleListData.vrParkingStatus == 'OUT' && vehicleListData.vrEndEpochDay === todayEpochDay) {
            //Entry Block
            let exitAttendantData: Report = {
              location: '',
              name: '',
              date: '',
              numberOfShifts: 0,
              firstShiftStartTime: '',
              latestShiftEndTime: '',
              PhoneNumber: '',
              CashInHand: 0,
              total_focCollection: 0,
              total_focCount: 0,
              total_inCount: 0,
              total_outCount: 0,
              total_passCollection: 0,
              total_passCount: 0,
              total_ticketCollection: 0,
              total_ticketDeletedCollection: 0,
              total_ticketDeletedCount: 0,
              total_lostTicketCollection: 0,
              total_lostTicketCount: 0,
              cashCollection: 0,
              digitalCollection: 0,
              cardCollection: 0,
              fastagCollection: 0
            };

            if (vehicleListData.vrExitedBy in reportMap) {
              exitAttendantData = reportMap[vehicleListData.vrExitedBy];
            } else {
              reportMap[vehicleListData.vrExitedBy] = exitAttendantData;
              exitAttendantData.PhoneNumber = vehicleListData.vrExitedBy;
              exitAttendantData.name = vehicleListData.vrExitedByName;
            }

            exitAttendantData.total_outCount = exitAttendantData.total_outCount + 1;

            // let collection: number;
            // if (reportItem.vrStartEpochDay < fromEpochDay) {
            //   collection = reportItem.vrParkingBalanceFare + reportItem.vrLostTicketCharge;

            // } else if (reportItem.vrEndEpochDay > toEpochDay) {
            //   collection = reportItem.vrParkingPrepaidFare;

            // } else {

            //   collection = reportItem.vrParkingTotalFare;
            // }

            exitAttendantData.total_ticketCollection = exitAttendantData.total_ticketCollection + (vehicleListData.vrParkingTotalFare != 0 ? vehicleListData.vrParkingTotalFare : 0);
            if (!isNaN(vehicleListData.vrLostTicketCharge) && vehicleListData.vrLostTicketCharge > 0) {
              exitAttendantData.total_lostTicketCollection = exitAttendantData.total_lostTicketCollection + (vehicleListData.vrLostTicketCharge != 0 ? vehicleListData.vrLostTicketCharge : 0);
              exitAttendantData.total_lostTicketCount = exitAttendantData.total_lostTicketCount + 1;
            }
            if (vehicleListData.customExitType.toLowerCase() == 'complimentary') {
              exitAttendantData.total_focCollection = exitAttendantData.total_focCollection + vehicleListData.vrCalculatedBalanceFare;
              exitAttendantData.total_focCount = exitAttendantData.total_focCount + 1;
            }

            if (vehicleListData.vrExitPaymentType === 0) {

              exitAttendantData.cashCollection = exitAttendantData.cashCollection + vehicleListData.vrParkingTotalFare;

            } else if (vehicleListData.vrExitPaymentType === 2) {
              exitAttendantData.digitalCollection = exitAttendantData.digitalCollection + vehicleListData.vrParkingTotalFare;
            } else if (vehicleListData.vrExitPaymentType === 3) {
              exitAttendantData.cardCollection = exitAttendantData.cardCollection + vehicleListData.vrParkingTotalFare;
            } else if (vehicleListData.vrExitPaymentType === 4) {
              exitAttendantData.fastagCollection = exitAttendantData.fastagCollection + vehicleListData.vrParkingTotalFare;
            }


          }

          if (vehicleListData.vrStartEpochDay === todayEpochDay) {
            //Entry Block
            let entryAttendantData: Report = {
              location: '',
              name: '',
              date: '',
              numberOfShifts: 0,
              firstShiftStartTime: '',
              latestShiftEndTime: '',
              PhoneNumber: '',
              CashInHand: 0,
              total_focCollection: 0,
              total_focCount: 0,
              total_inCount: 0,
              total_outCount: 0,
              total_passCollection: 0,
              total_passCount: 0,
              total_ticketCollection: 0,
              total_ticketDeletedCollection: 0,
              total_ticketDeletedCount: 0,
              total_lostTicketCollection: 0,
              total_lostTicketCount: 0,
              cashCollection: 0,
              digitalCollection: 0,
              cardCollection: 0,
              fastagCollection: 0
            };

            if (vehicleListData.vrEnteredBy in reportMap) {
              entryAttendantData = reportMap[vehicleListData.vrEnteredBy];
            } else {
              reportMap[vehicleListData.vrEnteredBy] = entryAttendantData;
              entryAttendantData.PhoneNumber = vehicleListData.vrEnteredBy;
              entryAttendantData.name = vehicleListData.vrEnteredByName;
            }

            entryAttendantData.total_inCount = entryAttendantData.total_inCount + 1;
            entryAttendantData.total_ticketCollection = entryAttendantData.total_ticketCollection + vehicleListData.vrParkingPrepaidFare;
          }

        }
        //Convert reportMap to an array and populate this.rearrangedReportArray

        //get remaining details for each attendant from Attendant node
        this.AttendantCollection = this.db.collection<AttendantFireStore>
          ('/reports/' + this.groupID + '/attendants/' + this.dateFilter.value + '/daily_summary/');
        this.Attendants = this.AttendantCollection.valueChanges();

        this.attendantDetailsSub = this.Attendants
          .pipe(
            take(1)
          ).subscribe((response: AttendantFireStore[]) => {
            for (let i = 0; i < response.length; i++) {
              let attendantReport: Report = reportMap[response[i].phoneNumber];
              if (attendantReport) {
                const shiftStartTime = new Date(response[i].firstShiftStartTime).toLocaleTimeString();

                const shiftEndTime = isNaN(response[i].latestShiftEndTime) ?
                  'N/A' : new Date(response[i].latestShiftEndTime).toLocaleTimeString();

                attendantReport.firstShiftStartTime = shiftStartTime;
                attendantReport.latestShiftEndTime = shiftEndTime;
                attendantReport.numberOfShifts = response[i].numberOfShifts;
                attendantReport.location = "The Capital Mall";
                attendantReport.date = response[i].date;

                //console.log(response[i].date);
              }
            }
          });

        //Get Keys from ReportMap
        //console.log(reportMap);
        Object.keys(reportMap).forEach(function (key) {
          this.rearrangedReportArray.push(reportMap[key]);
        }, this);

        //Sort the rearrangedReportArray based on Name

     this.rearrangedReportArray.sort((a, b) => {
            if (a.name < b.name) return 1;
            if (a.name > b.name) return -1;
            return 0;
          }); 

      }
    );

    this.stopLoader();
  }
  resetCounter() {
    throw new Error('Method not implemented.');
  }


  /* loadList() {
    this.AttendantCollection = this.db.collection<AttendantFireStore>
      ('/reports/' + this.groupID + '/attendants/' + this.dateFilter.value + '/daily_summary/');

    this.Attendants = this.AttendantCollection.valueChanges();

    this.attendantReportSubs = this.Attendants
      .pipe(
        take(1)
      )
      .subscribe(
        (response: AttendantFireStore[]) => {

          this.rearrangedReportArray = [];
          for (let i = 0; i < response.length; i++) {

            
            response[i].focCollection_2W = isNaN(response[i].focCollection_2W) ? 0 : response[i].focCollection_2W;
            response[i].focCount_2W = isNaN(response[i].focCount_2W) ? 0 : response[i].focCount_2W;
            response[i].inCount_2W = isNaN(response[i].inCount_2W) ? 0 : response[i].inCount_2W;
            response[i].outCount_2W = isNaN(response[i].outCount_2W) ? 0 : response[i].outCount_2W;
            response[i].passCollection_2W = isNaN(response[i].passCollection_2W) ? 0 : response[i].passCollection_2W;
            response[i].passCount_2W = isNaN(response[i].passCount_2W) ? 0 : response[i].passCount_2W;
           
            response[i].ticketCollection_2W = isNaN(response[i].ticketCollection_2W) ? 0 : response[i].ticketCollection_2W;
            response[i].ticketCount_2W = isNaN(response[i].ticketCount_2W) ? 0 : response[i].ticketCount_2W;
           
            response[i].lostTicketCollection_2W = isNaN(response[i].lostTicketCollection_2W) ? 0 : response[i].lostTicketCollection_2W;
            response[i].lostTicketCount_2W = isNaN(response[i].lostTicketCount_2W) ? 0 : response[i].lostTicketCount_2W;
            
            response[i].focCollection_4W = isNaN(response[i].focCollection_4W) ? 0 : response[i].focCollection_4W;
            response[i].focCount_4W = isNaN(response[i].focCount_4W) ? 0 : response[i].focCount_4W;
            response[i].inCount_4W = isNaN(response[i].inCount_4W) ? 0 : response[i].inCount_4W;
            response[i].outCount_4W = isNaN(response[i].outCount_4W) ? 0 : response[i].outCount_4W;
            response[i].passCollection_4W = isNaN(response[i].passCollection_4W) ? 0 : response[i].passCollection_4W;
            response[i].passCount_4W = isNaN(response[i].passCount_4W) ? 0 : response[i].passCount_4W;
           
            response[i].ticketCollection_4W = isNaN(response[i].ticketCollection_4W) ? 0 : response[i].ticketCollection_4W;
            response[i].ticketCount_4W = isNaN(response[i].ticketCount_4W) ? 0 : response[i].ticketCount_4W;
            
            response[i].lostTicketCollection_4W = isNaN(response[i].lostTicketCollection_4W) ? 0 : response[i].lostTicketCollection_4W;
            response[i].lostTicketCount_4W = isNaN(response[i].lostTicketCount_4W) ? 0 : response[i].lostTicketCount_4W;
            
            response[i].ticketDeletedCollection_2W = isNaN(response[i].ticketDeletedCollection_2W) ? 0 : response[i].ticketDeletedCollection_2W;
            response[i].ticketDeletedCount_2W = isNaN(response[i].ticketDeletedCount_2W) ? 0 : response[i].ticketDeletedCount_2W;
            response[i].ticketDeletedCollection_4W = isNaN(response[i].ticketDeletedCollection_4W) ? 0 : response[i].ticketDeletedCollection_4W;
            response[i].ticketDeletedCount_4W = isNaN(response[i].ticketDeletedCount_4W) ? 0 : response[i].ticketDeletedCount_4W;
          

          // New ADDED Field
            response[i].cashCollection = isNaN(response[i].cashCollection) ? 0 : response[i].cashCollection;
            response[i].cashCollection_2W = isNaN(response[i].cashCollection_2W) ? 0 : response[i].cashCollection_2W;
            response[i].cashCollection_4W = isNaN(response[i].cashCollection_4W) ? 0 : response[i].cashCollection_4W;
            response[i].cashPaymentCount = isNaN(response[i].cashPaymentCount) ? 0 : response[i].cashPaymentCount;
            response[i].cashPaymentCount_2W = isNaN(response[i].cashPaymentCount_2W) ? 0 : response[i].cashPaymentCount_2W;
            response[i].cashPaymentCount_4W = isNaN(response[i].cashPaymentCount_4W) ? 0 : response[i].cashPaymentCount_4W;
            response[i].digitalCollection = isNaN(response[i].digitalCollection) ? 0 : response[i].digitalCollection;
            response[i].digitalCollection_4W = isNaN(response[i].digitalCollection_4W) ? 0 : response[i].digitalCollection_4W;
            response[i].digitalPaymentCount = isNaN(response[i].digitalPaymentCount) ? 0 : response[i].digitalPaymentCount;
            response[i].digitalPaymentCount_4W = isNaN(response[i].digitalPaymentCount_4W) ? 0 : response[i].digitalPaymentCount_4W;
            response[i].digitalPaymentCount_2W = isNaN(response[i].digitalPaymentCount_2W) ? 0 : response[i].digitalPaymentCount_2W;
            response[i].cardCollection = isNaN(response[i].cardCollection) ? 0 : response[i].cardCollection;
            response[i].cardCollection_4W = isNaN(response[i].cardCollection_4W) ? 0 : response[i].cardCollection_4W;
            response[i].cardPaymentCount = isNaN(response[i].cardPaymentCount) ? 0 : response[i].cardPaymentCount;
            response[i].cardPaymentCount_4W = isNaN(response[i].cardPaymentCount_4W) ? 0 : response[i].cardPaymentCount_4W;
            response[i].cardCollection_2W = isNaN(response[i].cardCollection_2W) ? 0 : response[i].cardCollection_2W;
            response[i].cardPaymentCount_2W = isNaN(response[i].cardPaymentCount_2W) ? 0 : response[i].cardPaymentCount_2W;
            response[i].fastagCollection = isNaN(response[i].fastagCollection) ? 0 : response[i].fastagCollection;
            response[i].fastagCollection_4W = isNaN(response[i].fastagCollection_4W) ? 0 : response[i].fastagCollection_4W;
            response[i].fastagPaymentCount = isNaN(response[i].fastagPaymentCount) ? 0 : response[i].fastagPaymentCount;
            response[i].fastagPaymentCount_4W = isNaN(response[i].fastagPaymentCount_4W) ? 0 : response[i].fastagPaymentCount_4W;
            response[i].fastagCollection_2W = isNaN(response[i].fastagCollection_2W) ? 0 : response[i].fastagCollection_2W;
            response[i].fastagPaymentCount_2W = isNaN(response[i].fastagPaymentCount_2W) ? 0 : response[i].fastagPaymentCount_2W;



            const shiftStartTime = new Date(response[i].firstShiftStartTime).toLocaleTimeString();

            const shiftEndTime = isNaN(response[i].latestShiftEndTime) ?
              'N/A' : new Date(response[i].latestShiftEndTime).toLocaleTimeString();

               this.rearrangedReportArray.push({
              a1location: response[i].location,
              a2name: response[i].name,
              a3date: response[i].date,
              a5numberOfShifts: response[i].numberOfShifts,
              a6firstShiftStartTime: shiftStartTime,
              a7latestShiftEndTime: shiftEndTime,
              a8PhoneNumber: response[i].phoneNumber,
              a9CashInHand: response[i].ticketCollection_2W + response[i].ticketCollection_4W + response[i].lostTicketCollection_2W  + 
              response[i].lostTicketCollection_4W + response[i].passCollection_2W + response[i].passCollection_4W - response[i].ticketDeletedCollection_2W - response[i].ticketDeletedCollection_4W,
              a203cashCollection: response[i].cashCollection,
              // a9CashInHand: response[i].ticketCollection_2W + response[i].ticketCollection_4W - response[i].focCollection_2W
              // - response[i].focCollection_4W + response[i].lostTicketCollection_2W  + response[i].lostTicketCollection_4W,
              // a203cashCollection: response[i].cashCollection,
              a209digitalCollection: response[i].digitalCollection,
              a213cardCollection: response[i].cardCollection,
             a219fastagCollection: response[i].fastagCollection,
              a19total_passCollection: response[i].passCollection_2W + response[i].passCollection_4W,
              a20total_passCount: response[i].passCount_2W + response[i].passCount_4W,
              a17total_inCount: response[i].inCount_2W + response[i].inCount_4W,
              a18total_outCount: response[i].outCount_2W + response[i].outCount_4W,
              a25total_ticketCollection: response[i].ticketCollection_2W + response[i].ticketCollection_4W - response[i].ticketDeletedCollection_4W - response[i].ticketDeletedCollection_2W ,
              // a30total_lostTicketCollection: response[i].lostTicketCollection_2W + response[i].lostTicketCollection_4W,
              a31total_lostTicketCount: response[i].lostTicketCount_2W + response[i].lostTicketCount_4W,
              a30total_lostTicketCollection: response[i].lostTicketCollection_2W + response[i].lostTicketCollection_4W,
             // a15total_focCollection: response[i].focCollection_2W + response[i].focCollection_4W,
              a16total_focCount: response[i].focCount_2W + response[i].focCount_4W,
              a15total_focCollection: response[i].focCollection_2W + response[i].focCollection_4W,
              a28total_ticketDeletedCount: response[i].ticketDeletedCount_2W + response[i].ticketDeletedCount_4W,
              a27total_ticketDeletedCollection: response[i].ticketDeletedCollection_2W + response[i].ticketDeletedCollection_4W,
              
              // a17total_inCount: response[i].inCount_2W + response[i].inCount_4W,
              // a18total_outCount: response[i].outCount_2W + response[i].outCount_4W,
              // a19total_passCollection: response[i].passCollection_2W + response[i].passCollection_4W,
              // a20total_passCount: response[i].passCount_2W + response[i].passCount_4W,
              // a25total_ticketCollection: response[i].ticketCollection_2W + response[i].ticketCollection_4W,
              // a30total_lostTicketCollection: response[i].lostTicketCollection_2W + response[i].lostTicketCollection_4W,
              // a31total_lostTicketCount: response[i].lostTicketCount_2W + response[i].lostTicketCount_4W,
              // a203cashCollection: response[i].cashCollection,
              // a209digitalCollection: response[i].digitalCollection,
             // a212digitalPaymentCount: response[i].digitalPaymentCount,
             
            });
          }
        }
      );
  } */

  sortBy(by: string | any): void {

    this.rearrangedReportArray.sort((a: Report, b: Report) => {
      if (a[by] < b[by]) {
        return this.sorted ? 1 : -1;
      }
      if (a[by] > b[by]) {
        return this.sorted ? -1 : 1;
      }
      return 0;
    });

    this.sorted = !this.sorted;
  }


  stopLoader() {
    this.progressBarVisible = false;
  }

  downloadReport() {
    const csvData = this.rearrangedReportArray.map((attendant: Report) => {
      const CashInHand = attendant.cashCollection + 
                         attendant.fastagCollection + 
                         attendant.digitalCollection + 
                         attendant.cardCollection + 
                         attendant.total_lostTicketCollection;
  
      return {
        Location: attendant.location,
        Name: attendant.name,
        Date: attendant.date,
        'No Of Shifts': attendant.numberOfShifts,
        'FirstShift Start Time': attendant.firstShiftStartTime,
        'Latest Shift Start Time': attendant.latestShiftEndTime,
        'Phone Number': attendant.PhoneNumber,
        'Total Collection': CashInHand,  // Assuming total collection is CashInHand
        'Cash Collection': attendant.cashCollection,
        'UPI Collection': attendant.digitalCollection,
        'Card Collection': attendant.cardCollection,
        'Fastag Collection': attendant.fastagCollection,
        'Pass Creation': attendant.total_passCount,
        'Pass Collection': attendant.total_passCollection,
        'Vehicle Entered': attendant.total_inCount,
        'Vehicle Exited': attendant.total_outCount,
        'Ticket Collection': attendant.total_ticketCollection,
        'Lost Ticket Count': attendant.total_lostTicketCount,
        'Lost Ticket Amount': attendant.total_lostTicketCollection,
        'Complimentary Count': attendant.total_focCount,
        'Complimentary Amount': attendant.total_focCollection,
        'Deleted Ticket Count': attendant.total_ticketDeletedCount,
        'Deleted Ticket Collection': attendant.total_ticketDeletedCollection
      };
    });
    const reportLabel = this.title + ' ' + this.dateFilter.value + ' Report';
    this.downloadService.getExcelReport(csvData, reportLabel, this.headerData);
  }

  ngOnDestroy() {
    this.rearrangedReportArray = [];
    this.attendantReportSubs.unsubscribe();
    this.attendantDetailsSub.unsubscribe();
  }
}
