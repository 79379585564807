import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore } from '@angular/fire/firestore';
import { Camera } from '../camera.model';
import { MatSnackBar } from '@angular/material';
import { CameraEditService } from '../cameraEdit.service';
import { NgbModal, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { CameraFireStore } from '../cameraFireStore.model';
import { DatabaseService } from '../../services/database.service';


@Component({
  selector: 'app-edit-camera',
  templateUrl: './edit-camera.component.html',
  styleUrls: ['./edit-camera.component.css']
})
export class EditCameraComponent implements OnInit {

  db: AngularFirestore;
  cameraFirestoreInfo: CameraFireStore;
  camInfo: Camera;
  numberPlate: string;
  enTime: any;
  exTime: any;
  recTy: string;
  comName: string;
  imgIn: string;
  businessList: any[];
  businessID: string;
  businessName: string;
  groupID: string;
  selectedValue: any;
  numplate: string;
  editNumber: string;

  model: NgbDateStruct;
  dateFilter: BehaviorSubject<string | null>;
  StrMMYYYY: String;
  StrDate: Date;

  constructor(public activeModal: NgbActiveModal, db: AngularFirestore, private databaseService: DatabaseService,
    public snackBar: MatSnackBar, private cameraEditService: CameraEditService) {
    this.db = db;
    const date = new Date();
    this.model = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
    const StrToday = this.setDateFormat(this.model);
    this.dateFilter = new BehaviorSubject(StrToday);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    this.StrDate = this.setCalenderdate(this.model);

    this.setGroupID();
    this.setBusinessDropDownList();
    this.getCameraInformation();
  }

  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessID = this.cameraEditService.getBusinessID();
  }
  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  getCameraInformation() {
    this.camInfo = this.cameraEditService.getCameraInfo();
    this.numberPlate = this.camInfo.vrNumberPlate;
    this.enTime = this.camInfo.vrParkingStartTimeL;
    this.exTime = this.camInfo.vrParkingEndTimeL;
    this.imgIn = this.camInfo.vehicleImageUrl;
    this.numplate = this.camInfo.vrNumberPlate;
    this.editNumber = this.camInfo.vrEditVehicleNumber;

  }
  ngOnInit() {

  }

  onSubmit(Formdata) {
    this.activeModal.close('Close click');
    this.camInfo.vrEditVehicleNumber = Formdata.numberPlate;
    this.camInfo.vrNumberPlate = Formdata.numberPlate;
    this.camInfo.vrNumberPlateDigits = Formdata.numberplatedigit;
    this.camInfo.parkingModificationTimeStamp = new Date().getTime();
    this.camInfo.cameraModifiedById = 'Dashboard Admin';

    // Firestore URL
    const cameraCollection = this.db.collection<CameraFireStore>('/groups/' + this.groupID + '/bizs/' + this.businessID +
     '/anprRecords/' + this.StrMMYYYY + '/' + this.StrDate + '/');
     cameraCollection.doc(this.camInfo.id)
      .update({
        vrNumberPlate: Formdata.numplate,
        vrNumberPlateDigits: Formdata.numplate.substring(6),
        parkingModificationTimeStamp: new Date().getTime(),
        cameraModifiedById: 'Dashboard Admin',
      });
      // console.log('check' , this.numplate );
  }

  setDateFormat(model) {
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    const StrToday = Strdd + '-' + Strmm + '-' + yyyy;
    return StrToday;
  }
  setCalenderdate(model) {

    let Strdd = null;
    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const StrDate = Strdd;
    return StrDate;
  }

  setCalenderMonthYear(model) {
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    const yyyy = model.year;

    const StrToday = Strmm + '-' + yyyy;
    return StrToday;
  }

  filterByDate(model) {
    const StrToday = this.setDateFormat(model);
    this.dateFilter.next(StrToday);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    this.StrDate = this.setCalenderdate(this.model);
  }
}
