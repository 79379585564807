import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { User } from './user.model';
import { BehaviorSubject, Subject } from 'rxjs';

export interface Tags {
  tagAffectCounter: boolean;
  tagId: string;
  tagLabel: string;
}

@Injectable()
export class DatabaseService implements OnDestroy {

  db: AngularFireDatabase;
  uID: string;
  groupID: string;
  cameraID: string;

  userList: Observable<any[]>;
  fbBusinessList: Observable<any[]>;
  fbGroupID: Observable<any>;
  fbCompanyTagList: Observable<any[]>;

  businessList: string[];
  value: string[];
  keys: string[];
  bizArray: any[];
  tagArray: any[] = [];
  camOptArray: any[] = [];
  userListSubscription: Subscription;
  CompanyTagListSubscription: Subscription;
  groupList: string[];
  grpArray: any[];
  cameList: string[];

  // serialSubject = new Subject<string>();

  constructor(db: AngularFireDatabase) {
    this.db = db;
  }

  setUserID(uid: string) {
    this.uID = uid;
    // console.log('Setting user ID to ' + this.uID);
  }

  getUserID() {
    return this.uID;
  }

  setBusinessList(bizList: string[]) {

    this.businessList = bizList;
    this.value = Object.values(this.businessList);
    this.keys = Object.keys(this.businessList);
    this.bizArray = this.keys.map((x, i) => {
      return { 'id': x, 'name': this.value[i] };
    });
    this.bizArray.sort(this.GetSortOrder('name')); // Pass the attribute to be sorted on
    // console.log(this.bizArray);
  }

  setGroupList(grpList: string[]) {
    this.groupList = grpList;
    this.value = Object.values(this.groupList);
    this.keys = Object.keys(this.groupList);
    this.grpArray = this.keys.map((x, i) => {
      return {'id' : x, 'name': this.value[i] };
    });
    // console.log('gegedg' , this.grpArray);
  }

  setCamOptList(camList: string[]) {
    this.cameList = camList;
    this.value = Object.values(this.cameList);
    this.keys = Object.values(this.cameList);
    this.camOptArray = this.keys.map((x, i) => {
      return {'id' : x };
    });
    // console.log('CamOpt' , this.camOptArray);
    this.camOptArray.sort(this.GetSortOrder('id'));
  }

  getBusinessList() {
    return this.bizArray;
  }

  getCameraList() {
    return this.camOptArray;
  }

  getGroupList() {
    return this.grpArray;
  }

  setCameraID(cameraID: string) {
    this.cameraID = cameraID;
    console.log('Setting Camera ID' + this.cameraID);
  }

  setGroupID(groupID: string) {
    this.groupID = groupID;
    console.log('Setting Group ID to ' + this.groupID);
  }

  getGroupID() {
    return this.groupID;
  }

  getCameraID() {
    return this.cameraID;
  }

  GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  ngOnDestroy() {
    this.CompanyTagListSubscription.unsubscribe();
    this.userListSubscription.unsubscribe();
  }
}
