import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Pass } from './pass.model';

@Injectable()
export class PassEditService {

  editPass: Pass;
  editBusinessID: string;
  id: string;
  editRfid: Pass;
  serialSubject: BehaviorSubject<string | null> = new BehaviorSubject('');
  passesArr: Pass[];

  setSerialData(data: string) {
    console.log('dekooooo:', data);
    this.serialSubject.next(data);
  }

  setRfidEditServiceDetails(RfidInfo: Pass, BusinessId: string, passesData: Pass[]) {
    this.editRfid = RfidInfo;
    this.editBusinessID = BusinessId;
    this.passesArr = passesData;
  }
  getRfidInfo() {
    return this.editRfid;
  }

  setPassEditServiceDetails(PassInfo: Pass, BusinessId: string) {
    this.editPass = PassInfo;
    this.editBusinessID = BusinessId;

  }

  getPassInfo() {
    return this.editPass;
  }

  getBusinessID() {
    return this.editBusinessID;
  }

}