import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddBusinessComponent } from '../add-business/add-business.component';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Subscribable, Subscription, Observable } from 'rxjs';
import { BusinessFireStore } from './businessFirestore.model';
import { MatSnackBar } from '@angular/material';
import { take } from 'rxjs/operators';
import { DatabaseService } from '../services/database.service';

export interface Report {
  a1business_project: string;
  a2business_company: string;
  a3business_name: string;
  a4business_address_1: string;
  a5business_address_2: string;
  a6business_prefix: string;
}

@Component({
  selector: 'app-business-setup',
  templateUrl: './business-setup.component.html',
  styleUrls: ['./business-setup.component.css']
})
export class BusinessSetupComponent implements OnInit {
  title: 'Business Setup';
  afs: AngularFirestore;
  businessReportSubs: Subscription;
  groupID: string;
  businessID: string;
  rearrangedReportArray: Report[];
  data: BusinessFireStore[];
  businessList: any[];
  businessName: string;

  private reportingBusinessCollection: AngularFirestoreCollection<BusinessFireStore>;
  reportingBusiness: Observable<BusinessFireStore[]>;

  constructor(private modalService: NgbModal, afs: AngularFirestore, private databaseService: DatabaseService,
    public snackBar: MatSnackBar) {
      this.afs = afs;
     this.setGroupID();
    this.setBusinessDropDownList();
     }

     setGroupID() {
      this.groupID = this.databaseService.getGroupID();
    }
    setBusinessDropDownList() {
      this.businessList = this.databaseService.getBusinessList();
      this.businessName = this.businessName;
      this.businessID = this.businessID;
    }

    setBusinessID(biz) {
      this.businessName = biz.name;
      this.businessID = biz.id;
      this.loadList();
    }

  ngOnInit() {
    this.loadList();
  }

  loadList() {
    // console.log('check busi aa raha hai kya' , '/groups/' + this.groupId + '/bizs/');
    this.reportingBusinessCollection = this.afs.collection<BusinessFireStore>('/groups/' + this.groupID + '/bizs/');
    this.reportingBusiness = this.reportingBusinessCollection.valueChanges();

    this.businessReportSubs = this.reportingBusiness.pipe(
      take(1)
    ).subscribe(
      (response) => {
        this.data = response;
        // console.log('free', response);
        this.rearrangedReportArray = [];
        for (let i = 0; i < this.data.length; i++) {
          const reportItem = this.data[i];

          this.rearrangedReportArray.push({
            a1business_project: reportItem.business_project,
            a2business_company: reportItem.business_company,
            a3business_name: reportItem.business_name,
            a4business_address_1: reportItem.business_address_1,
            a5business_address_2: reportItem.business_address_2,
            a6business_prefix: reportItem.business_prefix,
          });
        }
      }
    );
  }

  openModal() {
    const modalRef = this.modalService.open(AddBusinessComponent);
    modalRef.componentInstance.name = 'World';
  }
}
