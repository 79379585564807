import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DownloadService } from '../services/download.service';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { DatabaseService } from '../services/database.service';
import { PathSetterService } from '../services/pathSetter.service';
import { PassFireStore } from './passFireStore.model';
import * as XLSX from 'xlsx';
import { AngularFireDatabase } from '@angular/fire/database';
import { tap } from 'rxjs/operators';

import { CapacityTags } from '../tags/capacity-tags/capacityTags.model';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { take } from 'rxjs/operators';
import * as firebase from 'firebase';

export interface PassUpload {
  location: string;
  numberplate: string;
  numberplateDigits: string;
  vehType: string;
  validFrom: string;
  validTill: string;
  duration: string;
  companyName: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  amt: string;
  groupID: string;
  title: string;
}

export interface CompanyTags {
  companyName: string;
  companyID: string;
}

@Component({
  selector: 'app-add-passes',
  templateUrl: './add-passes.component.html',
  styleUrls: ['./add-passes.component.css']
})
export class AddPassesComponent implements OnInit {

  @ViewChild('input') fileInputVariable: any;
  @ViewChild('stepper') stepper;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isOptional = false;
  showTable = false;
  // for updating the table value
  editField: string;
  // isEditable: boolean;
  arrayBuffer: any;
  file: File;
  passUploadList: PassUpload[] = [];

  private capacityTagsCollection: AngularFirestoreCollection<CapacityTags>;
  capacityTags: Observable<CapacityTags[]>;
  tagsReportSubs: Subscription;
  companyTagsArray: CompanyTags[] = [];

  groupID: string;
  businessList: any[];
  afdb: AngularFireDatabase;
  afs: AngularFirestore;
  localFirebaseID: string;
  data = [];
  progressbarValue: number;
  isProgressvarVisible: boolean;
  serverTimestamp: any;

  headerData: string[] = [
    'Location', 'Numberplate', 'NumberplateDigits', 'Vehicle Type', 'Group Pass (Y/N)', 'Valid From(DD)', 'Valid From(MM)',
    'Valid From(YYYY)', 'Valid Till(DD)', 'Valid Till(MM)', 'Valid Till(YYYY)', 'Duration Time(in month)', 'Company Name',
    'User Email', 'User FirstName', 'User LastName', 'User Phone', 'Amount',
  ];


  isValid = false;



  constructor(private _formBuilder: FormBuilder, private downloadService: DownloadService, afdb: AngularFireDatabase,
    afs: AngularFirestore, private databaseService: DatabaseService, private pathService: PathSetterService) {
    this.afdb = afdb;
    this.afs = afs;
    this.formatData = this.formatData.bind(this);
    this.isProgressvarVisible = false;
    // this.isEditable = true;
    this.setGroupID();
    this.setBusinessList();
  }

  ngOnInit() {

    this.firstFormGroup = this._formBuilder.group({
      // firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ''
    });
  }

  isValidForm() {
    return this.isValid;
  }

  downloadTemplate() {
    const reportLabel = 'Pass Upload Template';
    this.downloadService.getExcelReport(this.data, reportLabel, this.headerData);
  }


  formatData() {

    this.isValid = true;
    // this.isEditable = true;
    this.isProgressvarVisible = false;

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      this.passUploadList = [];
      for (let i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      const listData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      // const serverTimestamp = firebase.firestore.Timestamp.fromDate(new Date());
      // this.serverTimestamp = serverTimestamp.seconds;
      // console.log('Dagli' , serverTimestamp.seconds);

      for (let i = 0; i < listData.length; i++) {
        const listItem = listData[i];

        let passGroupID: string;
        let passTitle: string;

        // set pass group ID
        if (listItem['Group Pass (Y/N)'] === 'Y') {
          passGroupID = this.databaseService.getGroupID();
          passTitle = 'City Pass';
        } else {
          passGroupID = '';
          passTitle = 'normal';
        }
        // Checking for empty optional fields
        if (!listItem['User Email']) { listItem['User Email'] = ''; }
        if (!listItem['User LastName']) { listItem['User LastName'] = ''; }
        if (!listItem['User Phone']) { listItem['User Phone'] = ''; }

        listItem['Duration Time(in month)'] = String(listItem['Duration Time(in month)']);

        // assiging group pass
        this.passUploadList.push({
          location: listItem['Location'],
          numberplate: listItem['Numberplate'],
          numberplateDigits: listItem['NumberplateDigits'],
          vehType: listItem['Vehicle Type'],
          validFrom: listItem['Valid From(MM)'] + '/' + listItem['Valid From(DD)'] + '/' + listItem['Valid From(YYYY)'],
          validTill: listItem['Valid Till(MM)'] + '/' + listItem['Valid Till(DD)'] + '/' + listItem['Valid Till(YYYY)'],
          duration: listItem['Duration Time(in month)'],
          companyName: listItem['Company Name'],
          email: listItem['User Email'],
          firstname: listItem['User FirstName'],
          lastname: listItem['User LastName'],
          phone: listItem['User Phone'] = String(listItem['User Phone']),
          amt: listItem['Amount'],
          groupID: passGroupID,
          title: passTitle,
        });
      }
    };
    fileReader.readAsArrayBuffer(this.file);

  }

  changeListener(event) {
    this.clearData();
    this.file = event.target.files[0];
  }

  clearData() {
    this.isValid = false;
    this.passUploadList = [];
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  setBusinessList() {
    this.businessList = this.databaseService.getBusinessList();
  }

  setCompanyTags(businessID: string, companyTagName: string) {

    this.capacityTagsCollection = this.afs.collection<CapacityTags>('/groups/' + this.groupID + '/bizs/' + businessID +
      '/corporate_tags/');
    this.capacityTags = this.capacityTagsCollection.valueChanges();

    this.tagsReportSubs = this.capacityTags.pipe(
      take(1)
    ).subscribe(
      (response) => {
        this.data = response;
        this.companyTagsArray = [
          {
            companyID: '',
            companyName: 'NA'
          }
        ];
        for (let i = 0; i < this.data.length; i++) {
          const reportItem = this.data[i];
          this.companyTagsArray.push({
            companyName: reportItem.gpTagFullTitle,
            companyID: reportItem.gpTagId
          });
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }

  removePassItem(id: any) {
    // this.awaitingPersonList.push(this.personList[id]);
    this.passUploadList.splice(id, 1);
  }

  changePassItemValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent;
    this.passUploadList[id][property] = this.editField;
  }

  uploadTemplate() {
    this.isValid = false;
    // this.isEditable = false;
    this.isProgressvarVisible = true;

    let businessKey = null;
    let companyTagID = null;
    let validFromTimeStamp = null;
    let validTillTimeStamp = null;
    let validFromEpochDate = null;
    let validTillEpochDate = null;
    let itemkey = null;
    // let numberplate = null;
    // let numberplateDigits = null;
    let numberplateList = [];
    let numberplateDigitsList = [];
    let vehTypeList = [];
    let companyTagsArray = [];

    let FBSuccessfulUploadCount = 0;
    let FSSuccessfulUploadCount = 0;

    this.progressbarValue = 0;

    for (let i = 0; i < this.passUploadList.length; i++) {
      // Seting variables to default
      // add variables here later
      businessKey = null;
      companyTagID = null;
      validFromTimeStamp = null;
      validTillTimeStamp = null;
      validFromEpochDate = null;
      validTillEpochDate = null;
      // numberplate = null;
      // numberplateDigits = null;
      numberplateList = [];
      numberplateDigitsList = [];
      vehTypeList = [];
      companyTagsArray = [];

      // checking for vehicles numberplate and vehicle type in multipass
      numberplateList = this.passUploadList[i]['numberplate'].split('|');
      numberplateDigitsList = String(this.passUploadList[i]['numberplateDigits']).split('|');
      vehTypeList = this.passUploadList[i]['vehType'].split('|');


      // finding business key for the uploaded passes
      for (let k = 0; k < this.businessList.length; k++) {
        if (this.passUploadList[i]['location'].toLocaleLowerCase() === this.businessList[k].name.toLocaleLowerCase()) {
          businessKey = this.businessList[k].id;
          // console.log('Business Key: ' + businessKey);
          // setting the company tag list
          this.setCompanyTags(businessKey, this.passUploadList[i]['companyName']);
          break;
        }
      }
      // getting the numberplate digits
      //  todo

      // Getting the timestamp from the uploaded pass
      validFromTimeStamp = new Date(this.passUploadList[i]['validFrom']).getTime();
      validTillTimeStamp = new Date(this.passUploadList[i]['validTill']).getTime();
      validFromEpochDate = (validFromTimeStamp + 330 * 60 * 1000) / (1000 * 60 * 60 * 24);
      validTillEpochDate = (validTillTimeStamp + 330 * 60 * 1000) / (1000 * 60 * 60 * 24);
      const serverTstamp = firebase.firestore.Timestamp.fromDate(new Date());
      this.serverTimestamp = serverTstamp.seconds;
      // console.log('check timestamp', this.serverTimestamp);

      // setting the path and getting the ref from Firebase if businesskey isn't null
      if (businessKey !== null) {

        // Firestore URL
        const passCollection = this.afs.collection<PassFireStore>('/bizs/' + businessKey + '/passes/');

        // pushing for vehciles in the multipass array
        for (let j = 0; j < numberplateList.length; j++) {
          let itemsRef = null;
          const tempNumPlate = numberplateList[j];
          const tempNumPlateDigits = numberplateDigitsList[j];
          const tempVehType = vehTypeList[j];
          const tempFrom = validFromTimeStamp;
          const tempTill = validTillTimeStamp;

          // Firebase URL
          if (this.passUploadList[i]['title'] === 'City Pass') {
            itemsRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() + '/gpGroupPasses/' + this.groupID + '/All');
          } else {
            itemsRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() + '/passes/' + businessKey + '/All');
          }
          // console.log('VTList:', vehTypeList);
          // console.log('VTListJ:', validFromTimeStamp);
          // console.log('VTListI:', validTillTimeStamp);
          // populating the node with data
          this.localFirebaseID = '';
          Promise.resolve(
            itemsRef.push({
              parkingPassDisplayId: '', // NA
              parkingPassGroupId: this.passUploadList[i]['groupID'],
              passAmount: this.passUploadList[i]['amt'],
              passCompanyName: this.passUploadList[i]['companyName'],
              passDurationType: this.passUploadList[i]['duration'],
              passFirebaseId: '',
              passFirestoreDocKey: '', // fix later
              passImageUrl: '', // NA
              passIssueTimeStamp: new Date().getTime(),
              passIssuedById: 'Manager',
              passLocalTimeReverse: 0 - new Date().getTime(),
              passModificationTimeStamp: new Date().getTime(),
              passModifiedById: 'Dashboard Admin',
              passNumberPlate: tempNumPlate,
              passNumberPlateDigits: tempNumPlateDigits,
              passOriginalAmount: this.passUploadList[i]['amt'],
              passReceiptId: '', // NA
              passRfidTag: '', // NA
              passStatus: 141,
              passTitle: this.passUploadList[i]['title'],
              passValidFrom: tempFrom,
              passValidFromEpochDay: validFromEpochDate,  // add now
              passValidTill: tempTill,
              passValidTillEpochDay: validTillEpochDate, // add now
              passVehicleId: '', // NA
              passVehicleOwnerId: '', // NA
              passVehicleType: tempVehType,
              userEmail: this.passUploadList[i]['email'],
              userFirstName: this.passUploadList[i]['firstname'],
              userLastName: this.passUploadList[i]['lastname'],
              userPhone: this.passUploadList[i]['phone'],
              serverTimestamp: serverTstamp,
            }
            )).then((item) => {
              itemkey = item.key;
              this.localFirebaseID = itemkey;
              itemsRef.update(itemkey, { passFirebaseId: itemkey });
              FBSuccessfulUploadCount++;

              // console.log('Firebase Upload Success for id :' + FBSuccessfulUploadCount);

              Promise.resolve(
                passCollection.add({
                  firebaseId: this.localFirebaseID,
                  id: '',
                  parkingDedicated: null, // fix
                  parkingDedicatedSlot: null, // fix
                  parkingPassBusinessId: businessKey,
                  parkingPassDisplayId: '', // NA
                  parkingPassGroupId: this.passUploadList[i]['groupID'],
                  parkingSlot: null, // fix
                  parkingUnitNo: null, // fix
                  passAmount: this.passUploadList[i]['amt'],
                  passCompanyName: this.passUploadList[i]['companyName'],
                  passCompanyTagId: '',
                  passDurationTillMonthEnd: null, // fix
                  passDurationType: this.passUploadList[i]['duration'],
                  passFirebaseId: this.localFirebaseID, // fix
                  passFirestoreDocKey: '',
                  passImageUrl: '', // NA
                  passIsPrimary: 0, // fix
                  passIssueTimeStamp: new Date().getTime(),
                  passIssuedById: 'Dashboard Admin',
                  passLocalTimeReverse: 0 - new Date().getTime(),
                  passMasterId: '', // TODO
                  passModificationTimeStamp: new Date().getTime(),
                  passModifiedById: 'Dashboard Admin',
                  passNumberPlate: tempNumPlate,
                  passNumberPlateDigits: tempNumPlateDigits,
                  passOriginalAmount: this.passUploadList[i]['amt'],
                  passParkingRestrictions: null, // fix
                  passReceiptId: '', // NA
                  passRfidTag: '', // NA
                  passStatus: 141,
                  passTitle: this.passUploadList[i]['title'],
                  passValidFrom: tempFrom,
                  passValidFromEpochDay: validFromEpochDate,  // add now
                  passValidTill: tempTill,
                  passValidTillEpochDay: validTillEpochDate,  // add now
                  passVehicleBrandId: '', // NA
                  passVehicleBrandName: '', // NA
                  passVehicleId: '', // NA
                  passVehicleModelId: '', // NA
                  passVehicleModelName: '', // NA
                  passVehicleOwnerId: '', // NA
                  passVehicleType: tempVehType,
                  userEmail: this.passUploadList[i]['email'],
                  userFirstName: this.passUploadList[i]['firstname'],
                  userLastName: this.passUploadList[i]['lastname'],
                  userPhone: this.passUploadList[i]['phone'],
                  validityDetails: null,
                  serverTimestamp: serverTstamp,
                })).then((fsitem) => {
                  // mapping the company name and tag ID
                  for (let k = 0; k < this.companyTagsArray.length; k++) {
                    if (this.passUploadList[i]['companyName'].toLocaleLowerCase() ===
                      this.companyTagsArray[k].companyName.toLocaleLowerCase()) {
                      companyTagID = this.companyTagsArray[k].companyID;
                    }
                  }

                  itemkey = fsitem.id;
                  passCollection.doc(itemkey).update({ passFirestoreDocKey: itemkey, passCompanyTagId: companyTagID });
                  itemsRef.update(this.localFirebaseID, { passFirestoreDocKey: itemkey });
                  FSSuccessfulUploadCount++;

                  this.tagsReportSubs.unsubscribe();
                  // console.log('Firestore Upload Success for id :' + FSSuccessfulUploadCount);
                  // console.log('Total Passes :' + this.passUploadList.length);
                  this.progressbarValue = Math.round((FSSuccessfulUploadCount / this.passUploadList.length) * 100);
                  // console.log('Upload Percent:' + this.progressbarValue);

                  if (FSSuccessfulUploadCount === (this.passUploadList.length)) {

                    setTimeout(() => {
                      alert('Uploaded ' + FSSuccessfulUploadCount + ' Passes');
                      // console.log('finished upload');
                      this.stepper.selectedIndex = 0;
                      this.clearData();
                    }, 2000);
                  }
                }).catch(err => {
                  console.log(err);
                });
            }).catch(err => {
              console.log(err);
            });
        }
      }


    }

  }
}
