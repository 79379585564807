import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import {
  NgbDateStruct,
  NgbCalendar,
  NgbInputDatepicker,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/Subscription';
import { DatabaseService } from '../services/database.service';
import { DownloadService } from '../services/download.service';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import * as firebase from 'firebase/app';
import { map, filter, switchMap, take } from 'rxjs/operators';
import { NgModel } from '@angular/forms';
import {PathSetterService} from '../services/pathSetter.service';
import { SMS } from './sms.model';
import * as moment from 'moment';
const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one &&
  two &&
  two.year === one.year &&
  two.month === one.month &&
  two.day === one.day;
const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day < two.day
      : one.month < two.month
    : one.year < two.year;
const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day > two.day
      : one.month > two.month
    : one.year > two.year;
// tslint:disable-next-line:no-empty-interface
export interface Report {
//  a1BusinessId: string;
 a2Date: string;
 a3Time: string;
 a4phoneNumber: string;
 a5Status: string;
 a6Vehiclenumber: string;
 a7Name: string;
 a8Link: boolean;
 a9Genqr: boolean;
}
@Component({
  selector: 'app-sms-report',
  templateUrl: './sms-report.component.html',
  styleUrls: ['./sms-report.component.css'],
})
export class SmsReportComponent implements OnInit {
  @ViewChild('d') input: NgbInputDatepicker;
  @ViewChild(NgModel) datePick: NgModel;
  @ViewChild('myRangeInput') myRangeInput: ElementRef;
  title = 'SMS Delivery Report';
  db: AngularFirestore;
  afdb: AngularFireDatabase;
  public searchText: string;
  progressBarVisible = false;
  exitTime: any;
  exitDate: any;
  hoveredDate: NgbDateStruct;
  fromDate: any;
  toDate: any;
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  startDate: NgbDateStruct;
  model: any;
  dateFilterFrom: BehaviorSubject<number | null>;
  dateFilterTo: BehaviorSubject<number | null>;
  groupID: string;
  businessList: any[];
  businessID: string;
  businessName: string;
  rearrangedReportArray: Report[] = [];
  smsReportSubs: Subscription;
  smss: Observable<SMS[]>;
  data: SMS[];
  headerData: string[] = [
  'Date', 'Time', 'Phone Number', 'Vehicle Number' , 'Delivery Status'
];
  constructor(
    db: AngularFirestore,
    afdb: AngularFireDatabase,
    calendar: NgbCalendar,
    element: ElementRef,
    private renderer: Renderer2,
    private _parserFormatter: NgbDateParserFormatter,
    private databaseService: DatabaseService,
    private downloadService: DownloadService, private pathService: PathSetterService,
  ) {
    this.db = db;
    this.afdb = afdb;
    this.dateFilterFrom = new BehaviorSubject(new Date().getTime());
    this.dateFilterTo = new BehaviorSubject(new Date().getTime());
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
    this.startDate = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
    this.maxDate = { year: now.getFullYear() + 1, month: now.getMonth() + 1, day: now.getDate() };
    this.minDate = { year: now.getFullYear() - 1, month: now.getMonth() + 1, day: now.getDate() };
    this.setGroupID();
    this.setBusinessDropDownList();
  }
  ngOnInit() {
    this.loadList();
  }
  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }
  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }
  setBusinessID(biz) {
    this.progressBarVisible = true;
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.smsReportSubs.unsubscribe();
    this.loadList();
  }

  stopLoader() {
    this.progressBarVisible = false;
  }

  loadList() {
    this.smss = combineLatest([
      this.dateFilterFrom,
      this.dateFilterTo
    ]).pipe(
      switchMap(([filterFromDate, filterToDate]) =>
      this.db.collectionGroup<SMS>('smsRecords' , ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        if (filterFromDate) {
            // console.log('check dates:', filterFromDate, filterToDate);
            query = query.where('creationDate', '>=', filterFromDate)
              .orderBy('creationDate', 'desc');
          }
          if (filterToDate) { query = query.where('creationDate', '<=', filterToDate); }
          query = query.where('businessId', '==', this.businessID);
          return query;
      }).valueChanges()
      )
    );
    this.smsReportSubs = this.smss
    // .pipe(
    //   take(1),
    //   map(
    //     (response) => {
    //       const smsList: SMS[] = response;
    //        for (const smsItem of smsList) {
    //           if (!smsItem.phoneNumber) { smsItem.phoneNumber = 'N/A'; }
    //           for (let k = 0; k < this.businessList.length; k++) {
    //             if (smsItem.businessId === this.businessList[k].id) {
    //               smsItem.businessId = this.businessList[k].name;
    //             }
    //           }
    //         }
    //         return smsList;
    //     }
    //   )
    //   )
      .subscribe(
        (response) => {
          this.data = response;
          // console.log('sms data' , response);
          this.rearrangedReportArray = [];
          for (let i = 0; i < this.data.length; i++) {
            const reportItem = this.data[i];
             this.rearrangedReportArray.push({
               a2Date: moment(new Date(reportItem.creationDate)).format('DD/MM/YYYY'),
               a3Time: new Date(reportItem.creationDate).toLocaleTimeString(),
              //  a1BusinessId: reportItem.businessId,
               a4phoneNumber: reportItem.phoneNumber,
               a6Vehiclenumber: reportItem.vehicleNumber,
               a5Status: reportItem.status,
               a7Name: reportItem.name,
               a8Link: reportItem.smsOpen,
               a9Genqr: reportItem.qrDownload,
             });
             this.progressBarVisible = false;
          }
        },
        (error) => { console.log(error); }
      );
  }
  downloadExcelReport() {
    const reportLabel = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilterFrom.value).toLocaleDateString()
      + ' to ' + new Date(this.dateFilterTo.value).toLocaleDateString() + ' Report';
    this.downloadService.getExcelReport(this.rearrangedReportArray, reportLabel, this.headerData);
  }
  downloadPDFReport() {
    const reportLabel = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilterFrom.value).toLocaleDateString()
      + ' to ' + new Date(this.dateFilterTo.value).toLocaleDateString() + ' Report';
    const options = {
      styles: {
        fontSize: 1.5,
        font: 'helvetica', // helvetica, times, courier
      },
      startY: true, // false (indicates margin top value) or a number
      margin: { left: 1 },
      theme: 'striped',
      fillColor: false, // false for transparent or a color as described below
      textColor: 20,
      columnWidth: 'auto', // 'auto', 'wrap' or a number
      tableWidth: 'wrap', // 'auto', 'wrap' or a number,
    };
    this.downloadService.getPDFReport(this.rearrangedReportArray, reportLabel, this.headerData, options);
  }
 onDateSelection(date: NgbDateStruct) {
    let parsed = '';
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
      // this.model = `${this.fromDate.year} - ${this.toDate.year}`;
      this.input.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.fromDate != null) {
      parsed += this._parserFormatter.format(this.fromDate);
      const newDateFrom = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      newDateFrom.setHours(0, 0, 0);
      this.dateFilterFrom.next(newDateFrom.getTime());
    }
    if (this.toDate != null) {
      parsed += ' / ' + this._parserFormatter.format(this.toDate);
      // tslint:disable-next-line:no-shadowed-variable
      const newDateTo = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
      newDateTo.setHours(23, 59, 59);
      this.dateFilterTo.next(newDateTo.getTime());
    } else {
      const newDateTo = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      newDateTo.setHours(23, 59, 59);
      this.dateFilterTo.next(newDateTo.getTime());
    }
    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);
    // const newDateTo = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    //      newDateTo.setHours(23, 59, 59);
    //     this.dateFilterTo.next(newDateTo.getTime());
  }
  isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);
}
