import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupFireStore } from '../group-setup/groupFirestore.model';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.css']
})
export class AddGroupComponent implements OnInit {
  afs: AngularFirestore;
  groupID: string;
  constructor(public activeModal: NgbActiveModal, afs: AngularFirestore) {
    this.afs = afs;
  }

  ngOnInit() {
  }
  onSubmit(FormData) {
    // tslint:disable-next-line:prefer-const
    // tslint:disable-next-line:prefer-const
    // let name: string;
    // console.log('grp name' + FormData.groupName);
    let itemKey = null;
    const groupCollection = this.afs.collection<GroupFireStore>('/groups/');
    Promise.resolve(
      groupCollection.add({
        groupCreatedByName: 'Dashboard Admin',
        groupCreatedOn: new Date().getTime(),
        groupCreatedSorter: 0 - new Date().getTime(),
        groupID: '',
        groupName: '' + FormData.groupName,
      }))
      .then((fsitem) => {
        itemKey = fsitem.id;
        groupCollection.doc(itemKey).update({ groupID: itemKey })
          .catch(err => {
            // console.log(err);
            // console.log('dekh add group', groupCollection);
          });
      });

    //  window.location.reload();
  }
}
