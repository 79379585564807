import { Injectable } from '@angular/core';
import { CapacityTags } from './capacity-tags/capacityTags.model';
import { ReportingTags } from './reporting-tags/reportingTags.model';
import { PaymentTags } from './payment-tags/paymentTags.model';


@Injectable()
export class TagEditService {

  editCapacityTags: CapacityTags;
  editReportingTags: ReportingTags;
  editPaymentTags: PaymentTags;
  editBusinessID: string;
  tagType: string;

  setCapacityTagEditServiceDetails(CapacityTagsInfo: CapacityTags, BusinessId: string, TagType: string) {
    this.editCapacityTags = CapacityTagsInfo;
    this.editBusinessID = BusinessId;
    this.tagType = TagType;
  }

  setReportingTagEditServiceDetails(CapacityTagsInfo: ReportingTags, BusinessId: string, TagType: string) {
    this.editReportingTags = CapacityTagsInfo;
    this.editBusinessID = BusinessId;
    this.tagType = TagType;
  }

  setPaymentTagEditServiceDetails(paymentTagsInfo: PaymentTags, BusinessId: string, TagType: string) {
    this.editPaymentTags = paymentTagsInfo;
    this.editBusinessID = BusinessId;
    this.tagType = TagType;
  }

  getCapacityTagInfo() {
    return this.editCapacityTags;
  }

  getReportingTagInfo() {
    return this.editReportingTags;
  }

  getPaymentTagInfo() {
    return this.editPaymentTags;
  }

  getTagType() {
    return this.tagType;
  }

  getBusinessID() {
    return this.editBusinessID;
  }

}
