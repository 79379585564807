import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DatabaseService } from '../../services/database.service';
import { PathSetterService } from '../../services/pathSetter.service';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { PassFireStore } from '../../add-passes/passFireStore.model';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase';

@Component({
  selector: 'app-add-citypass-popup',
  templateUrl: './add-citypass-popup.component.html',
  styleUrls: ['./add-citypass-popup.component.css']
})
export class AddCitypassPopupComponent implements OnInit {

  groupID: string;
  businessList: any[];
  businessID: string;
  businessName: string;
  model = 1;
  isCityPass = true;
  FromDateModel;
  ToDateModel;
  localFirebaseID: string;
  afdb: AngularFireDatabase;
  afs: AngularFirestore;
  minDate: any;
  serverTimestamp: any;

  constructor(public activeModal: NgbActiveModal, private databaseService: DatabaseService,
    afdb: AngularFireDatabase, afs: AngularFirestore, private pathService: PathSetterService, public snackBar: MatSnackBar) {

    this.minDate = { year: new Date().getFullYear(), month: (new Date().getMonth()) + 1, day: new Date().getDate() };
    this.FromDateModel = { year: new Date().getFullYear(), month: (new Date().getMonth()) + 1, day: new Date().getDate() };
    this.ToDateModel = { year: new Date().getFullYear(), month: (new Date().getMonth()) + 2, day: new Date().getDate() };
    this.afdb = afdb;
    this.afs = afs;
  }

  ngOnInit() {
    this.setGroupID();
    this.setBusinessList();
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  setBusinessList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setBusinessID(biz) {
    this.businessName = biz.name;
    this.businessID = biz.id;
  }

  setDateFormat(model, isTillDate: boolean) {
    // console.log(model);
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    const StrToday = Strmm + '/' + Strdd + '/' + yyyy;

    const convertedDate = new Date(StrToday);

    if (isTillDate) {
      convertedDate.setHours(23);
      convertedDate.setMinutes(59);
      convertedDate.setSeconds(59);
    }

    return convertedDate.getTime();
  }

  onSubmit(Formdata) {
    this.activeModal.close('Close click');

    let vehType: string;
    let passGroupID: string;
    let passTitle: string;
    let itemkey = null;
    const validFromTimeStamp = this.setDateFormat(Formdata.dp1, false);
    const validTillTimeStamp = this.setDateFormat(Formdata.dp2, true);
    const validFromEpochDate = (validFromTimeStamp + 330 * 60 * 1000) / (1000 * 60 * 60 * 24);
    const validTillEpochDate = (validTillTimeStamp + 330 * 60 * 1000) / (1000 * 60 * 60 * 24);
    const serverTimestamp = firebase.firestore.Timestamp.fromDate(new Date());
    this.serverTimestamp = serverTimestamp.seconds;
    // console.log('Dagli' , serverTimestamp.seconds);

    if (Formdata.radioVehType === 1) {
      vehType = '4W';
    } else {
      vehType = '2W';
    }

    passGroupID = this.databaseService.getGroupID();
    passTitle = 'City Pass';

    if (this.businessID !== null) {

      // Firebase URL
      const itemsRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() + '/gpGroupPasses/' + this.groupID + '/All');
      // Firestore URL
      const passCollection = this.afs.collection<PassFireStore>('/bizs/' + this.businessID + '/passes/');

      this.localFirebaseID = '';
      Promise.resolve(
        itemsRef.push({
          parkingPassDisplayId: '', // NA
          parkingPassGroupId: passGroupID,
          passAmount: Formdata.amt,
          passCompanyName: '', // add for OC flavour
          passDurationType: '', // fix later
          passFirebaseId: '',
          passFirestoreDocKey: '', // fix later
          passImageUrl: '', // NA
          passIssueTimeStamp: new Date().getTime(),
          passIssuedById: 'Manager',
          passLocalTimeReverse: 0 - new Date().getTime(),
          passModificationTimeStamp: new Date().getTime(),
          passModifiedById: 'Dashboard Admin',
          passNumberPlate: Formdata.numplate,
          passNumberPlateDigits: Formdata.numplate.substring(6),
          passOriginalAmount: Formdata.amt,
          passReceiptId: '', // NA
          passRfidTag: '', // NA
          passStatus: 141,
          passTitle: passTitle,
          passValidFrom: validFromTimeStamp,
          passValidFromEpochDay: validFromEpochDate,  // add now
          passValidTill: validTillTimeStamp,
          passValidTillEpochDay: validTillEpochDate, // add now
          passVehicleId: '', // NA
          passVehicleOwnerId: '', // NA
          passVehicleType: vehType,
          userEmail: Formdata.email,
          userFirstName: Formdata.firstname,
          userLastName: Formdata.lastname,
          userPhone: Formdata.phone,
          serverTimestamp: serverTimestamp,
        }))
        .then((item) => {
          itemkey = item.key;
          this.localFirebaseID = itemkey;
          itemsRef.update(itemkey, { passFirebaseId: itemkey });
          Promise.resolve(
            passCollection.add({
              firebaseId: this.localFirebaseID,
              id: '',
              parkingDedicated: null, // fix
              parkingDedicatedSlot: null, // fix
              parkingPassBusinessId: this.businessID,
              parkingPassDisplayId: '', // NA
              parkingPassGroupId: passGroupID,
              parkingSlot: null, // fix
              parkingUnitNo: null, // fix
              passAmount: Formdata.amt,
              passCompanyName: '', // fix
              passCompanyTagId: '', // fix
              passDurationTillMonthEnd: null, // fix
              passDurationType: '', // fix
              passFirebaseId: this.localFirebaseID, // fix
              passFirestoreDocKey: '',
              passImageUrl: '', // NA
              passIsPrimary: 0, // fix
              passIssueTimeStamp: new Date().getTime(),
              passIssuedById: 'Dashboard Admin',
              passLocalTimeReverse: 0 - new Date().getTime(),
              passMasterId: '', // TODO
              passModificationTimeStamp: new Date().getTime(),
              passModifiedById: 'Dashboard Admin',
              passNumberPlate: Formdata.numplate,
              passNumberPlateDigits: Formdata.numplate.substring(6),
              passOriginalAmount: Formdata.amt,
              passParkingRestrictions: null, // fix
              passReceiptId: '', // NA
              passRfidTag: '', // NA
              passStatus: 141,
              passTitle: passTitle,
              passValidFrom: validFromTimeStamp,
              passValidFromEpochDay: validFromEpochDate,  // add now
              passValidTill: validTillTimeStamp,
              passValidTillEpochDay: validTillEpochDate,  // add now
              passVehicleBrandId: '', // NA
              passVehicleBrandName: '', // NA
              passVehicleId: '', // NA
              passVehicleModelId: '', // NA
              passVehicleModelName: '', // NA
              passVehicleOwnerId: '', // NA
              passVehicleType: vehType,
              userEmail: Formdata.email,
              userFirstName: Formdata.firstname,
              userLastName: Formdata.lastname,
              userPhone: Formdata.phone,
              validityDetails: null,
              serverTimestamp: serverTimestamp,
            }))
            .then((fsitem) => {
              itemkey = fsitem.id;
              passCollection.doc(itemkey).update({ passFirestoreDocKey: itemkey });
              itemsRef.update(this.localFirebaseID, { passFirestoreDocKey: itemkey });
            }).catch(err => {
              console.log(err);
            });
        }).catch(err => {
          console.log(err);
        });
    }
  }
}
