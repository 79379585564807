import { Component, OnInit  } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { CameraDetails } from './detail.model';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { DatabaseService } from '../services/database.service';
import { DownloadService } from '../services/download.service';
import { PathSetterService } from '../services/pathSetter.service';
import { take, map } from 'rxjs/operators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { JAN, MatSnackBar } from '@angular/material';
import { CameraFireStore } from '../camera/cameraFireStore.model';
import { CameraEditService } from '../camera/cameraEdit.service';
import { CameraFeedback } from './feedback.model';


export interface Report {
  a1finalPlate: string;
  a2ocrStrings: Map<string, number>;
  a3regexStrings: Map<string, number>;
  a4imageUrl: string;
}

@Component({
  selector: 'app-camera-details',
  templateUrl: './camera-details.component.html',
  styleUrls: ['./camera-details.component.css']
})
export class CameraDetailsComponent implements OnInit {
  title = 'Anpr Details View ';
  afs: AngularFirestore;
  detailsReportSubs: Subscription;
  groupID: string;
  selectedValue: string;
  selectedValue1: string;
  businessList: any[];
  businessID: string;
  businessName: string;
  cameraID: string;
  rearrangedReportArray: Report[] = [];
  recordList: any;
  camfeedback: CameraDetails;
  // cameraFirestoreInfo: CameraFireStore;
  myMap: any[] = [];
  model: NgbDateStruct;
  dateFilter: BehaviorSubject<string | null>;
  private detailsCollection: AngularFirestoreCollection<CameraDetails>;
  detailsOcr: Observable<CameraDetails[]>;
  public pressedButton: string;
  p = 1;
  isDemo: boolean;
  isMcgm: boolean;
  camOpt: string[] = [];
  detailsCam;

  isocrEnbld: false;
  isregexEnbld: false;
  isboxEnbld: false;
  // isnoEdbld = false;
  groundValue: string;

  constructor(afs: AngularFirestore, private databaseService: DatabaseService, private downloadService: DownloadService,
    private pathService: PathSetterService, private cameraEditService: CameraEditService, public snackBar: MatSnackBar) {
    this.afs = afs;
    const date = new Date();
    this.model = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
    const StrToday = this.setDateFormat(this.model);
    this.dateFilter = new BehaviorSubject(StrToday);
    this.isDemo = pathService.getDemoInfo();
    // this.isMcgm = pathService.getMcgmInfo();
    this.setGroupID();
    this.setBusinessDropDownList();
    this.setCameraDropDownList();
  }

  ngOnInit() {
    this.loadListCam();
    this.loadCameraList();

  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setCameraDropDownList() {
    this.camOpt = this.databaseService.getCameraList();
  }

  setCamID(cam) {
    this.cameraID = cam;
    this.loadListCam();
  }

  setBusinessID(biz) {

    this.businessName = biz.name;
    this.businessID = biz.id;
    // this.loadListCam();
  }

  onSubmit(FormData, id) {
    console.log('check', FormData);
    console.log('chefijdiof', id);
    const tempUpdate = {
      groundTruth: '',
      // improveOcr: false,
      // improveRegex: false,
      // improveBox: false
    };
    if (this.businessID !== null) {

      const CameraFeedbackG = this.afs.collection<CameraFeedback>('/groups/' + this.groupID + '/bizs/' + this.businessID +
        '/cameras/' + this.cameraID + '/' + this.dateFilter.value);

      // if (FormData.isboxEditable !== undefined) {
      //   tempUpdate.improveBox = FormData.isboxEditable;
      // }
      // if (FormData.isocrEditable !== undefined) {
      //   tempUpdate.improveOcr = FormData.isocrEditable;
      // }
      // if (FormData.isregexEditable !== undefined) {
      //   tempUpdate.improveRegex = FormData.isregexEditable;
      // }
      if (FormData.groundValue !== undefined) {
        tempUpdate.groundTruth = FormData.groundValue;
      }
      Promise.resolve(
        CameraFeedbackG.doc(id).update(tempUpdate)
          .catch(err => {
            console.log(err);
          }));
    }
  }

  loadCameraList() {
    // console.log('Load Lost');
    let cameras;
    // tslint:disable-next-line:max-line-length
    this.detailsCollection = this.afs.collection<CameraDetails>('/groups/' + this.groupID + '/bizs/' + this.businessID + '/cameras/');
    // this.detailsOcr = this.detailsCollection.valueChanges();
    cameras = this.detailsCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const id = a.payload.doc.id;
        // console.log('Id og:', id);
        // data.id = id;
        this.camOpt.push(id);
        // console.log('Id:', this.camOpt); // Get all the ID from firestore and store it in an array
        return id;
      }))
    ).subscribe(
      (response) => {
      });
  }

  loadListCam() {
    console.log('cameIDD:' + this.cameraID);
    // tslint:disable-next-line:max-line-length
    this.detailsCollection = this.afs.collection<CameraDetails>('/groups/' + this.groupID + '/bizs/' + this.businessID +
    '/cameras/' + this.cameraID + '/' + this.dateFilter.value);
    this.detailsCam = this.detailsCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as CameraDetails;
        const id = a.payload.doc.id;
        data.id = id;
        // console.log('Iddddddddd:', id);
        return data;
      }))
    ).subscribe(
      (response) => {
        this.myMap = [];
        this.recordList = response; // total record
        // console.log('dgf', this.recordList);
        this.recordList.forEach((record) => { // single record
          const meraObject = {
            ocrStrings: '',
            regexStrings: '',
            finalPlate: '',
            imageUrl: '',
            fullImageUrl: '',
            groundTruth: '',
            id: ''
          };
          Object.keys(record.ocrStrings).forEach((ocr) => {
            // console.log(ocr);
            meraObject.ocrStrings = meraObject.ocrStrings + ocr + ' : ' + record.ocrStrings[ocr] + '\n';
          });
          Object.keys(record.regexStrings).forEach((regx) => {
            // console.log(regx);
            meraObject.regexStrings = meraObject.regexStrings + regx + ' : ' + record.regexStrings[regx] + '\n';
          });
          meraObject.finalPlate = record.finalPlate;
          meraObject.imageUrl = record.imageUrl;
          meraObject.fullImageUrl = record.fullImageUrl;
          meraObject.groundTruth = record.groundTruth;
          meraObject.id = record.id;
          // console.log('gd:' , meraObject.groundTruth);
          this.myMap.push(meraObject);
        });
      });
  }

  setDateFormat(model) {
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    const StrToday = Strdd + '-' + Strmm + '-' + yyyy;
    return StrToday;
  }

  filterByDate(model) {
    this.detailsCam.unsubscribe();
    this.rearrangedReportArray = [];
    const StrToday = this.setDateFormat(model);
    this.dateFilter.next(StrToday);
    // this.loadListCam();
    // this.loadCameraList();
  }
}
