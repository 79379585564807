import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatabaseService } from '../../services/database.service';
import { PathSetterService } from '../../services/pathSetter.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-add-device-popup',
  templateUrl: './add-device-popup.component.html',
  styleUrls: ['./add-device-popup.component.css']
})
export class AddDevicePopupComponent implements OnInit {

  db: AngularFireDatabase;
  groupID: string;
  addDeviceSubs: Subscription;

  constructor(public activeModal: NgbActiveModal, private databaseService: DatabaseService, db: AngularFireDatabase,
    private pathService: PathSetterService, public snackBar: MatSnackBar) {
    this.db = db;
    this.setGroupID();
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  onSubmit(Formdata) {
    this.activeModal.close('Close click');
    // console.log('imeiNo' + this.imeiNoToEdit);
    // console.log('inventoryCode' + Formdata.inventoryCode);

    const deviceObj = {
      'imeiNo': Formdata.imeiNo,
      'inventoryCode': Formdata.inventoryCode,
    };

    const itemRef = this.db.list('/getParking/' + this.pathService.getPathEnvironment() + '/device_details/' + this.groupID);

    const item = this.db.list('/getParking/' + this.pathService.getPathEnvironment() + '/device_details/' + this.groupID
     + '/' + Formdata.imeiNo).valueChanges();

     this.addDeviceSubs = item.subscribe(snapshot => {
      if (snapshot.length === 0) {
        itemRef.update(Formdata.imeiNo, deviceObj);
        this.snackBar.open('Device added', 'dismiss', {
          duration: 3000
        });
        this.addDeviceSubs.unsubscribe();
      } else {
        this.snackBar.open('Device exists, please enter a different imei number', 'dismiss', {
          duration: 3000
        });
      }
    });
  }

  ngOnInit() {
  }


}
