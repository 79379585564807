import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { PaymentTags } from './paymentTags.model';

import { DatabaseService } from '../../services/database.service';
import { DownloadService } from '../../services/download.service';
import { PathSetterService } from '../../services/pathSetter.service';
import { TagEditService } from '../tagEdit.service';
import { TagAddService } from '../tagAdd.service';
import { AddTagsPopupComponent } from '../add-tags-popup/add-tags-popup.component';
import { EditTagsPopupComponent } from '../edit-tags-popup/edit-tags-popup.component';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
// tslint:disable-next-line:import-blacklist
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase/app';
import { map, switchMap, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { AuthService } from '../../core/auth.service';
import { User } from '../../services/user.model';

export interface Report {
  a1ExitType: string;
  a2DefaultAmount: number;
}

@Component({
  selector: 'app-payment-tags',
  templateUrl: './payment-tags.component.html',
  styleUrls: ['./payment-tags.component.css']
})
export class PaymentTagsComponent implements OnInit, OnDestroy {

  db: AngularFirestore;
  afdb: AngularFireDatabase;
  title = 'Payment Tags Summary';

  tagsReportSubs: Subscription;
  groupID: string;
  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: string;
  progressBarVisible = false;
  user: User;
  userSub: Subscription;
  headerData: string[] = ['Full Title', 'Amount'];
  rearrangedReportArray: Report[] = [];
  data: PaymentTags[];

  private paymentTagsCollection: AngularFirestoreCollection<PaymentTags>;
  paymentTags: Observable<PaymentTags[]>;

  constructor(private modalService: NgbModal, db: AngularFirestore, private databaseService: DatabaseService,
    private downloadService: DownloadService, private pathService: PathSetterService, public snackBar: MatSnackBar,
    private tagEditService: TagEditService, private tagAddService: TagAddService, afdb: AngularFireDatabase,
    public authService: AuthService) {
    this.db = db;
    this.afdb = afdb;
    this.setGroupID();
    this.setBusinessDropDownList();
  }

  ngOnInit() {
    this.userSub = this.authService.user.pipe(
      take(1)
    ).subscribe(user => this.user = user);
    // console.log('Setting User in dashbord component: ' + this.user.userRole);
    this.loadList();
  }

  canEdit() {
    if (this.authService.canEdit(this.user)) {
      return true;
    } else {
      return false;
    }
  }

  canDelete() {
    if (this.authService.canDelete(this.user)) {
      return true;
    } else {
      return false;
    }
  }


  loadList() {

    this.paymentTagsCollection = this.db.collection<PaymentTags>('/groups/' + this.groupID + '/bizs/' + this.businessID +
      '/payment_tags/');
    this.paymentTags = this.paymentTagsCollection.valueChanges();

    this.tagsReportSubs = this.paymentTags.pipe(
      take(1)
    ).subscribe(
      (response) => {
        this.data = response;
        this.rearrangedReportArray = [];
        for (let i = 0; i < this.data.length; i++) {
          const reportItem = this.data[i];
          // console.log(reportItem);

          this.rearrangedReportArray.push({
            a1ExitType: reportItem.exitType,
            a2DefaultAmount: reportItem.defaultAmount,
          });
          this.progressBarVisible = false;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  downloadExcelReport() {
    const reportLabel = this.title + ' ' + ' Report';
    this.downloadService.getExcelReport(this.rearrangedReportArray, reportLabel, this.headerData);
  }

  downloadPDFReport() {
    const reportLabel = this.title + ' Report';

    const options = {
      styles: {
        fontSize: 10,
        font: 'helvetica', // helvetica, times, courier
      },
      startY: true, // false (indicates margin top value) or a number
      margin: { left: 1 },
      theme: 'striped',
      fillColor: false, // false for transparent or a color as described below
      textColor: 20,
      columnWidth: 'auto', // 'auto', 'wrap' or a number
      tableWidth: 'wrap', // 'auto', 'wrap' or a number,

    };

    this.downloadService.getPDFReport(this.rearrangedReportArray, reportLabel, this.headerData, options);
  }

  stopLoader() {
    this.progressBarVisible = false;
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setBusinessID(biz) {
    this.progressBarVisible = true;
    this.tagsReportSubs.unsubscribe();
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.loadList();
  }

  openModal() {
    this.tagAddService.setTagType('Payment');
    const modalRef = this.modalService.open(AddTagsPopupComponent, { size: 'lg' });
    modalRef.componentInstance.name = 'World';
    modalRef.result.then(
      () => {
        this.rearrangedReportArray = [];
        this.data = [];
        this.tagsReportSubs.unsubscribe();
        this.loadList();
      }
    ).catch((error) => {
      console.log(error);
      console.log('cancel');
    });
  }

  removeTagsItem(id: any) {

    // // deleteing from firestore
    const pamyentTagDelCollection = this.db.collection<PaymentTags>('/groups/' + this.groupID + '/bizs/' + this.businessID +
      '/payment_tags/');
    pamyentTagDelCollection.doc(this.data[id]['tagId']).delete().then(
      () => {
        this.rearrangedReportArray = [];
        this.data = [];
        this.tagsReportSubs.unsubscribe();
        this.loadList();
      }
    );

    // deleting from firebase
    const itemsDelRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() +
      '/Setup/' + this.businessID + '/manager_settings/custom_exit_flow/');
    itemsDelRef.remove(this.data[id]['tagId']);
    this.snackBar.open('Tag deleted', 'dismiss', {
      duration: 3000
    });

  }

  editTagItem(index: any) {
    this.tagEditService.setPaymentTagEditServiceDetails(this.data[index], this.businessID, 'Payment');
    const modalRef2 = this.modalService.open(EditTagsPopupComponent, { size: 'lg' });
    modalRef2.componentInstance.name = 'World';
    modalRef2.result.then(
      () => {
        this.rearrangedReportArray = [];
        this.data = [];
        this.tagsReportSubs.unsubscribe();
        this.loadList();
      }
    ).catch((error) => {
      console.log(error);
      console.log('cancelling');
    });
  }

  ngOnDestroy() {
    this.rearrangedReportArray = [];
    this.data = [];
    this.tagsReportSubs.unsubscribe();
  }

}
