import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { DatabaseService } from '../services/database.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  constructor(public authService: AuthService, private router: Router) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/dashboard']);
    }
  }

  loginWithEmail(Formdata) {
    this.authService.emailLogin(Formdata.inputEmail, Formdata.inputPassword);
  }

  loginWithGoogle() {
    this.authService.googleLogin();
  }


}
