import { Injectable } from '@angular/core';

@Injectable()
export class TagAddService {
    tagType: string;

    setTagType(tagTypeInfo: string) {
        this.tagType = tagTypeInfo;
        // console.log(this.tagType);
    }

    getTagType() {
        return this.tagType;
    }
}
