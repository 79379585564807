import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { PassFireStore } from '../../add-passes/passFireStore.model';

import { DatabaseService } from '../../services/database.service';
import { CityPassEditService } from '../citypassEdit.service';
import { PathSetterService } from '../../services/pathSetter.service';
import { Pass } from '../../passes/pass.model';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-edit-citypass-popup',
  templateUrl: './edit-citypass-popup.component.html',
  styleUrls: ['./edit-citypass-popup.component.css']
})
export class EditCitypassPopupComponent implements OnInit {

  db: AngularFirestore;
  passInfo: Pass;
  passFireStoreInfo: PassFireStore;
  afdb: AngularFireDatabase;
  afs: AngularFirestore;
  groupID: string;

  isCityPass: boolean;
  model: number;
  numberPlate: string;
  userFirstName: string;
  userLastName: string;
  userPhone: string;
  userEmail: string;
  passAmount: number;

  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: any;

  constructor(public activeModal: NgbActiveModal, private databaseService: DatabaseService, private pathService: PathSetterService,
    public snackBar: MatSnackBar, private citypassEditService: CityPassEditService, afdb: AngularFireDatabase, afs: AngularFirestore) {
    this.afdb = afdb;
    this.afs = afs;
    this.setGroupID();

    this.setBusinessDropDownList();
    this.getPassInformation();
  }

  setBusinessDropDownList() {
    // console.log('inside set dropdown list');
    this.businessList = this.databaseService.getBusinessList();
    // console.log(this.businessList);
    // this.businessName = this.businessList[0].name;
    // this.businessID = this.businessList[0].id;
    this.businessID = this.citypassEditService.getBusinessID();
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  // setBusinessID() {
  //   // this.businessName = this.selectedValue.name;
  //   this.businessID = this.selectedValue;
  // }

  getPassInformation() {
    this.passInfo = this.citypassEditService.getPassInfo();
    this.numberPlate = this.passInfo.passNumberPlate;
    this.userFirstName = this.passInfo.userFirstName;
    this.userLastName = this.passInfo.userLastName;
    this.userPhone = this.passInfo.userPhone;
    this.userEmail = this.passInfo.userEmail;
    this.passAmount = this.passInfo.passAmount;

    if (this.passInfo.passVehicleType === '4W') {
      this.model = 1;
    } else {
      this.model = 2;
    }
  }

  onSubmit(Formdata) {

    // console.log(Formdata);

    this.activeModal.close('Close click');

    // let vehType: string;
    // if (Formdata.radioVehType === 1) {
    //   vehType = '4W';
    // } else {
    //   vehType = '2W';
    // }

    this.passInfo.userFirstName = Formdata.firstname;
    this.passInfo.userLastName = Formdata.lastname;
    this.passInfo.userPhone = Formdata.phone;
    this.passInfo.userEmail = Formdata.email;
    this.passInfo.passNumberPlate = Formdata.numplate;
    this.passInfo.passNumberPlateDigits = Formdata.numplate.substring(9);
    this.passInfo.passAmount = Formdata.amt;
    // this.passInfo.passVehicleType = vehType;
    this.passInfo.passModificationTimeStamp = new Date().getTime(),
    this.passInfo.passModifiedById = 'Dashboard Admin';

    const itemsRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() + '/gpGroupPasses/' + this.groupID + '/All/');
    itemsRef.update(this.passInfo.passFirebaseId, this.passInfo);
    this.snackBar.open('Pass updated', 'dismiss', {
      duration: 3000
    });

    // Firestore URL
    const passCollection = this.afs.collection<PassFireStore>('/bizs/' + this.businessID + '/passes/');

    passCollection.doc(this.passInfo.passFirestoreDocKey)
      .update({
        userFirstName: Formdata.firstname,
        userLastName: Formdata.lastname,
        userPhone: Formdata.phone,
        userEmail: Formdata.email,
        passNumberPlate: Formdata.numplate,
        passNumberPlateDigits: Formdata.numplate.substring(6),
        passAmount: Formdata.amt,
        // passVehicleType: vehType,
        passModificationTimeStamp: new Date().getTime(),
        passModifiedById: 'Dashboard Admin',
      });
  }
  ngOnInit() {
  }
}
