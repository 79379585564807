import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { NgbDateStruct, NgbCalendar, NgbInputDatepicker, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/Subscription';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { DatabaseService } from '../services/database.service';
import { DownloadService } from '../services/download.service';
import { PathSetterService } from '../services/pathSetter.service';
import * as firebase from 'firebase/app';
import { map, filter, switchMap, take } from 'rxjs/operators';
import { NgModel } from '@angular/forms';
import {Transactions} from './transaction.model';
import * as moment from 'moment';

const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;


    export interface Report {
      a1CreationDate: any;
      a2Amount: number;
      a3PaymentMode: string;
      a4BankName: string;
      a5GatewayName: string;
      a6Status: string;
      // a7PaymentType: string;
      a8Phone: string;
      a9TransId: string;
      a10OrderId: string;

    }

@Component({
  selector: 'app-transaction-report',
  templateUrl: './transaction-report.component.html',
  styleUrls: ['./transaction-report.component.css']
})
export class TransactionReportComponent implements OnInit {

  @ViewChild('d') input: NgbInputDatepicker;
  @ViewChild(NgModel) datePick: NgModel;
  @ViewChild('myRangeInput') myRangeInput: ElementRef;

  title = 'Daily Transaction Report';
  db: AngularFirestore;
  afdb: AngularFireDatabase;

  hoveredDate: NgbDateStruct;
  fromDate: any;
  toDate: any;
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  startDate: NgbDateStruct;
  model: any;
  dateFilterFrom: BehaviorSubject<number | null>;
  dateFilterTo: BehaviorSubject<number | null>;
  progressBarVisible = false;

  groupID: string;
  businessList: any[];
  businessID: string;
  businessName: string;
  rearrangedReportArray: Report[] = [];
  transactionReportSubs: Subscription;
  transs: Observable<Transactions[]>;
  data: Transactions[];
  headerData: string[] = [
     'Date' , 'Amount' , 'Payment Type' , 'Bank Name', 'Gateway Name' , 'Status' ,
    'Phone', 'Trans Id', 'OrderID'
  ];

  constructor(db: AngularFirestore, afdb: AngularFireDatabase, element: ElementRef, calendar: NgbCalendar,
  private renderer: Renderer2, private _parserFormatter: NgbDateParserFormatter, private databaseService: DatabaseService,
    private downloadService: DownloadService, private pathService: PathSetterService) {
    this.db = db;
    this.afdb = afdb;
    this.dateFilterFrom = new BehaviorSubject(new Date().getTime());
    this.dateFilterTo = new BehaviorSubject(new Date().getTime());
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
    this.startDate = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
    this.maxDate = { year: now.getFullYear() + 1, month: now.getMonth() + 1, day: now.getDate() };
    this.minDate = { year: now.getFullYear() - 1, month: now.getMonth() + 1, day: now.getDate() };
    this.setGroupID();
    this.setBusinessDropDownList();
}
ngOnInit() {
    this.loadList();
  }
  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }
  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }
  setBusinessID(biz) {
    this.progressBarVisible = true;
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.transactionReportSubs.unsubscribe();
    this.loadList();
  }

  stopLoader() {
    this.progressBarVisible = false;
  }

   loadList() {
    this.transs = combineLatest([
      this.dateFilterFrom,
      this.dateFilterTo
    ]).pipe(
      switchMap(([filterFromDate, filterToDate]) =>
      this.db.collectionGroup<Transactions>('payments' , ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        if (filterFromDate) {
            // console.log('check dates:', filterFromDate, filterToDate);
            query = query.where('paymentCreatedOn', '>=', filterFromDate)
              .orderBy('paymentCreatedOn', 'desc');
          }
          if (filterToDate) { query = query.where('paymentCreatedOn', '<=', filterToDate); }
          query = query.where('businessId', '==', this.businessID);
          return query;
      }).valueChanges()
      )
    );
    this.transactionReportSubs = this.transs
      .subscribe(
        (response) => {
          this.data = response;
          // console.log('sms data' , response);
          this.rearrangedReportArray = [];
          for (let i = 0; i < this.data.length; i++) {
            const reportItem = this.data[i];
             this.rearrangedReportArray.push({
               a1CreationDate : moment(new Date(reportItem.paymentCreatedOn)).format('DD/MM/YYYY'),
               a2Amount: reportItem.amount,
               a3PaymentMode: reportItem.paymentMode,
               a4BankName: reportItem.bankName,
               a5GatewayName: reportItem.gatewayName,
               a6Status: reportItem.status,
              //  a7PaymentType: reportItem.cardScheme,
               a8Phone: reportItem.phone,
               a9TransId: reportItem.txnId,
               a10OrderId: reportItem.orderId
             });
             this.progressBarVisible = false;
          }
        },
        (error) => { console.log(error); }
      );
   }
 downloadExcelReport() {
    const reportLabel = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilterFrom.value).toLocaleDateString()
      + ' to ' + new Date(this.dateFilterTo.value).toLocaleDateString() + ' Report';
    this.downloadService.getExcelReport(this.rearrangedReportArray, reportLabel, this.headerData);
  }
  downloadPDFReport() {
    const reportLabel = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilterFrom.value).toLocaleDateString()
      + ' to ' + new Date(this.dateFilterTo.value).toLocaleDateString() + ' Report';
    const options = {
      styles: {
        fontSize: 1.5,
        font: 'helvetica', // helvetica, times, courier
      },
      startY: true, // false (indicates margin top value) or a number
      margin: { left: 1 },
      theme: 'striped',
      fillColor: false, // false for transparent or a color as described below
      textColor: 20,
      columnWidth: 'auto', // 'auto', 'wrap' or a number
      tableWidth: 'wrap', // 'auto', 'wrap' or a number,
    };
    this.downloadService.getPDFReport(this.rearrangedReportArray, reportLabel, this.headerData, options);
  }
onDateSelection(date: NgbDateStruct) {
    let parsed = '';
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
      // this.model = `${this.fromDate.year} - ${this.toDate.year}`;
      this.input.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.fromDate != null) {
      parsed += this._parserFormatter.format(this.fromDate);
      const newDateFrom = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      newDateFrom.setHours(0, 0, 0);
      this.dateFilterFrom.next(newDateFrom.getTime());
    }
    if (this.toDate != null) {
      parsed += ' / ' + this._parserFormatter.format(this.toDate);
      // tslint:disable-next-line:no-shadowed-variable
      const newDateTo = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
      newDateTo.setHours(23, 59, 59);
      this.dateFilterTo.next(newDateTo.getTime());
    } else {
      const newDateTo = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      newDateTo.setHours(23, 59, 59);
      this.dateFilterTo.next(newDateTo.getTime());
    }
    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);
    // const newDateTo = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    //      newDateTo.setHours(23, 59, 59);
    //     this.dateFilterTo.next(newDateTo.getTime());
  }
  isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);
}
