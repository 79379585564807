import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-passes',
  templateUrl: './passes.component.html',
  styleUrls: ['./passes.component.css']
})
export class PassesComponent implements OnInit, OnDestroy {

  title = 'Pass Summary';

  constructor() { }

  ngOnInit() { }

  ngOnDestroy() { }
}
