import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { AngularFireDatabase } from '@angular/fire/database';
import { PathSetterService } from '../services/pathSetter.service';
import { Devices } from './device.model';
import { EditDevicesComponent } from './edit-devices/edit-devices.component';
import { AddDevicePopupComponent } from './add-device-popup/add-device-popup.component';
import { DeviceEditService } from './deviceEdit.service';
import { DatabaseService } from '../services/database.service';
import { DownloadService } from '../services/download.service';
import { take } from 'rxjs/operators';
import { AuthService } from '../core/auth.service';
import { User } from '../services/user.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit, OnDestroy {
  db: AngularFireDatabase;
  title = 'Device Management';
  existingDeviceSub: Subscription;
  existingDeviceList: Observable<any[]>;
  deviceList: Devices[];
  headerData: string[] = ['Device IMEI NO.', 'Device Label'];
  groupID: string;


  user: User;
  userSub: Subscription;

  constructor(public authService: AuthService, private modalService: NgbModal, db: AngularFireDatabase,
    private pathService: PathSetterService, private databaseService: DatabaseService, private downloadService: DownloadService,
    private deviceEditService: DeviceEditService) {
    this.db = db;
    this.setGroupID();
    }

  ngOnInit() {
    this.userSub = this.authService.user.pipe(
      take(1)
    ).subscribe(user => this.user = user);
    // console.log('Setting User in dashbord component: ' + this.user.userRole);
    this.loadList();
  }

  canEdit() {
    if (this.authService.canEdit(this.user)) {
      return true;
    } else {
      return false;
    }
  }

  canDelete() {
    if (this.authService.canDelete(this.user)) {
      return true;
    } else {
      return false;
    }
  }


  loadList() {

    this.existingDeviceList = this.db.list('/getParking/' + this.pathService.getPathEnvironment() + '/device_details/'
      + this.groupID).valueChanges();

    this.existingDeviceSub = this.existingDeviceList
      .pipe(
        take(1),
      )
      .subscribe(
        (data) => {
          const response = data;
          // console.log(response);
          this.deviceList = [];
          const imeiNo = Object.keys(response);
          for (let i = 0; i <= imeiNo.length; i++) {
            this.deviceList.push({
              imeiNo: response[imeiNo[i]]['imeiNo'],
              inventoryCode: response[imeiNo[i]]['inventoryCode'],
            });
          }
        });
    // console.log(this.deviceList);
  }

  deleteItem(index: number) {
    const itemsRef = this.db.list('/getParking/' + this.pathService.getPathEnvironment() + '/device_details/'
      + this.groupID);
    itemsRef.remove(this.deviceList[index]['imeiNo']);
    this.deviceList = [];
    this.existingDeviceSub.unsubscribe();
    this.loadList();
  }

  editItem(index: number) {
    this.deviceEditService.setDeviceEditServiceDetails(this.deviceList[index]['imeiNo'], this.deviceList[index]['inventoryCode']);
    const modalRef = this.modalService.open(EditDevicesComponent);
    modalRef.componentInstance.name = 'World';
  }

  openModal() {
    const modalRef2 = this.modalService.open(AddDevicePopupComponent);
    modalRef2.componentInstance.name = 'World';

  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  downloadExcelReport() {
    const reportLabel = this.title + ' ' + new Date().toLocaleDateString() + ' Report';
    this.downloadService.getExcelReport(this.deviceList, reportLabel, this.headerData);
  }

  downloadPDFReport() {
    const reportLabel = this.title + ' ' + new Date().toLocaleDateString() + ' Report';
    const options = {
      styles: {
        fontSize: 18,
        font: 'helvetica', // helvetica, times, courier
      },
      startY: true, // false (indicates margin top value) or a number
      margin: { left: 1 },
      theme: 'striped',
      fillColor: false, // false for transparent or a color as described below
      textColor: 20,
      columnWidth: 'auto', // 'auto', 'wrap' or a number
      tableWidth: 'wrap', // 'auto', 'wrap' or a number,
    };
    this.downloadService.getPDFReport(this.deviceList, reportLabel, this.headerData, options);
  }

  ngOnDestroy() {
    this.deviceList = [];
    this.userSub.unsubscribe();
    this.existingDeviceSub.unsubscribe();
  }

}
