import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../services/database.service';
import { Color } from 'ng2-charts';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { Vehicle } from '../vehicles/vehicle.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.css']
})
export class GraphsComponent implements OnInit {
  title = 'Charts';
  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: string;
  groupID: string;
  db: AngularFirestore;



  constructor(private databaseService: DatabaseService, db: AngularFirestore) {
    this.db = db;
    const date = new Date();
    this.setGroupID();
    this.setBusinessDropDownList();
  }
  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }
  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }
  setBusinessID(biz) {
    this.businessName = biz.name;
    this.businessID = biz.id;
  }

  ngOnInit() {
    // this.charTs();
    // this.hourlyList();
  }

  // hourlyList() {
  //   // this.resetCounter();
  //   console.log('busej', '/groups/' + this.groupID + '/bizs/' + this.businessID +
  //     '/parkingRecords/' + this.StrMMYYYY + '/' + this.StrDate);
  //   this.collection = this.db.collection('/groups/' + this.groupID + '/bizs/' + this.businessID +
  //     '/parkingRecords/' + this.StrMMYYYY + '/' + this.StrDate);
  //   this.collect = this.collection.valueChanges();
  //   this.collectionReportSubs = this.collect.subscribe(
  //     (response) => {
  //       // this.resetCounter();
  //       this.data = response;
  //       console.log('hourly report value:', response);

  //       this.rearrangeVehicleReport = [];
  //       for (let i = 0; i < this.data.length; i++) {
  //         const reportItem = this.data[i];
  //         // console.log('Report Time: ', new Date(reportItem.vrParkingStartTimeL).getHours());
  //         const hour = new Date(reportItem.vrParkingStartTimeL).getHours();
  //         console.log('hour:', hour);
  //         switch (hour) {
  //           case 0: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[0] = this.twoWheelerIn[0] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[0] = this.fourWheelerIn[0] + 1;
  //             }
  //             this.graphtotal['0AM-1AM'] = this.zeroToone++;
  //             break;
  //           }
  //           case 1: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[1] = this.twoWheelerIn[1] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[1] = this.fourWheelerIn[1] + 1;
  //             }
  //             this.graphtotal['1AM-2AM'] = this.oneTotwo++;
  //             break;
  //           }
  //           case 2: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[2] = this.twoWheelerIn[2] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[2] = this.fourWheelerIn[2] + 1;
  //             }
  //             this.graphtotal['2AM-3AM'] = this.twoTothree++;
  //             break;
  //           }
  //           case 3: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[3] = this.twoWheelerIn[3] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[3] = this.fourWheelerIn[3] + 1;
  //             }
  //             this.graphtotal['3AM-4AM'] = this.threeTofour++;
  //             break;
  //           }
  //           case 4: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[4] = this.twoWheelerIn[4] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[4] = this.fourWheelerIn[4] + 1;
  //             }
  //             this.graphtotal['4AM-5AM'] = this.fourTofive++;
  //             break;
  //           }
  //           case 5: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[5] = this.twoWheelerIn[5] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[5] = this.fourWheelerIn[5] + 1;
  //             }
  //             this.graphtotal['5AM-6AM'] = this.fiveTosix++;
  //             break;
  //           }
  //           case 6: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[6] = this.twoWheelerIn[6] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[6] = this.fourWheelerIn[6] + 1;
  //             }
  //             this.graphtotal['6AM-7AM'] = this.sixToseven++;
  //             break;
  //           }
  //           case 7: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[7] = this.twoWheelerIn[7] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[7] = this.fourWheelerIn[7] + 1;
  //             }
  //             this.graphtotal['7AM-8AM'] = this.sevenToeight++;
  //             break;
  //           }
  //           case 8: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[8] = this.twoWheelerIn[8] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[8] = this.fourWheelerIn[8] + 1;
  //             }
  //             this.graphtotal['8AM-9AM'] = this.eightTonine++;
  //             break;
  //           }
  //           case 9: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[9] = this.twoWheelerIn[9] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[9] = this.fourWheelerIn[9] + 1;
  //             }
  //             this.graphtotal['9AM-10AM'] = this.nineToten++;
  //             break;
  //           }
  //           case 10: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[10] = this.twoWheelerIn[10] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[10] = this.fourWheelerIn[10] + 1;
  //             }
  //             this.graphtotal['10AM-11AM'] = this.tenToeleven++;
  //             break;
  //           }
  //           case 11: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[11] = this.twoWheelerIn[11] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[11] = this.fourWheelerIn[11] + 1;
  //             }
  //             this.graphtotal['11AM-12PM'] = this.elevenTotwelve++;
  //             break;
  //           }
  //           case 12: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[12] = this.twoWheelerIn[12] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[12] = this.fourWheelerIn[12] + 1;
  //             }
  //             this.graphtotal['12PM-1PM'] = this.twelveTothirteen++;
  //             break;
  //           }
  //           case 13: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[13] = this.twoWheelerIn[13] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[13] = this.fourWheelerIn[13] + 1;
  //             }
  //             this.graphtotal['1PM-2PM'] = this.thirteenTofourteen++;
  //             break;
  //           }
  //           case 14: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[14] = this.twoWheelerIn[14] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[14] = this.fourWheelerIn[14] + 1;
  //             }
  //             this.graphtotal['2PM-3PM'] = this.fourteenTofifteen++;
  //             break;
  //           }
  //           case 15: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[15] = this.twoWheelerIn[15] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[15] = this.fourWheelerIn[15] + 1;
  //             }
  //             this.graphtotal['3PM-4PM'] = this.fifteenTosixteen++;
  //             break;
  //           }
  //           case 16: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[16] = this.twoWheelerIn[16] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[16] = this.fourWheelerIn[16] + 1;
  //             }
  //             // console.log(reportItem);
  //             this.graphtotal['4PM-5PM'] = this.sixteenToseventeen++;
  //             break;
  //           }
  //           case 17: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[17] = this.twoWheelerIn[17] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[17] = this.fourWheelerIn[17] + 1;
  //             }
  //             // console.log(reportItem);
  //             this.graphtotal['5PM-6PM'] = this.seventeenToeighteen++;
  //             break;
  //           }
  //           case 18: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[18] = this.twoWheelerIn[18] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[18] = this.fourWheelerIn[18] + 1;
  //             }
  //             this.graphtotal['6PM-7PM'] = this.eighteenTonineteen++;
  //             break;
  //           }
  //           case 19: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[19] = this.twoWheelerIn[19] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[19] = this.fourWheelerIn[19] + 1;
  //             }
  //             this.graphtotal['7PM-8PM'] = this.nineteenTotwenty++;
  //             break;
  //           }
  //           case 20: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[20] = this.twoWheelerIn[20] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[20] = this.fourWheelerIn[20] + 1;
  //             }
  //             this.graphtotal['8PM-9PM'] = this.twentyTotwentyone++;
  //             break;
  //           }
  //           case 21: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[21] = this.twoWheelerIn[21] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[21] = this.fourWheelerIn[21] + 1;
  //             }
  //             console.log(reportItem);
  //             this.graphtotal['9PM-10PM'] = this.twentyoneTotwentytwo++;
  //             break;
  //           }
  //           case 22: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[22] = this.twoWheelerIn[22] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[22] = this.fourWheelerIn[22] + 1;
  //             }
  //             this.graphtotal['10PM-11PM'] = this.twentytwoTotwentythree++;
  //             break;
  //           }
  //           case 23: {
  //             if (reportItem.vrVehicleType === '2W') {
  //               this.twoWheelerIn[23] = this.twoWheelerIn[23] + 1;
  //             }
  //             if (reportItem.vrVehicleType === '4W') {
  //               this.fourWheelerIn[23] = this.fourWheelerIn[23] + 1;
  //             }
  //             this.graphtotal['11PM-12AM'] = this.twentythreeTotwentyfour++;
  //             break;
  //           }
  //           default: {
  //             break;
  //           }
  //         }
  //       }
  //       console.log('Graph Total: ', this.graphtotal);
  //     },
  //     (error) => console.log(error)
  //   );
  // }


  // charTs() {
  //   this.lineChartOptions = {
  //     type: 'line',
  //     responsive: true,
  //     legend: {
  //       position: 'right' // place legend on the right side of chart
  //     },
  //     scales: {
  //       xAxes: [{
  //         stacked: true
  //       }],
  //       yAxes: [{
  //         stacked: true // this also..
  //       }]
  //     }
  //   };

  //   const newArray = this.graphtotal.map(o => {
  //     return { '11AM-12PM': 2 };
  //   });
  //   console.log('sdfjkhfjsdh', newArray);

  //     const myMap = [];
  //   Object.keys(this.graphtotal).forEach(function (key) {
  //     const value = this.graphtotal[key];
  //     // myMap.push({ businessName: value, businessId: key });
  //     // console.log(key);
  //   });
  //     // meraObject.regexStrings = meraObject.regexStrings + regx + ' : ' + record.regexStrings[regx] + '\n';
  //   // });
  //   this.lineChartLabels = ['8am-9am', '9am-10am', '10am-11am', '11am-12pm', '12pm-1pm', '1pm-2pm', '2pm-3pm', '3pm-4pm', '4pm-5pm',
  //     // tslint:disable-next-line:whitespace
  //     '5pm-6pm', '6pm-7pm', '7pm-8pm', '8pm-9pm', '9pm-10pm', '10pm-11pm', '11pm-12am']; // Hours
  //   this.lineChartType = 'line';
  //   // tslint:disable-next-line:no-inferrable-types
  //   this.lineChartLegend = true;
  //   this.lineChartData = [{
  //     label: 'Vehicles Entered',
  //     data: [41, 21, 85, 147, 148, 140, 116, 127, 222, 154, 161, 219, 203, 89, 16, 0],
  //     backgroundColor: '#2B9FF7'
  //   }];
  //   // tslint:disable-next-line:prefer-const
  //   let data = [
  //     41, 21, 85, 147, 148, 140, 116, 127, 222, 154, 161, 219, 203, 89, 16, 0
  //   ];
  //   // tslint:disable-next-line:prefer-const
  //   let clone = JSON.parse(JSON.stringify(this.lineChartData));
  //   clone[0].data = data;
  //   this.lineChartData = clone;
  // }

  // onChartClick(event) {
  //   console.log('fdsg', event);
  // }
}
