import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { User } from '../services/user.model';
import { DatabaseService } from '../services/database.service';
import { PathSetterService } from '../services/pathSetter.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import { switchMap, map, take } from 'rxjs/operators';


@Injectable()
export class AuthService {

  user: BehaviorSubject<User> = new BehaviorSubject(null);
  userDetails: firebase.User = null;
  error: any;
  token: string;

  constructor(private afAuth: AngularFireAuth,
    // private afs: AngularFirestore,
    private db: AngularFireDatabase, private router: Router, private databaseservice: DatabaseService,
    private pathService: PathSetterService) {
    this.afterSignin();
  }

  afterSignin() {
    this.afAuth.authState
      .pipe(
        switchMap((auth) => {
          if (auth) {
            /// signed in
            // console.log('signed in');
            this.userDetails = auth;
            // console.log(this.userDetails);
            // URL
            return this.db.object('/getParking/' + this.pathService.getPathEnvironment() + '/user/' + auth.uid).valueChanges();
          } else {
            /// not signed in
            // console.log('not signed in');
            return of(null);
          }
        }))
      .subscribe(userA => {
        // console.log(userA);
        this.user.next(userA);
        this.databaseservice.setUserID(this.user.value.userId);
        this.databaseservice.setGroupID(this.user.value.groupID); // If playtm id use group list function of db service
        this.databaseservice.setBusinessList(this.user.value.business_list);
        this.router.navigateByUrl('/dashboard');
      });
  }

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
  }

  emailLogin(email: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((user) => {
        this.afterSignin();
      })
      .catch(error => {
        // console.log(error);
        throw error;
      });
  }

  private async oAuthLogin(provider) {
    try {
      const credential = await this.afAuth.auth.signInWithPopup(provider);
      // console.log('creds' + credential);
      this.afterSignin();
    } catch (err) {
      this.error = err;
    }
  }

  signOut() {
    window.localStorage.clear();
    this.afAuth.auth.signOut().then(() => {
      this.router.navigate(['/']);
    });
  }

  isLoggedIn() {
    if (this.userDetails === null) {
      return false;
    } else {
      return true;
    }
  }

  ///// Role-based Authorization //////

  canRead(user: User): boolean {
    const allowed = ['GPADMIN','MANAGER', 'ADMIN'];
    return this.checkAuthorization(user, allowed);
  }

  canEdit(user: User): boolean {
    const allowed = ['GPADMIN','MANAGER', 'ADMIN', 'SUPERVISOR'];
    return this.checkAuthorization(user, allowed);
  }

  canDelete(user: User): boolean {
    const allowed = ['GPADMIN','MANAGER', 'ADMIN'];
    return this.checkAuthorization(user, allowed);
  }

  // determines if user has matching role
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) { return false; }
    for (const role of allowedRoles) {
      if (user.dashboardRole.toLocaleLowerCase() === role.toLocaleLowerCase()) {
        return true;
      }
    }
    return false;
  }

}


