import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../services/database.service';
import { PathSetterService } from '../services/pathSetter.service';
import { NgbModal, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { Camera } from './camera.model';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { DownloadService } from '../services/download.service';
import { take, map } from 'rxjs/operators';
import { CameraEditService } from './cameraEdit.service';
import { EditCameraComponent } from './edit-camera/edit-camera.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';



export interface Report {
  a1vrNumberPlate: string;
  a2vrNumberPlateDigits: string;
  a3cameraName: string;
  // a3vrParkingStartTimeL: any;
   a4vrParkingStartTimeL: any;
  // a5vrParkingEndTimeL: any;
  // a6vrParkingEndTimeL: any;
  a7vrRecordType: string;
  // a8vrFromAutomaticPms: boolean;
  //  a9imageUrlIn: string;
  //  a10imageUrlOut: string;
   a10vrCompanyTagname: string;
}
@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.css']
})
export class CameraComponent implements OnInit {
  db: AngularFirestore;
  title = 'Anpr Record List';
  existingRecSub: Subscription;
  existingRecList: Observable<Camera[]>;
  recoList: Camera[];
  groupID: string;
  rearrangeReports: Report[] = [];
  model: NgbDateStruct;
  dateFilter: BehaviorSubject<string | null>;
  isDemo: boolean;
  businessList: any[];
  businessID: string;
  businessName: string;
  StrMMYYYY: String;
  StrDate: string;
  selectedValue: string;
  // groupID: string;
  private anprCollection: AngularFirestoreCollection<Camera>;
  public searchText: string;

  headerData: string[] = ['Vehicle Number', 'Number Plate', ' Time' , 'Record Type' , 'Image Url'];

  constructor(db: AngularFirestore, private databaseService: DatabaseService, private downloadService: DownloadService,
    private pathService: PathSetterService, calendar: NgbCalendar, private cameraEditService: CameraEditService,
    private modalService: NgbModal) {
    this.db = db;
    const date = new Date();
    this.model = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
    const StrToday = this.setDateFormat(this.model);
    this.dateFilter = new BehaviorSubject(StrToday);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    // this.isDemo = pathService.getDemoInfo();
    // this.isMcgm = pathService.getMcgmInfo();
    this.setGroupID();
    this.StrDate = this.setCalenderdate(this.model);
    this.setBusinessDropDownList();
  }

  ngOnInit() {
    this.loadList();
  }
  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }
  loadList() {
    let camCollection: AngularFirestoreCollection<Camera>;
    let cameras;
    camCollection = this.db.collection<Camera>('/groups/' + this.groupID + '/bizs/' + this.businessID +
      '/anprRecords/' + this.StrMMYYYY + '/' + this.StrDate + '/',
      ref => ref.orderBy('vrParkingStartTimeL', 'desc'));
    cameras = camCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Camera;
        // console.log('Data:' , data.vrNumberPlate);
        const id = a.payload.doc.id;
        data.id = id;
        // console.log('Id:' , id);
        return { id, ...data };
      }))
    ).subscribe(
      (response) => {
        this.recoList = response;
        //  console.log('BABA' , response);
        this.rearrangeReports = [];
        for (let i = 0; i < this.recoList.length; i++) {
          const reportItem = this.recoList[i];
          this.rearrangeReports.push({
            a1vrNumberPlate: reportItem.vrNumberPlate,
            a2vrNumberPlateDigits: reportItem.vrNumberPlateDigits,
            a3cameraName: reportItem.cameraName,
            // a3vrParkingStartTimeL: new Date(reportItem.vrParkingStartTimeL).toLocaleDateString(),
             a4vrParkingStartTimeL: new Date(reportItem.vrParkingStartTimeL).toLocaleTimeString(),
            // a5vrParkingEndTimeL: new Date(reportItem.vrParkingEndTimeL).toLocaleDateString(),
            // a6vrParkingEndTimeL: new Date(reportItem.vrParkingEndTimeL).toLocaleTimeString(),
            a7vrRecordType: reportItem.vrRecordType,
            // a9imageUrlIn: reportItem.imageUrlIn,
            // a10imageUrlOut: reportItem.imageUrlOut
             a10vrCompanyTagname: reportItem.vrCompanyTagname
          });
      }
    },
    (error) => { console.log(error); }
    );
  }
  // loadListSC() {
  //   let camCollection: AngularFirestoreCollection<Camera>;
  //   let cameras;
  //   camCollection = this.db.collection<Camera>('/groups/' + this.groupID + '/bizs/' + this.businessID +
  //     '/parkingRecords/' + this.StrMMYYYY + '/' + this.StrDate + '/',
  //     ref => ref.orderBy('vrParkingStartTimeL', 'desc'));

  //   cameras = camCollection.snapshotChanges().pipe(
  //     map(actions => actions.map(a => {
  //       const data = a.payload.doc.data() as Camera;
  //       console.log('BABA DATA  ' + data);
  //       const id = a.payload.doc.id;
  //       data.id = id;
  //       console.log('BABA ID ' + id);
  //       return { id, ...data };
  //     }))
  //   ).subscribe(
  //     (response) => {
  //       this.recoList = response;
  //       console.log('BABA ', response);
  //     },
  //     (error) => { console.log(error); }
  //   );
  // }

  editCameraItem(index: any) {
    // console.log('Index:', index);
     this.loadList();
    this.cameraEditService.setCameraEditServiceDetails(this.recoList[index], this.businessID);
    const modalRef2 = this.modalService.open(EditCameraComponent);
    modalRef2.componentInstance.name = 'World';

  }

  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setBusinessID(biz) {
    // this.existingRecSub.unsubscribe();
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.loadList();
  }
  setDateFormat(model) {
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    const StrToday = Strdd + '-' + Strmm + '-' + yyyy;
    return StrToday;
  }
  setCalenderdate(model) {
    let Strdd = null;
    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const StrDate = Strdd;
    return StrDate;
  }

  setCalenderMonthYear(model) {
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    const yyyy = model.year;

    const StrToday = Strmm + '-' + yyyy;
    return StrToday;
  }

  filterByDate(model) {
    const StrToday = this.setDateFormat(model);
    this.dateFilter.next(StrToday);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    this.StrDate = this.setCalenderdate(this.model);
    // this.existingRecSub.unsubscribe();
    this.loadList();
  }
  downloadExcelReport() {
    const reportLabel = this.title + ' Reports';
    this.downloadService.getExcelReport(this.rearrangeReports, reportLabel, this.headerData);
  }

  downloadPDFReport() {
    const reportLabel = this.title + ' Reports';
    // const reportLabel = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilterFrom.value).toLocaleDateString()
    //   + ' to ' + new Date(this.dateFilterTo.value).toLocaleDateString() + ' Report';

    const options = {
      styles: {
        fontSize: 8,
        font: 'helvetica', // helvetica, times, courier
      },
      startY: true, // false (indicates margin top value) or a number
      margin: { left: 1 },
      theme: 'striped',
      fillColor: false, // false for transparent or a color as described below
      textColor: 20,
      columnWidth: 'auto', // 'auto', 'wrap' or a number
      tableWidth: 'wrap', // 'auto', 'wrap' or a number,

    };

    this.downloadService.getPDFReport(this.rearrangeReports, reportLabel, this.headerData, options);
  }
}
