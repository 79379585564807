import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
// tslint:disable-next-line:import-blacklist
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { DatabaseService } from '../services/database.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { DownloadService } from '../services/download.service';
import { PathSetterService } from '../services/pathSetter.service';
import { map, switchMap, take } from 'rxjs/operators';

export interface Companies {
  a1companyName: string;
  a2capacity2W: any;
  a3occupancy2W: any;
  a4capacity4W: any;
  a5occupancy4W: any;
}

@Component({
  selector: 'app-company-summary',
  templateUrl: './company-summary.component.html',
  styleUrls: ['./company-summary.component.css']
})

export class CompanysummaryComponent implements OnInit, OnDestroy {

  title = 'Company Wise Summary';
  afdb: AngularFireDatabase;

  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: string;
  businessid: string;
  companyReportSub: Subscription;
  companyList: Observable<any[]>;
  progressBarVisible = false;
  rearrangedCompanyArray: Companies[] = [];

  headerData: string[] = [
    'Company Name', 'Two Wheeler Capacity', 'Two Wheeler Occupancy', 'Four Wheeler Capacity', 'Four Wheeler Occupancy'];

  data: any[] = [];

  //  headerData: string[] = [
  //   'Sr.No' , 'Company Name' , '2W Total' , '4W Total' ];

  constructor(private databaseService: DatabaseService, afdb: AngularFireDatabase, private downloadService: DownloadService,
    private pathService: PathSetterService) {
    this.afdb = afdb;
    this.setBusinessDropDownList();
  }


  ngOnInit() {
    this.loadList();
  }

  loadList() {
    // URL
    console.log('company name check:' , '/getParking/' + this.pathService.getPathEnvironment() +
    '/Counter/' + this.businessID + '/corporate_park/');
    this.companyList = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() +
      '/Counter/' + this.businessID + '/corporate_park/').valueChanges();

    this.companyReportSub = this.companyList
      .pipe(take(1))
      .subscribe((response) => {
        this.data = response;
        this.rearrangedCompanyArray = [];
        for (let i = 0; i < this.data.length; i++) {
          const companyItem = this.data[i];
          let tempCapacity4W = 0;
          let tempCapacity2W = 0;
          let tempOccupancy2W = 0;
          let tempOccupancy4W = 0;
          let title = '';
          if (companyItem['2W']) {
            tempCapacity2W = companyItem['2W']['capacity'];
            tempOccupancy2W = companyItem['2W']['occupancy'];
          }
          if (companyItem['4W']) {
            tempCapacity4W = companyItem['4W']['capacity'];
            tempOccupancy4W = companyItem['4W']['occupancy'];
          }
          title = companyItem['title'];

          this.rearrangedCompanyArray.push({
            a1companyName: title,
            a2capacity2W: isNaN(tempCapacity2W) ? 0 : tempCapacity2W,
            a3occupancy2W: isNaN(tempOccupancy2W) ? 0 : tempOccupancy2W,
            a4capacity4W: isNaN(tempCapacity4W) ? 0 : tempCapacity4W,
            a5occupancy4W: isNaN(tempOccupancy4W) ? 0 : tempOccupancy4W,
          });
          this.progressBarVisible = false;
        }
      },
        (error) => {
          console.log(error);
        }
      );
  }

  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setBusinessID(biz) {
    // console.log('Inside Set business ID method');
    this.progressBarVisible = true;
    this.companyReportSub.unsubscribe();
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.loadList();
  }

  stopLoader() {
    this.progressBarVisible = false;
  }

  downloadExcelReport() {
    const reportLabel = this.title + ' Companies';
    this.downloadService.getExcelReport(this.rearrangedCompanyArray, reportLabel, this.headerData);
  }

  downloadPDFReport() {
    const reportLabel = this.title + ' Report';

    const options = {
      styles: {
        fontSize: 12,
        font: 'helvetica', // helvetica, times, courier
      },
      startY: true, // false (indicates margin top value) or a number
      margin: { left: 1 },
      theme: 'striped',
      fillColor: false, // false for transparent or a color as described below
      textColor: 20,
      columnWidth: 'auto', // 'auto', 'wrap' or a number
      tableWidth: 'wrap', // 'auto', 'wrap' or a number,
    };
    this.downloadService.getPDFReport(this.rearrangedCompanyArray, reportLabel, this.headerData, options);
  }

  ngOnDestroy() {
    this.companyReportSub.unsubscribe();
  }
}


