import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    //  Capacity
    // gptags = tags detail
    // counternode = has capacity and fname and sname has same firebase ID as gpTags

    // decorative tags
    // gp decoratuve tags = tag details
  }

}
