import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbInputDatepicker, NgbDateStruct, NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { Vehicle } from '../vehicles/vehicle.model';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { DatabaseService } from '../services/database.service';
import { DownloadService } from '../services/download.service';
import { PathSetterService } from '../services/pathSetter.service';
import { attachEmbeddedView } from '@angular/core/src/view';



@Component({
  selector: 'app-daily-collection',
  templateUrl: './daily-collection.component.html',
  styleUrls: ['./daily-collection.component.css']
})
export class DailyCollectionComponent implements OnInit {
  @ViewChild('d') datePicker: NgbInputDatepicker;
  title = 'Daily Summary';

  db: AngularFirestore;
  adb: AngularFireDatabase;
  data: Vehicle[];
  vehicleReport: Subscription;
  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: string;
  private vehicleCollection: AngularFirestoreCollection<Vehicle>;
  vehicles: Observable<Vehicle[]>;
  groupID: string;
  model: NgbDateStruct;
  dateFilter: BehaviorSubject<string | null>;
  StrMMYYYY: String;
  StrDate: string;
  StrYYYY: string;
  StrMM: string;

  exitTime: any;
  exitDate: any;

  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  dateFilterFrom: BehaviorSubject<number | null>;
  dateFilterTo: BehaviorSubject<number | null>;

  // Enter Vehicle types
  twoWheelerTicket = 0;
  threeWheelerTicket = 0;
  fourWheelerTicket = 0;
  twoWheelerPass = 0;
  threeWheelerPass = 0;
  fourWheelerPass = 0;
  twoWheelerTerminal = 0;
  twoWheelerPhone = 0;
  threeWheelerTerminal = 0;
  threeWheelerPhone = 0;
  fourWheelerTerminal = 0;
  fourWheelerPhone = 0;
  cycTicket = 0;
  cycPass = 0;
  cycPhone = 0;
  cycTerminal = 0;
  cvTicket = 0;
  cvPass = 0;
  cvPhone = 0;
  cvTerminal = 0;
  cartTicket = 0;
  cartPass = 0;
  cartPhone = 0;
  cartTerminal = 0;

  // Exit Vehicle Types
  twoWheelerexTerminal = 0;
  fourWheelerexTerminal = 0;
  threeWheelerexTerminal = 0;
  threeWheelerexPhone = 0;
  twoWheelerexPhone = 0;
  fourWheelerexPhone = 0;
  cycexPhone = 0;
  cycexTerminal = 0;
  cvexPhone = 0;
  cvexTerminal = 0;
  cartexPhone = 0;
  cartexTerminal = 0;
  cartexTicket = 0;
  cartexPass = 0;
  cycexTicket = 0;
  cycexPass = 0;
  cvexTicket = 0;
  cvexPass = 0;

  // Collections
  total = 0;
  twowheelerAmount = 0;
  threewheelerAmount = 0;
  fourwheelerAmount = 0;
  cvAmount = 0;
  cycAmount = 0;
  cartAmount = 0;
  // overnight = 0;

  constructor(db: AngularFirestore, private databaseService: DatabaseService, private downloadService: DownloadService,
    private pathService: PathSetterService, calendar: NgbCalendar, private modalService: NgbModal, adb: AngularFireDatabase) {
    this.db = db;
    this.adb = adb;
    const date = new Date();
    this.model = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
    const StrToday = this.setDateFormat(this.model);
    this.dateFilter = new BehaviorSubject(StrToday);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    this.StrYYYY = this.setCalenderYear(this.model);
    this.StrMM = this.setCalenderMonth(this.model);

    this.dateFilterFrom = new BehaviorSubject(new Date().getTime());
    this.dateFilterTo = new BehaviorSubject(new Date().getTime());
    // this.companyFilter = new BehaviorSubject('');
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
    this.setGroupID();
    this.StrDate = this.setCalenderdate(this.model);
    this.setBusinessDropDownList();
  }

  ngOnInit() {
    this.generateReport();

  }

  resetCounters() {
    this.twoWheelerPass = 0;
    this.threeWheelerPass = 0;
    this.fourWheelerPass = 0;
    this.twoWheelerTicket = 0;
    this.threeWheelerTicket = 0;
    this.fourWheelerTicket = 0;
    this.fourWheelerTerminal = 0;
    this.fourWheelerPhone = 0;
    this.threeWheelerTerminal = 0;
    this.threeWheelerPhone = 0;
    this.twoWheelerTerminal = 0;
    this.twoWheelerPhone = 0;
    this.fourWheelerexPhone = 0;
    this.fourWheelerexTerminal = 0;
    this.threeWheelerexPhone = 0;
    this.threeWheelerexTerminal = 0;
    this.twoWheelerexPhone = 0;
    this.twoWheelerexTerminal = 0;
    this.cycPass = 0;
    this.cycTicket = 0;
    this.cycPhone = 0;
    this.cycTerminal = 0;
    this.cycexPass = 0;
    this.cycexTicket = 0;
    this.cycexPhone = 0;
    this.cycexTerminal = 0;
    this.cvTicket = 0;
    this.cvPass = 0;
    this.cvPhone = 0;
    this.cvTerminal = 0;
    this.cvexTicket = 0;
    this.cvexPass = 0;
    this.cvexPhone = 0;
    this.cvexTerminal = 0;
    this.cartPass = 0;
    this.cartPhone = 0;
    this.cartTicket = 0;
    this.cartTerminal = 0;
    this.cartexTicket = 0;
    this.cartexPass = 0;
    this.cartexPhone = 0;
    this.cartexTerminal = 0;
    this.twowheelerAmount = 0;
    this.threewheelerAmount = 0;
    this.fourwheelerAmount = 0;
    this.cvAmount = 0;
    this.cycAmount = 0;
    this.cartAmount = 0;
  }

  generateReport() {
    this.resetCounters();
    this.vehicles = this.db.collection<Vehicle>('/groups/' + this.groupID + '/bizs/' + this.businessID +
      '/parkingRecords/' + this.StrYYYY + '/' + this.StrMM + '/' + this.StrDate + '/' + 'records').valueChanges();
    this.vehicleReport = this.vehicles.subscribe(
      (response) => {
        this.resetCounters();
        // console.log('Response dekho ', response);
        this.total = response.length;
        for (let i = 20; i < 33 ; i++) {
          // console.log('Date Testing:', new Date(parseInt(this.StrYYYY), parseInt(this.StrMM), parseInt(this.StrDate) -i));
        for (const vehicle of response) {
            // this.rearrangeReport = [];
          if (vehicle.vrVehicleType === '2W' && vehicle.vrRecordType === 'Ticket') {
            this.twoWheelerTicket++;
            // this.twowheelerAmount += vehicle.vrParkingTotalFare;
            // console.log('************************', vehicle.vrParkingTotalFare);
          }
          if (vehicle.vrVehicleType === '3W' && vehicle.vrRecordType === 'Ticket') {
            this.threeWheelerTicket++;
            this.threewheelerAmount += vehicle.vrParkingTotalFare;
            // console.log('*****3', vehicle.vrParkingTotalFare);
          }
          if (vehicle.vrVehicleType === '4W' && vehicle.vrRecordType === 'Ticket') {
            this.fourWheelerTicket++;
            this.fourwheelerAmount += vehicle.vrParkingTotalFare;
            // console.log('*****4', vehicle.vrParkingTotalFare);
          }
          if (vehicle.vrVehicleType === 'CYC' && vehicle.vrRecordType === 'Ticket') {
            this.cycTicket++;
            this.cycAmount += vehicle.vrParkingTotalFare;
            // console.log('*****CYC', vehicle.vrParkingTotalFare);
          }
          if (vehicle.vrVehicleType === 'CV' && vehicle.vrRecordType === 'Ticket') {
            this.cvTicket++;
            this.cvAmount += vehicle.vrParkingTotalFare;
            // console.log('*****CV', vehicle.vrParkingTotalFare);
          }
          if (vehicle.vrVehicleType === 'CART' && vehicle.vrRecordType === 'Ticket') {
            this.cartTicket++;
            this.cartAmount += vehicle.vrParkingTotalFare;
            // console.log('*****cart', vehicle.vrParkingTotalFare);
          }

          if (vehicle.vrVehicleType === '2W' && vehicle.vrRecordType === 'Pass') {
            this.twoWheelerPass++;
          }
          if (vehicle.vrVehicleType === '3W' && vehicle.vrRecordType === 'Pass') {
            this.threeWheelerPass++;
          }
          if (vehicle.vrVehicleType === '4W' && vehicle.vrRecordType === 'Pass') {
            this.fourWheelerPass++;
          }
          if (vehicle.vrVehicleType === 'CYC' && vehicle.vrRecordType === 'Pass') {
            this.cycPass++;
          }
          if (vehicle.vrVehicleType === 'CV' && vehicle.vrRecordType === 'Pass') {
            this.cvPass++;
          }
          if (vehicle.vrVehicleType === 'CART' && vehicle.vrRecordType === 'Pass') {
            this.cartPass++;
          }

          if (vehicle.vrVehicleType === '2W' && vehicle.vrEnteredByName !== '' && vehicle.vrEnteredByName === 'Ticketing Terminal') {
            this.twoWheelerTerminal++;
          }
          if (vehicle.vrVehicleType === '3W' && vehicle.vrEnteredByName !== '' && vehicle.vrEnteredByName === 'Ticketing Terminal') {
            this.threeWheelerTerminal++;
          }

          if (vehicle.vrVehicleType === '4W' && vehicle.vrEnteredByName !== '' && vehicle.vrEnteredByName === 'Ticketing Terminal') {
            this.fourWheelerTerminal++;
          }
          if (vehicle.vrVehicleType === 'CV' && vehicle.vrEnteredByName !== '' && vehicle.vrEnteredByName === 'Ticketing Terminal') {
            this.cvTerminal++;
          }
          if (vehicle.vrVehicleType === 'CYC' && vehicle.vrEnteredByName !== '' && vehicle.vrEnteredByName === 'Ticketing Terminal') {
            this.cycTerminal++;
          }
          if (vehicle.vrVehicleType === 'CART' && vehicle.vrEnteredByName !== '' && vehicle.vrEnteredByName === 'Ticketing Terminal') {
            this.cartTerminal++;
          }

          if (vehicle.vrVehicleType === '2W' && vehicle.vrEnteredByName !== '' && vehicle.vrEnteredByName !== 'Ticketing Terminal') {
            this.twoWheelerPhone++;
          }
          if (vehicle.vrVehicleType === '3W' && vehicle.vrEnteredByName !== '' && vehicle.vrEnteredByName !== 'Ticketing Terminal') {
            this.threeWheelerPhone++;
          }
          if (vehicle.vrVehicleType === '4W' && vehicle.vrEnteredByName !== '' && vehicle.vrEnteredByName !== 'Ticketing Terminal') {
            this.fourWheelerPhone++;
          }
          if (vehicle.vrVehicleType === 'CV' && vehicle.vrEnteredByName !== '' && vehicle.vrEnteredByName !== 'Ticketing Terminal') {
            this.cvPhone++;
          }
          if (vehicle.vrVehicleType === 'CYC' && vehicle.vrEnteredByName !== '' && vehicle.vrEnteredByName !== 'Ticketing Terminal') {
            this.cycPhone++;
          }
          if (vehicle.vrVehicleType === 'CART' && vehicle.vrEnteredByName !== '' && vehicle.vrEnteredByName !== 'Ticketing Terminal') {
            this.cartPhone++;
          }

          // EXIT

          if (vehicle.vrVehicleType === '2W' && vehicle.vrExitedByName !== '' && vehicle.vrExitedByName === 'Ticketing Terminal') {
            this.twoWheelerexTerminal++;
          }
          if (vehicle.vrVehicleType === '3W' && vehicle.vrExitedByName !== '' && vehicle.vrExitedByName === 'Ticketing Terminal') {
            this.threeWheelerexTerminal++;
          }
          if (vehicle.vrVehicleType === '4W' && vehicle.vrExitedByName !== '' && vehicle.vrExitedByName === 'Ticketing Terminal') {
            this.fourWheelerexTerminal++;
          }
          if (vehicle.vrVehicleType === 'CV' && vehicle.vrExitedByName !== '' && vehicle.vrExitedByName === 'Ticketing Terminal') {
            this.cvexTerminal++;
          }
          if (vehicle.vrVehicleType === 'CYC' && vehicle.vrExitedByName !== '' && vehicle.vrExitedByName === 'Ticketing Terminal') {
            this.cycexTerminal++;
          }
          if (vehicle.vrVehicleType === 'CART' && vehicle.vrExitedByName !== '' && vehicle.vrExitedByName === 'Ticketing Terminal') {
            this.cartexTerminal++;
          }

          if (vehicle.vrVehicleType === '2W' && vehicle.vrExitedByName !== '' && vehicle.vrExitedByName !== 'Ticketing Terminal') {
            this.twoWheelerexPhone++;
          }
          if (vehicle.vrVehicleType === '3W' && vehicle.vrExitedByName !== '' && vehicle.vrExitedByName !== 'Ticketing Terminal') {
            this.threeWheelerexPhone++;
          }
          if (vehicle.vrVehicleType === '4W' && vehicle.vrExitedByName !== '' && vehicle.vrExitedByName !== 'Ticketing Terminal') {
            this.fourWheelerexPhone++;
          }
          if (vehicle.vrVehicleType === 'CV' && vehicle.vrExitedByName !== '' && vehicle.vrExitedByName !== 'Ticketing Terminal') {
            this.cvexPhone++;
          }
          if (vehicle.vrVehicleType === 'CYC' && vehicle.vrExitedByName !== '' && vehicle.vrExitedByName !== 'Ticketing Terminal') {
            this.cycexPhone++;
          }
          if (vehicle.vrVehicleType === 'CART' && vehicle.vrExitedByName !== '' && vehicle.vrExitedByName !== 'Ticketing Terminal') {
            this.cartexPhone++;
          }
        }
      }
      }
    );
  }


  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }
  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setBusinessID(biz) {
    // this.resetCounters();
    this.vehicleReport.unsubscribe();
    this.businessName = biz.name;
    this.businessID = biz.id;
    // this.resetCounters();
    this.generateReport();
    // this.twoInCount();
    // this.fourInCount();
    // this.twoOutCount();
    // this.fourOutCount();
    // this.generateOvernight();
  }
  setDateFormat(model) {
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    const StrToday = Strdd + '-' + Strmm + '-' + yyyy;
    return StrToday;
  }
  setCalenderdate(model) {
    let Strdd = null;
    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const StrDate = Strdd;
    return StrDate;
  }

  setCalenderMonthYear(model) {
    let Strmm = null;
    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    const yyyy = model.year;

    const StrToday = yyyy + Strmm;
    return StrToday;
  }

  setCalenderYear(model) {
    const yyyy = model.year;
    return yyyy;
  }

  setCalenderMonth(model) {
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    return Strmm;
  }

  filterByDate(model) {
    // this.resetCounters();
    this.vehicleReport.unsubscribe();
    const StrToday = this.setDateFormat(model);
    this.dateFilter.next(StrToday);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    this.StrDate = this.setCalenderdate(this.model);
    this.StrYYYY = this.setCalenderYear(this.model);
    this.StrMM = this.setCalenderMonth(this.model);
    this.generateReport();
    // this.twoInCount();
    // this.fourInCount();
    // this.twoOutCount();
    // this.fourOutCount();
    // this.generateOvernight();
  }

  // onDateSelection(date: NgbDateStruct) {
  //   // this.progressBarVisible = true;
  //   if (!this.fromDate && !this.toDate) {
  //     this.fromDate = date;
  //   } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
  //     this.toDate = date;
  //     this.datePicker.close();
  //   } else {
  //     this.toDate = null;
  //     this.fromDate = date;
  //   }

  //   if (this.fromDate != null) {
  //     const newDateFrom = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
  //     this.dateFilterFrom.next(newDateFrom.getTime());
  //   }

  //   if (this.toDate != null) {
  //     const newDateTo = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
  //     newDateTo.setHours(23, 59, 59);
  //     this.dateFilterTo.next(newDateTo.getTime());
  //   } else {
  //     const newDateTo = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
  //     newDateTo.setHours(23, 59, 59);
  //     this.dateFilterTo.next(newDateTo.getTime());

  //   }
  // }
  // downloadExcelReport() { }
  // downloadPDFReport() { }


  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    this.vehicleReport.unsubscribe();
    // this.CompanyListSub.unsubscribe();
  }

}
