import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { PathSetterService } from '../services/pathSetter.service';
import { Devices } from '../devices/device.model';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { DatabaseService } from '../services/database.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DownloadService } from '../services/download.service';
import * as XLSX from 'xlsx';
import { take } from 'rxjs/operators';

export interface DeviceUpload {
  imeiNo: string;
  inventoryCode: string;
  isImeiValid: boolean;
  isInventoryCodeValid: boolean;
}

@Component({
  selector: 'app-add-devices',
  templateUrl: './add-devices.component.html',
  styleUrls: ['./add-devices.component.css']
})
export class AddDevicesComponent implements OnInit, OnDestroy {

  @ViewChild('input') fileInputVariable: any;
  @ViewChild('stepper') stepper;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isOptional = false;
  showTable = false;
  // for updating the table value
  editField: string;
  arrayBuffer: any;
  file: File;
  deviceUploadList: DeviceUpload[] = [];
  existingDeviceSub: Subscription;
  validateDeviceSubs: Subscription;
  existingDeviceList: Observable<any[]>;
  existingIMEIList: string[];
  groupID: string;
  afdb: AngularFireDatabase;
  localFirebaseID: string;
  data = [];
  progressbarValue: number;
  isProgressvarVisible: boolean;
  isValidatedForUpload: boolean;
  headerData: string[] = ['IMEI No.', 'Inventory Code'];
  isValid = false;

  constructor(private _formBuilder: FormBuilder, private downloadService: DownloadService, afdb: AngularFireDatabase,
    private databaseService: DatabaseService, private pathService: PathSetterService, public snackBar: MatSnackBar) {
    this.afdb = afdb;
    this.formatData = this.formatData.bind(this);
    this.isProgressvarVisible = false;
    this.isValidatedForUpload = false;
    this.setGroupID();
    this.getDeviceIMEIList();
  }


  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      // firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ''
    });
  }

  setGroupID() { this.groupID = this.databaseService.getGroupID(); }

  getDeviceIMEIList() {
    this.existingDeviceList = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() + '/device_details/'
      + this.groupID).valueChanges();

    this.existingDeviceSub = this.existingDeviceList
      .pipe(
        take(1),
      )
      .subscribe(
        (data) => {
          const response = data;
          this.existingIMEIList = [];
          const imeiNo = Object.keys(response);
          for (let i = 0; i <= imeiNo.length; i++) {
            const tempItem = String(response[imeiNo[i]]['imeiNo']);
            this.existingIMEIList.push(
              tempItem
            );
          }
        });
  }
  ValidateDeviceIMei(listItem) {
    let returnValue = true;
    //  Verifying length of IMEI no
    if (listItem.toString().length !== 15) {
      returnValue = false;
    }
    // console.log(listItem);
    //  Verifying if IMEI no exists
    for (let x = 0; x < this.existingIMEIList.length; x++) {
      if (String(listItem) === this.existingIMEIList[x]) {
        // console.log(listItem + ' IMEI no already exisits ');
        returnValue = false;
        break;
      }
    }
    return returnValue;
  }
  ValidateDeviceInventoryCode(listItem) {
    // if (! /^[a-zA-Z0-9]+$/.test(listItem)) {
    if (listItem.replace(/\s/g, '') === '') {
      // Validation failed
      return false;
    } else {
      return true;
    }
  }
  isValidForm() { return this.isValid; }

  downloadTemplate() {
    const reportLabel = 'Device Upload Template';
    this.downloadService.getExcelReport(this.data, reportLabel, this.headerData);
  }

  formatData() {

    this.isValid = true;
    this.isProgressvarVisible = false;

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      this.deviceUploadList = [];
      for (let i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      const listData = XLSX.utils.sheet_to_json(worksheet, { raw: true });

      for (let i = 0; i < listData.length; i++) {
        const listItem = listData[i];

        // Checking for empty fields and if IMEI Num exisits
        // if (this.ValidateDeviceIMei(listItem)) {
        this.deviceUploadList.push({
          imeiNo: listItem['IMEI No.'],
          inventoryCode: listItem['Inventory Code'],
          isImeiValid: this.ValidateDeviceIMei(listItem['IMEI No.']),
          isInventoryCodeValid: this.ValidateDeviceInventoryCode(listItem['Inventory Code']),
        });
      }
    };
    fileReader.readAsArrayBuffer(this.file);
  }

  changeListener(event) {
    this.clearData();
    this.file = event.target.files[0];
  }

  clearData() {
    this.isValid = false;
    this.deviceUploadList = [];
  }

  removeDeviceItem(id: any) {
    // this.awaitingPersonList.push(this.personList[id]);
    this.deviceUploadList.splice(id, 1);
    this.snackBar.open('Device removed', 'dismiss', {
      duration: 3000
    });
  }

  changeDeviceItemValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent;
    this.deviceUploadList[id][property] = this.editField;

    if (property === 'imeiNo') {
      this.deviceUploadList[id]['isImeiValid'] = this.ValidateDeviceIMei(this.editField);
    }


    if (property === 'inventoryCode') {
      if (this.ValidateDeviceInventoryCode(this.editField)) {
        this.deviceUploadList[id]['isInventoryCodeValid'] = true;
      } else {
        this.deviceUploadList[id]['isInventoryCodeValid'] = false;
      }
    }

  }


  uploadTemplate() {
    this.isValid = false;
    this.isProgressvarVisible = true;
    let FBSuccessfulUploadCount = 0;
    let validUploadCount = 0;
    let invalidUploadCount = 0;
    this.progressbarValue = 0;

    for (let x = 0; x < this.deviceUploadList.length; x++) {

      if (this.deviceUploadList[x]['isImeiValid'] === true && this.deviceUploadList[x]['isInventoryCodeValid'] === true) {
        validUploadCount++;
      } else {
        invalidUploadCount++;
      }

    }

    for (let i = 0; i < this.deviceUploadList.length; i++) {

      let itemsRef = null;
      // setting the path and getting the ref from Firebase if businesskey isn't null
      if (this.groupID !== null) {
        // Checkin if all field of that row is validated
        if (this.deviceUploadList[i]['isImeiValid'] === true && this.deviceUploadList[i]['isInventoryCodeValid'] === true) {
          // Firebase URL
          itemsRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() + '/device_details/' + this.groupID);
          // populating the node with data
          const DeviceUploadKey = String(this.deviceUploadList[i]['imeiNo']);
          Promise.resolve(
            itemsRef.set(DeviceUploadKey, {
              imeiNo: this.deviceUploadList[i]['imeiNo'],
              inventoryCode: this.deviceUploadList[i]['inventoryCode'],
            })).then(() => {
              this.deviceUploadList.splice(i, 1);
              FBSuccessfulUploadCount++;
              // console.log('Firebase Upload Success for id :' + FBSuccessfulUploadCount);
              this.progressbarValue = Math.round((FBSuccessfulUploadCount / this.deviceUploadList.length) * 100);
              if (validUploadCount !== 0) {
                if (FBSuccessfulUploadCount === (validUploadCount)) {
                  setTimeout(() => {
                    alert('Uploaded ' + FBSuccessfulUploadCount + ' Device(s). ' + invalidUploadCount + ' device(s) did not get uploaded');
                    // console.log('finished upload');
                    validUploadCount = 0;
                    invalidUploadCount = 0;
                    this.progressbarValue = 0;
                    this.existingIMEIList = [];
                    this.getDeviceIMEIList();
                    this.isProgressvarVisible = false;
                    this.isValid = true;
                    this.stepper.selectedIndex = 1;

                  }, 2000);
                }
              }

            }).catch(err => {
              // console.log(err);
            });

        }

        if (validUploadCount === 0) {
          setTimeout(() => {
            alert('Uploaded ' + FBSuccessfulUploadCount + ' Device(s). ' + invalidUploadCount + ' device(s) did not get uploaded');
            // console.log('finished upload');
            validUploadCount = 0;
            invalidUploadCount = 0;
            this.progressbarValue = 0;
            this.isProgressvarVisible = false;
            this.isValid = true;
            this.existingIMEIList = [];
            this.getDeviceIMEIList();
            this.stepper.selectedIndex = 1;

          }, 2000);
          break;
        }
      }
    }
  }

  ngOnDestroy() {
    this.deviceUploadList = [];
    this.existingIMEIList = [];
    this.existingDeviceSub.unsubscribe();
  }
}
