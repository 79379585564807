import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NgbInputDatepicker, NgbDateStruct, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgModel } from '@angular/forms';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { Vehicle } from '../vehicles/vehicle.model';
import { Subscription } from 'rxjs/Subscription';
import { DatabaseService } from '../services/database.service';
import { DownloadService } from '../services/download.service';
import { PathSetterService } from '../services/pathSetter.service';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap, take, map } from 'rxjs/operators';

export interface Report {
  a1BusinessId: string;
  a2VehicleType: string;
  a3NumberPlate: string;
  a4NumberPlateDigits: string;
  a5TicketId: string;
  a6EnteredBy: string;
  a7ParkingPrepaidFare;
  a8EntryTime: string;
  a9EntryDate: string;
  a10ExitedBy: string;
  a11ParkingBalanceFare;
  a12ExitTime: string;
  a13ExitDate: string;
  a14ModifiedBy: string;
  a15ModifiedOn: string;
  a16Status: string;
  a17ParkingDuration: any;
  a18RecordType: string;
  a19ParkingTotalFare;
  a20DeleteCollection;
  a21LostTicketCharge;
  a22TagName: string;
  a23CompanyTagname: string;
  a24ParkingSlotNo: string;
  a25ParkingUnitNo: string;
  a26TicketReprintCount: number;
  a27TicketRefundAmount: number;
  a28imageUrlIn: string;
  a29imageUrlOut: string;
  a30imageUrlCustomExit: string;
  a31BookingFare: number;
  a32customExitType: string;
}

const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'app-overnight',
  templateUrl: './overnight.component.html',
  styleUrls: ['./overnight.component.css']
})
export class OvernightComponent implements OnInit {

  @ViewChild('d') input: NgbInputDatepicker;
  @ViewChild(NgModel) datePick: NgModel;
  @ViewChild('myRangeInput') myRangeInput: ElementRef;

  title: 'Overnight Vehicle Report';

  db: AngularFirestore;
  afdb: AngularFireDatabase;

  public searchText: string;

  data: Vehicle[];
  headerData: string[] = [
    'Location', 'Vehicle Type', 'Numberplate', 'Numberplate Digits', 'Ticket ID', 'Entered By', 'Entry Collection',
    'Entry Time', 'Entry Date', 'Exited By', 'Exit Collection', 'Exit Time', 'Exit Date', 'Edited By', 'Edit Time', 'Status',
    'Parking Duration', 'Record Type', 'Total Collection', 'Delete Collection', 'Lost Ticket Charge', 'Tag Name', 'Company Tag Name',
    'Parking Slot No', 'Parking Unit No', 'Ticket Reprint Count', 'Ticket Refund Amount', 'Image URL In', 'Image URL Out',
    'Custom Exit URL', 'Booking Fare', 'Exit Tag'
  ];
  vehicleReportSubs: Subscription;


  vehicleInReportSubs: Subscription;
  vehicleOutReportSubs: Subscription;
  groupID: string;
  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: string;
  selectedCompanyName: string;
  progressBarVisible = false;
  isArya: boolean;

  exitTime: any;
  exitDate: any;

  rearrangedReportArray: Report[] = [];

  hoveredDate: NgbDateStruct;
  fromDate: any;
  toDate: any;
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  startDate: NgbDateStruct;
  model: any;
  dateFilterFrom: BehaviorSubject<number | null>;
  dateFilterTo: BehaviorSubject<number | null>;
  companyFilter: BehaviorSubject<string | null>;
  p = 1;
  status: string;
  private vehicleCollection: AngularFirestoreCollection<Vehicle>;
  vehicles: Observable<Vehicle[]>;
  vehi: Observable<any[]>;
  // compList: Observable<any[]>;
  // tagList: CompanyName[] = [];
  // tagData: any[] = [];

  constructor(db: AngularFirestore, private databaseService: DatabaseService, private downloadService: DownloadService,
    calendar: NgbCalendar, afdb: AngularFireDatabase,
    element: ElementRef, private renderer: Renderer2, private _parserFormatter: NgbDateParserFormatter) {
    this.afdb = afdb;
    this.db = db;
    this.status = 'IN';
    this.dateFilterFrom = new BehaviorSubject(new Date().getTime());
    this.dateFilterTo = new BehaviorSubject(new Date().getTime());
    this.companyFilter = new BehaviorSubject('');
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
    this.startDate = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
    this.maxDate = { year: now.getFullYear() + 1, month: now.getMonth() + 1, day: now.getDate() };
    this.minDate = { year: now.getFullYear() - 1, month: now.getMonth() + 1, day: now.getDate() };
    this.setGroupID();
    this.setBusinessDropDownList();

  }

  ngOnInit() {
    this.loadList();
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setBusinessID(biz) {
    this.progressBarVisible = true;
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.vehicleReportSubs.unsubscribe();
    this.loadList();
  }

  stopLoader() {
    this.progressBarVisible = false;
  }

  loadList() {
    this.vehicles = combineLatest([
      this.dateFilterFrom,
      this.dateFilterTo,
     ]
    ).pipe(
      switchMap(([filterFromDate, filterToDate]) =>
        this.db.collectionGroup<Vehicle>('records', ref => {
          let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
          if (filterFromDate) {
            // console.log('check dates:', filterFromDate, filterToDate);
            query = query.where('vrParkingStartTimeL', '>', filterFromDate)
            .where('vrParkingStatus' , '==' , this.status).orderBy('vrParkingStartTimeL', 'desc');
          }
          if (filterToDate) { query = query.where('vrParkingStartTimeL', '<=', filterToDate); }
          query = query.where('vrBusinessId', '==', this.businessID);
          return query;
        }).valueChanges()
      )
    );

    this.vehicleReportSubs = this.vehicles
      .pipe(
        take(1),
        map(
          (response) => {
            // console.log('overnight:' , response);
            const vehicleList: Vehicle[] = response;
            for (const vehicleItem of vehicleList) {
              if (!vehicleItem.vrModifiedBy) { vehicleItem.vrModifiedBy = 'N/A'; }
              if (!vehicleItem.vrCompanyTagname || vehicleItem.vrCompanyTagname === 'regular_park') {
                vehicleItem.vrCompanyTagname = 'N/A';
              }
              // if (!vehicleItem.vrExitedByName) { vehicleItem.vrExitedByName = 'N/A'; }
              if (!vehicleItem.vrParkingSlotNo) { vehicleItem.vrParkingSlotNo = 'N/A'; }
              if (!vehicleItem.customExitType) { vehicleItem.customExitType = 'N/A'; }
              if (!vehicleItem.vrParkingUnitNo) { vehicleItem.vrParkingUnitNo = 'N/A'; }
              if (!vehicleItem.vrTagName) { vehicleItem.vrTagName = 'N/A'; }
              for (let k = 0; k < this.businessList.length; k++) {
                if (vehicleItem.vrBusinessId === this.businessList[k].id) {
                  vehicleItem.vrBusinessId = this.businessList[k].name;
                }
              }
            }
            return vehicleList;
          }))
      .subscribe(
        (response) => {
          this.data = response;
          this.rearrangedReportArray = [];
          for (let i = 0; i < this.data.length; i++) {
            const reportItem = this.data[i];

            if (reportItem.vrParkingStatus === 'IN') {
              reportItem.vrParkingEndTimeL = 'NA';
            }

            const parkingDuration = reportItem.vrParkingDuration;
            // tslint:disable-next-line:no-bitwise
            const min = Math.floor((parkingDuration / 1000 / 60) << 0);
            const sec = Math.floor((parkingDuration / 1000) % 60);
            const formattedParkingDuration = min + ' mins ' + sec + 'seconds';

            this.rearrangedReportArray.push({
              a1BusinessId: reportItem.vrBusinessId,
              a2VehicleType: reportItem.vrVehicleType,
              a3NumberPlate: reportItem.vrNumberPlate,
              a4NumberPlateDigits: reportItem.vrNumberPlateDigits,
              a5TicketId: reportItem.vrTicketId,
              a6EnteredBy: reportItem.vrEnteredByName,
              a7ParkingPrepaidFare: reportItem.vrParkingPrepaidFare,
              a8EntryTime: new Date(reportItem.vrParkingStartTimeL).toLocaleTimeString(),
              a9EntryDate: new Date(reportItem.vrParkingStartTimeL).toLocaleDateString(),
              a10ExitedBy: reportItem.vrExitedByName,
              a11ParkingBalanceFare: reportItem.vrParkingBalanceFare,
              a12ExitTime: new Date(reportItem.vrParkingEndTimeL).toLocaleTimeString(),
              a13ExitDate: new Date(reportItem.vrParkingEndTimeL).toLocaleDateString(),
              a14ModifiedBy: reportItem.vrModifiedBy,
              a15ModifiedOn: new Date(reportItem.parkingModificationTimeStamp).toLocaleString(),
              a16Status: reportItem.vrParkingStatus,
              a17ParkingDuration: formattedParkingDuration,
              a18RecordType: reportItem.vrRecordType,
              a19ParkingTotalFare: reportItem.vrParkingTotalFare,
              a20DeleteCollection: reportItem.vrDeleteCollection,
              a21LostTicketCharge: reportItem.vrLostTicketCharge,
              a22TagName: reportItem.vrTagName,
              a23CompanyTagname: reportItem.vrCompanyTagname,
              a24ParkingSlotNo: reportItem.vrParkingSlotNo,
              a25ParkingUnitNo: reportItem.vrParkingUnitNo,
              a26TicketReprintCount: reportItem.vrTicketReprintCount,
              a27TicketRefundAmount: reportItem.vrRefundAmount,
              a28imageUrlIn: reportItem.imageUrlIn,
              a29imageUrlOut: reportItem.imageUrlOut,
              a30imageUrlCustomExit: reportItem.imageUrlCustomExit,
              a31BookingFare: reportItem.vrBookingFare,
              a32customExitType: reportItem.customExitType,
            });
            this.progressBarVisible = false;
            //  console.log('Overnight Report item: ', this.data);
          }
        },
        (error) => { console.log(error); }
      );
  }

  downloadExcelReport() {
    const reportLabel = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilterFrom.value).toLocaleDateString()
      + ' to ' + new Date(this.dateFilterTo.value).toLocaleDateString() + ' Report';
    this.downloadService.getExcelReport(this.rearrangedReportArray, reportLabel, this.headerData);
  }

  downloadPDFReport() {
    const reportLabel = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilterFrom.value).toLocaleDateString()
      + ' to ' + new Date(this.dateFilterTo.value).toLocaleDateString() + ' Report';
    const options = {
      styles: {
        fontSize: 1.5,
        font: 'helvetica', // helvetica, times, courier
      },
      startY: true, // false (indicates margin top value) or a number
      margin: { left: 1 },
      theme: 'striped',
      fillColor: false, // false for transparent or a color as described below
      textColor: 20,
      columnWidth: 'auto', // 'auto', 'wrap' or a number
      tableWidth: 'wrap', // 'auto', 'wrap' or a number,
    };
    this.downloadService.getPDFReport(this.rearrangedReportArray, reportLabel, this.headerData, options);
  }

  onDateSelection(date: NgbDateStruct) {
    let parsed = '';
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
      // this.model = `${this.fromDate.year} - ${this.toDate.year}`;
      this.input.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.fromDate != null) {
      parsed += this._parserFormatter.format(this.fromDate);
      const newDateFrom = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      newDateFrom.setHours(0, 0, 0);
      this.dateFilterFrom.next(newDateFrom.getTime());
    }
    if (this.toDate != null) {
      parsed += ' / ' + this._parserFormatter.format(this.toDate);
      // tslint:disable-next-line:no-shadowed-variable
      const newDateTo = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
      newDateTo.setHours(23, 59, 59);
      this.dateFilterTo.next(newDateTo.getTime());
    } else {
      const newDateTo = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      newDateTo.setHours(23, 59, 59);
      this.dateFilterTo.next(newDateTo.getTime());
    }
    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);
    // const newDateTo = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    //      newDateTo.setHours(23, 59, 59);
    //     this.dateFilterTo.next(newDateTo.getTime());
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    this.vehicleReportSubs.unsubscribe();
  }

  isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);

}
