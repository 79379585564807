import { Injectable } from '@angular/core';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
// declare var jsPDF: any; // Important
// import * as jsPDF from 'jspdf';
// import 'jspdf-autotable';
declare let jsPDF;

@Injectable()
export class DownloadService {

  constructor() { }

  getExcelReport(data: any, reportLabel: string, headerData: string[]) {

    // console.log('Downloading Report');

    const options = {
      // fieldSeparator: ';',
      // quoteStrings: '"',
      // decimalseparator: ',',
      // showLabels: true,
      // showTitle: false,
      // useBom: true,
      headers: headerData
    };
    // tslint:disable-next-line:no-unused-expression
    new Angular2Csv(data, reportLabel, options);

  }

  getExcelReport1(data: any, reportLabel: string, headerData1: string[]) {

    // console.log('Downloading Report');

    const options = {
      // fieldSeparator: ';',
      // quoteStrings: '"',
      // decimalseparator: ',',
      // showLabels: true,
      // showTitle: false,
      // useBom: true,
      headers: headerData1
    };
    // tslint:disable-next-line:no-unused-expression
    new Angular2Csv(data, reportLabel, options);

  }

  getPDFReport(data: any, reportLabel: string, headerData: string[], options: any) {

    const doc = new jsPDF();
    const rows = [];

    // tslint:disable-next-line:forin
    for (const x in data) {
      rows.push(Object.values(data[x]));
    }
    doc.autoTable(headerData, rows, options);
    doc.save(reportLabel);
  }
}
