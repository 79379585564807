import {Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from 'angularfire2/firestore';
import { Vehicle } from '../vehicles/vehicle.model';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import {NgbDateStruct, NgbCalendar, NgbModal, NgbInputDatepicker, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { DatabaseService } from '../services/database.service';
import { DownloadService } from '../services/download.service';
import { PathSetterService } from '../services/pathSetter.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { switchMap } from 'rxjs/operators';
import { NgModel } from '@angular/forms';
import { Loop } from '../vehicle-report/loop.model';
import { Boom } from '../vehicle-report/boombarrier.model';

export interface Count {
  vehicleType: string;
}

const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
    one &&
  two &&
  two.year === one.year &&
  two.month === one.month &&
  two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day < two.day
      : one.month < two.month
    : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day > two.day
      : one.month > two.month
    : one.year > two.year;

@Component({
  selector: 'app-vehicle-report',
  templateUrl: './vehicle-report.component.html',
  styleUrls: ['./vehicle-report.component.css']
})
export class VehicleReportComponent implements OnInit {
  @ViewChild('d') datePicker: NgbInputDatepicker;
  @ViewChild('d') input: NgbInputDatepicker;
  @ViewChild(NgModel) datePick: NgModel;
  @ViewChild('myRangeInput') myRangeInput: ElementRef;

  title = 'Vehicle Summary';
  db: AngularFirestore;
  adb: AngularFireDatabase;
  data: Vehicle[];
  vehicleReportSubs: Subscription;
  vehicleReportSubs1: Subscription;
  overnightReportSubs: Subscription;
  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: string;
  private vehicleCollection: AngularFirestoreCollection<Vehicle>;
  vehicles: Observable<Vehicle[]>;
  vehicles1: Observable<Vehicle[]>;
  overnight: Observable<Vehicle[]>;
  groupID: string;
  // model: NgbDateStruct;
  dateFilter: BehaviorSubject<string | null>;
  StrMMYYYY: String;
  StrDate: string;
  StrYYYY: string;
  StrMM: string;
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  startDate: NgbDateStruct;
  model: any;
  exitTime: any;
  exitDate: any;
  status: string;

  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  dateFilterFrom: BehaviorSubject<number | null>;
  dateFilterTo: BehaviorSubject<number | null>;

  loopReportSubs: Subscription;
  loopReportSubs1: Subscription;
  loopscounts: Observable<Loop[]>;
  loopsCo: Observable<Loop[]>;
  boomCount: Observable<Boom[]>;
  boomCount1: Observable<Boom[]>;
  boomReportEntSubs: Subscription;
  boomReportExiSubs: Subscription;

  in2Counter = 0;
  in4Counter = 0;
  out2Counter = 0;
  out4Counter = 0;
  in2Entry = 0;
  in2Exit = 0;
  in4Entry = 0;
  in4Exit = 0;
  twoWheelerTicket = 0;
  threeWheelerTicket = 0;
  fourWheelerTicket = 0;
  twoWheelerPass = 0;
  threeWheelerPass = 0;
  fourWheelerPass = 0;
  twoWheelerTerminal = 0;
  twoWheelerPhone = 0;
  threeWheelerTerminal = 0;
  threeWheelerPhone = 0;
  fourWheelerTerminal = 0;
  fourWheelerPhone = 0;
  twoWheelerexTerminal = 0;
  fourWheelerexTerminal = 0;
  threeWheelerexTerminal = 0;
  threeWheelerexPhone = 0;
  twoWheelerexPhone = 0;
  fourWheelerexPhone = 0;

  // For MCGM PPL - Recepit
  twoWheelerReceipt = 0;
  threeWheelerReceipt = 0;
  fourWheelerReceipt = 0;

  cycTicket = 0;
  cycPass = 0;
  cycPhone = 0;
  cycTerminal = 0;
  cycexTicket = 0;
  cycexPass = 0;
  cycexPhone = 0;
  cycexTerminal = 0;

  cvTicket = 0;
  cvPass = 0;
  cvPhone = 0;
  cvTerminal = 0;
  cvexTicket = 0;
  cvexPass = 0;
  cvexPhone = 0;
  cvexTerminal = 0;

  cartTicket = 0;
  cartPass = 0;
  cartPhone = 0;
  cartTerminal = 0;
  cartexTicket = 0;
  cartexPass = 0;
  cartexPhone = 0;
  cartexTerminal = 0;
  total = 0;
  total1 = 0;
  overnightin = 0;

  boomtwoentry = 0;
  boomtwoexit = 0;
  boomfourentry = 0;
  boomfourexit = 0;
  boommanual = 0;
  boomEntrytotal = 0;
  boomExittotal = 0;

  constructor(
    db: AngularFirestore,
    private databaseService: DatabaseService,
    private downloadService: DownloadService,
    private pathService: PathSetterService,
    calendar: NgbCalendar,
    private modalService: NgbModal,
    adb: AngularFireDatabase,
    element: ElementRef,
    private renderer: Renderer2,
    private _parserFormatter: NgbDateParserFormatter
  ) {
    this.db = db;
    this.adb = adb;
    this.status = 'IN'
    this.dateFilterFrom = new BehaviorSubject(new Date().getTime());
    this.dateFilterTo = new BehaviorSubject(new Date().getTime());
    // this.companyFilter = new BehaviorSubject('');
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
    this.maxDate = {
      year: now.getFullYear() + 1,
      month: now.getMonth() + 1,
      day: now.getDate()
    };
    this.minDate = {
      year: now.getFullYear() - 1,
      month: now.getMonth() + 1,
      day: now.getDate()
    };
    this.setGroupID();
    this.setBusinessDropDownList();
  }

  ngOnInit() {
    this.generateInReport();
    this.generateOutReport();
    this.overNight();
    // this.load2();
    this.load4();
    this.boomEntry();
    this.boomExit();
  }

  resetCountersBoomEntry() {
    this.boomtwoentry = 0;
    this.boomfourentry = 0;
    this.boommanual = 0;
    this.boomEntrytotal = 0;
  }

  resetCountersBoomExit() {
    this.boomtwoexit = 0;
    this.boomfourexit = 0;
    // this.boommanual = 0;
    this.boomExittotal = 0;
  }

  resetCounters() {
    this.twoWheelerPass = 0;
    this.threeWheelerPass = 0;
    this.fourWheelerPass = 0;
    this.twoWheelerTicket = 0;
    this.threeWheelerTicket = 0;
    this.fourWheelerTicket = 0;
    this.fourWheelerTerminal = 0;
    this.fourWheelerPhone = 0;
    this.threeWheelerTerminal = 0;
    this.threeWheelerPhone = 0;
    this.twoWheelerTerminal = 0;
    this.twoWheelerPhone = 0;
    this.cycPass = 0;
    this.cycTicket = 0;
    this.cycPhone = 0;
    this.cycTerminal = 0;
    this.cvTicket = 0;
    this.cvPass = 0;
    this.cvPhone = 0;
    this.cvTerminal = 0;
    this.cartPass = 0;
    this.cartPhone = 0;
    this.cartTicket = 0;
    this.cartTerminal = 0;
    this.twoWheelerReceipt = 0;
    this.threeWheelerReceipt = 0;
    this.fourWheelerReceipt = 0;
    this.total = 0;
  }

  resetCountersOut() {
    this.fourWheelerexPhone = 0;
    this.fourWheelerexTerminal = 0;
    this.threeWheelerexPhone = 0;
    this.threeWheelerexTerminal = 0;
    this.twoWheelerexPhone = 0;
    this.twoWheelerexTerminal = 0;
    this.cycexPass = 0;
    this.cycexTicket = 0;
    this.cycexPhone = 0;
    this.cycexTerminal = 0;
    this.cartexTicket = 0;
    this.cartexPass = 0;
    this.cartexPhone = 0;
    this.cartexTerminal = 0;
    this.total1 = 0;
  }

  resetCountersLoop4w() {
    this.in2Entry = 0;
    this.in2Exit = 0;
    this.in4Entry = 0;
    this.in4Exit = 0;
    this.in2Counter = 0;
    this.in4Counter = 0;
    this.out2Counter = 0;
    this.out4Counter = 0;
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }
  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setBusinessID(biz) {
    this.resetCounters();
    this.resetCountersOut();
    this.resetCountersLoop4w();
    this.resetCountersBoomEntry();
    this.resetCountersBoomExit();
    this.vehicleReportSubs.unsubscribe();
    this.vehicleReportSubs1.unsubscribe();
    // this.loopReportSubs.unsubscribe();
    this.loopReportSubs1.unsubscribe();
    this.boomReportEntSubs.unsubscribe();
    this.boomReportExiSubs.unsubscribe();
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.generateInReport();
    this.generateOutReport();
    this.overNight();
    // this.load2();
    this.load4();
    this.boomEntry();
    this.boomExit();
  }

  getEpochDayFromTimestamp(epochTimestamp: number) {
    const today = new Date(epochTimestamp);
    today.setHours(15);
    const todayEpochDay = Math.floor(
      (today.getTime() + 330 * 60 * 1000) / (1000 * 60 * 60 * 24)
    );
    return todayEpochDay;
  }

  overNight() {
    this.resetCounters();
    this.overnight = combineLatest([
      this.dateFilterFrom,
      this.dateFilterTo,
     ]
    ).pipe(
      switchMap(([filterFromDate, filterToDate]) =>
        this.db.collectionGroup<Vehicle>('records', ref => {
          let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
          if (filterFromDate) {
            // console.log('check dates:', filterFromDate, filterToDate);
            query = query.where('vrParkingStartTimeL', '>', filterFromDate)
            .where('vrParkingStatus' , '==' , this.status).orderBy('vrParkingStartTimeL', 'desc');
          }
          if (filterToDate) { query = query.where('vrParkingStartTimeL', '<', filterToDate); }
          query = query.where('vrBusinessId', '==', this.businessID);
          return query;
        }).valueChanges()
      )
    );
    this.overnightReportSubs = this.overnight.subscribe(response => {
      this.resetCounters();
      this.overnightin = response.length;
      console.log('Overnight', this.overnightin);
    });
  }
  boomEntry() {
    this.resetCountersBoomEntry();
    this.boomCount = combineLatest([
      this.dateFilterFrom,
      this.dateFilterTo
    ]).pipe(
      switchMap(([filterFromDate, filterToDate]) =>
        this.db
          .collectionGroup<Boom>('BOOMENTRY', ref => {
            let query:
              | firebase.firestore.CollectionReference
              | firebase.firestore.Query = ref;
            // console.log('Cgefosg:' , filterFromDate , filterToDate);
            if (filterFromDate) {
              // console.log('check dates:', filterFromDate, filterToDate);
              query = query
                .where('timeStampMillis', '>=', filterFromDate)
                .orderBy('timeStampMillis', 'desc');
            }
            if (filterToDate) {
              query = query.where('timeStampMillis', '<=', filterToDate);
            }
              query = query.where('businessId', '==', this.businessID);
            // console.log(query);
            return query;
          })
          .valueChanges()
      )
    );
    this.boomReportEntSubs = this.boomCount.subscribe(response => {
      this.boomEntrytotal = response.length;
      // console.log('boombarrier:' , this.boomEntrytotal);
      this.resetCountersBoomEntry();
      for (const boom of response) {
        if (boom.vehType === 'MANUAL' && boom.event === 'OPEN') {
          // console.log('BoomBarrierManual:' , this.boommanual);
          this.boommanual++;
        }
        if (boom.vehType === '2W') {
          //  console.log('BoomBarrier2W:' , this.boomtwoentry);
          this.boomtwoentry++;
        }
        if (boom.vehType === '4W') {
          //  console.log('BoomBarrier4W:' , this.boomfourentry);
          this.boomfourentry++;
        }
      }
    });
  }

  boomExit() {
    this.resetCountersBoomExit();
    this.boomCount1 = combineLatest([
      this.dateFilterFrom,
      this.dateFilterTo
    ]).pipe(
      switchMap(([filterFromDate, filterToDate]) =>
        this.db
          .collectionGroup<Boom>('BOOMEXIT', ref => {
            let query:
              | firebase.firestore.CollectionReference
              | firebase.firestore.Query = ref;
            // console.log('tiem:' , filterFromDate , filterToDate);
            if (filterFromDate) {
              // console.log('check dates:', filterFromDate, filterToDate);
              query = query
                .where('timeStampMillis', '>=', filterFromDate)
                .orderBy('timeStampMillis', 'desc');
            }
            if (filterToDate) {
              query = query.where('timeStampMillis', '<=', filterToDate);
            }
             query = query.where('businessId', '==', this.businessID);
            return query;
          })
          .valueChanges()
      )
    );
    this.boomReportExiSubs = this.boomCount1.subscribe(response => {
      this.boomExittotal = response.length;
      // console.log('boombarrierexit:' , this.boomExittotal);
      this.resetCountersBoomExit();
      for (const boomexit of response) {
        if (boomexit.vehType === 'MANUAL' && boomexit.event === 'OPEN') {
          // console.log('BoomBarrierManual:' , this.boommanual);
          this.boommanual++;
        }
        if (boomexit.vehType === '2W') {
          // console.log('BoomBarrier2Wexit:' , this.boomtwoexit);
          this.boomtwoexit++;
        }
        if (boomexit.vehType === '4W') {
          //  console.log('BoomBarrier4W:' , this.boomfourentry);
          this.boomfourexit++;
        }
      }
    });
  }

  generateInReport() {
    this.resetCounters();
    this.vehicles = combineLatest([
      this.dateFilterFrom,
      this.dateFilterTo
    ]).pipe(
      switchMap(([filterFromDate, filterToDate]) =>
        this.db
          .collectionGroup<Vehicle>('records', ref => {
            let query:
              | firebase.firestore.CollectionReference
              | firebase.firestore.Query = ref;
            if (filterFromDate) {
              query = query
                .where('vrParkingStartTimeL', '>=', filterFromDate)
                .orderBy('vrParkingStartTimeL', 'desc');
            }
            if (filterToDate) {
              query = query.where('vrParkingStartTimeL', '<=', filterToDate);
            }
            query = query.where('vrBusinessId', '==', this.businessID);
            return query;
          })
          .valueChanges()
      )
    );
    this.vehicleReportSubs = this.vehicles.subscribe(response => {
      this.total = response.length;
      this.resetCounters();
      for (const vehicle of response) {
        if (
          vehicle.vrVehicleType === '2W' &&
          vehicle.vrRecordType === 'Ticket'
        ) {
          this.twoWheelerTicket++;
        }
        if (
          vehicle.vrVehicleType === '3W' &&
          vehicle.vrRecordType === 'Ticket'
        ) {
          this.threeWheelerTicket++;
        }
        if (
          vehicle.vrVehicleType === '4W' &&
          vehicle.vrRecordType === 'Ticket'
        ) {
          this.fourWheelerTicket++;
        }
        if (
          vehicle.vrVehicleType === 'CYC' &&
          vehicle.vrRecordType === 'Ticket'
        ) {
          this.cycTicket++;
        }
        if (
          vehicle.vrVehicleType === 'CV' &&
          vehicle.vrRecordType === 'Ticket'
        ) {
          this.cvTicket++;
        }
        if (
          vehicle.vrVehicleType === 'CART' &&
          vehicle.vrRecordType === 'Ticket'
        ) {
          this.cartTicket++;
        }

        if (vehicle.vrVehicleType === '2W' && vehicle.vrRecordType === 'Pass') {
          this.twoWheelerPass++;
        }
        if (vehicle.vrVehicleType === '3W' && vehicle.vrRecordType === 'Pass') {
          this.threeWheelerPass++;
        }
        if (vehicle.vrVehicleType === '4W' && vehicle.vrRecordType === 'Pass') {
          this.fourWheelerPass++;
        }
        if (
          vehicle.vrVehicleType === 'CYC' &&
          vehicle.vrRecordType === 'Pass'
        ) {
          this.cycPass++;
        }
        if (vehicle.vrVehicleType === 'CV' && vehicle.vrRecordType === 'Pass') {
          this.cvPass++;
        }
        if (
          vehicle.vrVehicleType === 'CART' &&
          vehicle.vrRecordType === 'Pass'
        ) {
          this.cartPass++;
        }

        // if (vehicle.vrVehicleType === '2W' && vehicle.vrRecordType === 'Ticket' &&
        //  vehicle.vrParkingStatus === 'OUT') {
        //    console.log('recepitdrdf:' , this.twoWheelerReceipt);
        //    this.twoWheelerReceipt++;
        //  }
        //  if (vehicle.vrVehicleType === '3W' && vehicle.vrRecordType === 'Ticket' &&
        //  vehicle.vrParkingStatus === 'OUT') {
        //    console.log('rtertert:' , this.threeWheelerReceipt);
        //    this.threeWheelerReceipt++;
        //  }
        //  if (vehicle.vrVehicleType === '4W' && vehicle.vrRecordType === 'Ticket' &&
        //  vehicle.vrParkingStatus === 'OUT') {
        //    console.log('itdrdf:' , this.fourWheelerReceipt);
        //    this.fourWheelerReceipt++;
        //  }

        if (
          vehicle.vrVehicleType === '2W' &&
          vehicle.vrEnteredByName !== '' &&
          (vehicle.vrEnteredByName === 'Ticketing Terminal' || vehicle.vrEnteredByName === '2W T1')
        ) {
          this.twoWheelerTerminal++;
        }
        if (
          vehicle.vrVehicleType === '3W' &&
          vehicle.vrEnteredByName !== '' &&
          vehicle.vrEnteredByName === 'Ticketing Terminal'
        ) {
          this.threeWheelerTerminal++;
        }

        if (
          vehicle.vrVehicleType === '4W' &&
          vehicle.vrEnteredByName !== '' &&
          ( vehicle.vrEnteredByName === 'Ticketing Terminal' || vehicle.vrEnteredByName === '4W T1' || vehicle.vrEnteredByName === '4W T2')
        ) {
          this.fourWheelerTerminal++;
        }
        if (
          vehicle.vrVehicleType === 'CV' &&
          vehicle.vrEnteredByName !== '' &&
          vehicle.vrEnteredByName === 'Ticketing Terminal'
        ) {
          this.cvTerminal++;
        }
        if (
          vehicle.vrVehicleType === 'CYC' &&
          vehicle.vrEnteredByName !== '' &&
          vehicle.vrEnteredByName === 'Ticketing Terminal'
        ) {
          this.cycTerminal++;
        }
        if (
          vehicle.vrVehicleType === 'CART' &&
          vehicle.vrEnteredByName !== '' &&
          vehicle.vrEnteredByName === 'Ticketing Terminal'
        ) {
          this.cartTerminal++;
        }

        if (
          vehicle.vrVehicleType === '2W' &&
          vehicle.vrEnteredByName !== '' &&
          ( vehicle.vrEnteredByName !== 'Ticketing Terminal' && vehicle.vrEnteredByName !== '2W T1')
        ) {
          this.twoWheelerPhone++;
        }
        if (
          vehicle.vrVehicleType === '3W' &&
          vehicle.vrEnteredByName !== '' &&
          vehicle.vrEnteredByName !== 'Ticketing Terminal'
        ) {
          this.threeWheelerPhone++;
        }
        if (
          vehicle.vrVehicleType === '4W' &&
          vehicle.vrEnteredByName !== '' &&
          ( vehicle.vrEnteredByName !== 'Ticketing Terminal' && vehicle.vrEnteredByName !== '4W T1' && vehicle.vrEnteredByName !== '4W T2')
        ) {
          this.fourWheelerPhone++;
        }
        if (
          vehicle.vrVehicleType === 'CV' &&
          vehicle.vrEnteredByName !== '' &&
          vehicle.vrEnteredByName !== 'Ticketing Terminal'
        ) {
          this.cvPhone++;
        }
        if (
          vehicle.vrVehicleType === 'CYC' &&
          vehicle.vrEnteredByName !== '' &&
          vehicle.vrEnteredByName !== 'Ticketing Terminal'
        ) {
          this.cycPhone++;
        }
        if (
          vehicle.vrVehicleType === 'CART' &&
          vehicle.vrEnteredByName !== '' &&
          vehicle.vrEnteredByName !== 'Ticketing Terminal'
        ) {
          this.cartPhone++;
        }
      }
    });
  }

  generateOutReport() {
    this.resetCountersOut();
    const today = new Date();
    today.setHours(15);
    const todayEpochDay = Math.floor(
      (today.getTime() + 330 * 60 * 1000) / (1000 * 60 * 60 * 24)
    );
    this.vehicles1 = combineLatest([
      this.dateFilterFrom,
      this.dateFilterTo
    ]).pipe(
      switchMap(([filterFromDate, filterToDate]) =>
        this.db
          .collectionGroup<Vehicle>('records', ref => {
            const fromEpochDay = this.getEpochDayFromTimestamp(filterFromDate);
            const toEpochDay = this.getEpochDayFromTimestamp(filterToDate);
            let query:
              | firebase.firestore.CollectionReference
              | firebase.firestore.Query = ref;
            if (fromEpochDay) {
              query = query
                .where('vrEndEpochDay', '>=', fromEpochDay)
                .where('vrEndEpochDay', '<=', toEpochDay)
                .orderBy('vrEndEpochDay', 'desc');
            }
            if (toEpochDay) {
              query = query.where('vrEndEpochDay', '<=', toEpochDay);
            }
            query = query.where('vrBusinessId', '==', this.businessID);
            return query;
          })
          .valueChanges()
      )
    );
    this.vehicleReportSubs1 = this.vehicles1.subscribe(response => {
      this.total1 = response.length;
      this.resetCountersOut();
      for (const vehicleout of response) {
        if (
          vehicleout.vrVehicleType === '2W' &&
          vehicleout.vrExitedByName !== '' &&
          vehicleout.vrExitedByName === 'Ticketing Terminal'
        ) {
          this.twoWheelerexTerminal++;
        }
        if (
          vehicleout.vrVehicleType === '3W' &&
          vehicleout.vrExitedByName !== '' &&
          vehicleout.vrExitedByName === 'Ticketing Terminal'
        ) {
          this.threeWheelerexTerminal++;
        }
        if (
          vehicleout.vrVehicleType === '4W' &&
          vehicleout.vrExitedByName !== '' &&
          vehicleout.vrExitedByName === 'Ticketing Terminal'
        ) {
          this.fourWheelerexTerminal++;
        }
        if (
          vehicleout.vrVehicleType === 'CV' &&
          vehicleout.vrExitedByName !== '' &&
          vehicleout.vrExitedByName === 'Ticketing Terminal'
        ) {
          this.cvexTerminal++;
        }
        // tslint:disable-next-line:max-line-length
        if (
          vehicleout.vrVehicleType === 'CYC' &&
          vehicleout.vrExitedByName !== '' &&
          vehicleout.vrExitedByName === 'Ticketing Terminal'
        ) {
          this.cycexTerminal++;
        }
        // tslint:disable-next-line:max-line-length
        if (
          vehicleout.vrVehicleType === 'CART' &&
          vehicleout.vrExitedByName !== '' &&
          vehicleout.vrExitedByName === 'Ticketing Terminal'
        ) {
          this.cartexTerminal++;
        }

        if (
          vehicleout.vrVehicleType === '2W' &&
          vehicleout.vrExitedByName !== '' &&
          vehicleout.vrExitedByName !== 'Ticketing Terminal'
        ) {
          this.twoWheelerexPhone++;
        }
        if (
          vehicleout.vrVehicleType === '3W' &&
          vehicleout.vrExitedByName !== '' &&
          vehicleout.vrExitedByName !== 'Ticketing Terminal'
        ) {
          this.threeWheelerexPhone++;
        }
        if (
          vehicleout.vrVehicleType === '4W' &&
          vehicleout.vrExitedByName !== '' &&
          vehicleout.vrExitedByName !== 'Ticketing Terminal'
        ) {
          this.fourWheelerexPhone++;
        }
        if (
          vehicleout.vrVehicleType === 'CV' &&
          vehicleout.vrExitedByName !== '' &&
          vehicleout.vrExitedByName !== 'Ticketing Terminal'
        ) {
          this.cvexPhone++;
        }
        // tslint:disable-next-line:max-line-length
        if (
          vehicleout.vrVehicleType === 'CYC' &&
          vehicleout.vrExitedByName !== '' &&
          vehicleout.vrExitedByName !== 'Ticketing Terminal'
        ) {
          this.cycexPhone++;
        }
        // tslint:disable-next-line:max-line-length
        if (
          vehicleout.vrVehicleType === 'CART' &&
          vehicleout.vrExitedByName !== '' &&
          vehicleout.vrExitedByName !== 'Ticketing Terminal'
        ) {
          this.cartexPhone++;
        }
      }
    });
  }
  // load2() {
  //   this.resetCountersLoop4w();
  //   this.loopscounts = combineLatest([
  //     this.dateFilterFrom,
  //     this.dateFilterTo
  //   ]).pipe(
  //     switchMap(([filterFromDate, filterToDate]) =>
  //       this.db
  //         .collectionGroup<Loop>('2WLOOP', ref => {
  //           let query1:
  //             | firebase.firestore.CollectionReference
  //             | firebase.firestore.Query = ref;
  //           if (filterFromDate) {
  //             query1 = query1
  //               .where('timestamp', '>=', filterFromDate)
  //               .orderBy('timestamp', 'desc');
  //           }
  //           if (filterToDate) {
  //             query1 = query1.where('timestamp', '<=', filterToDate);
  //           }
  //           query1 = query1.where('bussiness_ID', '==', this.businessID);
  //           return query1;
  //         })
  //         .valueChanges()
  //     )
  //   );
  //   this.loopReportSubs = this.loopscounts.subscribe(response => {
  //     this.in2Counter = response.length;
  //     //  this.resetCounters();
  //     this.resetCountersLoop4w();
  //     for (const loopss of response) {
  //       if (loopss.laneType === 'Entry') {
  //         this.in2Entry++;
  //       }
  //       if (loopss.laneType === 'Exit') {
  //         this.in2Exit++;
  //       }
  //     }
  //   });
  // }
  load4() {
    this.resetCountersLoop4w();
    this.loopsCo = combineLatest([this.dateFilterFrom, this.dateFilterTo]).pipe(
      switchMap(([filterFromDate, filterToDate]) =>
        this.db
          .collectionGroup<Loop>('4WLOOP', ref => {
            let query1:
              | firebase.firestore.CollectionReference
              | firebase.firestore.Query = ref;
            if (filterFromDate) {
              query1 = query1
                .where('timestamp', '>=', filterFromDate)
                .orderBy('timestamp', 'desc');
            }
            if (filterToDate) {
              query1 = query1.where('timestamp', '<=', filterToDate);
            }
            query1 = query1.where('bussiness_ID', '==', this.businessID);
            return query1;
          })
          .valueChanges()
      )
    );
    this.loopReportSubs1 = this.loopsCo.subscribe(response => {
      this.in4Counter = response.length;
      this.resetCountersLoop4w();
      //  this.resetCounters();
      for (const loopss of response) {
        if (loopss.laneType === 'Entry') {
          this.in4Entry++;
        }
        if (loopss.laneType === 'Exit') {
          this.in4Exit++;
        }
      }
    });
  }

  onDateSelection(date: NgbDateStruct) {
    // this.progressBarVisible = true;
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
      this.datePicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.fromDate != null) {
      const newDateFrom = new Date(
        this.fromDate.year,
        this.fromDate.month - 1,
        this.fromDate.day
      );
      this.dateFilterFrom.next(newDateFrom.getTime());
    }

    if (this.toDate != null) {
      const newDateTo = new Date(
        this.toDate.year,
        this.toDate.month - 1,
        this.toDate.day
      );
      newDateTo.setHours(23, 59, 59);
      this.dateFilterTo.next(newDateTo.getTime());
    } else {
      const newDateTo = new Date(
        this.fromDate.year,
        this.fromDate.month - 1,
        this.fromDate.day
      );
      newDateTo.setHours(23, 59, 59);
      this.dateFilterTo.next(newDateTo.getTime());
    }
  }

  onDateSelection1(date: NgbDateStruct) {
    let parsed = '';
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
      this.input.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.fromDate != null) {
      parsed += this._parserFormatter.format(this.fromDate);
      const newDateFrom = new Date(
        this.fromDate.year,
        this.fromDate.month - 1,
        this.fromDate.day
      );
      newDateFrom.setHours(7, 59, 59);
      this.dateFilterFrom.next(newDateFrom.getTime());
    }
    if (this.toDate != null) {
      parsed += ' / ' + this._parserFormatter.format(this.toDate);
      const newDateTo = new Date(
        this.toDate.year,
        this.toDate.month - 1,
        this.toDate.day + 1
      );
      newDateTo.setHours(7, 59, 59);
      //  newDateTo.setHours(23, 59 , 59);
      this.dateFilterTo.next(newDateTo.getTime());
    } else {
      const newDateTo = new Date(
        this.fromDate.year,
        this.fromDate.month - 1,
        this.fromDate.day + 1
      );
      newDateTo.setHours(7, 59, 59);
      // newDateTo.setHours(15);
      this.dateFilterTo.next(newDateTo.getTime());
    }
    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);
  }

  downloadExcelReport() {}
  downloadPDFReport() {}

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    this.vehicleReportSubs.unsubscribe();
    this.vehicleReportSubs1.unsubscribe();
    this.overnightReportSubs.unsubscribe();
    // this.loopReportSubs.unsubscribe();
    this.loopReportSubs1.unsubscribe();
    this.boomReportEntSubs.unsubscribe();
    this.boomReportExiSubs.unsubscribe();
  }

  isHovered = date =>
    this.fromDate &&
    !this.toDate &&
    this.hoveredDate &&
    after(date, this.fromDate) &&
    before(date, this.hoveredDate)
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);
}
