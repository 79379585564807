import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { NgbDateStruct, NgbCalendar, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

import { Daypass } from './daypasses.model';
import { DownloadService } from '../services/download.service';
import { DatabaseService } from '../services/database.service';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
// tslint:disable-next-line:import-blacklist
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase/app';
import { map, switchMap, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

export interface Report {
  a1dayPassBusinessId: string;
  a2dayPassDisplayId: string;
  a3dayPassVehicleType: string;
  a4dayPassNumberPlateDigits: string;
  a5dayPassStatus: string;
  a6dayPassValidOnDate: Date;
  a7dayPassIssuedById: string;
  a8dayPassIssueTimeStamp: string;
  a9dayPassAmount: number;
}

@Component({
  selector: 'app-daypasses',
  templateUrl: './daypasses.component.html',
  styleUrls: ['./daypasses.component.css']
})
export class DaypassesComponent implements OnInit, OnDestroy {
  @ViewChild('d') datePicker: NgbInputDatepicker;

  db: AngularFirestore;
  title = 'Day Pass Summary';

  data: Daypass[];
  daypassReportSubs: Subscription;
  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: string;
  p = 1;

  headerData: string[] = [
    'Location', 'Pass ID', 'Vehicle Type', 'Numberplate', 'Status', 'Valid On', 'Issued By', 'Issue Time', 'Amount'
  ];

  rearrangedReportArray: Report[] = [];
  progressBarVisible = false;

  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  dateFilterFrom: BehaviorSubject<number | null>;
  dateFilterTo: BehaviorSubject<number | null>;

  private daypassCollection: AngularFirestoreCollection<Daypass>;
  daypasses: Observable<Daypass[]>;

  constructor(db: AngularFirestore, private databaseService: DatabaseService,
    private downloadService: DownloadService, calendar: NgbCalendar) {

    this.db = db;

    this.dateFilterFrom = new BehaviorSubject(new Date().getTime());
    this.dateFilterTo = new BehaviorSubject(new Date().getTime());
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
    this.setBusinessDropDownList();
  }

  ngOnInit() {
    this.loadList();
  }

  loadList() {
    this.daypasses = combineLatest([
      this.dateFilterFrom,
      this.dateFilterTo
    ]).pipe(switchMap(([filterFromDate, filterToDate]) =>
      this.db.collection<Daypass>('/bizs/' + this.businessID + '/dayPasses', ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        if (filterFromDate) { query = query.where('dayPassIssueTimeStamp', '>=', filterFromDate).orderBy('dayPassIssueTimeStamp', 'desc'); }
        if (filterToDate) { query = query.where('dayPassIssueTimeStamp', '<=', filterToDate); }
        return query;
      }).valueChanges()
    )
    );

    this.daypassReportSubs = this.daypasses
    .pipe(
      take(1),
      map(
        (response) => {
          const dayPasses: Daypass[] = response;
          for (const dayPass of dayPasses) {

            if (dayPass.dayPassIssueTimeStamp) {
              dayPass.dayPassIssueTimeStamp = new Date(dayPass.dayPassIssueTimeStamp).toLocaleTimeString();
            }
            if (dayPass.dayPassStatus === 147) {
              dayPass.dayPassStatus = 'Vehicle Entered';
            } else if (dayPass.dayPassStatus === -1) {
              dayPass.dayPassStatus = 'undefined';
            } else {
              dayPass.dayPassStatus = 'N/A';
            }

            for (let k = 0; k < this.businessList.length; k++) {
              if (dayPass.dayPassBusinessId === this.businessList[k].id) {
                dayPass.dayPassBusinessId = this.businessList[k].name;
              }
            }
          }
          return dayPasses;
        }))
      .subscribe(
        (responses) => {
          this.data = responses;
          // console.log(this.data[0]);
          this.rearrangedReportArray = [];
          for (let i = 0; i < this.data.length; i++) {
            const reportItem = this.data[i];
            this.rearrangedReportArray.push({
              a1dayPassBusinessId: reportItem.dayPassBusinessId,
              a2dayPassDisplayId: reportItem.dayPassDisplayId,
              a3dayPassVehicleType: reportItem.dayPassVehicleType,
              a4dayPassNumberPlateDigits: reportItem.dayPassNumberPlateDigits,
              a5dayPassStatus: reportItem.dayPassStatus,
              a6dayPassValidOnDate: reportItem.dayPassValidOnDate,
              a7dayPassIssuedById: reportItem.dayPassIssuedById,
              a8dayPassIssueTimeStamp: reportItem.dayPassIssueTimeStamp,
              a9dayPassAmount: reportItem.dayPassAmount,
            });
            this.progressBarVisible = false;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  stopLoader() {
    this.progressBarVisible = false;
  }
  downloadExcelReport() {
    const reportLabel = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilterFrom.value).toLocaleDateString()
      + ' to ' + new Date(this.dateFilterTo.value).toLocaleDateString() + ' Report';
    this.downloadService.getExcelReport(this.rearrangedReportArray, reportLabel, this.headerData);
  }

  downloadPDFReport() {
    const reportLabel = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilterFrom.value).toLocaleDateString()
      + ' to ' + new Date(this.dateFilterTo.value).toLocaleDateString() + ' Report';

    const options = {
      styles: {
        fontSize: 9,
        font: 'helvetica', // helvetica, times, courier
      },
      startY: true, // false (indicates margin top value) or a number
      margin: { left: 1 },
      theme: 'striped',
      fillColor: false, // false for transparent or a color as described below
      textColor: 20,
      columnWidth: 'auto', // 'auto', 'wrap' or a number
      tableWidth: 'wrap', // 'auto', 'wrap' or a number,

    };

    this.downloadService.getPDFReport(this.rearrangedReportArray, reportLabel, this.headerData, options);
  }

  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setBusinessID(biz) {
    this.progressBarVisible = true;
    this.daypassReportSubs.unsubscribe();
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.loadList();
  }

  onDateSelection(date: NgbDateStruct) {
    this.progressBarVisible = true;
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
      this.datePicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    // console.log(this.fromDate);
    // console.log(this.toDate);

    if (this.fromDate != null) {
      const newDateFrom = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      this.dateFilterFrom.next(newDateFrom.getTime());
    }

    if (this.toDate != null) {
      const newDateTo = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
      newDateTo.setHours(23, 59, 59);
      this.dateFilterTo.next(newDateTo.getTime());
    } else {
      const newDateTo = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      newDateTo.setHours(23, 59, 59);
      this.dateFilterTo.next(newDateTo.getTime());
    }
  }

  ngOnDestroy() {
    this.daypassReportSubs.unsubscribe();
  }

  isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);
}

