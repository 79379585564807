import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatSnackBar } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { switchMap, take } from 'rxjs/operators';
import { AuthService } from '../core/auth.service';
import { DatabaseService } from '../services/database.service';
import { DownloadService } from '../services/download.service';
import { PathSetterService } from '../services/pathSetter.service';
import { User } from '../services/user.model';
import { AddAttendantPopupComponent } from './add-attendant-popup/add-attendant-popup.component';
import { AttendantEditService } from './attendantEdit.service';
import { AttendantInfo } from './attendantInfo.model';
import { EditAttendantComponent } from './edit-attendant/edit-attendant.component';



export interface Reports {
  a1name: string;
  a2businessId: string;
  a3phoneNumber: string;
  a4password: string;
}

@Component({
  selector: 'app-attendant-management',
  templateUrl: './attendant-management.component.html',
  styleUrls: ['./attendant-management.component.css']
})
export class AttendantManagementComponent implements OnInit, OnDestroy {

  db: AngularFireDatabase;
  title = 'Attendant Management';
  p = 1;
  existingAttendantSub: Subscription;
  existingAttendantList: Observable<any[]>;
  attendantList: AttendantInfo[] = [];

  businessList: any[];
  businessID: string;
  businessName: string;
  businessSelectFilter: BehaviorSubject<string | null>;

  headerData: string[] = [
    'Name', 'Location', 'PhoneNumber', 'Passwords',
  ];
  rearrangedAttendantArray: Reports[] = [];

  user: User;
  userSub: Subscription;


  constructor(db: AngularFireDatabase, private databaseService: DatabaseService, private modalService: NgbModal,
    private downloadService: DownloadService, private pathService: PathSetterService, public snackBar: MatSnackBar,
    private attendantEditService: AttendantEditService, public authService: AuthService) {
    this.db = db;
    this.setBusinessList();
  }

  canEdit() {
    if (this.authService.canEdit(this.user)) {
      return true;
    } else {
      return false;
    }
  }

  canDelete() {
    if (this.authService.canDelete(this.user)) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.userSub = this.authService.user.pipe(
      take(1)
    ).subscribe(user => this.user = user);
    // console.log('Setting User in dashbord component: ' + this.user.userRole);
    this.loadList();
  }

  loadList() {

    this.existingAttendantList = this.businessSelectFilter.pipe(switchMap(biz =>

      this.db.list('/getParking/' + this.pathService.getPathEnvironment() + '/operator_info/', ref =>
        biz ? ref.orderByKey().equalTo(biz) : ref
      ).valueChanges())
    );

    this.existingAttendantSub = this.existingAttendantList.subscribe(
      (data) => {
        const respone = data[0];
        this.attendantList = [];
        this.rearrangedAttendantArray = [];
        const PhoneKeys = Object.keys(respone);
        for (let i = 0; i < PhoneKeys.length; i++) {

          this.attendantList.push({
            active: respone[PhoneKeys[i]]['active'],
            androidId: respone[PhoneKeys[i]]['androidId'],
            businessId: this.businessName,
            name: respone[PhoneKeys[i]]['name'],
            password: respone[PhoneKeys[i]]['password'],
            phoneNumber: respone[PhoneKeys[i]]['phoneNumber'],
            time_stamp: respone[PhoneKeys[i]]['time_stamp'],
          });

          this.rearrangedAttendantArray.push({
            a1name: respone[PhoneKeys[i]]['name'],
            a2businessId: this.businessName,
            a3phoneNumber: respone[PhoneKeys[i]]['phoneNumber'],
            a4password: respone[PhoneKeys[i]]['password'],
          });

        }
      });
  }

  setBusinessList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
    this.businessSelectFilter = new BehaviorSubject(this.businessList[0].id);
  }

  setBusinessID(biz) {
    this.attendantList = [];
    this.existingAttendantSub.unsubscribe();
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.businessSelectFilter.next(biz.id);
    this.loadList();
  }

  openModal() {
    const modalRef = this.modalService.open(AddAttendantPopupComponent);
    modalRef.componentInstance.name = 'World';
  }

  removeDeviceItem(id: any) {
    // this.awaitingPersonList.push(this.personList[id]);
    const itemsRef = this.db.list('/getParking/' + this.pathService.getPathEnvironment() + '/operator_info/' + this.businessID);
    itemsRef.remove(this.attendantList[id]['phoneNumber']);
    this.snackBar.open('Attendant removed', 'dismiss', {
      duration: 3000
    });
  }

  editItem(index: any) {
    this.attendantEditService.setDeviceEditServiceDetails(this.attendantList[index], this.businessID);
    const modalRef2 = this.modalService.open(EditAttendantComponent);
    modalRef2.componentInstance.name = 'World';
  }

  ngOnDestroy() {
    this.attendantList = [];
    this.existingAttendantSub.unsubscribe();
    this.userSub.unsubscribe();
  }
  downloadExcelReport() {
    const reportLabel = this.title + ' Reports';
    this.downloadService.getExcelReport(this.rearrangedAttendantArray, reportLabel, this.headerData);

  }

  downloadPDFReport() {
    const reportLabel = this.title + ' Reports';
    // const reportLabel = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilterFrom.value).toLocaleDateString()
    //   + ' to ' + new Date(this.dateFilterTo.value).toLocaleDateString() + ' Report';

    const options = {
      styles: {
        fontSize: 10,
        font: 'helvetica', // helvetica, times, courier
      },
      startY: true, // false (indicates margin top value) or a number
      margin: { left: 1 },
      theme: 'striped',
      fillColor: false, // false for transparent or a color as described below
      textColor: 20,
      columnWidth: 'auto', // 'auto', 'wrap' or a number
      tableWidth: 'wrap', // 'auto', 'wrap' or a number,

    };

    this.downloadService.getPDFReport(this.rearrangedAttendantArray, reportLabel, this.headerData, options);
  }
}
