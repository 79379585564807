import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatabaseService } from '../../services/database.service';
import { PathSetterService } from '../../services/pathSetter.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-add-attendant-popup',
  templateUrl: './add-attendant-popup.component.html',
  styleUrls: ['./add-attendant-popup.component.css']
})
export class AddAttendantPopupComponent implements OnInit, OnDestroy {
  @Input() name;

  db: AngularFireDatabase;
  businessList: any[];
  businessID: string;
  businessName: string;
  attendantPassword: String;
  addAttendantSubs: Subscription;


  constructor(public activeModal: NgbActiveModal, private databaseService: DatabaseService, db: AngularFireDatabase,
    private pathService: PathSetterService, public snackBar: MatSnackBar) {
    this.db = db;
    this.attendantPassword = '0000';
    this.setBusinessList();
  }

  setBusinessList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setBusinessID(biz) {
    this.businessName = biz.name;
    this.businessID = biz.id;
  }

  onSubmit(Formdata) {
    this.activeModal.close('Close click');

    const attendantObj = {
      'active': false,
      'androidId': '',
      'businessId': this.businessID,
      'name': Formdata.name,
      'password': Formdata.password,
      'phoneNumber': Formdata.phone,
      'time_stamp': 0 - (new Date).getTime()
    };

    const itemRef = this.db.list('/getParking/' + this.pathService.getPathEnvironment() + '/operator_info/');
    const item = this.db.list('/getParking/' + this.pathService.getPathEnvironment() + '/operator_info/'
      + this.businessID + '/' + Formdata.phone).valueChanges();

    this.addAttendantSubs = item.subscribe(snapshot => {
      if (snapshot.length === 0) {
        itemRef.update(this.businessID + '/' + Formdata.phone, attendantObj);
        this.snackBar.open('Attendant added', 'dismiss', {
          duration: 3000
        });
        this.addAttendantSubs.unsubscribe();
      } else {
        this.snackBar.open('Attendant exists, please enter a different number', 'dismiss', {
          duration: 3000
        });
      }
    });
  }

  suggestPassword() {
    const min = 0, max = 9999;
    this.attendantPassword = ('0' + (Math.floor(Math.random() * (max - min + 1)) + min)).substr(-4);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
