import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { MaterialModule } from './core/material.module';
import { DatabaseService } from './services/database.service';
import { DownloadService } from './services/download.service';
import { PathSetterService } from './services/pathSetter.service';
import { VehicleComponent } from './vehicles/vehicle.component';
// import {LogService} from './services/log.service';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import 'hammerjs';
import { ChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { AddBusinessComponent } from './add-business/add-business.component';
import { AddCapacityTagComponent } from './add-capacity-tag/add-capacity-tag.component';
import { AddDevicesComponent } from './add-devices/add-devices.component';
import { AddGroupComponent } from './add-group/add-group.component';
import { AddPassesComponent } from './add-passes/add-passes.component';
import { AppRoutingModule } from './app-routing.module';
import { AddAttendantPopupComponent } from './attendant-management/add-attendant-popup/add-attendant-popup.component';
import { AttendantManagementComponent } from './attendant-management/attendant-management.component';
import { AttendantEditService } from './attendant-management/attendantEdit.service';
import { EditAttendantComponent } from './attendant-management/edit-attendant/edit-attendant.component';
import { AttendantReportComponent } from './attendant-report/attendant-report.component';
import { AttendantComponent } from './attendant/attendant.component';
import { BusinessSetupComponent } from './business-setup/business-setup.component';
import { BusinessSummaryComponent } from './business-summary/business-summary.component';
import { CameraDetailsComponent } from './camera-details/camera-details.component';
import { CameraComponent } from './camera/camera.component';
import { CameraEditService } from './camera/cameraEdit.service';
import { EditCameraComponent } from './camera/edit-camera/edit-camera.component';
import { AddCitypassPopupComponent } from './city-passes/add-citypass-popup/add-citypass-popup.component';
import { CityPassesComponent } from './city-passes/city-passes.component';
import { CityPassEditService } from './city-passes/citypassEdit.service';
import { CreatedCityPassesComponent } from './city-passes/created-city-passes/created-city-passes.component';
import { EditCitypassPopupComponent } from './city-passes/edit-citypass-popup/edit-citypass-popup.component';
import { ExpiredCityPassesComponent } from './city-passes/expired-city-passes/expired-city-passes.component';
import { ValidCityPassesComponent } from './city-passes/valid-city-passes/valid-city-passes.component';
import { CollectionSummaryComponent } from './collection-summary/collection-summary.component';
import { CompanysummaryComponent } from './company-summary/company-summary.component';
import { DailyCollectionComponent } from './daily-collection/daily-collection.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DaypassesComponent } from './daypasses/daypasses.component';
import { DemoCollectionComponent } from './demo-collection/demo-collection.component';
import { AddDevicePopupComponent } from './devices/add-device-popup/add-device-popup.component';
import { DeviceEditService } from './devices/deviceEdit.service';
import { DevicesComponent } from './devices/devices.component';
import { EditDevicesComponent } from './devices/edit-devices/edit-devices.component';
import { EodComponent } from './eod/eod.component';
import { GraphsComponent } from './graphs/graphs.component';
import { GroupSetupComponent } from './group-setup/group-setup.component';
import { GroupSummaryComponent } from './group-summary/group-summary.component';
import { HeaderComponent } from './header/header.component';
import { HourlyReportComponent } from './hourly-report/hourly-report.component';
import { LoginComponent } from './login/login.component';
import { LoopCountsComponent } from './loop-counts/loop-counts.component';
import { OvernightComponent } from './overnight/overnight.component';
import { AddPassPopupComponent } from './passes/add-pass-popup/add-pass-popup.component';
import { CreatedPassesComponent } from './passes/created-passes/created-passes.component';
import { EditPassPopupComponent } from './passes/edit-pass-popup/edit-pass-popup.component';
import { ExpiredPassesComponent } from './passes/expired-passes/expired-passes.component';
import { IssueRfidPopupComponent } from './passes/issue-rfid-popup/issue-rfid-popup.component';
import { PassEditService } from './passes/passEdit.service';
import { PassesComponent } from './passes/passes.component';
import { RfidComponent } from './passes/rfid/rfid.component';
import { ValidPassesComponent } from './passes/valid-passes/valid-passes.component';
import { NumberplateFilterPipe } from './pipes/numberplate-filter.pipe';
import { TableFilterPipe } from './pipes/table-filter.pipe';
import { RtoListComponent } from './rto-list/rto-list.component';
import { RtoComponent } from './rto/rto.component';
import { UtilService } from './services/util.service';
import { ShiftReportComponent } from './shift-report/shift-report.component';
import { SmsReportComponent } from './sms-report/sms-report.component';
import { AddTagsPopupComponent } from './tags/add-tags-popup/add-tags-popup.component';
import { CapacityTagsComponent } from './tags/capacity-tags/capacity-tags.component';
import { EditTagsPopupComponent } from './tags/edit-tags-popup/edit-tags-popup.component';
import { PaymentTagsComponent } from './tags/payment-tags/payment-tags.component';
import { ReportingTagsComponent } from './tags/reporting-tags/reporting-tags.component';
import { TagAddService } from './tags/tagAdd.service';
import { TagEditService } from './tags/tagEdit.service';
import { TagsComponent } from './tags/tags.component';
import { TransactionReportComponent } from './transaction-report/transaction-report.component';
import { VehicleReportComponent } from './vehicle-report/vehicle-report.component';
import { VisitorSummaryComponent } from './visitor-summary/visitor-summary.component';
import { DwellComponent } from './dwell/dwell.component';


@NgModule({
  declarations: [
    AppComponent,
    VehicleComponent,
    PassesComponent,
    HeaderComponent,
    LoginComponent,
    DaypassesComponent,
    DashboardComponent,
    GroupSummaryComponent,
    BusinessSummaryComponent,
    ValidPassesComponent,
    ExpiredPassesComponent,
    CreatedPassesComponent,
    AttendantManagementComponent,
    AddAttendantPopupComponent,
    AttendantComponent,
    CityPassesComponent,
    CreatedCityPassesComponent,
    ExpiredCityPassesComponent,
    ValidCityPassesComponent,
    AddPassesComponent,
    CompanysummaryComponent,
    VisitorSummaryComponent,
    AddDevicesComponent,
    TableFilterPipe,
    NumberplateFilterPipe,
    DevicesComponent,
    EditDevicesComponent,
    CameraComponent,
    EditCameraComponent,
    AddDevicePopupComponent,
    EditAttendantComponent,
    AddPassPopupComponent,
    EditPassPopupComponent,
    AddCitypassPopupComponent,
    EditCitypassPopupComponent,
    TagsComponent,
    CapacityTagsComponent,
    ReportingTagsComponent,
    PaymentTagsComponent,
    AddTagsPopupComponent,
    EditTagsPopupComponent,
    AddCapacityTagComponent,
    LoopCountsComponent,
    CameraDetailsComponent,
    BusinessSetupComponent,
    GroupSetupComponent,
    AddBusinessComponent,
    AddGroupComponent,
    VehicleReportComponent,
    AttendantReportComponent,
    HourlyReportComponent,
    GraphsComponent,
    CollectionSummaryComponent,
    DailyCollectionComponent,
    DemoCollectionComponent,
    OvernightComponent,
    ShiftReportComponent,
    TransactionReportComponent,
    SmsReportComponent,
    RfidComponent,
    IssueRfidPopupComponent,
    RtoComponent,
    RtoListComponent,
    EodComponent,
    DwellComponent
  ],
  imports: [
    BrowserModule,
    FormsModule, 
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule.withServerTransition({ appId: 'universal-cli' }),
    CoreModule, // core angularfire imports module
    MaterialModule, // imports material library for display
    AngularFontAwesomeModule,
    NgbModule.forRoot(),
    AppRoutingModule,
    NgxPaginationModule,
    ChartsModule,
    RouterModule
  ],
  entryComponents: [AddAttendantPopupComponent, EditDevicesComponent, AddDevicePopupComponent, EditAttendantComponent,
    AddPassPopupComponent, EditPassPopupComponent, AddCitypassPopupComponent, EditCitypassPopupComponent, AddTagsPopupComponent,
    EditTagsPopupComponent, EditCameraComponent , AddBusinessComponent, AddGroupComponent, IssueRfidPopupComponent],
  providers: [DatabaseService, DownloadService, PathSetterService, DeviceEditService, PassEditService, CityPassEditService, TagEditService,
    AttendantEditService, TagAddService, CameraEditService , UtilService],
  bootstrap: [AppComponent]
})
export class AppModule { }
