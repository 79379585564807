import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatabaseService } from '../services/database.service';
import { DownloadService } from '../services/download.service';
import { PathSetterService } from '../services/pathSetter.service';
import { Vehicle } from '../vehicles/vehicle.model';

@Component({
  selector: 'app-eod',
  templateUrl: './eod.component.html',
  styleUrls: ['./eod.component.css']
})
export class EodComponent implements OnInit {


  db: AngularFirestore;
  title = 'EOD Collated Report';

  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: string;
  groupID: string;

  model: NgbDateStruct;
  dateFilter: BehaviorSubject<string | null>;
  // dateFilterFrom: BehaviorSubject<number | null>;
  // dateFilterTo: BehaviorSubject<number | null>;
  StrMMYYYY: String;
  StrDate: string;
  StrYYYY: string;
  StrMM: string;
  selectedDate: Date;

  fromDate: any;
  toDate: any;
  fromEpoc: number;
  toEpoc: number;

  vehiclesReportSubs: Subscription;
  private vehicles: AngularFirestoreCollection<Vehicle>;
  vehicles2: Observable<Vehicle[]>;
  vehicles1: Observable<Vehicle[]>;
  data: any[] = [];

  data1 = 0;
  total = 0;

  p2W = 0;
  p4W = 0;
  f2W = 0;
  f4W = 0;
  en2W = 0;
  en4W = 0;
  ex2W = 0;
  ex4W = 0;
  lost2W = 0;
  lost4W = 0;
  lost2WA = 0;
  lost4WA = 0;
  free2W = 0;
  free4W = 0;
  comp2W = 0;
  comp4W = 0;
  comp2WA = 0;
  comp4WA = 0;
  amount = 0;
  amount2W = 0;
  amount4W = 0;
  overNight2W = 0;
  overNight4W = 0;
  overNight2WA = 0;
  overNight4WA = 0;
  cash2W =0;
  cash4W = 0;
  upi2W = 0;
  upi4W = 0;
  fastag4W=0;
  headerData: string[] = [
    'Total Entered', 'Total Exit', 'Vehicle Paid'
  ];


  constructor(private databaseService: DatabaseService, db: AngularFirestore, adb: AngularFireDatabase, private downloadService: DownloadService,
    private pathService: PathSetterService, calendar: NgbCalendar) {

    this.db = db;
    const date = new Date();
    this.model = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
    const StrToday = this.setDateFormat(this.model);
    this.dateFilter = new BehaviorSubject(StrToday);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    this.StrYYYY = this.setCalenderYear(this.model);
    this.StrMM = this.setCalenderMonth(this.model);
    this.selectedDate = this.getTimeStamp(this.model);
    this.setGroupID();
    this.StrDate = this.setCalenderdate(this.model);
    this.setBusinessDropDownList();
  }


  ngOnInit() {
    this.loadList();
  }

  resetCounter() {
    this.p2W = 0;
    this.p4W = 0;
    this.en2W = 0;
    this.en4W = 0;
    this.ex2W = 0;
    this.ex4W = 0;
    this.amount = 0;
    this.free2W = 0;
    this.free4W = 0;
    this.lost2W = 0;
    this.lost4W = 0;
    this.lost2WA = 0;
    this.lost4WA = 0;
    this.comp2W = 0;
    this.comp4W = 0;
    this.comp2WA = 0;
    this.comp4WA = 0;
    this.amount2W = 0;
    this.amount4W = 0;
    this.overNight2W = 0;
    this.overNight4W = 0;
    this.overNight2WA = 0;
    this.overNight4WA = 0;
    this.cash2W =0;
    this.cash4W = 0;
    this.upi2W = 0;
    this.upi4W = 0;
    this.fastag4W=0;
  }

  loadList() {
    let today = new Date(this.selectedDate);
    // console.log('Date:', today, this.selectedDate);
    today.setHours(0);
    let todayEpochDay = Math.floor((today.getTime() + 330 * 60 * 1000) / (1000 * 60 * 60 * 24));

    this.resetCounter();

    this.vehicles2 = this.db.collectionGroup<Vehicle>('records', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('vrStartEpochDay', '==', todayEpochDay).where('vrBusinessId', '==', this.businessID);
      return query;
    }).valueChanges();

    this.vehicles1 = this.db.collectionGroup<Vehicle>('records', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('vrEndEpochDay', '==', todayEpochDay).where('vrBusinessId', '==', this.businessID);
      return query;
    }).valueChanges();

    const tempArray = [this.vehicles2, this.vehicles1];

    const allVehicles = combineLatest(tempArray)
      .pipe(
        map(adArray => adArray.reduce((acc, curr) =>
          [...acc, ...curr], []))
      );

    this.vehiclesReportSubs = allVehicles.subscribe(
      (response) => {
        this.resetCounter();
        this.data1 = response.length;
        // console.log('DATA SIZE ',this.data1)

        const uniqHashMap = {};

        for (const vehicleListData of response) {

          if (vehicleListData.vrFirebaseId in uniqHashMap) continue;
          uniqHashMap[vehicleListData.vrFirebaseId] = true;

          let collection: number;
          if (vehicleListData.vrStartEpochDay < todayEpochDay) {
            collection = vehicleListData.vrParkingBalanceFare + vehicleListData.vrLostTicketCharge;

          } else if (vehicleListData.vrEndEpochDay > todayEpochDay) {
            collection = vehicleListData.vrParkingPrepaidFare;

          } else {

            collection = vehicleListData.vrParkingTotalFare;
          }

          this.amount = this.amount + collection;

          if (
            vehicleListData.vrVehicleType === '2W' && (vehicleListData.vrStartEpochDay < 0 || vehicleListData.vrStartEpochDay === todayEpochDay)
          ) {
            this.en2W++;
          }
          if (
            vehicleListData.vrVehicleType === '4W' && (vehicleListData.vrStartEpochDay < 0 || vehicleListData.vrStartEpochDay === todayEpochDay)
          ) {
            this.en4W++;
          }
          if
            (
            vehicleListData.vrVehicleType === '2W' &&
            vehicleListData.vrParkingStatus === 'OUT' &&
            (vehicleListData.vrEndEpochDay === todayEpochDay)
          ) {
            this.ex2W++;
          }
          if
            (
            vehicleListData.vrVehicleType === '4W' &&
            vehicleListData.vrParkingStatus === 'OUT' &&
            (vehicleListData.vrEndEpochDay === todayEpochDay)
          ) {
            this.ex4W++;
          }

          // Parking Charges
          if (vehicleListData.vrVehicleType === '2W' && collection) {
            this.amount2W = this.amount2W + collection;
          }

          if (vehicleListData.vrVehicleType === '4W' && collection) {
            this.amount4W = this.amount4W + collection;
          }

          if (vehicleListData.vrVehicleType === '2W' &&
            vehicleListData.vrParkingTotalFare == 10 &&
            vehicleListData.vrEndEpochDay === todayEpochDay) {
            this.p2W++;
            // console.log('paid count:' , this.p2W);
          }

          if (vehicleListData.vrVehicleType === '4W' &&
            vehicleListData.vrParkingTotalFare == 30 &&
            vehicleListData.vrEndEpochDay === todayEpochDay) {
            this.p4W++;
            // console.log('paid 4 count:' , this.p4W);

          }

          // Duration
          if (vehicleListData["vrParkingDuration"] !== undefined) {
            const parkingDuration = vehicleListData.vrParkingDuration;
            // tslint:disable-next-line:no-bitwise
            const min = Math.floor((parkingDuration / 1000 / 60) << 0);
            const sec = Math.floor((parkingDuration / 1000) % 60);
            const formattedParkingDuration = min + ' mins ' + sec + 'seconds';

            if (min <= 15 && min > 0 && vehicleListData.vrVehicleType === '2W' && vehicleListData.vrParkingStatus === 'OUT' && vehicleListData.vrParkingTotalFare == 0) {
              this.free2W++;
            }
            if (min <= 15 && min > 0 && vehicleListData.vrVehicleType === '4W' && vehicleListData.vrParkingStatus === 'OUT' && vehicleListData.vrParkingTotalFare == 0) {
              this.free4W++;
            }
          }

          if (vehicleListData.vrStartEpochDay > 0 && vehicleListData.vrStartEpochDay !== todayEpochDay && vehicleListData.vrEndEpochDay === todayEpochDay &&
            vehicleListData.vrVehicleType === '2W') {

            this.overNight2W++;
          }

          if (vehicleListData.vrStartEpochDay > 0 && vehicleListData.vrStartEpochDay !== todayEpochDay && vehicleListData.vrEndEpochDay === todayEpochDay &&
            vehicleListData.vrVehicleType === '4W') {
            this.overNight4W++;
          }

          if (vehicleListData.vrStartEpochDay > 0 && vehicleListData.vrStartEpochDay !== todayEpochDay &&
            vehicleListData.vrEndEpochDay === todayEpochDay &&
            vehicleListData.vrVehicleType === '2W' && collection) {
            this.overNight2WA = this.overNight2WA + collection;
            // console.log('OvernightA:', this.overNight2WA);
          }

          if (vehicleListData.vrStartEpochDay > 0 && vehicleListData.vrStartEpochDay !== todayEpochDay &&
            vehicleListData.vrEndEpochDay === todayEpochDay &&
            vehicleListData.vrVehicleType === '4W' && collection) {
            this.overNight4WA = this.overNight4WA + collection;
          }

          if (vehicleListData.customExitType === '2W Lost Ticket' && vehicleListData.vrParkingTotalFare !== 0 && vehicleListData.vrVehicleType === '2W' && vehicleListData.vrEndEpochDay === todayEpochDay) {
            this.lost2W++;
          }

          if (vehicleListData.customExitType === '4W Lost Ticket' && vehicleListData.vrParkingTotalFare !== 0 && vehicleListData.vrVehicleType === '4W' && vehicleListData.vrEndEpochDay === todayEpochDay) {
            this.lost4W++;
          }

          if (vehicleListData.customExitType === '2W Lost Ticket' &&
            vehicleListData.vrVehicleType === '2W' &&
            vehicleListData.vrEndEpochDay === todayEpochDay && collection) {
            this.lost2WA = this.lost2WA + collection;
            // console.log('lost2A' , this.lost2WA);
          }

          if (vehicleListData.customExitType === '4W Lost Ticket' &&
            vehicleListData.vrVehicleType === '4W' &&
            vehicleListData.vrEndEpochDay === todayEpochDay && collection) {
            this.lost4WA = this.lost4WA + collection;
            // console.log('lost4A' , this.lost4WA);
          }


          if (vehicleListData.customExitType === 'Complimentary' && vehicleListData.vrParkingTotalFare == 0 && vehicleListData.vrVehicleType === '2W' && vehicleListData.vrEndEpochDay === todayEpochDay) {
            this.comp2W++;
          }

          if (vehicleListData.customExitType === 'Complimentary' && vehicleListData.vrParkingTotalFare == 0 && vehicleListData.vrVehicleType === '4W' && vehicleListData.vrEndEpochDay === todayEpochDay) {
            this.comp4W++;
          }


          if(vehicleListData.vrExitPaymentType === 0 && vehicleListData.vrVehicleType === '4W'){
            this.cash4W = this.cash4W + collection;
          } 
          if(vehicleListData.vrExitPaymentType === 0 && vehicleListData.vrVehicleType === '2W'){
            this.cash2W = this.cash2W + collection;
          }

          if(vehicleListData.vrExitPaymentType === 2 && vehicleListData.vrVehicleType === '4W'){
            this.upi4W = this.upi4W + collection;
          } 
          if(vehicleListData.vrExitPaymentType === 2 && vehicleListData.vrVehicleType === '2W'){
            this.upi2W = this.upi2W + collection;
          }

          if(vehicleListData.vrExitPaymentType === 4 && vehicleListData.vrVehicleType === '4W'){
            this.fastag4W = this.fastag4W + collection;
          } 
          
          

          // if (vehicleListData.customExitType === 'Complimentary' &&  
          //     vehicleListData.vrVehicleType === '2W' && collection &&
          //     vehicleListData.vrEndEpochDay === todayEpochDay) {
          //   this.comp2WA = this.comp2WA + collection;
          //   console.log('Comp2WA:' , this.comp2WA);
          // }

          // if (vehicleListData.customExitType === 'Complimentary' &&  
          //     vehicleListData.vrVehicleType === '4W' && collection &&
          //     vehicleListData.vrEndEpochDay === todayEpochDay) {
          //   this.comp4WA = this.comp4WA + collection;
          //   console.log('Comp4WA:' , this.comp4WA);
          // }

        }
        console.log('Err');
      }
    );
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }
  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setBusinessID(biz) {
    this.resetCounter();
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.loadList();
    // this.resetCounter();
  }
  setDateFormat(model) {
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    const StrToday = yyyy + Strmm;
    return StrToday;
  }
  setCalenderdate(model) {
    let Strdd = null;
    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const StrDate = Strdd;
    return StrDate;
  }

  setCalenderMonthYear(model) {
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    const yyyy = model.year;

    const StrToday = Strmm + '-' + yyyy;
    return StrToday;
  }
  setCalenderYear(model) {
    const yyyy = model.year;
    return yyyy;
  }

  setCalenderMonth(model) {
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    return Strmm;
  }

  getTimeStamp(model) {
    const date = new Date();
    date.setMonth(model.month - 1);
    date.setFullYear(model.year);
    date.setDate(model.day);
    return date;
  }


  filterByDate(model) {
    this.resetCounter();
    this.vehiclesReportSubs.unsubscribe();
    const StrToday = this.setDateFormat(model);
    this.dateFilter.next(StrToday);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    this.StrYYYY = this.setCalenderYear(this.model);
    this.StrMM = this.setCalenderMonth(this.model);
    this.StrDate = this.setCalenderdate(this.model);
    this.selectedDate = this.getTimeStamp(this.model);
    this.loadList();
  }

  ngOnDestroy() {
    this.vehiclesReportSubs.unsubscribe();
    this.resetCounter();
  }

  downloadExcelReport() {
    const reportLabel = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilter.value).toLocaleDateString()
      + ' to ' + new Date(this.dateFilter.value).toLocaleDateString() + ' Report';
    this.downloadService.getExcelReport(this.data, reportLabel, this.headerData);
  }

}
