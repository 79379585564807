import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { BusinessFireStore } from '../business-setup/businessFirestore.model';
import { Subscription, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { DatabaseService } from '../services/database.service';
import { GroupFireStore } from '../group-setup/groupFirestore.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


export interface Report {
  a1groupName: string;
  a2groupId: string;
}

@Component({
  selector: 'app-add-business',
  templateUrl: './add-business.component.html',
  styleUrls: ['./add-business.component.css']
})
export class AddBusinessComponent implements OnInit {
afs: AngularFirestore;
business_id: string;
groupID: string;
groupName: string;
groupList: any[];
groupReportSubs: Subscription;
rearrangedReportArray: Report[] = [];
data: GroupFireStore[];
private reportingGroupCollection: AngularFirestoreCollection<GroupFireStore>;
reportingGroups: Observable<GroupFireStore[]>;


  constructor(public activeModal: NgbActiveModal , afs: AngularFirestore, private databaseService: DatabaseService) {
    this.afs = afs;
    this.setGroupList();
    this.setGroupID();
   }
   setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }
  setGroupList() {
    this.groupList = this.databaseService.getGroupList();
    // console.log(this.groupList);
    this.groupName = this.groupName;
    this.groupID = this.groupID;
  }
  setGroup(grp) {
    this.groupName = grp.a1groupName;
    this.groupID = grp.a2groupId;
    console.log(this.groupID);
  }
  ngOnInit() {
    this.loadList();
  }
  loadList() {
    this.reportingGroupCollection = this.afs.collection<GroupFireStore>('/groups/');

    this.reportingGroups = this.reportingGroupCollection.valueChanges();

    this.groupReportSubs = this.reportingGroups.pipe(
      take(1)
    ).subscribe(
      (response) => {
        this.data = response;
        // console.log('dfsdf' , this.data);
        this.rearrangedReportArray = [];
        for (let i = 0; i < this.data.length; i++) {
          const reportItem = this.data[i];

          this.rearrangedReportArray.push({
            a1groupName: reportItem.groupName,
            a2groupId: reportItem.groupID
          });
          // console.log('chehkdsf' , this.rearrangedReportArray);
        }
      });
  }
  onSubmit(FormData) {
    this.activeModal.close('Close click');
    // console.log('jsdfsdf' + this.business_id);
    let itemKey = null;

    const businessCollection = this.afs.collection<BusinessFireStore>('/groups/' + this.groupID + '/bizs/');
    Promise.resolve(
      businessCollection.add({
        business_project: '' + FormData.business_project,
        business_company: '' + FormData.business_company,
        business_name: '' + FormData.business_name,
        business_address_1: '' + FormData.business_address_1,
        business_address_2: '' + FormData.business_address_2,
        business_id: '',
        business_prefix: '' + FormData.business_prefix,
      }))
      .then((fsitem) => {
        itemKey = fsitem.id;
        businessCollection.doc(itemKey).update({ business_id: itemKey })
          .catch(err => {
            // console.log(err);
          });
      });
    //  window.location.reload();
  }
}
