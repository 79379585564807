import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { PassFireStore } from '../../add-passes/passFireStore.model';

import { DatabaseService } from '../../services/database.service';
import { PassEditService } from '../passEdit.service';
import { PathSetterService } from '../../services/pathSetter.service';
import { Pass } from '../pass.model';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { CompanyTags } from '../add-pass-popup/add-pass-popup.component';
import { CapacityTags } from '../../tags/capacity-tags/capacityTags.model';



@Component({
  selector: 'app-edit-pass-popup',
  templateUrl: './edit-pass-popup.component.html',
  styleUrls: ['./edit-pass-popup.component.css']
})
export class EditPassPopupComponent implements OnInit {

  db: AngularFirestore;
  passInfo: Pass;
  passFireStoreInfo: PassFireStore;
  afdb: AngularFireDatabase;
  afs: AngularFirestore;
  groupID: string;

  isCityPass: boolean;
  model: number;
  numberPlate: string;
  userFirstName: string;
  userLastName: string;
  userPhone: string;
  userEmail: string;
  passAmount: number;

  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: any;

  capacityID: string;
  capacityName: string;
  companyTagsArray: CompanyTags[] = [];
  data: CapacityTags[];
  tagsReportSubs: Subscription;
  private capacityTagsCollection: AngularFirestoreCollection<CapacityTags>;
  capacityTags: Observable<CapacityTags[]>;

  constructor(public activeModal: NgbActiveModal, private databaseService: DatabaseService, private pathService: PathSetterService,
    public snackBar: MatSnackBar, private passEditService: PassEditService, afdb: AngularFireDatabase, afs: AngularFirestore) {
    this.afdb = afdb;
    this.afs = afs;
    this.setGroupID();

    this.setBusinessDropDownList();
    this.getPassInformation();
  }

  setBusinessDropDownList() {
    // console.log('inside set dropdown list');
    this.businessList = this.databaseService.getBusinessList();
    // console.log(this.businessList);
    // this.businessName = this.businessList[0].name;
    // this.businessID = this.businessList[0].id;
    this.businessID = this.passEditService.getBusinessID();
    this.setCompanyTags();
  }

  setCompanyTags() {

    this.capacityTagsCollection = this.afs.collection<CapacityTags>('/groups/' + this.groupID + '/bizs/' + this.businessID +
      '/corporate_tags/');
    this.capacityTags = this.capacityTagsCollection.valueChanges();

    this.tagsReportSubs = this.capacityTags.pipe(
      take(1)
    ).subscribe(
      (response) => {
        this.data = response;
        this.companyTagsArray = [
          {
            companyID: '',
            companyName: 'NA'
          }
        ];
        for (let i = 0; i < this.data.length; i++) {
          const reportItem = this.data[i];
          // console.log(reportItem);
          this.companyTagsArray.push({
            companyName: reportItem.gpTagFullTitle,
            companyID: reportItem.gpTagId
          });
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  setCompanyID(tag) {
    this.capacityID = tag.companyID;
    this.capacityName = tag.companyName;
    // console.log(this.capacityID);
    // console.log(this.capacityName);
  }

  // setBusinessID() {
  //   // this.businessName = this.selectedValue.name;
  //   this.businessID = this.selectedValue;
  // }

  getPassInformation() {
    this.passInfo = this.passEditService.getPassInfo();
    this.numberPlate = this.passInfo.passNumberPlate;
    this.userFirstName = this.passInfo.userFirstName;
    this.userLastName = this.passInfo.userLastName;
    this.userPhone = this.passInfo.userPhone;
    this.userEmail = this.passInfo.userEmail;
    this.passAmount = this.passInfo.passAmount;
    this.capacityName = this.passInfo.passCompanyName;
    this.capacityID = this.passInfo.passCompanyTagId;


    if (this.passInfo.passVehicleType === '4W') {
      this.model = 1;
    } else {
      this.model = 2;
    }
  }

  onSubmit(Formdata) {

     console.log(Formdata);

    this.activeModal.close('Close click');

    // let vehType: string;
    // if (Formdata.radioVehType === 1) {
    //   vehType = '4W';
    // } else {
    //   vehType = '2W';
    // }

    if (this.capacityName === 'select' || this.capacityName === 'NA') {
      this.capacityID = '';
      this.capacityName = '';
    }

    this.passInfo.userFirstName = Formdata.firstname;
    this.passInfo.userLastName = Formdata.lastname;
    this.passInfo.userPhone = Formdata.phone;
    this.passInfo.userEmail = Formdata.email;
    this.passInfo.passNumberPlate = Formdata.numplate;
    this.passInfo.passNumberPlateDigits = Formdata.numplate.substring(6);
    this.passInfo.passAmount = Formdata.amt;
    // this.passInfo.passVehicleType = vehType;
    this.passInfo.passCompanyName = this.capacityName;
    this.passInfo.passCompanyTagId = this.capacityID;
    this.passInfo.passModificationTimeStamp = new Date().getTime(),
    this.passInfo.passModifiedById = 'Dashboard Admin';

    const itemsRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() + '/passes/' + this.businessID + '/All/');
    itemsRef.update(this.passInfo.passFirebaseId, this.passInfo);
    this.snackBar.open('Pass updated', 'dismiss', {
      duration: 3000
    });

    // Firestore URL
    const passCollection = this.afs.collection<PassFireStore>('/bizs/' + this.businessID + '/passes/');
    passCollection.doc(this.passInfo.passFirestoreDocKey)
      .update({
        userFirstName: Formdata.firstname,
        userLastName: Formdata.lastname,
        userPhone: Formdata.phone,
        userEmail: Formdata.email,
        passNumberPlate: Formdata.numplate,
        passNumberPlateDigits: Formdata.numplate.substring(6),
        passAmount: Formdata.amt,
        // passVehicleType: vehType,
        passCompanyName: this.capacityName,
        passCompanyTagId: this.capacityID,
        passModificationTimeStamp: new Date().getTime(),
        passModifiedById: 'Dashboard Admin',
      });
  }

  ngOnInit() { }
}
