import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatabaseService } from '../services/database.service';
import { AngularFireDatabase } from '@angular/fire/database';
// tslint:disable-next-line:import-blacklist
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DownloadService } from '../services/download.service';
import { PathSetterService } from '../services/pathSetter.service';
import { map, switchMap, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

export interface CompanyVisitorTag {
  a1CompanyName: string;
  a2CabCount: number;
  a3VisitorCount: number;
  a4InterviewCount: number;
  a5EmployeeCount: number;
  a6VendorCount: number;
  a7PassCount: number;
  // a8PassNotAvCount: number;
}
@Component({
  selector: 'app-visitor-summary',
  templateUrl: './visitor-summary.component.html',
  styleUrls: ['./visitor-summary.component.css']
})
export class VisitorSummaryComponent implements OnInit {

  title = 'Company Visitor Summary';

  headerData: string[] = [
    'Company Name', 'Visitor Count', 'Vendor Count', 'ICICI Bank Count', 'Interview Count', 'Cab Count'];

  groupID: string;
  StrToday: string;
  businessList: any[];
  tagList: any[] = [];
  businessID: string;
  selectedValue: string;
  businessName: string;
  db: AngularFireDatabase;
  progressBarVisible = false;
  companyVisitorList: Observable<any[]>;
  companyVisitorSub: Subscription;
  rearrangedReportArray: CompanyVisitorTag[] = [];
  model: NgbDateStruct;
  dateFilter: BehaviorSubject<string | null>;
  companyName: any;
  companyList: any[] = [];

  constructor(private databaseService: DatabaseService, db: AngularFireDatabase, private downloadService: DownloadService,
    private pathService: PathSetterService) {
    this.db = db;

    const date = new Date();
    this.model = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
    const StrToday = this.setDateFormat(this.model);
    this.dateFilter = new BehaviorSubject(StrToday);

    this.setGroupID();
    this.setBusinessDropDownList();
  }

  ngOnInit() {
    this.loadList();
    // this.getBusinessIdDecorativeTags(bizID);
    //this.getTags();
  }

  getTags() {
    // console.log('Tags', this.businessID);
    // this.companyVisitorList = this.db.list('/getParking/' + this.pathService.getPathEnvironment() +
    // '/gpDecorativeTags/' + this.businessID).valueChanges();
    // this.companyVisitorSub = this.companyVisitorList.subscribe((response) => {
    //   console.log('dfs', response);
    //   this.tagList = response;
    //   //  this.rearrangedReportArray = [{ a1CompanyName: 'default' }];
    //   for (let i = 0; i < this.rearrangedReportArray.length; i++) {
    //     let tagLabel = '';
    //     tagLabel = this.rearrangedReportArray[i]['tagLabel'];
    //     this.tagList.push({
    //       a1CompanyName: tagLabel,
    //     });
    //   }
    //   console.log('kjklhkjhkjhkhkhkh', this.tagList);
    // });
  }
  loadList() {
    // URL
    this.companyVisitorList = this.db.list('/getParking/' + this.pathService.getPathEnvironment() +
      '/gpDecorativeTags/' + this.businessID).valueChanges();

    this.companyVisitorSub = this.companyVisitorList.subscribe((response) => {
      // console.log('dfs', response);
      this.tagList = response;
      //  this.rearrangedReportArray = [{ a1CompanyName: 'default' }];
      for (let i = 0; i < this.rearrangedReportArray.length; i++) {
        let tagLabel = '';
        tagLabel = this.rearrangedReportArray[i]['tagLabel'];
        this.tagList.push({
          a1CompanyName: tagLabel,
        });
      }
      // console.log(this.tagList);
    });
    // console.log('Tags', this.tagList);

    // URL
    this.companyVisitorList = this.db.list('getParking/' + this.pathService.getPathEnvironment() +
      '/tenantSummary/' + this.groupID + '/' + this.dateFilter.value + '/' + this.businessID + '/').valueChanges();
    // console.log(this.companyVisitorList);

    this.companyVisitorSub = this.companyVisitorList
      .pipe(
        take(1)
      )
      .subscribe(
        (response) => {
          this.companyList = response;
          // console.log('.....', response);
          for (let i = 0; i < response.length; i++) {
            const companyName = response[i]['companyName'];
            let cabVehCount = 0;
            let VisitorVehCount = 0;
            let InterViewVehCount = 0;
            let EmployeeVehCount = 0;
            let VendorVehCount = 0;
            let PassVehCount = 0;
            //  let PassNotCount = 0;
            // console.log('Inside Company Loop', this.tagList[0].tagLabel);
            const tagKeys = Object.keys(response[i]['tags']);
            for (let j = 0; j < tagKeys.length; j++) {
              if (response[i]['tags'][tagKeys[j]]['tagLabel'] === this.tagList[0].tagLabel) {
                cabVehCount = Object.keys(response[i]['tags'][tagKeys[j]]['vehicleList']).length;
              }
              if (response[i]['tags'][tagKeys[j]]['tagLabel'] === this.tagList[1].tagLabel) {
                VisitorVehCount = Object.keys(response[i]['tags'][tagKeys[j]]['vehicleList']).length;
              }
              if (response[i]['tags'][tagKeys[j]]['tagLabel'] === this.tagList[2].tagLabel) {
                InterViewVehCount = Object.keys(response[i]['tags'][tagKeys[j]]['vehicleList']).length;
              }
              if (response[i]['tags'][tagKeys[j]]['tagLabel'] === this.tagList[3].tagLabel) {
                EmployeeVehCount = Object.keys(response[i]['tags'][tagKeys[j]]['vehicleList']).length;
              }
              if (response[i]['tags'][tagKeys[j]]['tagLabel'] === this.tagList[4].tagLabel) {
                VendorVehCount = Object.keys(response[i]['tags'][tagKeys[j]]['vehicleList']).length;
              }
              if (response[i]['tags'][tagKeys[j]]['tagLabel'] === this.tagList[5].tagLabel) {
                PassVehCount = Object.keys(response[i]['tags'][tagKeys[j]]['vehicleList']).length;
              }
              // if (response[i]['tags'][tagKeys[j]]['tagLabel'] === this.tagList[6].tagLabel) {
              //   PassNotCount = Object.keys(response[i]['tags'][tagKeys[j]]['vehicleList']).length;
              // }
            }
            this.rearrangedReportArray.push(
              {
                a1CompanyName: companyName,
                a2CabCount: cabVehCount,
                a3VisitorCount: VisitorVehCount,
                a4InterviewCount: InterViewVehCount,
                a5EmployeeCount: EmployeeVehCount,
                a6VendorCount: VendorVehCount,
                a7PassCount: PassVehCount,
                //  a8PassNotAvCount: PassNotCount,
              }
            );
            // console.log(companyName);
          }
        }
      );
    // console.log('Company list', this.rearrangedReportArray);
  }
  //   // URL
  //   this.companyVisitorList = this.db.list('getParking/' + this.pathService.getPathEnvironment() +
  // '/tenantSummary/' + this.groupID + '/' + this.dateFilter.value +
  //     '/' + this.businessID + '/').valueChanges();

  //   this.companyVisitorSub = this.companyVisitorList
  //     .take(1)
  //     .subscribe(
  //       (response) => {

  //         for (let i = 0; i < response.length; i++) {

  //           const companyName = response[i]['companyName'];
  //           let cabVehCount = 0;
  //           let VisitorVehCount = 0;
  //           let InterViewVehCount = 0;
  //           let EmployeeVehCount = 0;
  //           let VendorVehCount = 0;

  //           const tagKeys = Object.keys(response[i]['tags']);

  //           for (let j = 0; j < tagKeys.length; j++) {
  //             if (response[i]['tags'][tagKeys[j]]['tagLabel'] === 'Cab Vehicle') {
  //               cabVehCount = Object.keys(response[i]['tags'][tagKeys[j]]['vehicleList']).length;
  //             }
  //             if (response[i]['tags'][tagKeys[j]]['tagLabel'] === 'Visitor') {
  //               VisitorVehCount = Object.keys(response[i]['tags'][tagKeys[j]]['vehicleList']).length;
  //             }
  //             if (response[i]['tags'][tagKeys[j]]['tagLabel'] === 'Interview') {
  //               InterViewVehCount = Object.keys(response[i]['tags'][tagKeys[j]]['vehicleList']).length;
  //             }
  //             if (response[i]['tags'][tagKeys[j]]['tagLabel'] === 'Employee') {
  //               EmployeeVehCount = Object.keys(response[i]['tags'][tagKeys[j]]['vehicleList']).length;
  //             }
  //             if (response[i]['tags'][tagKeys[j]]['tagLabel'] === 'Vendor') {
  //               VendorVehCount = Object.keys(response[i]['tags'][tagKeys[j]]['vehicleList']).length;
  //             }
  //           }

  //           this.rearrangedReportArray.push(
  //             {
  //               a1CompanyName: companyName,
  //               a2VisitorCount: VisitorVehCount,
  //               a3VendorCount: VendorVehCount,
  //               a4ICICIBankCount: EmployeeVehCount,
  //               a5InterviewCount: InterViewVehCount,
  //               a6CabCount: cabVehCount,
  //             }
  //           );
  //         }
  //         this.progressBarVisible = false;
  //       });
  // }


  setDateFormat(model) {
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    const StrToday = Strdd + '-' + Strmm + '-' + yyyy;
    return StrToday;
  }

  filterByDate(model) {
    // this.progressBarVisible = true;
    this.companyVisitorSub.unsubscribe();
    this.rearrangedReportArray = [];
    const StrToday = this.setDateFormat(model);
    this.dateFilter.next(StrToday);
    this.getTags();
    this.loadList();
  }


  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  setBusinessDropDownList() {
    this.rearrangedReportArray = [];
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
    this.getTags();
    this.loadList();
    // this.getBusinessIdDecorativeTags(this.businessID);
  }

  setBusinessID(biz) {
    this.rearrangedReportArray = [];
    // this.progressBarVisible = true;
    // this.companyReportSub.unsubscribe();
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.getTags();
    // this.getBusinessIdDecorativeTags(this.businessID);
    this.loadList();
  }

  // // getBusinessIdDecorativeTags(bizID) {
  // //   this.tagList = this.databaseService.getDecorativeTags(bizID);
  // //   console.log(this.tagList);
  // // }

  downloadExcelReport() {
    const reportLabel = this.title + ' ' + this.dateFilter.value + ' Report';
    this.downloadService.getExcelReport(this.rearrangedReportArray, reportLabel, this.headerData);
  }

  downloadPDFReport() {
    const reportLabel = this.title + ' ' + this.dateFilter.value + ' Report';

    const options = {
      styles: {
        fontSize: 12,
        font: 'helvetica', // helvetica, times, courier
      },
      startY: true, // false (indicates margin top value) or a number
      margin: { left: 1 },
      theme: 'striped',
      fillColor: false, // false for transparent or a color as described below
      textColor: 20,
      columnWidth: 'auto', // 'auto', 'wrap' or a number
      tableWidth: 'wrap', // 'auto', 'wrap' or a number,
    };
    this.downloadService.getPDFReport(this.rearrangedReportArray, reportLabel, this.headerData, options);
  }

  stopLoader() {
    this.progressBarVisible = false;
  }

}
