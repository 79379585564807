import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { NgbDateStruct, NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { DatabaseService } from '../services/database.service';
import { DownloadService } from '../services/download.service';
import { PathSetterService } from '../services/pathSetter.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { Business } from './business.model';
import { AttendantFireStore } from '../attendant/attendant.model';

export interface Shiftdetail {
  a1shiftStart1: string;
  a2shiftEnd1: string;
  a3shiftStart2: string;
  a4shiftEnd2: string;
}

@Component({
  selector: 'app-shift-report',
  templateUrl: './shift-report.component.html',
  styleUrls: ['./shift-report.component.css']
})
export class ShiftReportComponent implements OnInit {
  title = 'Shift Report';
  afs: AngularFirestore;
  db: AngularFireDatabase;
  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: string;
  groupID: string;
  model: NgbDateStruct;
  dateFilter: BehaviorSubject<string | null>;
  StrMMYYYY: String;
  StrDate: string;
  shiftSub: Subscription;
  shiftList: Observable<any[]>;
  selectDate: Date;
  data: Business[];
  private AttendantShiftCollection: AngularFirestoreCollection<AttendantFireStore>;
  AttendantsShift: Observable<AttendantFireStore[]>;
  shiftDetails: Business[] = [];
  attshift: Shiftdetail[] = [];


  constructor(afs: AngularFirestore, db: AngularFireDatabase , private databaseService: DatabaseService,
    private downloadService: DownloadService, private pathService: PathSetterService, calendar: NgbCalendar,
    private modalService: NgbModal) {
      this.afs = afs;
      this.db = db;
      const date = new Date();
      this.model = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
      const StrToday = this.setDateFormat(this.model);
      this.dateFilter = new BehaviorSubject(StrToday);
      this.StrMMYYYY = this.setCalenderMonthYear(this.model);
      this.selectDate = this.getTimeStamp(this.model);
      this.setGroupID();
      this.StrDate = this.setCalenderdate(this.model);
      this.setBusinessDropDownList();
     }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }
  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  getTimeStamp(model) {
    const date = new Date();
    date.setMonth(model.month - 1);
    date.setFullYear(model.year);
    date.setDate(model.day);
    return date;
  }

  setBusinessID(biz) {
    this.businessName = biz.name;
    this.businessID = biz.id;
  }
  setDateFormat(model) {
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    const StrToday = Strdd + '-' + Strmm + '-' + yyyy;
    return StrToday;
  }
  setCalenderdate(model) {
    let Strdd = null;
    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const StrDate = Strdd;
    return StrDate;
  }

  setCalenderMonthYear(model) {
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    const yyyy = model.year;

    const StrToday = Strmm + '-' + yyyy;
    return StrToday;
  }

  filterByDate(model) {
    const StrToday = this.setDateFormat(model);
    this.dateFilter.next(StrToday);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    this.StrDate = this.setCalenderdate(this.model);
  }

  ngOnInit() {
    this.getShifttime();
  }

  getShifttime() {
  const shiftf = this.db.database.ref('/getParking/' + this.pathService.getPathEnvironment() + '/gpBusinesses/'
  + this.businessID);
  shiftf.once('value').then(function(snapshot) {
    console.log('jnfjs:' , snapshot.val().attShift1);
    console.log('jnfjs:' , snapshot.val().attShift2);
    // ...
  });
    // this.shiftSub = shiftf.subscribe(
    //   (response) => {
    //     // this.data = response;
    //     console.log('Shift check:' , response);
    //     this.attshift = [];
    //     this.attshift.push({
    //       a1shiftStart1: response[24],
    //       a2shiftEnd1: response[25],
    //       a3shiftStart2: response[26],
    //       a4shiftEnd2: response[27]
    //     });
    //     console.log('jnfjs:' , this.attshift);
    //   }
    // );
  }

  loadList() {

    this.AttendantShiftCollection = this.afs.collection<AttendantFireStore>
    ('/reports/' + this.groupID + '/attendants/' + this.dateFilter.value + '/daily_summary/',
     );

  this.AttendantsShift = this.AttendantShiftCollection.valueChanges();

  }
  // downloadExcelReport() { }

  // downloadPDFReport() { }

}
