import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Observable';

import { switchMap, map, take, tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean> | Promise<boolean> | boolean {

    return this.authService.user
      .pipe(
        take(1),
        map(user => {
          // user.userRole should be removed later
          // tslint:disable-next-line:no-unused-expression
          if (user.userRole.toUpperCase() === 'MANAGER', 'ADMIN' || user.dashboardRole.toUpperCase() === 'MANAGER', 'ADMIN') {
            // console.log('user role is manager , admin');
            return true;
          } else if (user.userRole.toUpperCase() === 'SUPERVISOR' || user.dashboardRole.toUpperCase() === 'SUPERVISOR') {
            // console.log('user role is supervisor');
            return true;
          } else if (user.userRole.toUpperCase() === 'OPS' || user.dashboardRole.toUpperCase() === 'OPS') {
            // console.log('user role is ops');
            return true;
          } else {
            return false;
          }
        }),
        tap(authorized => {
          if (!authorized) {
            // console.log('route prevented!');
          }
        }));
  }
}

