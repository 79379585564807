import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';

import { CapacityTags } from '../capacity-tags/capacityTags.model';
import { ReportingTags } from '../reporting-tags/reportingTags.model';
import { PaymentTags } from '../payment-tags/paymentTags.model';

import { DatabaseService } from '../../services/database.service';
import { TagEditService } from '../tagEdit.service';
import { PathSetterService } from '../../services/pathSetter.service';

import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-edit-tags-popup',
  templateUrl: './edit-tags-popup.component.html',
  styleUrls: ['./edit-tags-popup.component.css']
})
export class EditTagsPopupComponent implements OnInit {

  afdb: AngularFireDatabase;
  afs: AngularFirestore;

  tagType: string;
  capTagInfo: CapacityTags;
  repTagInfo: ReportingTags;
  payTagInfo: PaymentTags;

  groupID: string;
  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: any;

  paymentModifierDisp: string;
  paymentModifierNo: number;

  constructor(public activeModal: NgbActiveModal, private databaseService: DatabaseService, private pathService: PathSetterService,
    public snackBar: MatSnackBar, private tagEditService: TagEditService, afdb: AngularFireDatabase, afs: AngularFirestore) {
    this.afdb = afdb;
    this.afs = afs;
    this.setGroupID();
    this.setBusinessDropDownList();
    this.getPassInformation();
  }

  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  setBusinessDropDownList() {
    this.businessID = this.tagEditService.getBusinessID();
  }

  getPassInformation() {
    this.tagType = this.tagEditService.getTagType();

    if (this.tagType === 'Capacity') {
      this.capTagInfo = this.tagEditService.getCapacityTagInfo();
    }

    if (this.tagType === 'Reporting') {
      this.repTagInfo = this.tagEditService.getReportingTagInfo();
    }

    if (this.tagType === 'Payment') {

      this.payTagInfo = this.tagEditService.getPaymentTagInfo();
      this.setPaymentModifier(this.payTagInfo.amountModifierFlag);
    }
  }

  onSubmit(Formdata) {
    this.activeModal.close('Close click');

    // console.log(Formdata);

    if (this.tagType === 'Capacity') {
      this.editCapacityTags(Formdata);
    }

    if (this.tagType === 'Reporting') {
      this.editReportingTags(Formdata);
    }

    if (this.tagType === 'Payment') {
      this.editPaymentTags(Formdata);
    }


  }

  setPaymentModifier(paymentmodNo: number) {

    switch (paymentmodNo) {
      case 920: {
        this.paymentModifierDisp = 'No change to parking charge';
        this.paymentModifierNo = 920;
        break;
      }
      case 921: {
        this.paymentModifierDisp = 'Replace parking charge with amount';
        this.paymentModifierNo = 921;
        break;
      }
      case 922: {
        this.paymentModifierDisp = 'Add amount to parking charge';
        this.paymentModifierNo = 922;
        break;
      }
      case 924: {
        this.paymentModifierDisp = 'Exempt on purchase proof';
        this.paymentModifierNo = 924;
        break;
      }
      case 925: {
        this.paymentModifierDisp = 'Exempt on min purchase';
        this.paymentModifierNo = 925;
        break;
      }
      case 926: {
        this.paymentModifierDisp = 'Exempt if purchase exceeds parking charge';
        this.paymentModifierNo = 926;
        break;
      }
      default: {
        console.log('Invalid choice');
        break;
      }
    }
  }

  editPaymentTags(Formdata) {

    const PaymentTagsCollection = this.afs.collection<PaymentTags>('/groups/' + this.groupID + '/bizs/' + this.businessID +
      '/payment_tags/');

    PaymentTagsCollection.doc(this.payTagInfo.tagId)
      .update({
        exitType: Formdata.tagType,
        editAmount: Formdata.isAmountEditable,
        defaultAmount: Formdata.amt,
        editComment: Formdata.isCommentEditable,
        commentRequired: Formdata.isCommentRequired,
        defaultComment: Formdata.defComment,
        photoEnabled: Formdata.isPhotoEnabled,
        photoRequired: Formdata.isPhotoRequired,
        exitTypeLabel: Formdata.tagLabel,
        minTime: Formdata.minDur,
        maxTime: Formdata.maxDur,
        shoppingBillAmountRequired: Formdata.isShoppingBillRequired,
        amountModifierFlag: this.paymentModifierNo,
      });

      const itemRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() +
      '/Setup/' + this.businessID + '/manager_settings/custom_exit_flow/');

      itemRef.update(this.payTagInfo.tagId, {
        exitType: Formdata.tagType,
        editAmount: Formdata.isAmountEditable,
        defaultAmount: Formdata.amt,
        editComment: Formdata.isCommentEditable,
        commentRequired: Formdata.isCommentRequired,
        defaultComment: Formdata.defComment,
        photoEnabled: Formdata.isPhotoEnabled,
        photoRequired: Formdata.isPhotoRequired,
        exitTypeLabel: Formdata.tagLabel,
        minTime: Formdata.minDur,
        maxTime: Formdata.maxDur,
        shoppingBillAmountRequired: Formdata.isShoppingBillRequired,
        amountModifierFlag: this.paymentModifierNo,
      });

  }

  editReportingTags(Formdata) {

    const reportingTagsCollection = this.afs.collection<ReportingTags>('/groups/' + this.groupID + '/bizs/' + this.businessID +
      '/reporting_tags/');

    reportingTagsCollection.doc(this.repTagInfo.tagId)
      .update({
        tagLabel: Formdata.fullTitle,
        affectCounter: Formdata.CapacityModifier
      });

      const itemRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() + '/gpDecorativeTags/'
      + this.businessID + '/');
      itemRef.update(this.repTagInfo.tagId, {
        tagLabel: Formdata.fullTitle,
        affectCounter: Formdata.CapacityModifier
      });
  }

  editCapacityTags(Formdata) {
    // Firestore URL
    const capacityTagsCollection = this.afs.collection<CapacityTags>('/groups/' + this.groupID + '/bizs/' + this.businessID +
      '/corporate_tags/');
    capacityTagsCollection.doc(this.capTagInfo.gpTagId)
      .update({
        gpTagFullTitle: Formdata.fullTitle,
        gpTagTitle: Formdata.shortTitle,
        gpTagParkingCapacity: {
          '2W': Formdata.twoWhlCap,
          '4W': Formdata.fourWhlCap
        }
      });

      const itemRef = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() + '/gpTags/' + this.businessID + '/');
      itemRef.update(this.capTagInfo.gpTagId, {
        gpTagFullTitle: Formdata.fullTitle,
        gpTagTitle: Formdata.shortTitle,
        gpTagParkingCapacity: {
          '2W': Formdata.twoWhlCap,
          '4W': Formdata.fourWhlCap
        }
      });
  }

  ngOnInit() {
  }

}
