import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatSnackBar } from '@angular/material';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatabaseService } from '../../services/database.service';
import { PathSetterService } from '../../services/pathSetter.service';
import { AttendantEditService } from '../attendantEdit.service';
import { AttendantInfo } from '../attendantInfo.model';

@Component({
  selector: 'app-edit-attendant',
  templateUrl: './edit-attendant.component.html',
  styleUrls: ['./edit-attendant.component.css']
})
export class EditAttendantComponent implements OnInit {

  db: AngularFireDatabase;

  attendant: AttendantInfo;
  attendantName: string;
  locationName: string;
  locationID: string;
  phone: string;
  password: string;



  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: any;
  // editDeviceSubs: Subscription;

  constructor(public activeModal: NgbActiveModal, private databaseService: DatabaseService, db: AngularFireDatabase,
    private pathService: PathSetterService, public snackBar: MatSnackBar, private attendantEditService: AttendantEditService) {
    this.db = db;
    this.setBusinessDropDownList();
    this.getAttendantInformation();
  }

  getAttendantInformation() {
    this.attendant = this.attendantEditService.getAttendantInfo();
    this.attendantName = this.attendant.name;
    this.locationName = this.attendant.businessId;
    this.phone = this.attendant.phoneNumber;
    this.password = this.attendant.password;
    this.locationID = this.attendantEditService.getBusinessID();
  }

  setBusinessDropDownList() {
    // console.log('inside set dropdown list');
    this.businessList = this.databaseService.getBusinessList();
    // console.log(this.businessList);
    // this.businessName = this.businessList[0].name;
    // this.businessID = this.businessList[0].id;
  }

  setBusinessID() {
    // this.businessName = this.selectedValue.name;
    this.businessID = this.selectedValue;
  }

  onSubmit(Formdata) {
    this.activeModal.close('Close click');

    const attendantObj = {
      'active': this.attendant.active,
      'androidId': this.attendant.androidId,
      'businessId': Formdata.bizLocation,
      'name': Formdata.AttendantName,
      'password': Formdata.pass,
      'phoneNumber': this.attendant.phoneNumber,
      'time_stamp': this.attendant.time_stamp,
    };

    // TODO remove attendant from old place, add attendant to new one if the location is different
    if (Formdata.bizLocation !== this.locationID) {

      // Removing attendant from the old location
      const itemsRefDel = this.db.list('/getParking/' + this.pathService.getPathEnvironment() + '/operator_info/' + this.locationID);
      itemsRefDel.remove(this.phone);

      // adding attendant to the new location
      const itemRef = this.db.list('/getParking/' + this.pathService.getPathEnvironment() + '/operator_info/');
      itemRef.update(Formdata.bizLocation + '/' + this.phone, attendantObj);
      this.snackBar.open('Attendant shifted and updated', 'dismiss', {
        duration: 3000
      });
    } else {
      // Updating the exisiting attendant in the current location
      const itemRef = this.db.list('/getParking/' + this.pathService.getPathEnvironment() + '/operator_info/');
      itemRef.update(Formdata.bizLocation + '/' + this.phone, attendantObj);
      this.snackBar.open('Attendant updated', 'dismiss', {
        duration: 3000
      });

    }
  }

  ngOnInit() {
  }

}
