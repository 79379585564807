import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { NgModel } from '@angular/forms';
import { NgbCalendar, NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import * as firebase from 'firebase/app';
import * as moment from 'moment';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../core/auth.service';
import { DatabaseService } from '../services/database.service';
import { DownloadService } from '../services/download.service';
import { PathSetterService } from '../services/pathSetter.service';
import { User } from '../services/user.model';
import { Vehicle } from './vehicle.model';


// const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
//   one && two && two.year === one.year && two.month === one.month && two.day === one.day;

// const before = (one: NgbDateStruct, two: NgbDateStruct) =>
//   !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
//     ? false : one.day < two.day : one.month < two.month : one.year < two.year;

// const after = (one: NgbDateStruct, two: NgbDateStruct) =>
//   !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
//     ? false : one.day > two.day : one.month > two.month : one.year > two.year;

const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

export interface Report {
  a1BusinessId: string;
  a2VehicleType: string;
  a3NumberPlate: string;
  a4NumberPlateDigits: string;
  a5TicketId: string;
  a6EnteredBy: string;
  a7ParkingPrepaidFare;
  a8EntryTime: string;
  a9EntryDate: string;
  a10ExitedBy: string;
  a11ParkingBalanceFare;
  a12ExitTime: string;
  a13ExitDate: string;
  a14ModifiedBy: string;
  a15ModifiedOn: string;
  a16Status: string;
  a17ParkingDuration: any;
  a18RecordType: string;
  a19ParkingTotalFare;
  a20DeleteCollection;
  a21LostTicketCharge;
  a22TagName: string;
  a23CompanyTagname: string;
  a24ParkingSlotNo: string;
  a25ParkingUnitNo: string;
  a26TicketReprintCount: number;
  a27TicketRefundAmount: number;
  a28imageUrlIn: string;
  a29imageUrlOut: string;
  a30imageUrlCustomExit: string;
  a31BookingFare: number;
  a32customExitType: string;
  a33Driver: string;
  a34entryLane: string;
  a35exitLane: string;
  a36paymentType: number;
  a37UhfTag: string;
  // a33EndEpocdate: number;
  // a34StartEpocdate: number;
}

export interface Report1 {
  a2VehicleType: string;
  a3NumberPlate: string;
  a5TicketId: string;
  a6EnteredBy: string;
  a8EntryTime: string;
  a9EntryDate: string; 
  a10ExitedBy: string; 
  a11ParkingBalanceFare; 
  a12ExitTime: string; 
  a13ExitDate: string;
  a16Status: string;
  a17ParkingDuration: any;
  // a18RecordType: string;
  a19ParkingTotalFare; 
  a20DeleteCollection;
  a21LostTicketCharge; 
  a22TagName: string;
  a23CompanyTagname: string;
  a27TicketRefundAmount: number;
  // a28imageUrlIn: string;
  // a29imageUrlOut: string;
  a32customExitType: string;
  a34entryLane: string;
  a35exitLane: string;
  a36paymentType: number;
  a37UhfTag: string;
 // a37vrFirebaseId: string;
} 

export interface CompanyName {
  comtagName: string;
}
@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.css']
})

export class VehicleComponent implements OnInit, OnDestroy {
  // @ViewChild('d') datePicker: NgbInputDatepicker;
  // @ViewChild('myRangeInput') myRangeInput: ElementRef;

  @ViewChild('d') input: NgbInputDatepicker;
  @ViewChild(NgModel) datePick: NgModel;
  @ViewChild('myRangeInput') myRangeInput: ElementRef;


  db: AngularFirestore;
  afdb: AngularFireDatabase;
  title = 'Vehicle Details';

  public searchText: string;


  data: Vehicle[];
  headerData: string[] = [
    'Location', 'Vehicle Type', 'Numberplate', 'Numberplate Digits', 'Ticket ID', 'Entered By', 'Entry Collection',
    'Entry Time', 'Entry Date', 'Exited By', 'Exit Collection', 'Exit Time', 'Exit Date', 'Edited By', 'Edit Time', 'Status',
    'Parking Duration', 'Record Type', 'Total Collection', 'Delete Collection', 'Lost Ticket Charge', 'Tag Name', 'Company Tag Name',
    'Parking Slot No', 'Parking Unit No', 'Ticket Reprint Count', 'Ticket Refund Amount', 'Image URL In', 'Image URL Out',
    'Custom Exit URL', 'Booking Fare', 'Exit Tag', 'Phone Number'
  ];

  headerData1: string[] = [
     'Vehicle Type', 'Numberplate','FASTAG Id', 'Ticket ID', 'Entered By','Entry Time', 'Entry Date', 'Exited By', 'Exit Collection', 'Exit Time', 'Exit Date', 'Status',
    'Parking Duration', 'Total Collection', 'Delete Collection', 'Lost Ticket Charge', 'Tag Name', 'Company Tag Name','Ticket Refund Amount','Exit Tag', 'Entry Lane',
    'Exit Lane', 'Payment Type'
  ];

  vehicleReportSubs: Subscription;
  CompanyListSub: Subscription;
  groupID: string;
  businessList: any[];
  businessID: string;
  businessName: string;
  selectedValue: string;
  selectedCompanyName: string;
  progressBarVisible = false;
  isArya: boolean;

  exitTime: any;
  exitDate: any;

  rearrangedReportArray: Report[] = [];
  rearrangedReportArray1: Report1[] = [];

  hoveredDate: NgbDateStruct;
  fromDate: any;
  toDate: any;

  fromEpoc: number;
  toEpoc: number;

  user: User;
  userSub: Subscription;

  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  startDate: NgbDateStruct;
  model: any;
  dateFilterFrom: BehaviorSubject<number | null>;
  dateFilterTo: BehaviorSubject<number | null>;
  companyFilter: BehaviorSubject<string | null>;
  p = 1;
  private vehicleCollection: AngularFirestoreCollection<Vehicle>;

  vehicles2: Observable<Vehicle[]>;
  vehicles1: Observable<Vehicle[]>;
  compList: Observable<any[]>;
  tagList: CompanyName[] = [];
  tagData: any[] = [];
  overnight: number;
  status: string;


  constructor(db: AngularFirestore, private databaseService: DatabaseService, private downloadService: DownloadService,
    private pathService: PathSetterService, calendar: NgbCalendar, afdb: AngularFireDatabase, public authService: AuthService,
    element: ElementRef, private renderer: Renderer2, private _parserFormatter: NgbDateParserFormatter) {
    this.afdb = afdb;
    this.db = db;
    this.status = 'IN';
    this.dateFilterFrom = new BehaviorSubject(new Date().getTime());
    this.dateFilterTo = new BehaviorSubject(new Date().getTime());
    this.companyFilter = new BehaviorSubject('');
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
    this.startDate = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
    this.maxDate = { year: now.getFullYear() + 1, month: now.getMonth() + 1, day: now.getDate() };
    this.minDate = { year: now.getFullYear() - 1, month: now.getMonth() + 1, day: now.getDate() };
    this.isArya = pathService.getAryaFeatures();
    this.setBusinessDropDownList();
    this.setGroupID();
  }

  ngOnInit() {
    this.userSub = this.authService.user.pipe(
      take(1)
    ).subscribe(user => this.user = user);
    // console.log('Setting User in dashbord component: ' + this.user.userRole);

    this.loadList1();
  }

  canEdit() {
    if (this.authService.canEdit(this.user)) {
      return true;
    } else {
      return false;
    }
  }

  canDelete() {
    if (this.authService.canDelete(this.user)) {
      return true;
    } else {
      return false;
    }
  }

  // ngOnInit() {
  //   this.loadList1();
  //   // this.generateOvernight();
  // }
  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  getEpochDayFromTimestamp(epochTimestamp: number) {
    const today = new Date(epochTimestamp);
    today.setHours(15);
    const todayEpochDay = Math.floor((today.getTime() + 330 * 60 * 1000) / (1000 * 60 * 60 * 24));
    return todayEpochDay;
  }

  getDateFromEpochDay(epochDay: number) {
    const resultDate = new Date(epochDay * (1000 * 60 * 60 * 24));
   // console.log('Input', epochDay, 'Output', resultDate.toString());
    return resultDate;
  }


  loadList1() {
    const today = new Date();
    today.setHours(15);
    const todayEpochDay = Math.floor((today.getTime() + 330 * 60 * 1000) / (1000 * 60 * 60 * 24));
   
    this.vehicles2 = combineLatest([
      this.dateFilterFrom,
      this.dateFilterTo,
      this.companyFilter]
    ).pipe(
      switchMap(([filterFromDate, filterToDate, comtagName]) => {
        const fromEpochDay = this.getEpochDayFromTimestamp(filterFromDate);
        const toEpochDay = this.getEpochDayFromTimestamp(filterToDate);
    
        return this.db.collectionGroup<Vehicle>('records', ref => {
          let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
    
          if (fromEpochDay) {
            query = query.where('vrStartEpochDay', '>=', fromEpochDay)
              .where('vrStartEpochDay', '<=', toEpochDay)
              .orderBy('vrStartEpochDay', 'desc');
          }
    
          if (comtagName) {
            query = query.where('vrTagName', '==', comtagName);
          }
    
          query = query.where('vrBusinessId', '==', this.businessID);
          return query;
        }).valueChanges().pipe(
          // In-memory filtering to ensure that vrEndEpochDay is within range
          map((vehicles: Vehicle[]) => {
            return vehicles.filter(vehicle => {
              return vehicle.vrStartEpochDay >= fromEpochDay && vehicle.vrStartEpochDay <= toEpochDay;
            });
          })
        );
      })
    );

    
    this.vehicles1 = combineLatest([
      this.dateFilterFrom,
      this.dateFilterTo,
      this.companyFilter]
    ).pipe(
      switchMap(([filterFromDate, filterToDate, comtagName]) => {
        const fromEpochDay = this.getEpochDayFromTimestamp(filterFromDate);
        const toEpochDay = this.getEpochDayFromTimestamp(filterToDate);
    
        return this.db.collectionGroup<Vehicle>('records', ref => {
          let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
    
          if (fromEpochDay) {
            query = query.where('vrEndEpochDay', '>=', fromEpochDay)
              .where('vrEndEpochDay', '<=', toEpochDay)
              .orderBy('vrEndEpochDay', 'desc');
          }
    
          if (comtagName) {
            query = query.where('vrTagName', '==', comtagName);
          }
    
          query = query.where('vrBusinessId', '==', this.businessID);
          return query;
        }).valueChanges().pipe(
          // In-memory filtering to ensure that vrEndEpochDay is within range
          map((vehicles: Vehicle[]) => {
            return vehicles.filter(vehicle => {
              return vehicle.vrEndEpochDay >= fromEpochDay && vehicle.vrEndEpochDay <= toEpochDay;
            });
          })
        );
      })
    );

    const tempArray = [this.vehicles2, this.vehicles1];
   // console.log('Temp Array', tempArray);

    // tempArray = tempArray.filter((el, i, a) => i === a.indexOf(el));
    // console.log('New Aarray : ', tempArray);

    const allVehicles = combineLatest(tempArray)
      .pipe(
        map(voArray => voArray.reduce((acc, curr) =>
          [...acc, ...curr], []))
      );

    this.vehicleReportSubs = allVehicles
      .pipe(
        // take(1),
        map(
          (response) => {
            // console.log('fsdkl', response);
            const vehicleList: Vehicle[] = response;

            for (const vehicleItem of vehicleList) {
              if (!vehicleItem.vrModifiedBy) { vehicleItem.vrModifiedBy = 'N/A'; }
              if (!vehicleItem.vrCompanyTagname || vehicleItem.vrCompanyTagname === 'regular_park') {
                vehicleItem.vrCompanyTagname = 'N/A';
              }
              // if (!vehicleItem.vrExitedByName) { vehicleItem.vrExitedByName = 'N/A'; }
              if (!vehicleItem.vrParkingSlotNo) { vehicleItem.vrParkingSlotNo = 'N/A'; }
              if (!vehicleItem.customExitType) { vehicleItem.customExitType = 'N/A'; }
              if (!vehicleItem.vrParkingUnitNo) { vehicleItem.vrParkingUnitNo = 'N/A'; }
              if (!vehicleItem.vrTagName) { vehicleItem.vrTagName = 'N/A'; }
              for (let k = 0; k < this.businessList.length; k++) {
                if (vehicleItem.vrBusinessId === this.businessList[k].id) {
                  vehicleItem.vrBusinessId = this.businessList[k].name;
                }
              }
            }
            return vehicleList;
          }))
      .subscribe(
        (response) => {
          this.data = response;
          this.rearrangedReportArray = [];
          this.rearrangedReportArray1 = [];

          const uniqHashMap = {};
          for (let i = 0; i < this.data.length; i++) {
            const reportItem = this.data[i];

            // tslint:disable-next-line:curly
            if (reportItem.vrFirebaseId in uniqHashMap) continue;
            uniqHashMap[reportItem.vrFirebaseId] = true;

            if (reportItem.vrParkingStatus === 'IN') {
              reportItem.vrParkingEndTimeL = 'Not Exited';
            }
            const fromEpochDay = this.getEpochDayFromTimestamp(this.dateFilterFrom.getValue());
            const toEpochDay = this.getEpochDayFromTimestamp(this.dateFilterTo.getValue());

            if (reportItem.vrNumberPlate === '4577') {
              // console.log('fromEpochDay:', fromEpochDay);
              // console.log('toEpochDay:', toEpochDay);
              // console.log('vrStartEpochDay:', reportItem.vrStartEpochDay);
              // console.log('vrEndEpochDay:', reportItem.vrEndEpochDay);
            }
            let collection: number;
            if (reportItem.vrStartEpochDay < fromEpochDay) {
              collection = reportItem.vrParkingBalanceFare + reportItem.vrLostTicketCharge;

            } else if (reportItem.vrEndEpochDay > toEpochDay) {
              collection = reportItem.vrParkingPrepaidFare;

            } else {

              collection = reportItem.vrParkingTotalFare;
            }

            let paymentType: any;
            if(reportItem.vrExitPaymentType === 0){
              paymentType = 'CASH';
            } else if (reportItem.vrExitPaymentType === 1){
              paymentType = 'SMART CARD';
            } else if (reportItem.vrExitPaymentType === 2){
              paymentType = 'UPI';
            } else if (reportItem.vrExitPaymentType === 3) {
              paymentType = 'DEBIT/CREDIT CARD';
            } else if (reportItem.vrExitPaymentType === 4) {
              paymentType = 'FASTAG';
            }else{
              paymentType = 'N/A';
            }
           // console.log('PaymentType:' + paymentType);

            const parkingDuration = reportItem.vrParkingDuration;
            // tslint:disable-next-line:no-bitwise
            const min = Math.floor((parkingDuration / 1000 / 60) << 0);
            const sec = Math.floor((parkingDuration / 1000) % 60);
            const formattedParkingDuration = min + ' mins ' + sec + 'seconds';
            
            this.rearrangedReportArray.push(
              {
              a1BusinessId: reportItem.vrBusinessId,
              a2VehicleType: reportItem.vrVehicleType,
              a3NumberPlate: reportItem.vrNumberPlate,
              a4NumberPlateDigits: reportItem.vrNumberPlateDigits,
              a5TicketId: reportItem.vrTicketId,
              a6EnteredBy: reportItem.vrEnteredByName,
              a7ParkingPrepaidFare: reportItem.vrParkingPrepaidFare,
              a8EntryTime: moment(new Date(reportItem.vrParkingStartTimeL)).format('h:mm:ss A'),
              // a9EntryDate: new Date(reportItem.vrParkingStartTimeL).toLocaleDateString(),
              a9EntryDate: moment(new Date(reportItem.vrParkingStartTimeL)).format('DD/MM/YYYY'),
              a10ExitedBy: reportItem.vrExitedByName,
              a11ParkingBalanceFare: reportItem.vrParkingBalanceFare,
              a12ExitTime: moment(new Date(reportItem.vrParkingEndTimeL)).format('h:mm:ss A'),
              a13ExitDate: moment(new Date(reportItem.vrParkingEndTimeL)).format('DD/MM/YYYY'),
              a14ModifiedBy: reportItem.vrModifiedBy, // parkingModificationTimeStamp
              a15ModifiedOn: new Date(reportItem.parkingModificationTimeStamp).toLocaleString(),
              a16Status: reportItem.vrParkingStatus,
              a17ParkingDuration: formattedParkingDuration,
              a18RecordType: reportItem.vrRecordType,
              // a19ParkingTotalFare: reportItem.vrParkingTotalFare,
              a19ParkingTotalFare: collection,
              a20DeleteCollection: reportItem.vrDeleteCollection,
              a21LostTicketCharge: reportItem.vrLostTicketCharge,
              a22TagName: reportItem.vrTagName,
              a23CompanyTagname: reportItem.vrCompanyTagname,
              a24ParkingSlotNo: reportItem.vrParkingSlotNo,
              a25ParkingUnitNo: reportItem.vrParkingUnitNo,
              a26TicketReprintCount: reportItem.vrTicketReprintCount,
              a27TicketRefundAmount: reportItem.vrRefundAmount,
              a28imageUrlIn: (reportItem.imageUrlIn === undefined || reportItem.imageUrlIn === null) ? '' : reportItem.imageUrlIn,
              a29imageUrlOut: (reportItem.imageUrlOut === undefined || reportItem.imageUrlOut === null) ? '' : reportItem.imageUrlOut,
              a30imageUrlCustomExit: reportItem.imageUrlCustomExit,
              a31BookingFare: reportItem.vrBookingFare,
              a32customExitType: reportItem.customExitType,
              a33Driver: reportItem.driverPhoneNumber,
              a34entryLane: reportItem.vrEntryLaneNo,
              a35exitLane: reportItem.vrExitLaneNo,
              a36paymentType: paymentType,
              a37UhfTag: reportItem.vrUhfTag,
            });

            this.rearrangedReportArray1.push({
              a2VehicleType: reportItem.vrVehicleType,
              a3NumberPlate: reportItem.vrNumberPlate,
              a37UhfTag: reportItem.vrUhfTag,
              a5TicketId: reportItem.vrTicketId,
              a6EnteredBy: reportItem.vrEnteredByName,
              a8EntryTime: moment(new Date(reportItem.vrParkingStartTimeL)).format('h:mm:ss A'),
              a9EntryDate: moment(new Date(reportItem.vrParkingStartTimeL)).format('DD/MM/YYYY'),
              a10ExitedBy: reportItem.vrExitedByName,
              a11ParkingBalanceFare: reportItem.vrParkingBalanceFare,
              a12ExitTime: moment(new Date(reportItem.vrParkingEndTimeL)).format('h:mm:ss A'),
              a13ExitDate: moment(new Date(reportItem.vrParkingEndTimeL)).format('DD/MM/YYYY'),
              a16Status: reportItem.vrParkingStatus,
              a17ParkingDuration: formattedParkingDuration,
             // a18RecordType: reportItem.vrRecordType,
              a19ParkingTotalFare: collection,
              a20DeleteCollection: reportItem.vrDeleteCollection,
              a21LostTicketCharge: reportItem.vrLostTicketCharge,
              a22TagName: reportItem.vrTagName,
              a23CompanyTagname: reportItem.vrCompanyTagname,
              a27TicketRefundAmount: reportItem.vrRefundAmount,
              a32customExitType: reportItem.customExitType,
              a34entryLane: reportItem.vrEntryLaneNo,
              a35exitLane: reportItem.vrExitLaneNo,
              a36paymentType: paymentType,
              
            //  a37vrFirebaseId: "'" + reportItem.vrFirebaseId
             // a36paymentType: reportItem.vrExitPaymentType
              // a28imageUrlIn: reportItem.imageUrlIn,
              // a29imageUrlOut: reportItem.imageUrlOut,
              
            });
            // console.log('AD-->'+ this.rearrangedReportArray1);
            this.progressBarVisible = false;
            // console.log('Vehicle Report item: ', this.data);

          }
        },
        (error) => { console.log(error); }
      );
  }

  // loadList() {
  //   this.vehicles = combineLatest([
  //     this.dateFilterFrom,
  //     this.dateFilterTo,
  //     this.companyFilter]
  //   ).pipe(
  //     switchMap(([filterFromDate, filterToDate, comtagName]) =>
  //       this.db.collectionGroup<Vehicle>('records', ref => {
  //         let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
  //         if (filterFromDate) {
  //            console.log('check dates:', filterFromDate, filterToDate);
  //           query = query.where('vrParkingStartTimeL', '>=', filterFromDate)
  //             .orderBy('vrParkingStartTimeL', 'desc');
  //         }
  //         // query = query.where('vrParkingStartTimeL', '<=', filterToDate).where('vrParkingStatus' , '==' , 'IN');
  //         if (filterToDate) { query = query.where('vrParkingStartTimeL', '<=', filterToDate); }
  //         if (comtagName) { query = query.where('vrTagName', '==', comtagName); }
  //         query = query.where('vrBusinessId', '==', this.businessID);
  //         return query;
  //       }).valueChanges()
  //     )
  //   );

  //   this.vehicleReportSubs = this.vehicles
  //     .pipe(
  //       take(1),
  //       map(
  //         (response) => {
  //           const vehicleList: Vehicle[] = response;
  //           for (const vehicleItem of vehicleList) {
  //             if (!vehicleItem.vrModifiedBy) { vehicleItem.vrModifiedBy = 'N/A'; }
  //             if (!vehicleItem.vrCompanyTagname || vehicleItem.vrCompanyTagname === 'regular_park') {
  //               vehicleItem.vrCompanyTagname = 'N/A';
  //             }
  //             // if (!vehicleItem.vrExitedByName) { vehicleItem.vrExitedByName = 'N/A'; }
  //             if (!vehicleItem.vrParkingSlotNo) { vehicleItem.vrParkingSlotNo = 'N/A'; }
  //             if (!vehicleItem.customExitType) { vehicleItem.customExitType = 'N/A'; }
  //             if (!vehicleItem.vrParkingUnitNo) { vehicleItem.vrParkingUnitNo = 'N/A'; }
  //             if (!vehicleItem.vrTagName) { vehicleItem.vrTagName = 'N/A'; }
  //             for (let k = 0; k < this.businessList.length; k++) {
  //               if (vehicleItem.vrBusinessId === this.businessList[k].id) {
  //                  vehicleItem.vrBusinessId = this.businessList[k].name;
  //               }
  //             }
  //           }
  //           return vehicleList;
  //         }))
  //     .subscribe(
  //       (response) => {
  //         this.data = response;
  //         this.rearrangedReportArray = [];
  //         for (let i = 0; i < this.data.length; i++) {
  //           const reportItem = this.data[i];

  //           if (reportItem.vrParkingStatus === 'IN') {
  //             reportItem.vrParkingEndTimeL = 'NA';
  //           }

  //           const parkingDuration = reportItem.vrParkingDuration;
  //           // tslint:disable-next-line:no-bitwise
  //           const min = Math.floor((parkingDuration / 1000 / 60) << 0);
  //           const sec = Math.floor((parkingDuration / 1000) % 60);
  //           const formattedParkingDuration = min + ' mins ' + sec + 'seconds';

  //           this.rearrangedReportArray.push({
  //             a1BusinessId: reportItem.vrBusinessId,
  //             a2VehicleType: reportItem.vrVehicleType,
  //             a3NumberPlate: reportItem.vrNumberPlate,
  //             a4NumberPlateDigits: reportItem.vrNumberPlateDigits,
  //             a5TicketId: reportItem.vrTicketId,
  //             a6EnteredBy: reportItem.vrEnteredByName,
  //             a7ParkingPrepaidFare: reportItem.vrParkingPrepaidFare,
  //             a8EntryTime: new Date(reportItem.vrParkingStartTimeL).toLocaleTimeString(),
  //             a9EntryDate: new Date(reportItem.vrParkingStartTimeL).toLocaleDateString(),
  //             a10ExitedBy: reportItem.vrExitedByName,
  //             a11ParkingBalanceFare: reportItem.vrParkingBalanceFare,
  //             a12ExitTime: new Date(reportItem.vrParkingEndTimeL).toLocaleTimeString(),
  //             a13ExitDate: new Date(reportItem.vrParkingEndTimeL).toLocaleDateString(),
  //             a14ModifiedBy: reportItem.vrModifiedBy,
  //             a15ModifiedOn: new Date(reportItem.parkingModificationTimeStamp).toLocaleString(),
  //             a16Status: reportItem.vrParkingStatus,
  //             a17ParkingDuration: formattedParkingDuration,
  //             a18RecordType: reportItem.vrRecordType,
  //             a19ParkingTotalFare: reportItem.vrParkingTotalFare,
  //             a20DeleteCollection: reportItem.vrDeleteCollection,
  //             a21LostTicketCharge: reportItem.vrLostTicketCharge,
  //             a22TagName: reportItem.vrTagName,
  //             a23CompanyTagname: reportItem.vrCompanyTagname,
  //             a24ParkingSlotNo: reportItem.vrParkingSlotNo,
  //             a25ParkingUnitNo: reportItem.vrParkingUnitNo,
  //             a26TicketReprintCount: reportItem.vrTicketReprintCount,
  //             a27TicketRefundAmount: reportItem.vrRefundAmount,
  //             a28imageUrlIn: reportItem.imageUrlIn,
  //             a29imageUrlOut: reportItem.imageUrlOut,
  //             a30imageUrlCustomExit: reportItem.imageUrlCustomExit,
  //             a31BookingFare: reportItem.vrBookingFare,
  //             a32customExitType: reportItem.customExitType,
  //           });
  //           this.progressBarVisible = false;
  //           // console.log('Vehicle Report item: ', this.data);
  //         }
  //       },
  //       (error) => { console.log(error); }
  //     );
  // }

  // generateOvernight() {
  //   this.vehicles = combineLatest([
  //    this.dateFilterTo,
  //    this.dateFilterFrom]
  //   ).pipe(
  //     // tslint:disable-next-line: deprecation
  //     switchMap(([ filterToDate]) =>
  //       this.db.collectionGroup<Vehicle>('records', ref => {
  //         let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
  //        query = query.where('vrParkingStartTimeL', '<=', filterToDate).where('vrParkingStatus' , '==' , 'IN');
  //         return query;
  //       }).valueChanges()));
  //     this.vehicleReportSubs = this.vehicles.subscribe(
  //       (response) => {
  //         this.overnight = response.length;
  //         console.log('OVERNIGHT' , this.overnight);
  //       });
  // }

  stopLoader() {
    this.progressBarVisible = false;
  }


  setCompanyTagsList(bizID) {
    if (bizID !== undefined) {

      this.compList = this.afdb.list('/getParking/' + this.pathService.getPathEnvironment() + '/gpTags/' + this.businessID).valueChanges();

      this.CompanyListSub = this.compList.subscribe((response) => {
        this.tagData = response;
        this.tagList = [{ comtagName: 'default' }];
        for (let i = 0; i < this.tagData.length; i++) {
          let gpTagFullTitle = '';
          gpTagFullTitle = this.tagData[i]['gpTagFullTitle'];
          this.tagList.push({
            comtagName: gpTagFullTitle,
          });
        }
        // console.log(this.tagList);
      });
    }
  }

  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
    this.setCompanyTagsList(this.businessID);
  }

  setBusinessID(biz) {
    this.progressBarVisible = true;
    this.vehicleReportSubs.unsubscribe();
    this.CompanyListSub.unsubscribe();
    this.tagList = [];
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.setCompanyTagsList(this.businessID);
    // this.loadList();
    this.loadList1();
  }

  setCompanyTags(companyName) {
    this.progressBarVisible = true;
    this.vehicleReportSubs.unsubscribe();
    if (companyName === 'default') {
      this.companyFilter.next('');
    } else {
      this.companyFilter.next(companyName);
    }
    // this.loadList();
  }

  downloadExcelReport() {
    const reportLabel = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilterFrom.value).toLocaleDateString()
      + ' to ' + new Date(this.dateFilterTo.value).toLocaleDateString() + ' Report';
    this.downloadService.getExcelReport(this.rearrangedReportArray, reportLabel, this.headerData);
  }

  downloadPDFReport() {
    const reportLabel = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilterFrom.value).toLocaleDateString()
      + ' to ' + new Date(this.dateFilterTo.value).toLocaleDateString() + ' Report';
    const options = {
      styles: {
        fontSize: 1.5,
        font: 'helvetica', // helvetica, times, courier
      },
      startY: true, // false (indicates margin top value) or a number
      margin: { left: 1 },
      theme: 'striped',
      fillColor: false, // false for transparent or a color as described below
      textColor: 20,
      columnWidth: 'auto', // 'auto', 'wrap' or a number
      tableWidth: 'wrap', // 'auto', 'wrap' or a number,
    };
    this.downloadService.getPDFReport(this.rearrangedReportArray, reportLabel, this.headerData, options);
  }

  downloadExcelReport1() {
    const reportLabel1 = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilterFrom.value).toLocaleDateString()
      + ' to ' + new Date(this.dateFilterTo.value).toLocaleDateString() + ' Report';
    this.downloadService.getExcelReport1(this.rearrangedReportArray1, reportLabel1, this.headerData1);
  }

  // downloadPDFReport1() {
  //   const reportLabel1 = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilterFrom.value).toLocaleDateString()
  //     + ' to ' + new Date(this.dateFilterTo.value).toLocaleDateString() + ' Report';
  //   const options = {
  //     styles: {
  //       fontSize: 1.5,
  //       font: 'helvetica', // helvetica, times, courier
  //     },
  //     startY: true, // false (indicates margin top value) or a number
  //     margin: { left: 1 },
  //     theme: 'striped',
  //     fillColor: false, // false for transparent or a color as described below
  //     textColor: 20,
  //     columnWidth: 'auto', // 'auto', 'wrap' or a number
  //     tableWidth: 'wrap', // 'auto', 'wrap' or a number,
  //   };
  //   this.downloadService.getPDFReport1(this.rearrangedReportArray1, reportLabel1, this.headerData, options);
  // }


  onDateSelection(date: NgbDateStruct) {
    let parsed = '';
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
      // this.model = `${this.fromDate.year} - ${this.toDate.year}`;
      this.input.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.fromDate != null) {
      parsed += this._parserFormatter.format(this.fromDate);
      const newDateFrom = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      // newDateFrom.setHours(0, 0, 0);
      this.dateFilterFrom.next(newDateFrom.getTime());
    }
    if (this.toDate != null) {
      parsed += ' / ' + this._parserFormatter.format(this.toDate);
      // tslint:disable-next-line:no-shadowed-variable
      const newDateTo = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
      // newDateTo.setHours(23, 59, 59);
      this.dateFilterTo.next(newDateTo.getTime());
    } else {
      const newDateTo = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      // newDateTo.setHours(23, 59, 59);
      this.dateFilterTo.next(newDateTo.getTime());
    }
    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);
    // const newDateTo = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    //      newDateTo.setHours(23, 59, 59);
    //     this.dateFilterTo.next(newDateTo.getTime());
  }

  ngOnDestroy() {
    this.vehicleReportSubs.unsubscribe();
    this.CompanyListSub.unsubscribe();
  }

  isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);
}
