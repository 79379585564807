import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddCapacityTagComponent } from './add-capacity-tag/add-capacity-tag.component';
import { AddDevicesComponent } from './add-devices/add-devices.component';
import { AddPassesComponent } from './add-passes/add-passes.component';
import { AttendantManagementComponent } from './attendant-management/attendant-management.component';
import { AttendantReportComponent } from './attendant-report/attendant-report.component';
import { AttendantComponent } from './attendant/attendant.component';
import { BusinessSetupComponent } from './business-setup/business-setup.component';
import { CameraDetailsComponent } from './camera-details/camera-details.component';
import { CameraComponent } from './camera/camera.component';
import { CityPassesComponent } from './city-passes/city-passes.component';
import { CreatedCityPassesComponent } from './city-passes/created-city-passes/created-city-passes.component';
import { ExpiredCityPassesComponent } from './city-passes/expired-city-passes/expired-city-passes.component';
import { ValidCityPassesComponent } from './city-passes/valid-city-passes/valid-city-passes.component';
import { CompanysummaryComponent } from './company-summary/company-summary.component';
import { AuthGuard } from './core/auth.guard';
import { CanReadGuard } from './core/can-read.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DaypassesComponent } from './daypasses/daypasses.component';
import { DemoCollectionComponent } from './demo-collection/demo-collection.component';
import { DevicesComponent } from './devices/devices.component';
import { EodComponent } from './eod/eod.component';
import { GraphsComponent } from './graphs/graphs.component';
import { GroupSetupComponent } from './group-setup/group-setup.component';
import { HourlyReportComponent } from './hourly-report/hourly-report.component';
import { LoginComponent } from './login/login.component';
import { LoopCountsComponent } from './loop-counts/loop-counts.component';
import { OvernightComponent } from './overnight/overnight.component';
import { CreatedPassesComponent } from './passes/created-passes/created-passes.component';
import { ExpiredPassesComponent } from './passes/expired-passes/expired-passes.component';
import { PassesComponent } from './passes/passes.component';
import { RfidComponent } from './passes/rfid/rfid.component';
import { ValidPassesComponent } from './passes/valid-passes/valid-passes.component';
import { RtoListComponent } from './rto-list/rto-list.component';
import { RtoComponent } from './rto/rto.component';
import { ShiftReportComponent } from './shift-report/shift-report.component';
import { SmsReportComponent } from './sms-report/sms-report.component';
import { CapacityTagsComponent } from './tags/capacity-tags/capacity-tags.component';
import { PaymentTagsComponent } from './tags/payment-tags/payment-tags.component';
import { ReportingTagsComponent } from './tags/reporting-tags/reporting-tags.component';
import { TagsComponent } from './tags/tags.component';
import { TransactionReportComponent } from './transaction-report/transaction-report.component';
import { VehicleReportComponent } from './vehicle-report/vehicle-report.component';
import { VehicleComponent } from './vehicles/vehicle.component';
import { VisitorSummaryComponent } from './visitor-summary/visitor-summary.component';
import { DwellComponent } from './dwell/dwell.component';



const appRoutes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },

  {
    path: 'passes', component: PassesComponent, canActivate: [AuthGuard],
    children: [
      { path: 'created-passes', component: CreatedPassesComponent },
      { path: 'valid-passes', component: ValidPassesComponent },
      { path: 'expired-passes', component: ExpiredPassesComponent },
      { path: 'rfid', component:RfidComponent },
      { path: 'add-passes', component: AddPassesComponent, canActivate: [CanReadGuard] }
    ]
  },
  {
    path: 'tags', component: TagsComponent, canActivate: [AuthGuard],
    children: [
      { path: 'capacity-tags', component: CapacityTagsComponent },
      { path: 'reporting-tags', component: ReportingTagsComponent },
      { path: 'payment-tags', component: PaymentTagsComponent },
      { path: 'add-capacity-tag', component: AddCapacityTagComponent, canActivate: [CanReadGuard]}
    ]
  },

  { path: 'attendant-management', component: AttendantManagementComponent, canActivate: [AuthGuard] },
  { path: 'attendant-details', component: AttendantComponent, canActivate: [AuthGuard] },
  {path: 'attendant-summary', component: AttendantReportComponent, canActivate: [AuthGuard] },
  {path: 'graphs', component: GraphsComponent, canActivate: [AuthGuard] },
  {path: 'shifts', component: ShiftReportComponent, canActivate: [AuthGuard] },
  {path: 'rto', component: RtoComponent, canActivate: [AuthGuard]},
  {path: 'eod', component: EodComponent, canActivate: [AuthGuard]},
  {path: 'rto-list', component: RtoListComponent, canActivate: [AuthGuard]},
  {path: 'dwell', component: DwellComponent, canActivate: [AuthGuard]},

  // Only for Arya
  { path: 'devices', component: DevicesComponent, canActivate: [AuthGuard] },
  { path: 'add-device', component: AddDevicesComponent, canActivate: [CanReadGuard] },
  { path: 'loop', component: LoopCountsComponent, canActivate: [AuthGuard]},

  {
    path: 'city-passes', component: CityPassesComponent, canActivate: [AuthGuard],
    children: [
      { path: 'created-city-passes', component: CreatedCityPassesComponent },
      { path: 'valid-city-passes', component: ValidCityPassesComponent },
      { path: 'expired-city-Passes', component: ExpiredCityPassesComponent }
    ]
  },
  { path: 'daypasses', component: DaypassesComponent, canActivate: [AuthGuard] },
  { path: 'ocr', component: CameraDetailsComponent, canActivate: [AuthGuard] },

  { path: 'transactions', component: TransactionReportComponent, canActivate: [AuthGuard] },
  { path: 'sms', component: SmsReportComponent, canActivate: [AuthGuard]},

  // Only for OC
  { path: 'company-summary', component: CompanysummaryComponent, canActivate: [AuthGuard] },
  { path: 'visitor-summary', component: VisitorSummaryComponent, canActivate: [AuthGuard] },
  { path: 'vehicle-summary', component: VehicleReportComponent, canActivate: [AuthGuard] },
  {path: 'hourly-summary', component: HourlyReportComponent, canActivate: [AuthGuard] },
  { path: 'vehicles', component: VehicleComponent, canActivate: [AuthGuard] },
  // {path: 'daily-collection', component: DailyCollectionComponent, canActivate: [AuthGuard] },
  {path: 'daily', component: DemoCollectionComponent, canActivate: [AuthGuard] },
  {path: 'overnight', component: OvernightComponent, canActivate: [AuthGuard] },



  // setup flows
  {path: 'businesslist', component: BusinessSetupComponent},
  {path: 'grouplist', component: GroupSetupComponent},

  // work in progress
  // Camera Component for Dev Env
  { path: 'camera', component: CameraComponent, },

  { path: 'login', component: LoginComponent, },
];


@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
