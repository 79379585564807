import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { DatabaseService } from '../services/database.service';
import { BehaviorSubject } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

export interface GroupDetail {
  vehicleEnteredCount: number;
  vehicleExitedCount: number;
  ticketEntryCollection: number;
  ticketExitCollection: number;
  ticketDeletedCount: number;
  ticketDeletedCollection: number;
  passCount: number;
  passAmount: number;
  dayPassCount: number;
  dayPassAmount: number;
  timeStamp: string;
  currentlyActive: number;
}

@Component({
  selector: 'app-group-summary',
  templateUrl: './group-summary.component.html',
  styleUrls: ['./group-summary.component.css']
})
export class GroupSummaryComponent implements OnInit {

  title = 'Group Summary';
  db: AngularFirestore;
  groupID: string;
  public isCollapsed = true;
  private groupSummaryDoc: AngularFirestoreDocument<GroupDetail>;

  groupSummary: Observable<GroupDetail>;
  model: NgbDateStruct;
  dateFilter: BehaviorSubject<string | null>;

  constructor(private databaseService: DatabaseService, db: AngularFirestore) {
    this.db = db;
    const date = new Date();
    this.model = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
    const StrToday = this.setDateFormat(this.model);
    this.dateFilter = new BehaviorSubject(StrToday);
    this.setGroupID();
  }

  ngOnInit() {
    // this.loadData();
  }

  // loadData() {
  //   this.groupSummaryDoc = this.db.doc<GroupDetail>('/reports/' + this.groupID + '/daily_summary/' + this.dateFilter.value);
  //   this.groupSummary = this.groupSummaryDoc.valueChanges();
  // }


  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }

  setDateFormat(model) {
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    const StrToday = Strdd + '-' + Strmm + '-' + yyyy;
    return StrToday;
  }

  filterByDate(model) {
    const StrToday = this.setDateFormat(model);
    this.dateFilter.next(StrToday);
    // this.loadData();
  }

}
