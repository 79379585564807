import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, combineLatest } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
// tslint:disable-next-line:import-blacklist
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { DatabaseService } from '../services/database.service';
import { DownloadService } from '../services/download.service';
import { Vehicle } from '../vehicles/vehicle.model';
import { map } from 'rxjs/operators';

interface Reports {
  a2EntryTime: number;
}

@Component({
  selector: 'app-hourly-report',
  templateUrl: './hourly-report.component.html',
  styleUrls: ['./hourly-report.component.css']
})
export class HourlyReportComponent implements OnInit {
  title = 'Hourly Vehicle Report';
  zeroToone = 0;
  oneTotwo = 0;
  twoTothree = 0;
  threeTofour = 0;
  fourTofive = 0;
  fiveTosix = 0;
  sixToseven = 0;
  sevenToeight = 0;
  eightTonine = 0;
  nineToten = 0;
  tenToeleven = 0;
  elevenTotwelve = 0;
  twelveTothirteen = 0;
  thirteenTofourteen = 0;
  fourteenTofifteen = 0;
  fifteenTosixteen = 0;
  sixteenToseventeen = 0;
  seventeenToeighteen = 0;
  eighteenTonineteen = 0;
  nineteenTotwenty = 0;
  twentyTotwentyone = 0;
  twentyoneTotwentytwo = 0;
  twentytwoTotwentythree = 0;
  twentythreeTotwentyfour = 0;
  twentyfourtozero = 0;
  total = 0;
  total1= 0;
  twoWheelerIn = [];
  threeWheelerIn = [];
  fourWheelerIn = [];
  cvIn = [];
  cycIn = [];
  cartIn = [];
  twoWheelerOut = [];
  threeWheelerOut = [];
  fourWheelerOut = [];
  cvOut = [];
  cycOut = [];
  cartOut = [];
  vehiclesReportSubs: Subscription;
  private vehicles: AngularFirestoreCollection<Vehicle>;
  vehicles2: Observable<Vehicle[]>;
  vehicles1: Observable<Vehicle[]>;
  db: AngularFirestore;
  businessName: string;
  collectionReportSubs: Subscription;
  private collection: AngularFirestoreCollection<Vehicle>;
  collect: Observable<Vehicle[]>;
  collectionReportSubs1: Subscription;
  private collection1: AngularFirestoreCollection<Vehicle>;
  collect1: Observable<Vehicle[]>;
  groupID: string;
  selectedValue: string;
  progressBarVisible = false;
  rearrangeVehicleReport: Reports[] = [];
  rearrangeVehicleReport1: Reports[] = [];
  model: NgbDateStruct;
  data: Vehicle[] = [];
  data1: Vehicle[] = [];
  ht: any[];
  businessList: any[];
  businessID: string;
  dateFilter: BehaviorSubject<string | null>;
  StrMMYYYY: String;
  StrYYYY: string;
  StrMM: string;
  StrDate: string;

  fromEpochDay: any;
  epochDay: number;
  dateFrom: BehaviorSubject<number | null>;
  selectedDate: Date;


  headerData: string[] = [
    'Hours', 'Total' , '2W IN Count', '4W IN Count', '2W OUT Count', '4W OUT Count', ];

  constructor(db: AngularFirestore, public databaseService: DatabaseService, private downloadService: DownloadService) {
    this.db = db;
    const date = new Date();
    this.model = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
    const StrToday = this.setDateFormat(this.model);
    this.dateFilter = new BehaviorSubject(StrToday);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    this.StrYYYY = this.setCalenderYear(this.model);
    this.StrMM = this.setCalenderMonth(this.model);
    this.dateFrom = new BehaviorSubject(new Date().getTime());
    this.selectedDate = this.getTimeStamp(this.model);
    this.setGroupID();
    this.StrDate = this.setCalenderdate(this.model);
    this.setBusinessDropDownList();
  }

  ngOnInit() {
    this.getReportData();
    // this.hourlyListEntry();
    // this.hourlyListExit();
  }

  getReportData(){
    let today = new Date(this.selectedDate);
   // console.log('Date:', today, this.selectedDate);
    today.setHours(15);
    let todayEpochDay = Math.floor((today.getTime() + 330 * 60 * 1000) / (1000 * 60 * 60 * 24));

    this.resetCounter();
    this.resetCounterOut();

    this.vehicles2 = this.db.collectionGroup<Vehicle>('records', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('vrStartEpochDay', '==', todayEpochDay).where('vrBusinessId', '==', this.businessID);
      return query;
    }).valueChanges();

    this.vehicles1 = this.db.collectionGroup<Vehicle>('records', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('vrEndEpochDay', '==', todayEpochDay).where('vrBusinessId', '==', this.businessID);
      return query;
    }).valueChanges();

    const tempArray = [this.vehicles2, this.vehicles1];

    const allVehicles = combineLatest(tempArray)
      .pipe(
        map(adArray => adArray.reduce((acc, curr) =>
          [...acc, ...curr], []))
      );

    this.vehiclesReportSubs = allVehicles.subscribe(
      (response) => {
        this.resetCounter();
        this.resetCounterOut();
        const uniqHashMap = {};
        for (const reportItem of response) {
          if (reportItem.vrFirebaseId in uniqHashMap) continue;
          uniqHashMap[reportItem.vrFirebaseId] = true;
          if(reportItem.vrStartEpochDay <0 || reportItem.vrStartEpochDay == todayEpochDay){

            const enhour = new Date(reportItem.vrParkingStartTimeL).getHours();
          //console.log('hour:', enhour);
          switch (enhour) {
            case 0: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[0] = this.twoWheelerIn[0] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[0] = this.threeWheelerIn[0] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[0] = this.fourWheelerIn[0] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[0] = this.cvIn[0] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[0] = this.cycIn[0] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[0] = this.cartIn[0] + 1;
              }
              this.zeroToone++;
              break;
            }
            case 1: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[1] = this.twoWheelerIn[1] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[1] = this.threeWheelerIn[1] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[1] = this.fourWheelerIn[1] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[1] = this.cvIn[1] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[1] = this.cycIn[1] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[1] = this.cartIn[1] + 1;
              }
              this.oneTotwo++;
              break;
            }
            case 2: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[2] = this.twoWheelerIn[2] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[2] = this.threeWheelerIn[2] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[2] = this.fourWheelerIn[2] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[2] = this.cvIn[2] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[2] = this.cycIn[2] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[2] = this.cartIn[2] + 1;
              }
              this.twoTothree++;
              break;
            }
            case 3: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[3] = this.twoWheelerIn[3] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[3] = this.threeWheelerIn[3] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[3] = this.fourWheelerIn[3] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[3] = this.cvIn[3] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[3] = this.cycIn[3] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[3] = this.cartIn[3] + 1;
              }
              this.threeTofour++;
              break;
            }
            case 4: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[4] = this.twoWheelerIn[4] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[4] = this.threeWheelerIn[4] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[4] = this.fourWheelerIn[4] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[4] = this.cvIn[4] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[4] = this.cycIn[4] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[4] = this.cartIn[4] + 1;
              }
              this.fourTofive++;
              break;
            }
            case 5: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[5] = this.twoWheelerIn[5] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[5] = this.threeWheelerIn[5] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[5] = this.fourWheelerIn[5] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[5] = this.cvIn[5] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[5] = this.cycIn[5] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[5] = this.cartIn[5] + 1;
              }
              this.fiveTosix++;
              break;
            }
            case 6: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[6] = this.twoWheelerIn[6] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[6] = this.threeWheelerIn[6] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[6] = this.fourWheelerIn[6] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[6] = this.cvIn[6] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[6] = this.cycIn[6] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[6] = this.cartIn[6] + 1;
              }
              this.sixToseven++;
              break;
            }
            case 7: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[7] = this.twoWheelerIn[7] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[7] = this.threeWheelerIn[7] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[7] = this.fourWheelerIn[7] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[7] = this.cvIn[7] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[7] = this.cycIn[7] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[7] = this.cartIn[7] + 1;
              }
              this.sevenToeight++;
              break;
            }
            case 8: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[8] = this.twoWheelerIn[8] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[8] = this.threeWheelerIn[8] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[8] = this.fourWheelerIn[8] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[8] = this.cvIn[8] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[8] = this.cycIn[8] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[8] = this.cartIn[8] + 1;
              }
              this.eightTonine++;
              break;
            }
            case 9: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[9] = this.twoWheelerIn[9] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[9] = this.threeWheelerIn[9] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[9] = this.fourWheelerIn[9] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[9] = this.cvIn[9] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[9] = this.cycIn[9] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[9] = this.cartIn[9] + 1;
              }
              this.nineToten++;
              break;
            }
            case 10: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[10] = this.twoWheelerIn[10] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[10] = this.threeWheelerIn[10] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[10] = this.fourWheelerIn[10] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[10] = this.cvIn[10] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[10] = this.cycIn[10] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[10] = this.cartIn[10] + 1;
              }
              this.tenToeleven++;
              break;
            }
            case 11: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[11] = this.twoWheelerIn[11] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[11] = this.threeWheelerIn[11] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[11] = this.fourWheelerIn[11] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[11] = this.cvIn[11] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[11] = this.cycIn[11] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[11] = this.cartIn[11] + 1;
              }
              this.elevenTotwelve++;
              break;
            }
            case 12: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[12] = this.twoWheelerIn[12] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[12] = this.threeWheelerIn[12] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[12] = this.fourWheelerIn[12] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[12] = this.cvIn[12] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[12] = this.cycIn[12] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[12] = this.cartIn[12] + 1;
              }
              this.twelveTothirteen++;
              break;
            }
            case 13: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[13] = this.twoWheelerIn[13] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[13] = this.threeWheelerIn[13] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[13] = this.fourWheelerIn[13] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[13] = this.cvIn[13] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[13] = this.cycIn[13] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[13] = this.cartIn[13] + 1;
              }
              this.thirteenTofourteen++;
              break;
            }
            case 14: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[14] = this.twoWheelerIn[14] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[14] = this.threeWheelerIn[14] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[14] = this.fourWheelerIn[14] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[14] = this.cvIn[14] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[14] = this.cycIn[14] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[14] = this.cartIn[14] + 1;
              }
              this.fourteenTofifteen++;
              break;
            }
            case 15: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[15] = this.twoWheelerIn[15] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[15] = this.threeWheelerIn[15] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[15] = this.fourWheelerIn[15] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[15] = this.cvIn[15] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[15] = this.cycIn[15] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[15] = this.cartIn[15] + 1;
              }
              this.fifteenTosixteen++;
              break;
            }
            case 16: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[16] = this.twoWheelerIn[16] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[16] = this.threeWheelerIn[16] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[16] = this.fourWheelerIn[16] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[16] = this.cvIn[16] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[16] = this.cycIn[16] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[16] = this.cartIn[16] + 1;
              }
              // console.log(reportItem);
              this.sixteenToseventeen++;
              break;
            }
            case 17: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[17] = this.twoWheelerIn[17] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[17] = this.threeWheelerIn[17] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[17] = this.fourWheelerIn[17] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[17] = this.cvIn[17] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[17] = this.cycIn[17] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[17] = this.cartIn[17] + 1;
              }
              // console.log(reportItem);
              this.seventeenToeighteen++;
              break;
            }
            case 18: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[18] = this.twoWheelerIn[18] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[18] = this.threeWheelerIn[18] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[18] = this.fourWheelerIn[18] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[18] = this.cvIn[18] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[18] = this.cycIn[18] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[18] = this.cartIn[18] + 1;
              }
              this.eighteenTonineteen++;
              break;
            }
            case 19: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[19] = this.twoWheelerIn[19] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[19] = this.threeWheelerIn[19] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[19] = this.fourWheelerIn[19] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[19] = this.cvIn[19] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[19] = this.cycIn[19] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[19] = this.cartIn[19] + 1;
              }
              this.nineteenTotwenty++;
              break;
            }
            case 20: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[20] = this.twoWheelerIn[20] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[20] = this.threeWheelerIn[20] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[20] = this.fourWheelerIn[20] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[20] = this.cvIn[20] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[20] = this.cycIn[20] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[20] = this.cartIn[20] + 1;
              }
              this.twentyTotwentyone++;
              break;
            }
            case 21: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[21] = this.twoWheelerIn[21] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[21] = this.threeWheelerIn[21] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[21] = this.fourWheelerIn[21] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[21] = this.cvIn[21] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[21] = this.cycIn[21] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[21] = this.cartIn[21] + 1;
              }
              // console.log('check 21', reportItem);
              this.twentyoneTotwentytwo++;
              break;
            }
            case 22: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[22] = this.twoWheelerIn[22] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[22] = this.threeWheelerIn[22] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[22] = this.fourWheelerIn[22] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[22] = this.cvIn[22] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[22] = this.cycIn[22] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[22] = this.cartIn[22] + 1;
              }
              this.twentytwoTotwentythree++;
              break;
            }
            case 23: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[23] = this.twoWheelerIn[23] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[23] = this.threeWheelerIn[23] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[23] = this.fourWheelerIn[23] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[23] = this.cvIn[23] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[23] = this.cycIn[23] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[23] = this.cartIn[23] + 1;
              }
              this.twentythreeTotwentyfour++;
              break;
            }
            case 24: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[24] = this.twoWheelerIn[24] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[24] = this.threeWheelerIn[24] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[24] = this.fourWheelerIn[24] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[24] = this.cvIn[24] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[24] = this.cycIn[24] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[24] = this.cartIn[24] + 1;
              }
              this.twentyfourtozero++;
              break;
            }
            default: {
              break;
            }
          }

          }
          if(reportItem.vrEndEpochDay == todayEpochDay){
            const hourOut = new Date(reportItem.vrParkingEndTimeL).getHours();
            //  console.log('hour:', hourOut);
              // Switch Case Starts
                  switch (hourOut) {
              case 0: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[0] = this.twoWheelerOut[0] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.twoWheelerOut[0] = this.twoWheelerOut[0] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[0] = this.fourWheelerOut[0] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[0] = this.cvOut[0] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[0] = this.cycOut[0] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[0] = this.cartOut[0] + 1;
                }
                // this.zeroToone++;
                break;
              }
              case 1: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[1] = this.twoWheelerOut[1] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[1] = this.threeWheelerOut[1] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[1] = this.fourWheelerOut[1] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[1] = this.cvOut[1] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[1] = this.cycOut[1] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[1] = this.cartOut[1] + 1;
                }
                // this.oneTotwo++;
                break;
              }
              case 2: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[2] = this.twoWheelerOut[2] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[2] = this.threeWheelerOut[2] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[2] = this.fourWheelerOut[2] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[2] = this.cvOut[2] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[2] = this.cycOut[2] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[2] = this.cartOut[2] + 1;
                }
                // this.twoTothree++;
                break;
              }
              case 3: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[3] = this.twoWheelerOut[3] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[3] = this.threeWheelerOut[3] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[3] = this.fourWheelerOut[3] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[3] = this.cvOut[3] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[3] = this.cycOut[3] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[3] = this.cartOut[3] + 1;
                }
                // this.threeTofour++;
                break;
              }
              case 4: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[4] = this.twoWheelerOut[4] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[4] = this.threeWheelerOut[4] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[4] = this.fourWheelerOut[4] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[4] = this.cvOut[4] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[4] = this.cycOut[4] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[4] = this.cartOut[4] + 1;
                }
                // this.fourTofive++;
                break;
              }
              case 5: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[5] = this.twoWheelerOut[5] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[5] = this.threeWheelerOut[5] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[5] = this.fourWheelerOut[5] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[5] = this.cvOut[5] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[5] = this.cycOut[5] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[5] = this.cartOut[5] + 1;
                }
                // this.fiveTosix++;
                break;
              }
              case 6: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[6] = this.twoWheelerOut[6] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[6] = this.threeWheelerOut[6] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[6] = this.fourWheelerOut[6] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[6] = this.cvOut[6] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[6] = this.cycOut[6] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[6] = this.cartOut[6] + 1;
                }
                // this.sixToseven++;
                break;
              }
              case 7: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[7] = this.twoWheelerOut[7] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[7] = this.threeWheelerOut[7] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[7] = this.fourWheelerOut[7] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[7] = this.cvOut[7] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[7] = this.cycOut[7] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[7] = this.cartOut[7] + 1;
                }
                // this.sevenToeight++;
                break;
              }
              case 8: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[8] = this.twoWheelerOut[8] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[8] = this.threeWheelerOut[8] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[8] = this.fourWheelerOut[8] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[8] = this.cvOut[8] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[8] = this.cycOut[8] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[8] = this.cartOut[8] + 1;
                }
                // this.eightTonine++;
                break;
              }
              case 9: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[9] = this.twoWheelerOut[9] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[9] = this.threeWheelerOut[9] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[9] = this.fourWheelerOut[9] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[9] = this.cvOut[9] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[9] = this.cycOut[9] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[9] = this.cartOut[9] + 1;
                }
                // this.nineToten++;
                break;
              }
              case 10: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[10] = this.twoWheelerOut[10] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[10] = this.threeWheelerOut[10] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[10] = this.fourWheelerOut[10] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[10] = this.cvOut[10] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[10] = this.cycOut[10] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[10] = this.cartOut[10] + 1;
                }
                // this.tenToeleven++;
                break;
              }
              case 11: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[11] = this.twoWheelerOut[11] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[11] = this.threeWheelerOut[11] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[11] = this.fourWheelerOut[11] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[11] = this.cvOut[11] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[11] = this.cycOut[11] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[11] = this.cartOut[11] + 1;
                }
                // this.elevenTotwelve++;
                break;
              }
              case 12: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[12] = this.twoWheelerOut[12] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[12] = this.threeWheelerOut[12] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[12] = this.fourWheelerOut[12] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[12] = this.cvOut[12] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[12] = this.cycOut[12] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[12] = this.cartOut[12] + 1;
                }
                // this.twelveTothirteen++;
                break;
              }
              case 13: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[13] = this.twoWheelerOut[13] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[13] = this.threeWheelerOut[13] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[13] = this.fourWheelerOut[13] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[13] = this.cvOut[13] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[13] = this.cycOut[13] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[13] = this.cartOut[13] + 1;
                }
                // this.thirteenTofourteen++;
                break;
              }
              case 14: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[14] = this.twoWheelerOut[14] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[14] = this.threeWheelerOut[14] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[14] = this.fourWheelerOut[14] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[14] = this.cvOut[14] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[14] = this.cycOut[14] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[14] = this.cartOut[14] + 1;
                }
                // this.fourteenTofifteen++;
                break;
              }
              case 15: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[15] = this.twoWheelerOut[15] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[15] = this.threeWheelerOut[15] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[15] = this.fourWheelerOut[15] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[15] = this.cvOut[15] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[15] = this.cycOut[15] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[15] = this.cartOut[15] + 1;
                }
                // this.fifteenTosixteen++;
                break;
              }
              case 16: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[16] = this.twoWheelerOut[16] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[16] = this.threeWheelerOut[16] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[16] = this.fourWheelerOut[16] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[16] = this.cvOut[16] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[16] = this.cycOut[16] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[16] = this.cartOut[16] + 1;
                }
                // this.sixteenToseventeen++;
                break;
              }
              case 17: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[17] = this.twoWheelerOut[17] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[17] = this.threeWheelerOut[17] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[17] = this.fourWheelerOut[17] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[17] = this.cvOut[17] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[17] = this.cycOut[17] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[17] = this.cartOut[17] + 1;
                }
                // this.seventeenToeighteen++;
                break;
              }
              case 18: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[18] = this.twoWheelerOut[18] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[18] = this.threeWheelerOut[18] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[18] = this.fourWheelerOut[18] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[18] = this.cvOut[18] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[18] = this.cycOut[18] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[18] = this.cartOut[18] + 1;
                }
                // this.eighteenTonineteen++;
                break;
              }
              case 19: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[19] = this.twoWheelerOut[19] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[19] = this.threeWheelerOut[19] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[19] = this.fourWheelerOut[19] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[19] = this.cvOut[19] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[19] = this.cycOut[19] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[19] = this.cartOut[19] + 1;
                }
                // this.nineteenTotwenty++;
                break;
              }
              case 20: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[20] = this.twoWheelerOut[20] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[20] = this.threeWheelerOut[20] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[20] = this.fourWheelerOut[20] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[20] = this.cvOut[20] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[20] = this.cycOut[20] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[20] = this.cartOut[20] + 1;
                }
                // this.twentyTotwentyone++;
                break;
              }
              case 21: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[21] = this.twoWheelerOut[21] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[21] = this.threeWheelerOut[21] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[21] = this.fourWheelerOut[21] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[21] = this.cvOut[21] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[21] = this.cycOut[21] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[21] = this.cartOut[21] + 1;
                }
                // this.twentyoneTotwentytwo++;
                break;
              }
              case 22: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[22] = this.twoWheelerOut[22] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[22] = this.threeWheelerOut[22] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[22] = this.fourWheelerOut[22] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[22] = this.cvOut[22] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[22] = this.cycOut[22] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[22] = this.cartOut[22] + 1;
                }
                // this.twentytwoTotwentythree++;
                break;
              }
              case 23: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[23] = this.twoWheelerOut[23] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[23] = this.threeWheelerOut[23] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[23] = this.fourWheelerOut[23] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[23] = this.cvOut[23] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[23] = this.cycOut[23] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[23] = this.cartOut[23] + 1;
                }
                // this.twentythreeTotwentyfour++;
                break;
              }
              case 24: {
                if (reportItem.vrVehicleType === '2W') {
                  this.twoWheelerOut[24] = this.twoWheelerOut[24] + 1;
                }
                if (reportItem.vrVehicleType === '3W') {
                  this.threeWheelerOut[24] = this.threeWheelerOut[24] + 1;
                }
                if (reportItem.vrVehicleType === '4W') {
                  this.fourWheelerOut[24] = this.fourWheelerOut[24] + 1;
                }
                if (reportItem.vrVehicleType === 'CV') {
                  this.cvOut[24] = this.cvOut[24] + 1;
                }
                if (reportItem.vrVehicleType === 'CYC') {
                  this.cycOut[24] = this.cycOut[24] + 1;
                }
                if (reportItem.vrVehicleType === 'CART') {
                  this.cartOut[24] = this.cartOut[24] + 1;
                }
                // this.twentythreeTotwentyfour++;
                break;
              }
              default: {
                break;
              }
            }
          }

        }
        console.log('Err');
      }
    );
  }

  resetCounter() {
    this.zeroToone = 0;
    this.oneTotwo = 0;
    this.twoTothree = 0;
    this.threeTofour = 0;
    this.fourTofive = 0;
    this.fiveTosix = 0;
    this.sixToseven = 0;
    this.sevenToeight = 0;
    this.eightTonine = 0;
    this.nineToten = 0;
    this.tenToeleven = 0;
    this.elevenTotwelve = 0;
    this.twelveTothirteen = 0;
    this.thirteenTofourteen = 0;
    this.fourteenTofifteen = 0;
    this.fifteenTosixteen = 0;
    this.sixteenToseventeen = 0;
    this.seventeenToeighteen = 0;
    this.eighteenTonineteen = 0;
    this.nineteenTotwenty = 0;
    this.twentyTotwentyone = 0;
    this.twentyoneTotwentytwo = 0;
    this.twentytwoTotwentythree = 0;
    this.twentythreeTotwentyfour = 0;
    this.twentyfourtozero = 0;
    this.total = 0;

    for (let i = 0; i <= 24; i++) {
      this.twoWheelerIn[i] = 0;
      this.threeWheelerIn[i] = 0;
      this.fourWheelerIn[i] = 0;
      this.cvIn[i] = 0;
      this.cycIn[i] = 0;
      this.cartIn[i] = 0;
    }

  }

  resetCounterOut() {
    this.zeroToone = 0;
    this.oneTotwo = 0;
    this.twoTothree = 0;
    this.threeTofour = 0;
    this.fourTofive = 0;
    this.fiveTosix = 0;
    this.sixToseven = 0;
    this.sevenToeight = 0;
    this.eightTonine = 0;
    this.nineToten = 0;
    this.tenToeleven = 0;
    this.elevenTotwelve = 0;
    this.twelveTothirteen = 0;
    this.thirteenTofourteen = 0;
    this.fourteenTofifteen = 0;
    this.fifteenTosixteen = 0;
    this.sixteenToseventeen = 0;
    this.seventeenToeighteen = 0;
    this.eighteenTonineteen = 0;
    this.nineteenTotwenty = 0;
    this.twentyTotwentyone = 0;
    this.twentyoneTotwentytwo = 0;
    this.twentytwoTotwentythree = 0;
    this.twentythreeTotwentyfour = 0;
    this.twentyfourtozero = 0;
    this.total1 = 0;

    for (let i = 0; i <= 24; i++) {
      this.twoWheelerOut[i] = 0;
      this.threeWheelerOut[i] = 0;
      this.fourWheelerOut[i] = 0;
      this.cvOut[i] = 0;
      this.cycOut[i] = 0;
      this.cartOut[i] = 0;
    }

  }
 // For getting total counts
  twoWheelerInCount() {
    let totalIn = 0;
    for (let i = 0; i <= 24; i++) {
      totalIn = totalIn + this.twoWheelerIn[i];
    }
    return totalIn;
  }

  threeWheelerInCount() {
    let totalIn = 0;
    for (let i = 0; i <= 24; i++) {
      totalIn = totalIn + this.threeWheelerIn[i];
    }
    return totalIn;
  }

  fourWheelerInCount() {
    let totalIn = 0;
    for (let i = 0; i <= 24; i++) {
      totalIn = totalIn + this.fourWheelerIn[i];
    }
    return totalIn;
  }

  cvInCount() {
    let totalIn = 0;
    for (let i = 0; i <= 24; i++) {
      totalIn = totalIn + this.cvIn[i];
    }
    return totalIn;
  }
  cycInCount() {
    let totalIn = 0;
    for (let i = 0; i <= 24; i++) {
      totalIn = totalIn + this.cycIn[i];
    }
    return totalIn;
  }
  cartInCount() {
    let totalIn = 0;
    for (let i = 0; i <= 24; i++) {
      totalIn = totalIn + this.cartIn[i];
    }
    return totalIn;
  }

  twoWheelerOutCount() {
    let totalOut = 0;
    for (let i = 0; i <= 24; i++) {
      totalOut = totalOut + this.twoWheelerOut[i];
    }
    return totalOut;
  }

  threeWheelerOutCount() {
    let totalOut = 0;
    for (let i = 0; i <= 24; i++) {
      totalOut = totalOut + this.threeWheelerOut[i];
    }
    return totalOut;
  }

  fourWheelerOutCount() {
    let totalOut = 0;
    for (let i = 0; i <= 24; i++) {
      totalOut = totalOut + this.fourWheelerOut[i];
    }
    return totalOut;
  }
  cvOutCount() {
    let totalOut = 0;
    for (let i = 0; i <= 24; i++) {
      totalOut = totalOut + this.cvOut[i];
    }
    return totalOut;
  }
  cycOutCount() {
    let totalOut = 0;
    for (let i = 0; i <= 24; i++) {
      totalOut = totalOut + this.cycOut[i];
    }
    return totalOut;
  }
  cartOutCount() {
    let totalOut = 0;
    for (let i = 0; i <= 24; i++) {
      totalOut = totalOut + this.cartOut[i];
    }
    return totalOut;
  }

  hourlyListEntry() {
    this.resetCounter();
    const today = new Date(this.selectedDate);
    today.setHours(15);
    const todayEpochDay = Math.floor((today.getTime() + 330 * 60 * 1000) / (1000 * 60 * 60 * 24));

    this.collect = this.db.collectionGroup<Vehicle>('records', ref => {

      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('vrStartEpochDay', '==', todayEpochDay).where('vrBusinessId','==',this.businessID);
      //  console.log('rererer:' + todayEpochDay);
      return query;
    }).valueChanges();

    this.collectionReportSubs = this.collect.subscribe(
      (response) => {
        this.resetCounter();
        this.data = response;
       // console.log('Dek' , response.length);
        this.rearrangeVehicleReport = [];
        for (let i = 0; i < this.data.length; i++) {
          const reportItem = this.data[i];
          const hour = new Date(reportItem.vrParkingStartTimeL).getHours();
        //  console.log('hour:', hour);
          switch (hour) {
            case 0: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[0] = this.twoWheelerIn[0] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[0] = this.threeWheelerIn[0] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[0] = this.fourWheelerIn[0] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[0] = this.cvIn[0] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[0] = this.cycIn[0] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[0] = this.cartIn[0] + 1;
              }
              this.zeroToone++;
              break;
            }
            case 1: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[1] = this.twoWheelerIn[1] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[1] = this.threeWheelerIn[1] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[1] = this.fourWheelerIn[1] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[1] = this.cvIn[1] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[1] = this.cycIn[1] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[1] = this.cartIn[1] + 1;
              }
              this.oneTotwo++;
              break;
            }
            case 2: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[2] = this.twoWheelerIn[2] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[2] = this.threeWheelerIn[2] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[2] = this.fourWheelerIn[2] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[2] = this.cvIn[2] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[2] = this.cycIn[2] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[2] = this.cartIn[2] + 1;
              }
              this.twoTothree++;
              break;
            }
            case 3: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[3] = this.twoWheelerIn[3] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[3] = this.threeWheelerIn[3] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[3] = this.fourWheelerIn[3] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[3] = this.cvIn[3] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[3] = this.cycIn[3] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[3] = this.cartIn[3] + 1;
              }
              this.threeTofour++;
              break;
            }
            case 4: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[4] = this.twoWheelerIn[4] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[4] = this.threeWheelerIn[4] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[4] = this.fourWheelerIn[4] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[4] = this.cvIn[4] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[4] = this.cycIn[4] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[4] = this.cartIn[4] + 1;
              }
              this.fourTofive++;
              break;
            }
            case 5: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[5] = this.twoWheelerIn[5] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[5] = this.threeWheelerIn[5] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[5] = this.fourWheelerIn[5] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[5] = this.cvIn[5] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[5] = this.cycIn[5] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[5] = this.cartIn[5] + 1;
              }
              this.fiveTosix++;
              break;
            }
            case 6: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[6] = this.twoWheelerIn[6] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[6] = this.threeWheelerIn[6] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[6] = this.fourWheelerIn[6] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[6] = this.cvIn[6] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[6] = this.cycIn[6] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[6] = this.cartIn[6] + 1;
              }
              this.sixToseven++;
              break;
            }
            case 7: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[7] = this.twoWheelerIn[7] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[7] = this.threeWheelerIn[7] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[7] = this.fourWheelerIn[7] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[7] = this.cvIn[7] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[7] = this.cycIn[7] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[7] = this.cartIn[7] + 1;
              }
              this.sevenToeight++;
              break;
            }
            case 8: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[8] = this.twoWheelerIn[8] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[8] = this.threeWheelerIn[8] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[8] = this.fourWheelerIn[8] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[8] = this.cvIn[8] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[8] = this.cycIn[8] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[8] = this.cartIn[8] + 1;
              }
              this.eightTonine++;
              break;
            }
            case 9: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[9] = this.twoWheelerIn[9] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[9] = this.threeWheelerIn[9] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[9] = this.fourWheelerIn[9] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[9] = this.cvIn[9] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[9] = this.cycIn[9] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[9] = this.cartIn[9] + 1;
              }
              this.nineToten++;
              break;
            }
            case 10: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[10] = this.twoWheelerIn[10] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[10] = this.threeWheelerIn[10] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[10] = this.fourWheelerIn[10] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[10] = this.cvIn[10] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[10] = this.cycIn[10] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[10] = this.cartIn[10] + 1;
              }
              this.tenToeleven++;
              break;
            }
            case 11: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[11] = this.twoWheelerIn[11] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[11] = this.threeWheelerIn[11] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[11] = this.fourWheelerIn[11] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[11] = this.cvIn[11] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[11] = this.cycIn[11] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[11] = this.cartIn[11] + 1;
              }
              this.elevenTotwelve++;
              break;
            }
            case 12: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[12] = this.twoWheelerIn[12] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[12] = this.threeWheelerIn[12] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[12] = this.fourWheelerIn[12] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[12] = this.cvIn[12] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[12] = this.cycIn[12] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[12] = this.cartIn[12] + 1;
              }
              this.twelveTothirteen++;
              break;
            }
            case 13: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[13] = this.twoWheelerIn[13] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[13] = this.threeWheelerIn[13] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[13] = this.fourWheelerIn[13] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[13] = this.cvIn[13] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[13] = this.cycIn[13] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[13] = this.cartIn[13] + 1;
              }
              this.thirteenTofourteen++;
              break;
            }
            case 14: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[14] = this.twoWheelerIn[14] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[14] = this.threeWheelerIn[14] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[14] = this.fourWheelerIn[14] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[14] = this.cvIn[14] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[14] = this.cycIn[14] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[14] = this.cartIn[14] + 1;
              }
              this.fourteenTofifteen++;
              break;
            }
            case 15: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[15] = this.twoWheelerIn[15] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[15] = this.threeWheelerIn[15] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[15] = this.fourWheelerIn[15] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[15] = this.cvIn[15] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[15] = this.cycIn[15] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[15] = this.cartIn[15] + 1;
              }
              this.fifteenTosixteen++;
              break;
            }
            case 16: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[16] = this.twoWheelerIn[16] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[16] = this.threeWheelerIn[16] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[16] = this.fourWheelerIn[16] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[16] = this.cvIn[16] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[16] = this.cycIn[16] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[16] = this.cartIn[16] + 1;
              }
              // console.log(reportItem);
              this.sixteenToseventeen++;
              break;
            }
            case 17: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[17] = this.twoWheelerIn[17] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[17] = this.threeWheelerIn[17] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[17] = this.fourWheelerIn[17] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[17] = this.cvIn[17] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[17] = this.cycIn[17] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[17] = this.cartIn[17] + 1;
              }
              // console.log(reportItem);
              this.seventeenToeighteen++;
              break;
            }
            case 18: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[18] = this.twoWheelerIn[18] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[18] = this.threeWheelerIn[18] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[18] = this.fourWheelerIn[18] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[18] = this.cvIn[18] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[18] = this.cycIn[18] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[18] = this.cartIn[18] + 1;
              }
              this.eighteenTonineteen++;
              break;
            }
            case 19: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[19] = this.twoWheelerIn[19] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[19] = this.threeWheelerIn[19] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[19] = this.fourWheelerIn[19] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[19] = this.cvIn[19] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[19] = this.cycIn[19] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[19] = this.cartIn[19] + 1;
              }
              this.nineteenTotwenty++;
              break;
            }
            case 20: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[20] = this.twoWheelerIn[20] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[20] = this.threeWheelerIn[20] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[20] = this.fourWheelerIn[20] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[20] = this.cvIn[20] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[20] = this.cycIn[20] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[20] = this.cartIn[20] + 1;
              }
              this.twentyTotwentyone++;
              break;
            }
            case 21: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[21] = this.twoWheelerIn[21] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[21] = this.threeWheelerIn[21] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[21] = this.fourWheelerIn[21] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[21] = this.cvIn[21] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[21] = this.cycIn[21] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[21] = this.cartIn[21] + 1;
              }
              // console.log('check 21', reportItem);
              this.twentyoneTotwentytwo++;
              break;
            }
            case 22: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[22] = this.twoWheelerIn[22] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[22] = this.threeWheelerIn[22] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[22] = this.fourWheelerIn[22] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[22] = this.cvIn[22] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[22] = this.cycIn[22] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[22] = this.cartIn[22] + 1;
              }
              this.twentytwoTotwentythree++;
              break;
            }
            case 23: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[23] = this.twoWheelerIn[23] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[23] = this.threeWheelerIn[23] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[23] = this.fourWheelerIn[23] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[23] = this.cvIn[23] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[23] = this.cycIn[23] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[23] = this.cartIn[23] + 1;
              }
              this.twentythreeTotwentyfour++;
              break;
            }
            case 24: {
              if (reportItem.vrVehicleType === '2W') {
                this.twoWheelerIn[24] = this.twoWheelerIn[24] + 1;
              }
              if (reportItem.vrVehicleType === '3W') {
                this.threeWheelerIn[24] = this.threeWheelerIn[24] + 1;
              }
              if (reportItem.vrVehicleType === '4W') {
                this.fourWheelerIn[24] = this.fourWheelerIn[24] + 1;
              }
              if (reportItem.vrVehicleType === 'CV') {
                this.cvIn[24] = this.cvIn[24] + 1;
              }
              if (reportItem.vrVehicleType === 'CYC') {
                this.cycIn[24] = this.cycIn[24] + 1;
              }
              if (reportItem.vrVehicleType === 'CART') {
                this.cartIn[24] = this.cartIn[24] + 1;
              }
              this.twentyfourtozero++;
              break;
            }
            default: {
              break;
            }
          }
        }
      },
      (error) => console.log(error)
    );
  }

  hourlyListExit(){
    this.resetCounterOut();
    const today = new Date(this.selectedDate);
    today.setHours(15);
    const todayEpochDay = Math.floor((today.getTime() + 330 * 60 * 1000) / (1000 * 60 * 60 * 24));

    this.collect1 = this.db.collectionGroup<Vehicle>('records', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('vrEndEpochDay', '==', todayEpochDay).where('vrBusinessId','==',this.businessID);
      //  console.log('ererererere:' + todayEpochDay);
      return query;
    }).valueChanges();
    this.collectionReportSubs1 = this.collect1.subscribe(
      (response) => {
        this.resetCounterOut();
        this.data1 = response;
        
      //  console.log('euserhw:' , response.length);
        this.rearrangeVehicleReport1 = [];
        for (let i = 0; i < this.data1.length; i++) {
          
          const reportItem1 = this.data1[i];
          console.log('Overnight2W:', reportItem1.vrStartEpochDay);
          const hourOut = new Date(reportItem1.vrParkingEndTimeL).getHours();
        //  console.log('hour:', hourOut);
          // Switch Case Starts
              switch (hourOut) {
          case 0: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[0] = this.twoWheelerOut[0] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.twoWheelerOut[0] = this.twoWheelerOut[0] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[0] = this.fourWheelerOut[0] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[0] = this.cvOut[0] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[0] = this.cycOut[0] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[0] = this.cartOut[0] + 1;
            }
            // this.zeroToone++;
            break;
          }
          case 1: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[1] = this.twoWheelerOut[1] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[1] = this.threeWheelerOut[1] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[1] = this.fourWheelerOut[1] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[1] = this.cvOut[1] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[1] = this.cycOut[1] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[1] = this.cartOut[1] + 1;
            }
            // this.oneTotwo++;
            break;
          }
          case 2: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[2] = this.twoWheelerOut[2] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[2] = this.threeWheelerOut[2] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[2] = this.fourWheelerOut[2] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[2] = this.cvOut[2] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[2] = this.cycOut[2] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[2] = this.cartOut[2] + 1;
            }
            // this.twoTothree++;
            break;
          }
          case 3: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[3] = this.twoWheelerOut[3] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[3] = this.threeWheelerOut[3] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[3] = this.fourWheelerOut[3] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[3] = this.cvOut[3] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[3] = this.cycOut[3] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[3] = this.cartOut[3] + 1;
            }
            // this.threeTofour++;
            break;
          }
          case 4: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[4] = this.twoWheelerOut[4] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[4] = this.threeWheelerOut[4] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[4] = this.fourWheelerOut[4] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[4] = this.cvOut[4] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[4] = this.cycOut[4] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[4] = this.cartOut[4] + 1;
            }
            // this.fourTofive++;
            break;
          }
          case 5: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[5] = this.twoWheelerOut[5] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[5] = this.threeWheelerOut[5] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[5] = this.fourWheelerOut[5] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[5] = this.cvOut[5] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[5] = this.cycOut[5] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[5] = this.cartOut[5] + 1;
            }
            // this.fiveTosix++;
            break;
          }
          case 6: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[6] = this.twoWheelerOut[6] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[6] = this.threeWheelerOut[6] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[6] = this.fourWheelerOut[6] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[6] = this.cvOut[6] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[6] = this.cycOut[6] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[6] = this.cartOut[6] + 1;
            }
            // this.sixToseven++;
            break;
          }
          case 7: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[7] = this.twoWheelerOut[7] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[7] = this.threeWheelerOut[7] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[7] = this.fourWheelerOut[7] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[7] = this.cvOut[7] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[7] = this.cycOut[7] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[7] = this.cartOut[7] + 1;
            }
            // this.sevenToeight++;
            break;
          }
          case 8: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[8] = this.twoWheelerOut[8] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[8] = this.threeWheelerOut[8] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[8] = this.fourWheelerOut[8] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[8] = this.cvOut[8] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[8] = this.cycOut[8] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[8] = this.cartOut[8] + 1;
            }
            // this.eightTonine++;
            break;
          }
          case 9: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[9] = this.twoWheelerOut[9] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[9] = this.threeWheelerOut[9] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[9] = this.fourWheelerOut[9] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[9] = this.cvOut[9] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[9] = this.cycOut[9] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[9] = this.cartOut[9] + 1;
            }
            // this.nineToten++;
            break;
          }
          case 10: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[10] = this.twoWheelerOut[10] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[10] = this.threeWheelerOut[10] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[10] = this.fourWheelerOut[10] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[10] = this.cvOut[10] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[10] = this.cycOut[10] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[10] = this.cartOut[10] + 1;
            }
            // this.tenToeleven++;
            break;
          }
          case 11: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[11] = this.twoWheelerOut[11] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[11] = this.threeWheelerOut[11] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[11] = this.fourWheelerOut[11] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[11] = this.cvOut[11] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[11] = this.cycOut[11] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[11] = this.cartOut[11] + 1;
            }
            // this.elevenTotwelve++;
            break;
          }
          case 12: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[12] = this.twoWheelerOut[12] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[12] = this.threeWheelerOut[12] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[12] = this.fourWheelerOut[12] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[12] = this.cvOut[12] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[12] = this.cycOut[12] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[12] = this.cartOut[12] + 1;
            }
            // this.twelveTothirteen++;
            break;
          }
          case 13: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[13] = this.twoWheelerOut[13] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[13] = this.threeWheelerOut[13] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[13] = this.fourWheelerOut[13] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[13] = this.cvOut[13] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[13] = this.cycOut[13] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[13] = this.cartOut[13] + 1;
            }
            // this.thirteenTofourteen++;
            break;
          }
          case 14: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[14] = this.twoWheelerOut[14] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[14] = this.threeWheelerOut[14] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[14] = this.fourWheelerOut[14] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[14] = this.cvOut[14] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[14] = this.cycOut[14] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[14] = this.cartOut[14] + 1;
            }
            // this.fourteenTofifteen++;
            break;
          }
          case 15: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[15] = this.twoWheelerOut[15] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[15] = this.threeWheelerOut[15] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[15] = this.fourWheelerOut[15] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[15] = this.cvOut[15] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[15] = this.cycOut[15] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[15] = this.cartOut[15] + 1;
            }
            // this.fifteenTosixteen++;
            break;
          }
          case 16: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[16] = this.twoWheelerOut[16] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[16] = this.threeWheelerOut[16] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[16] = this.fourWheelerOut[16] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[16] = this.cvOut[16] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[16] = this.cycOut[16] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[16] = this.cartOut[16] + 1;
            }
            // this.sixteenToseventeen++;
            break;
          }
          case 17: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[17] = this.twoWheelerOut[17] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[17] = this.threeWheelerOut[17] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[17] = this.fourWheelerOut[17] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[17] = this.cvOut[17] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[17] = this.cycOut[17] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[17] = this.cartOut[17] + 1;
            }
            // this.seventeenToeighteen++;
            break;
          }
          case 18: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[18] = this.twoWheelerOut[18] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[18] = this.threeWheelerOut[18] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[18] = this.fourWheelerOut[18] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[18] = this.cvOut[18] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[18] = this.cycOut[18] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[18] = this.cartOut[18] + 1;
            }
            // this.eighteenTonineteen++;
            break;
          }
          case 19: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[19] = this.twoWheelerOut[19] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[19] = this.threeWheelerOut[19] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[19] = this.fourWheelerOut[19] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[19] = this.cvOut[19] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[19] = this.cycOut[19] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[19] = this.cartOut[19] + 1;
            }
            // this.nineteenTotwenty++;
            break;
          }
          case 20: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[20] = this.twoWheelerOut[20] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[20] = this.threeWheelerOut[20] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[20] = this.fourWheelerOut[20] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[20] = this.cvOut[20] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[20] = this.cycOut[20] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[20] = this.cartOut[20] + 1;
            }
            // this.twentyTotwentyone++;
            break;
          }
          case 21: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[21] = this.twoWheelerOut[21] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[21] = this.threeWheelerOut[21] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[21] = this.fourWheelerOut[21] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[21] = this.cvOut[21] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[21] = this.cycOut[21] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[21] = this.cartOut[21] + 1;
            }
            // this.twentyoneTotwentytwo++;
            break;
          }
          case 22: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[22] = this.twoWheelerOut[22] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[22] = this.threeWheelerOut[22] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[22] = this.fourWheelerOut[22] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[22] = this.cvOut[22] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[22] = this.cycOut[22] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[22] = this.cartOut[22] + 1;
            }
            // this.twentytwoTotwentythree++;
            break;
          }
          case 23: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[23] = this.twoWheelerOut[23] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[23] = this.threeWheelerOut[23] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[23] = this.fourWheelerOut[23] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[23] = this.cvOut[23] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[23] = this.cycOut[23] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[23] = this.cartOut[23] + 1;
            }
            // this.twentythreeTotwentyfour++;
            break;
          }
          case 24: {
            if (reportItem1.vrVehicleType === '2W') {
              this.twoWheelerOut[24] = this.twoWheelerOut[24] + 1;
            }
            if (reportItem1.vrVehicleType === '3W') {
              this.threeWheelerOut[24] = this.threeWheelerOut[24] + 1;
            }
            if (reportItem1.vrVehicleType === '4W') {
              this.fourWheelerOut[24] = this.fourWheelerOut[24] + 1;
            }
            if (reportItem1.vrVehicleType === 'CV') {
              this.cvOut[24] = this.cvOut[24] + 1;
            }
            if (reportItem1.vrVehicleType === 'CYC') {
              this.cycOut[24] = this.cycOut[24] + 1;
            }
            if (reportItem1.vrVehicleType === 'CART') {
              this.cartOut[24] = this.cartOut[24] + 1;
            }
            // this.twentythreeTotwentyfour++;
            break;
          }
          default: {
            break;
          }
        }
      }
    },
    (error) => console.log(error)
  );
}
  setGroupID() {
    this.groupID = this.databaseService.getGroupID();
  }
  setBusinessDropDownList() {
    this.businessList = this.databaseService.getBusinessList();
    this.businessName = this.businessList[0].name;
    this.businessID = this.businessList[0].id;
  }

  setBusinessID(biz) {
    this.collectionReportSubs.unsubscribe();
    this.collectionReportSubs1.unsubscribe();
    this.businessName = biz.name;
    this.businessID = biz.id;
    this.getReportData();
    // this.hourlyListEntry();
    // this.hourlyListExit();
    this.resetCounter();
    this.resetCounterOut();
  }
  setDateFormat(model) {
    let Strdd = null;
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }

    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const yyyy = model.year;

    // const StrToday = Strdd + '-' + Strmm + '-' + yyyy;
    const StrToday = yyyy + Strmm;
    return StrToday;
  }
  setCalenderdate(model) {
    let Strdd = null;
    if (model.day < 10) {
      Strdd = '0' + model.day;
    } else {
      Strdd = model.day;
    }
    const StrDate = Strdd;
    return StrDate;
  }

  setCalenderMonthYear(model) {
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    const yyyy = model.year;

    const StrToday = Strmm + '-' + yyyy;
    return StrToday;
  }
  setCalenderYear(model) {
    const yyyy = model.year;
    return yyyy;
  }

  setCalenderMonth(model) {
    let Strmm = null;

    if ((model.month) < 10) {
      Strmm = '0' + (model.month);
    } else {
      Strmm = (model.month);
    }
    return Strmm;
  }

  getTimeStamp(model) {
    const date = new Date();
    date.setMonth(model.month - 1);
    date.setFullYear(model.year);
    date.setDate(model.day);
    return date;
  }

  filterByDate(model) {
    this.resetCounter();
    this.resetCounterOut();
    this.vehiclesReportSubs.unsubscribe();
    // this.collectionReportSubs.unsubscribe();
    // this.collectionReportSubs1.unsubscribe();
    const StrToday = this.setDateFormat(model);
    this.dateFilter.next(StrToday);
    // this.fromEpochDay = this.getEpochDayFromTimestamp(this.epochDay);
    this.StrMMYYYY = this.setCalenderMonthYear(this.model);
    this.StrYYYY = this.setCalenderYear(this.model);
    this.StrMM = this.setCalenderMonth(this.model);
    this.StrDate = this.setCalenderdate(this.model);
    this.selectedDate = this.getTimeStamp(this.model);
    this.getReportData();
    // this.hourlyListEntry();
    // this.hourlyListExit();
  }

  downloadExcelReport() {
    const reportLabel = this.title + ' ' + this.dateFilter.value + ' Report';
    this.downloadService.getExcelReport(this.rearrangeVehicleReport, reportLabel, this.headerData);
  }
  downloadPDFReport() {
    const reportLabel = this.title + ' Reports';
    // const reportLabel = this.businessName + ' ' + this.title + ' ' + new Date(this.dateFilterFrom.value).toLocaleDateString()
    //   + ' to ' + new Date(this.dateFilterTo.value).toLocaleDateString() + ' Report';

    const options = {
      styles: {
        fontSize: 10,
        font: 'helvetica', // helvetica, times, courier
      },
      startY: true, // false (indicates margin top value) or a number
      margin: { left: 1 },
      theme: 'striped',
      fillColor: false, // false for transparent or a color as described below
      textColor: 20,
      columnWidth: 'auto', // 'auto', 'wrap' or a number
      tableWidth: 'wrap', // 'auto', 'wrap' or a number,

    };

    this.downloadService.getPDFReport(this.rearrangeVehicleReport, reportLabel, this.headerData, options);
  }
}
