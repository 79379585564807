import { Injectable } from '@angular/core';

@Injectable()
export class DeviceEditService {

  imeiNo: string;
  InventoryCode: string;

  setDeviceEditServiceDetails(editImeiNo: string, editInventoryCode: string) {
    this.imeiNo = editImeiNo;
    this.InventoryCode = editInventoryCode;
  }

  getImeiNo() {
    return this.imeiNo;
  }

  getInventoryCode() {
    return this.InventoryCode;
  }

}
